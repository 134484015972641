<template>
  <b-modal id="purchase-modal" size="xl">
    <template #modal-header>
      <CloseButton modal-name="purchase-modal" />
    </template>
    <PurchaseFlow
      :show-plans="showPlans"
      :plans-new-page="plansNewPage"
      @start-free-download="$emit('start-free-download')"
    />
  </b-modal>
</template>

<script>
import CloseButton from 'UI/CloseButton';
import PurchaseFlow from '@/components/PurchaseFlow';

export default {
  components: {
    PurchaseFlow,
    CloseButton
  },
  props: {
    showPlans: {
      type: Boolean,
      default: false,
      required: false
    },
    plansNewPage: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>

<style lang="scss">
#purchase-modal {
  .modal-dialog {
    @include xl {
      max-width: 980px;
    }
    @include lg {
      max-width: 550px;
    }
  }
  .modal-header {
    border: none;
    padding: 0;
  }
  .modal-footer {
    display: none;
  }
  .close {
    outline: none;
    position: absolute;
    right: -20px;
    top: 15px;
    background-color: #ffffff40;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
    height: 30px;
    display: flex;
    text-align: center;
    align-items: center;
    color: white;
    opacity: 1;
    font-weight: 400;
    z-index: 100;
    @include md {
      right: 20px;
      top: 20px;
      background-color: #505050;
    }
  }
  .close:hover {
    background-color: #27272740;
    @include md {
      background-color: #969696;
    }
  }
}
</style>
