<template>
  <div class="item" :style="itemStyles(data)">
    <slot :data="{ ...data, borderRadius }"></slot>
    <figure class="vx-grid-figure" :class="{ 'placeholder-shimmer': !loadedImage }">
      <picture>
        <source :srcset="buildSrcWebP(data.image)" type="image/webp" />
        <source :srcset="data.image" :type="data.isSvg ? 'image/png' : 'image/jpeg'" />
        <img
          class="item-img"
          :src="data.image"
          :alt="data.name"
          :style="{
            borderRadius: borderRadius + 'px'
          }"
          loading="lazy"
          @load="removePlaceholder()"
        />
      </picture>
      <figcaption class="vx-grid-figcaption">
        {{ data.name }}
      </figcaption>
    </figure>
    <slot name="bottomBar" :data="{ ...data, borderRadius }"></slot>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    borderRadius: {
      type: Number,
      required: false,
      default: 4
    }
  },
  data() {
    return {
      marginTop: 5,
      marginBottom: 5,
      loadedImage: false
    };
  },
  methods: {
    itemStyles(item) {
      return {
        width: item.width + 'px',
        height: item.height + 'px',
        marginTop: this.marginTop + 'px',
        marginBottom: this.marginBottom + 'px',
        marginRight: item.marginRight + 'px',
        marginLeft: item.marginLeft + 'px'
      };
    },
    removePlaceholder() {
      this.loadedImage = true;
    },
    buildSrcWebP(link) {
      return link + '?fmt=webp&h=250';
    }
  }
};
</script>

<style lang="scss" scoped>
.vx-grid-figcaption {
  display: none;
}
.vx-grid-figure {
  background-color: $backgroundGray;
  border-radius: 7px !important;
}
.item-img {
  pointer-events: none;
}
.item {
  float: left;
  position: relative;
  img,
  figure {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img[lazy='loading'],
  img[lazy='error'] {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(
      to right,
      #f6f7f9 20%,
      #e9ebee 40%,
      #f6f7f9 60%,
      #f6f7f9 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 100%;
  }
}

.placeholder-shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 20%, #e9ebee 40%, #f6f7f9 60%, #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
