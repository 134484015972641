<template>
  <b-skeleton-wrapper :style="{ borderRadius }" :loading="loading">
    <template #default>
      <slot></slot>
    </template>
    <template #loading>
      <b-skeleton-img no-aspect></b-skeleton-img>
    </template>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  components: {},
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    borderRadius: {
      type: String,
      default: '15px'
    }
  }
};
</script>

<style lang="scss" scoped>
.b-skeleton-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
