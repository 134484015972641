export default (context, inject) => {
  inject('urlNameChecker', urlNameChecker(context));
  inject('urlNameGenerate', urlNameGenerate(context));
  inject('getGenericUrlName', getGenericUrlName);
};

const urlNameChecker = (context) => (routes) => {
  if (!Array.isArray(routes)) {
    routes = [routes];
  }
  const actualRoute = context.route.name ? context.route.name.toLowerCase() : '';
  var match = false;
  routes.forEach((route) => {
    if (!match) {
      match = actualRoute.startsWith(
        `${
          typeof route === 'object' && route !== null
            ? route.name.toLowerCase()
            : route.toLowerCase()
        }___`
      );
    }
  });
  return match;
};

const urlNameGenerate = (context) => (name) => {
  if (name.includes('___')) {
    name = name.split('___')[0];
  }
  return `${name}___${context.store.$i18n.locale}`;
};

const getGenericUrlName = (routeName) => {
  return routeName.split('___')[0];
};
