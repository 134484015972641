import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3bdda8ca = () => interopDefault(import('../pages/plans.vue' /* webpackChunkName: "pages/plans" */))
const _2a26929e = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _21938044 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _eb5fe722 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _aeaf7eb2 = () => interopDefault(import('../pages/choose-your-gift/index.vue' /* webpackChunkName: "pages/choose-your-gift/index" */))
const _13994b38 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _67af5f14 = () => interopDefault(import('../pages/purchase/index.vue' /* webpackChunkName: "pages/purchase/index" */))
const _1d8fe774 = () => interopDefault(import('../pages/enterprise/index.vue' /* webpackChunkName: "pages/enterprise/index" */))
const _a03428ba = () => interopDefault(import('../pages/crafts.vue' /* webpackChunkName: "pages/crafts" */))
const _3493b267 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _3cffc191 = () => interopDefault(import('../pages/design/index.vue' /* webpackChunkName: "pages/design/index" */))
const _5dfdef2e = () => interopDefault(import('../pages/design-plans/index.vue' /* webpackChunkName: "pages/design-plans/index" */))
const _7b29b49c = () => interopDefault(import('../pages/editables/index.vue' /* webpackChunkName: "pages/editables/index" */))
const _af920896 = () => interopDefault(import('../pages/styles/index.vue' /* webpackChunkName: "pages/styles/index" */))
const _43747704 = () => interopDefault(import('../pages/featured-vectors/index.vue' /* webpackChunkName: "pages/featured-vectors/index" */))
const _2a2fb1c2 = () => interopDefault(import('../pages/free-vectors/index.vue' /* webpackChunkName: "pages/free-vectors/index" */))
const _3f85201e = () => interopDefault(import('../pages/graphics/index.vue' /* webpackChunkName: "pages/graphics/index" */))
const _16a46d72 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _15cb7c8c = () => interopDefault(import('../pages/icon/index.vue' /* webpackChunkName: "pages/icon/index" */))
const _5091e736 = () => interopDefault(import('../pages/lifetime-plans.vue' /* webpackChunkName: "pages/lifetime-plans" */))
const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _31331fea = () => interopDefault(import('../pages/logos/index.vue' /* webpackChunkName: "pages/logos/index" */))
const _27dc5a3c = () => interopDefault(import('../pages/merch/index.vue' /* webpackChunkName: "pages/merch/index" */))
const _03500082 = () => interopDefault(import('../pages/merch-plans/index.vue' /* webpackChunkName: "pages/merch-plans/index" */))
const _eb675000 = () => interopDefault(import('../pages/new-vexels.vue' /* webpackChunkName: "pages/new-vexels" */))
const _40d4a7e1 = () => interopDefault(import('../pages/niches/index.vue' /* webpackChunkName: "pages/niches/index" */))
const _fff9ef82 = () => interopDefault(import('../pages/our-graphics/index.vue' /* webpackChunkName: "pages/our-graphics/index" */))
const _aacfd9de = () => interopDefault(import('../pages/payment-successful.vue' /* webpackChunkName: "pages/payment-successful" */))
const _ad7ae2ee = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _312f97f9 = () => interopDefault(import('../pages/profile/cancellation.vue' /* webpackChunkName: "pages/profile/cancellation" */))
const _47440c1f = () => interopDefault(import('../pages/profile/cancellation/canceled.vue' /* webpackChunkName: "pages/profile/cancellation/canceled" */))
const _2f0c1d0b = () => interopDefault(import('../pages/profile/cancellation/feedback.vue' /* webpackChunkName: "pages/profile/cancellation/feedback" */))
const _d7e29910 = () => interopDefault(import('../pages/profile/cancellation/reminder.vue' /* webpackChunkName: "pages/profile/cancellation/reminder" */))
const _b2c30088 = () => interopDefault(import('../pages/profile/cancellation/index.vue' /* webpackChunkName: "pages/profile/cancellation/index" */))
const _2e5bcfca = () => interopDefault(import('../pages/profile/cancellation/reason.vue' /* webpackChunkName: "pages/profile/cancellation/reason" */))
const _47a24a4c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _0aba15a7 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _8b8a1636 = () => interopDefault(import('../pages/profile/downloads.vue' /* webpackChunkName: "pages/profile/downloads" */))
const _46cc5216 = () => interopDefault(import('../pages/profile/billing.vue' /* webpackChunkName: "pages/profile/billing" */))
const _6f8491b8 = () => interopDefault(import('../pages/profile/billing/index.vue' /* webpackChunkName: "pages/profile/billing/index" */))
const _8e45674c = () => interopDefault(import('../pages/profile/billing/invoice-history.vue' /* webpackChunkName: "pages/profile/billing/invoice-history" */))
const _46dd0937 = () => interopDefault(import('../pages/profile/billing/payments.vue' /* webpackChunkName: "pages/profile/billing/payments" */))
const _f7e4ff5e = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _60043ed5 = () => interopDefault(import('../pages/profile/tools.vue' /* webpackChunkName: "pages/profile/tools" */))
const _26b99198 = () => interopDefault(import('../pages/profile/tools/index.vue' /* webpackChunkName: "pages/profile/tools/index" */))
const _3a404b88 = () => interopDefault(import('../pages/profile/tools/merch-digest.vue' /* webpackChunkName: "pages/profile/tools/merch-digest" */))
const _2e16d082 = () => interopDefault(import('../pages/profile/my-requests/index.vue' /* webpackChunkName: "pages/profile/my-requests/index" */))
const _3cb7c1f2 = () => interopDefault(import('../pages/profile/ai-merch-maker.vue' /* webpackChunkName: "pages/profile/ai-merch-maker" */))
const _20729066 = () => interopDefault(import('../pages/profile/buy-credits.vue' /* webpackChunkName: "pages/profile/buy-credits" */))
const _3b2ede6a = () => interopDefault(import('../pages/profile/buy-design-request-credits.vue' /* webpackChunkName: "pages/profile/buy-design-request-credits" */))
const _702888fc = () => interopDefault(import('../pages/profile/design-tools.vue' /* webpackChunkName: "pages/profile/design-tools" */))
const _40b4594a = () => interopDefault(import('../pages/profile/my-designs.vue' /* webpackChunkName: "pages/profile/my-designs" */))
const _52a7404d = () => interopDefault(import('../pages/profile/my-designs/index.vue' /* webpackChunkName: "pages/profile/my-designs/index" */))
const _431c0317 = () => interopDefault(import('../pages/profile/my-designs/designer-projects.vue' /* webpackChunkName: "pages/profile/my-designs/designer-projects" */))
const _1094e45c = () => interopDefault(import('../pages/profile/my-designs/favorites.vue' /* webpackChunkName: "pages/profile/my-designs/favorites" */))
const _97afb300 = () => interopDefault(import('../pages/profile/my-designs/my-requests.vue' /* webpackChunkName: "pages/profile/my-designs/my-requests" */))
const _08edf334 = () => interopDefault(import('../pages/profile/my-designs/my-tshirt-designs.vue' /* webpackChunkName: "pages/profile/my-designs/my-tshirt-designs" */))
const _3b7a42a8 = () => interopDefault(import('../pages/profile/my-merch-store.vue' /* webpackChunkName: "pages/profile/my-merch-store" */))
const _32984fa4 = () => interopDefault(import('../pages/profile/my-plan.vue' /* webpackChunkName: "pages/profile/my-plan" */))
const _930e3f60 = () => interopDefault(import('../pages/profile/my-requests/new.vue' /* webpackChunkName: "pages/profile/my-requests/new" */))
const _fcbacd38 = () => interopDefault(import('../pages/psd/index.vue' /* webpackChunkName: "pages/psd/index" */))
const _26460d52 = () => interopDefault(import('../pages/redeem-coupon.vue' /* webpackChunkName: "pages/redeem-coupon" */))
const _61d7d640 = () => interopDefault(import('../pages/spin-wheel.vue' /* webpackChunkName: "pages/spin-wheel" */))
const _656c0323 = () => interopDefault(import('../pages/svg-png/index.vue' /* webpackChunkName: "pages/svg-png/index" */))
const _7415383b = () => interopDefault(import('../pages/trending-vectors/index.vue' /* webpackChunkName: "pages/trending-vectors/index" */))
const _06715d12 = () => interopDefault(import('../pages/topics/index.vue' /* webpackChunkName: "pages/topics/index" */))
const _d55f9968 = () => interopDefault(import('../pages/design/featured-graphics/index.vue' /* webpackChunkName: "pages/design/featured-graphics/index" */))
const _78c798f5 = () => interopDefault(import('../pages/design/trending-graphics/index.vue' /* webpackChunkName: "pages/design/trending-graphics/index" */))
const _05aa9c9f = () => interopDefault(import('../pages/design/graphics/index.vue' /* webpackChunkName: "pages/design/graphics/index" */))
const _0ea863de = () => interopDefault(import('../pages/design/latest-vectors/index.vue' /* webpackChunkName: "pages/design/latest-vectors/index" */))
const _defd265a = () => interopDefault(import('../pages/editables/p/index.vue' /* webpackChunkName: "pages/editables/p/index" */))
const _195334e3 = () => interopDefault(import('../pages/free-vectors/p/index.vue' /* webpackChunkName: "pages/free-vectors/p/index" */))
const _2f067c3f = () => interopDefault(import('../pages/graphics/p/index.vue' /* webpackChunkName: "pages/graphics/p/index" */))
const _e5b115a6 = () => interopDefault(import('../pages/icon/p/index.vue' /* webpackChunkName: "pages/icon/p/index" */))
const _0bc050d9 = () => interopDefault(import('../pages/login/forgot.vue' /* webpackChunkName: "pages/login/forgot" */))
const _6ef5b02e = () => interopDefault(import('../pages/login/forgot-callback.vue' /* webpackChunkName: "pages/login/forgot-callback" */))
const _63d384ec = () => interopDefault(import('../pages/logos/p/index.vue' /* webpackChunkName: "pages/logos/p/index" */))
const _782a47c8 = () => interopDefault(import('../pages/merch/designs/index.vue' /* webpackChunkName: "pages/merch/designs/index" */))
const _759859a0 = () => interopDefault(import('../pages/merch/trending-designs/index.vue' /* webpackChunkName: "pages/merch/trending-designs/index" */))
const _d6d9dc0e = () => interopDefault(import('../pages/merch/featured-designs/index.vue' /* webpackChunkName: "pages/merch/featured-designs/index" */))
const _5ad009dc = () => interopDefault(import('../pages/merch/latest-designs/index.vue' /* webpackChunkName: "pages/merch/latest-designs/index" */))
const _299ce3fa = () => interopDefault(import('../pages/merch/p/index.vue' /* webpackChunkName: "pages/merch/p/index" */))
const _49fc6e7c = () => interopDefault(import('../pages/merch/png/index.vue' /* webpackChunkName: "pages/merch/png/index" */))
const _9c72ddd8 = () => interopDefault(import('../pages/merch/psd/index.vue' /* webpackChunkName: "pages/merch/psd/index" */))
const _646899ba = () => interopDefault(import('../pages/merch/vectors/index.vue' /* webpackChunkName: "pages/merch/vectors/index" */))
const _5faabc05 = () => interopDefault(import('../pages/psd/p/index.vue' /* webpackChunkName: "pages/psd/p/index" */))
const _76c0b204 = () => interopDefault(import('../pages/svg-png/p/index.vue' /* webpackChunkName: "pages/svg-png/p/index" */))
const _8619e1de = () => interopDefault(import('../pages/templates/mockup-generator.vue' /* webpackChunkName: "pages/templates/mockup-generator" */))
const _6a19c044 = () => interopDefault(import('../pages/templates/t-shirt-maker.vue' /* webpackChunkName: "pages/templates/t-shirt-maker" */))
const _01bedc80 = () => interopDefault(import('../pages/design/graphics/p/index.vue' /* webpackChunkName: "pages/design/graphics/p/index" */))
const _59ff3d2e = () => interopDefault(import('../pages/merch/designs/p/index.vue' /* webpackChunkName: "pages/merch/designs/p/index" */))
const _78e3ab1d = () => interopDefault(import('../pages/merch/png/p/index.vue' /* webpackChunkName: "pages/merch/png/p/index" */))
const _16af87b5 = () => interopDefault(import('../pages/merch/psd/p/index.vue' /* webpackChunkName: "pages/merch/psd/p/index" */))
const _45f46204 = () => interopDefault(import('../pages/merch/vectors/p/index.vue' /* webpackChunkName: "pages/merch/vectors/p/index" */))
const _b1ca7eee = () => interopDefault(import('../pages/design/featured-graphics/p/_page.vue' /* webpackChunkName: "pages/design/featured-graphics/p/_page" */))
const _eb61401c = () => interopDefault(import('../pages/design/trending-graphics/p/_page.vue' /* webpackChunkName: "pages/design/trending-graphics/p/_page" */))
const _6a80211c = () => interopDefault(import('../pages/design/graphics/p/_page.vue' /* webpackChunkName: "pages/design/graphics/p/_page" */))
const _2b29149b = () => interopDefault(import('../pages/design/latest-vectors/p/_page.vue' /* webpackChunkName: "pages/design/latest-vectors/p/_page" */))
const _6c95f76d = () => interopDefault(import('../pages/merch/trending-designs/p/_page.vue' /* webpackChunkName: "pages/merch/trending-designs/p/_page" */))
const _3dbeb014 = () => interopDefault(import('../pages/merch/featured-designs/p/_page.vue' /* webpackChunkName: "pages/merch/featured-designs/p/_page" */))
const _3bc1a605 = () => interopDefault(import('../pages/merch/designs/p/_page.vue' /* webpackChunkName: "pages/merch/designs/p/_page" */))
const _9ce0a262 = () => interopDefault(import('../pages/merch/latest-designs/p/_page.vue' /* webpackChunkName: "pages/merch/latest-designs/p/_page" */))
const _3cb6208e = () => interopDefault(import('../pages/merch/png/p/_page.vue' /* webpackChunkName: "pages/merch/png/p/_page" */))
const _7f70cc51 = () => interopDefault(import('../pages/merch/psd/p/_page.vue' /* webpackChunkName: "pages/merch/psd/p/_page" */))
const _a294b2c0 = () => interopDefault(import('../pages/merch/vectors/p/_page.vue' /* webpackChunkName: "pages/merch/vectors/p/_page" */))
const _0b539b52 = () => interopDefault(import('../pages/design/graphics/_search/index.vue' /* webpackChunkName: "pages/design/graphics/_search/index" */))
const _0d7a9d22 = () => interopDefault(import('../pages/editables/p/_page.vue' /* webpackChunkName: "pages/editables/p/_page" */))
const _6bee0cd4 = () => interopDefault(import('../pages/editables/preview/_id/index.vue' /* webpackChunkName: "pages/editables/preview/_id/index" */))
const _5d3cf641 = () => interopDefault(import('../pages/featured-vectors/p/_page.vue' /* webpackChunkName: "pages/featured-vectors/p/_page" */))
const _fbd70d02 = () => interopDefault(import('../pages/free-vectors/p/_page.vue' /* webpackChunkName: "pages/free-vectors/p/_page" */))
const _d0707e4a = () => interopDefault(import('../pages/graphics/p/_page.vue' /* webpackChunkName: "pages/graphics/p/_page" */))
const _142e8c6e = () => interopDefault(import('../pages/icon/p/_page.vue' /* webpackChunkName: "pages/icon/p/_page" */))
const _66d66cf0 = () => interopDefault(import('../pages/logos/p/_page.vue' /* webpackChunkName: "pages/logos/p/_page" */))
const _1a1eb700 = () => interopDefault(import('../pages/merch/designs/_search/index.vue' /* webpackChunkName: "pages/merch/designs/_search/index" */))
const _53f2d29f = () => interopDefault(import('../pages/merch/p/_page.vue' /* webpackChunkName: "pages/merch/p/_page" */))
const _3b5ad734 = () => interopDefault(import('../pages/merch/png/_search/index.vue' /* webpackChunkName: "pages/merch/png/_search/index" */))
const _5884e1cc = () => interopDefault(import('../pages/merch/psd/_search/index.vue' /* webpackChunkName: "pages/merch/psd/_search/index" */))
const _664a84db = () => interopDefault(import('../pages/merch/vectors/_search/index.vue' /* webpackChunkName: "pages/merch/vectors/_search/index" */))
const _9f27b688 = () => interopDefault(import('../pages/our-graphics/p/_page.vue' /* webpackChunkName: "pages/our-graphics/p/_page" */))
const _88bca9f2 = () => interopDefault(import('../pages/png-svg/preview/_id/index.vue' /* webpackChunkName: "pages/png-svg/preview/_id/index" */))
const _6f27febe = () => interopDefault(import('../pages/psd/p/_page.vue' /* webpackChunkName: "pages/psd/p/_page" */))
const _21c67248 = () => interopDefault(import('../pages/psd/preview/_id/index.vue' /* webpackChunkName: "pages/psd/preview/_id/index" */))
const _40fc12c0 = () => interopDefault(import('../pages/svg-png/p/_page.vue' /* webpackChunkName: "pages/svg-png/p/_page" */))
const _2e9b7490 = () => interopDefault(import('../pages/trending-vectors/p/_page.vue' /* webpackChunkName: "pages/trending-vectors/p/_page" */))
const _53b54dd7 = () => interopDefault(import('../pages/vectors/preview/_id/index.vue' /* webpackChunkName: "pages/vectors/preview/_id/index" */))
const _3ac5ae58 = () => interopDefault(import('../pages/design/graphics/_search/p/_page.vue' /* webpackChunkName: "pages/design/graphics/_search/p/_page" */))
const _1e63b4bd = () => interopDefault(import('../pages/merch/designs/_search/p/_page.vue' /* webpackChunkName: "pages/merch/designs/_search/p/_page" */))
const _15e7eb1e = () => interopDefault(import('../pages/merch/png/_search/p/_page.vue' /* webpackChunkName: "pages/merch/png/_search/p/_page" */))
const _6fddcf09 = () => interopDefault(import('../pages/merch/psd/_search/p/_page.vue' /* webpackChunkName: "pages/merch/psd/_search/p/_page" */))
const _22c6ea58 = () => interopDefault(import('../pages/merch/vectors/_search/p/_page.vue' /* webpackChunkName: "pages/merch/vectors/_search/p/_page" */))
const _08d62431 = () => interopDefault(import('../pages/editables/preview/_id/_name/index.vue' /* webpackChunkName: "pages/editables/preview/_id/_name/index" */))
const _1df3863c = () => interopDefault(import('../pages/png-svg/preview/_id/_name/index.vue' /* webpackChunkName: "pages/png-svg/preview/_id/_name/index" */))
const _daa2613a = () => interopDefault(import('../pages/psd/preview/_id/_name/index.vue' /* webpackChunkName: "pages/psd/preview/_id/_name/index" */))
const _c92ef89c = () => interopDefault(import('../pages/vectors/preview/_id/_name/index.vue' /* webpackChunkName: "pages/vectors/preview/_id/_name/index" */))
const _0d00493c = () => interopDefault(import('../pages/category/_id/index.vue' /* webpackChunkName: "pages/category/_id/index" */))
const _4923fc73 = () => interopDefault(import('../pages/collections/_slug/index.vue' /* webpackChunkName: "pages/collections/_slug/index" */))
const _aa4a6002 = () => interopDefault(import('../pages/purchase/_id/index.vue' /* webpackChunkName: "pages/purchase/_id/index" */))
const _6e59defe = () => interopDefault(import('../pages/design-plans/_keyword.vue' /* webpackChunkName: "pages/design-plans/_keyword" */))
const _d0dc0f2c = () => interopDefault(import('../pages/editables/_search/index.vue' /* webpackChunkName: "pages/editables/_search/index" */))
const _51289b30 = () => interopDefault(import('../pages/styles/_slug/index.vue' /* webpackChunkName: "pages/styles/_slug/index" */))
const _a75d170c = () => interopDefault(import('../pages/free-vectors/_search/index.vue' /* webpackChunkName: "pages/free-vectors/_search/index" */))
const _4cf0c6d6 = () => interopDefault(import('../pages/graphics/_search/index.vue' /* webpackChunkName: "pages/graphics/_search/index" */))
const _6afa5578 = () => interopDefault(import('../pages/icon/_search/index.vue' /* webpackChunkName: "pages/icon/_search/index" */))
const _35244c7a = () => interopDefault(import('../pages/logos/_search/index.vue' /* webpackChunkName: "pages/logos/_search/index" */))
const _81708e66 = () => interopDefault(import('../pages/merch-plans/_keyword.vue' /* webpackChunkName: "pages/merch-plans/_keyword" */))
const _0767765c = () => interopDefault(import('../pages/niches/_slug/index.vue' /* webpackChunkName: "pages/niches/_slug/index" */))
const _053b9a1c = () => interopDefault(import('../pages/psd/_search/index.vue' /* webpackChunkName: "pages/psd/_search/index" */))
const _0b2deb03 = () => interopDefault(import('../pages/purchase-plan/_id/index.vue' /* webpackChunkName: "pages/purchase-plan/_id/index" */))
const _7eca564a = () => interopDefault(import('../pages/svg-png/_search/index.vue' /* webpackChunkName: "pages/svg-png/_search/index" */))
const _fc43d11c = () => interopDefault(import('../pages/topics/_slug/index.vue' /* webpackChunkName: "pages/topics/_slug/index" */))
const _1fffb627 = () => interopDefault(import('../pages/editables/_search/p/_page.vue' /* webpackChunkName: "pages/editables/_search/p/_page" */))
const _02d06e37 = () => interopDefault(import('../pages/free-vectors/_search/p/_page.vue' /* webpackChunkName: "pages/free-vectors/_search/p/_page" */))
const _0e36ccda = () => interopDefault(import('../pages/graphics/_search/p/_page.vue' /* webpackChunkName: "pages/graphics/_search/p/_page" */))
const _5a2cc481 = () => interopDefault(import('../pages/icon/_search/p/_page.vue' /* webpackChunkName: "pages/icon/_search/p/_page" */))
const _6668a540 = () => interopDefault(import('../pages/logos/_search/p/_page.vue' /* webpackChunkName: "pages/logos/_search/p/_page" */))
const _6c70994e = () => interopDefault(import('../pages/psd/_search/p/_page.vue' /* webpackChunkName: "pages/psd/_search/p/_page" */))
const _2a433a58 = () => interopDefault(import('../pages/svg-png/_search/p/_page.vue' /* webpackChunkName: "pages/svg-png/_search/p/_page" */))
const _606d6777 = () => interopDefault(import('../pages/category/_id/_slug/index.vue' /* webpackChunkName: "pages/category/_id/_slug/index" */))
const _d70aff5c = () => interopDefault(import('../pages/collections/_slug/_page/index.vue' /* webpackChunkName: "pages/collections/_slug/_page/index" */))
const _9feb4562 = () => interopDefault(import('../pages/styles/_slug/_page/index.vue' /* webpackChunkName: "pages/styles/_slug/_page/index" */))
const _4c0b277b = () => interopDefault(import('../pages/niches/_slug/_page/index.vue' /* webpackChunkName: "pages/niches/_slug/_page/index" */))
const _8c279a68 = () => interopDefault(import('../pages/purchase-plan/_id/_keyword/index.vue' /* webpackChunkName: "pages/purchase-plan/_id/_keyword/index" */))
const _25e3bfde = () => interopDefault(import('../pages/topics/_slug/_page/index.vue' /* webpackChunkName: "pages/topics/_slug/_page/index" */))
const _1fbb95f4 = () => interopDefault(import('../pages/category/_id/_slug/p/_page.vue' /* webpackChunkName: "pages/category/_id/_slug/p/_page" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7068dbe1 = () => interopDefault(import('../pages/_uid.vue' /* webpackChunkName: "pages/_uid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/abonnements",
    component: _3bdda8ca,
    name: "plans___de"
  }, {
    path: "/about-us",
    component: _2a26929e,
    name: "about-us___en"
  }, {
    path: "/about-us",
    component: _2a26929e,
    name: "about-us___de"
  }, {
    path: "/allgemeine-eschaftsbedingungen",
    component: _21938044,
    name: "terms-and-conditions___de"
  }, {
    path: "/categorias",
    component: _eb5fe722,
    name: "categories___es"
  }, {
    path: "/categorias",
    component: _eb5fe722,
    name: "categories___pt"
  }, {
    path: "/categories",
    component: _eb5fe722,
    name: "categories___en"
  }, {
    path: "/choose-your-gift",
    component: _aeaf7eb2,
    name: "choose-your-gift___en"
  }, {
    path: "/choose-your-gift",
    component: _aeaf7eb2,
    name: "choose-your-gift___de"
  }, {
    path: "/choose-your-gift",
    component: _aeaf7eb2,
    name: "choose-your-gift___es"
  }, {
    path: "/choose-your-gift",
    component: _aeaf7eb2,
    name: "choose-your-gift___pt"
  }, {
    path: "/colecciones",
    component: _13994b38,
    name: "collections___es"
  }, {
    path: "/colecoes",
    component: _13994b38,
    name: "collections___pt"
  }, {
    path: "/collections",
    component: _13994b38,
    name: "collections___en"
  }, {
    path: "/compra",
    component: _67af5f14,
    name: "purchase___es"
  }, {
    path: "/compra",
    component: _67af5f14,
    name: "purchase___pt"
  }, {
    path: "/corporativa",
    component: _1d8fe774,
    name: "enterprise___es"
  }, {
    path: "/corporativo",
    component: _1d8fe774,
    name: "enterprise___pt"
  }, {
    path: "/crafts",
    component: _a03428ba,
    name: "crafts___en"
  }, {
    path: "/crafts",
    component: _a03428ba,
    name: "crafts___de"
  }, {
    path: "/crafts",
    component: _a03428ba,
    name: "crafts___es"
  }, {
    path: "/crafts",
    component: _a03428ba,
    name: "crafts___pt"
  }, {
    path: "/das-unternehmen",
    component: _1d8fe774,
    name: "enterprise___de"
  }, {
    path: "/datenschutzerklaerung",
    component: _3493b267,
    name: "privacy-policy___de"
  }, {
    path: "/design",
    component: _3cffc191,
    name: "design___en"
  }, {
    path: "/design",
    component: _3cffc191,
    name: "design___de"
  }, {
    path: "/design",
    component: _3cffc191,
    name: "design___es"
  }, {
    path: "/design",
    component: _3cffc191,
    name: "design___pt"
  }, {
    path: "/design-abos",
    component: _5dfdef2e,
    name: "design-plans___de"
  }, {
    path: "/design-plans",
    component: _5dfdef2e,
    name: "design-plans___en"
  }, {
    path: "/editables",
    component: _7b29b49c,
    name: "editables___en"
  }, {
    path: "/editables",
    component: _7b29b49c,
    name: "editables___de"
  }, {
    path: "/editables",
    component: _7b29b49c,
    name: "editables___es"
  }, {
    path: "/editables",
    component: _7b29b49c,
    name: "editables___pt"
  }, {
    path: "/enterprise",
    component: _1d8fe774,
    name: "enterprise___en"
  }, {
    path: "/estilo",
    component: _af920896,
    name: "styles___pt"
  }, {
    path: "/estilos",
    component: _af920896,
    name: "styles___es"
  }, {
    path: "/featured-vectors",
    component: _43747704,
    name: "featured-vectors___en"
  }, {
    path: "/featured-vectors",
    component: _43747704,
    name: "featured-vectors___de"
  }, {
    path: "/free-vectors",
    component: _2a2fb1c2,
    name: "free-vectors___en"
  }, {
    path: "/free-vectors",
    component: _2a2fb1c2,
    name: "free-vectors___de"
  }, {
    path: "/graphics",
    component: _3f85201e,
    name: "graphics___en"
  }, {
    path: "/graphics",
    component: _3f85201e,
    name: "graphics___de"
  }, {
    path: "/graphics",
    component: _3f85201e,
    name: "graphics___es"
  }, {
    path: "/graphics",
    component: _3f85201e,
    name: "graphics___pt"
  }, {
    path: "/home",
    component: _16a46d72,
    name: "home___en"
  }, {
    path: "/home",
    component: _16a46d72,
    name: "home___de"
  }, {
    path: "/home",
    component: _16a46d72,
    name: "home___es"
  }, {
    path: "/home",
    component: _16a46d72,
    name: "home___pt"
  }, {
    path: "/icon",
    component: _15cb7c8c,
    name: "icon___en"
  }, {
    path: "/icon",
    component: _15cb7c8c,
    name: "icon___de"
  }, {
    path: "/icon",
    component: _15cb7c8c,
    name: "icon___es"
  }, {
    path: "/icon",
    component: _15cb7c8c,
    name: "icon___pt"
  }, {
    path: "/kategorien",
    component: _eb5fe722,
    name: "categories___de"
  }, {
    path: "/kauf",
    component: _67af5f14,
    name: "purchase___de"
  }, {
    path: "/lifetime-plans",
    component: _5091e736,
    name: "lifetime-plans___en"
  }, {
    path: "/lifetime-plans",
    component: _5091e736,
    name: "lifetime-plans___de"
  }, {
    path: "/lifetime-plans",
    component: _5091e736,
    name: "lifetime-plans___es"
  }, {
    path: "/lifetime-plans",
    component: _5091e736,
    name: "lifetime-plans___pt"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login___en"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login___de"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login___es"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login___pt"
  }, {
    path: "/logos",
    component: _31331fea,
    name: "logos___en"
  }, {
    path: "/logos",
    component: _31331fea,
    name: "logos___de"
  }, {
    path: "/logos",
    component: _31331fea,
    name: "logos___es"
  }, {
    path: "/logos",
    component: _31331fea,
    name: "logos___pt"
  }, {
    path: "/merch",
    component: _27dc5a3c,
    name: "merch___en"
  }, {
    path: "/merch",
    component: _27dc5a3c,
    name: "merch___de"
  }, {
    path: "/merch",
    component: _27dc5a3c,
    name: "merch___es"
  }, {
    path: "/merch",
    component: _27dc5a3c,
    name: "merch___pt"
  }, {
    path: "/merch-plans",
    component: _03500082,
    name: "merch-plans___en"
  }, {
    path: "/merch-plans",
    component: _03500082,
    name: "merch-plans___de"
  }, {
    path: "/merch-plans",
    component: _03500082,
    name: "merch-plans___es"
  }, {
    path: "/merch-plans",
    component: _03500082,
    name: "merch-plans___pt"
  }, {
    path: "/neue-vexels",
    component: _eb675000,
    name: "new-vexels___de"
  }, {
    path: "/new-vexels",
    component: _eb675000,
    name: "new-vexels___en"
  }, {
    path: "/niches",
    component: _40d4a7e1,
    name: "niches___en"
  }, {
    path: "/nichos",
    component: _40d4a7e1,
    name: "niches___es"
  }, {
    path: "/nichos",
    component: _40d4a7e1,
    name: "niches___pt"
  }, {
    path: "/nischen",
    component: _40d4a7e1,
    name: "niches___de"
  }, {
    path: "/nossos-vetores",
    component: _fff9ef82,
    name: "our-graphics___pt"
  }, {
    path: "/novo-vexels",
    component: _eb675000,
    name: "new-vexels___pt"
  }, {
    path: "/nuestros-vectores",
    component: _fff9ef82,
    name: "our-graphics___es"
  }, {
    path: "/nuevo-vexels",
    component: _eb675000,
    name: "new-vexels___es"
  }, {
    path: "/our-graphics",
    component: _fff9ef82,
    name: "our-graphics___en"
  }, {
    path: "/our-graphics",
    component: _fff9ef82,
    name: "our-graphics___de"
  }, {
    path: "/pagamento-bem-sucedido",
    component: _aacfd9de,
    name: "payment-successful___pt"
  }, {
    path: "/pago-exitoso",
    component: _aacfd9de,
    name: "payment-successful___es"
  }, {
    path: "/payment-successful",
    component: _aacfd9de,
    name: "payment-successful___en"
  }, {
    path: "/perfil",
    component: _ad7ae2ee,
    children: [{
      path: "cancellation",
      component: _312f97f9,
      children: [{
        path: "canceled",
        component: _47440c1f,
        name: "profile-cancellation-canceled___es"
      }, {
        path: "feedback",
        component: _2f0c1d0b,
        name: "profile-cancellation-feedback___es"
      }, {
        path: "reminder",
        component: _d7e29910,
        name: "profile-cancellation-reminder___es"
      }, {
        path: "/perfil/cancelacion",
        component: _b2c30088,
        name: "profile-cancellation___es"
      }, {
        path: "/perfil/cancelacion/razon",
        component: _2e5bcfca,
        name: "profile-cancellation-reason___es"
      }]
    }, {
      path: "/perfil",
      component: _47a24a4c,
      name: "profile___es"
    }, {
      path: "/perfil/cuenta",
      component: _0aba15a7,
      name: "profile-account___es"
    }, {
      path: "/perfil/descargas",
      component: _8b8a1636,
      name: "profile-downloads___es"
    }, {
      path: "/perfil/facturacion",
      component: _46cc5216,
      children: [{
        path: "/perfil/facturacion",
        component: _6f8491b8,
        name: "profile-billing___es"
      }, {
        path: "/perfil/facturacion/historial-de-recibos",
        component: _8e45674c,
        name: "profile-billing-invoice-history___es"
      }, {
        path: "/perfil/facturacion/pagos",
        component: _46dd0937,
        name: "profile-billing-payments___es"
      }]
    }, {
      path: "/perfil/favoritos",
      component: _f7e4ff5e,
      name: "profile-favorites___es"
    }, {
      path: "/perfil/herramientas",
      component: _60043ed5,
      children: [{
        path: "/perfil/herramientas",
        component: _26b99198,
        name: "profile-tools___es"
      }, {
        path: "/perfil/herramientas/guia-de-merch",
        component: _3a404b88,
        name: "profile-tools-merch-digest___es"
      }]
    }, {
      path: "/perfil/mis-pedidos-de-diseno",
      component: _2e16d082,
      name: "profile-my-requests___es"
    }, {
      path: "/profile/ai-merch-maker",
      component: _3cb7c1f2,
      name: "profile-ai-merch-maker___es"
    }, {
      path: "/profile/buy-credits",
      component: _20729066,
      name: "profile-buy-credits___es"
    }, {
      path: "/profile/buy-design-request-credits",
      component: _3b2ede6a,
      name: "profile-buy-design-request-credits___es"
    }, {
      path: "/profile/design-tools",
      component: _702888fc,
      name: "profile-design-tools___es"
    }, {
      path: "/profile/my-designs",
      component: _40b4594a,
      children: [{
        path: "/profile/my-designs",
        component: _52a7404d,
        name: "profile-my-designs___es"
      }, {
        path: "/profile/my-designs/designer-projects",
        component: _431c0317,
        name: "profile-my-designs-designer-projects___es"
      }, {
        path: "/profile/my-designs/favorites",
        component: _1094e45c,
        name: "profile-my-designs-favorites___es"
      }, {
        path: "/profile/my-designs/my-requests",
        component: _97afb300,
        name: "profile-my-designs-my-requests___es"
      }, {
        path: "/profile/my-designs/my-tshirt-designs",
        component: _08edf334,
        name: "profile-my-designs-my-tshirt-designs___es"
      }]
    }, {
      path: "/profile/my-merch-store",
      component: _3b7a42a8,
      name: "profile-my-merch-store___es"
    }, {
      path: "/profile/my-plan",
      component: _32984fa4,
      name: "profile-my-plan___es"
    }, {
      path: "/perfil/mis-pedidos-de-diseno/nuevo",
      component: _930e3f60,
      name: "profile-my-requests-new___es"
    }]
  }, {
    path: "/perfil",
    component: _ad7ae2ee,
    children: [{
      path: "cancellation",
      component: _312f97f9,
      children: [{
        path: "canceled",
        component: _47440c1f,
        name: "profile-cancellation-canceled___pt"
      }, {
        path: "feedback",
        component: _2f0c1d0b,
        name: "profile-cancellation-feedback___pt"
      }, {
        path: "reminder",
        component: _d7e29910,
        name: "profile-cancellation-reminder___pt"
      }, {
        path: "/perfil/cancelamento",
        component: _b2c30088,
        name: "profile-cancellation___pt"
      }, {
        path: "/perfil/cancelamento/motivo",
        component: _2e5bcfca,
        name: "profile-cancellation-reason___pt"
      }]
    }, {
      path: "/perfil",
      component: _47a24a4c,
      name: "profile___pt"
    }, {
      path: "/perfil/conta",
      component: _0aba15a7,
      name: "profile-account___pt"
    }, {
      path: "/perfil/downloads",
      component: _8b8a1636,
      name: "profile-downloads___pt"
    }, {
      path: "/perfil/faturamento",
      component: _46cc5216,
      children: [{
        path: "/perfil/faturamento",
        component: _6f8491b8,
        name: "profile-billing___pt"
      }, {
        path: "/perfil/faturamento/historico-de-faturas",
        component: _8e45674c,
        name: "profile-billing-invoice-history___pt"
      }, {
        path: "/perfil/faturamento/pagamentos",
        component: _46dd0937,
        name: "profile-billing-payments___pt"
      }]
    }, {
      path: "/perfil/favoritos",
      component: _f7e4ff5e,
      name: "profile-favorites___pt"
    }, {
      path: "/perfil/ferramentas",
      component: _60043ed5,
      children: [{
        path: "/perfil/ferramentas",
        component: _26b99198,
        name: "profile-tools___pt"
      }, {
        path: "/perfil/ferramentas/guia-merch",
        component: _3a404b88,
        name: "profile-tools-merch-digest___pt"
      }]
    }, {
      path: "/perfil/meus-requests",
      component: _2e16d082,
      name: "profile-my-requests___pt"
    }, {
      path: "/profile/ai-merch-maker",
      component: _3cb7c1f2,
      name: "profile-ai-merch-maker___pt"
    }, {
      path: "/profile/buy-credits",
      component: _20729066,
      name: "profile-buy-credits___pt"
    }, {
      path: "/profile/buy-design-request-credits",
      component: _3b2ede6a,
      name: "profile-buy-design-request-credits___pt"
    }, {
      path: "/profile/design-tools",
      component: _702888fc,
      name: "profile-design-tools___pt"
    }, {
      path: "/profile/my-designs",
      component: _40b4594a,
      children: [{
        path: "/profile/my-designs",
        component: _52a7404d,
        name: "profile-my-designs___pt"
      }, {
        path: "/profile/my-designs/designer-projects",
        component: _431c0317,
        name: "profile-my-designs-designer-projects___pt"
      }, {
        path: "/profile/my-designs/favorites",
        component: _1094e45c,
        name: "profile-my-designs-favorites___pt"
      }, {
        path: "/profile/my-designs/my-requests",
        component: _97afb300,
        name: "profile-my-designs-my-requests___pt"
      }, {
        path: "/profile/my-designs/my-tshirt-designs",
        component: _08edf334,
        name: "profile-my-designs-my-tshirt-designs___pt"
      }]
    }, {
      path: "/profile/my-merch-store",
      component: _3b7a42a8,
      name: "profile-my-merch-store___pt"
    }, {
      path: "/profile/my-plan",
      component: _32984fa4,
      name: "profile-my-plan___pt"
    }, {
      path: "/perfil/meus-requests/novo",
      component: _930e3f60,
      name: "profile-my-requests-new___pt"
    }]
  }, {
    path: "/planes",
    component: _3bdda8ca,
    name: "plans___es"
  }, {
    path: "/planes-design",
    component: _5dfdef2e,
    name: "design-plans___es"
  }, {
    path: "/planos",
    component: _3bdda8ca,
    name: "plans___pt"
  }, {
    path: "/planos-design",
    component: _5dfdef2e,
    name: "design-plans___pt"
  }, {
    path: "/plans",
    component: _3bdda8ca,
    name: "plans___en"
  }, {
    path: "/politica-de-privacidad",
    component: _3493b267,
    name: "privacy-policy___es"
  }, {
    path: "/politica-de-privacidade",
    component: _3493b267,
    name: "privacy-policy___pt"
  }, {
    path: "/privacy-policy",
    component: _3493b267,
    name: "privacy-policy___en"
  }, {
    path: "/profil",
    component: _ad7ae2ee,
    children: [{
      path: "cancellation",
      component: _312f97f9,
      children: [{
        path: "canceled",
        component: _47440c1f,
        name: "profile-cancellation-canceled___de"
      }, {
        path: "feedback",
        component: _2f0c1d0b,
        name: "profile-cancellation-feedback___de"
      }, {
        path: "reminder",
        component: _d7e29910,
        name: "profile-cancellation-reminder___de"
      }, {
        path: "/profil/stornierung",
        component: _b2c30088,
        name: "profile-cancellation___de"
      }, {
        path: "/profil/stornierung/grund",
        component: _2e5bcfca,
        name: "profile-cancellation-reason___de"
      }]
    }, {
      path: "/profil",
      component: _47a24a4c,
      name: "profile___de"
    }, {
      path: "/profil/downloads",
      component: _8b8a1636,
      name: "profile-downloads___de"
    }, {
      path: "/profil/favoriten",
      component: _f7e4ff5e,
      name: "profile-favorites___de"
    }, {
      path: "/profil/konto",
      component: _0aba15a7,
      name: "profile-account___de"
    }, {
      path: "/profil/meine-anfragen",
      component: _2e16d082,
      name: "profile-my-requests___de"
    }, {
      path: "/profil/rechnungsstellung",
      component: _46cc5216,
      children: [{
        path: "/profil/rechnungsstellung",
        component: _6f8491b8,
        name: "profile-billing___de"
      }, {
        path: "/profil/rechnungsstellung/rechnungshistorie",
        component: _8e45674c,
        name: "profile-billing-invoice-history___de"
      }, {
        path: "/profil/rechnungsstellung/xahlungen",
        component: _46dd0937,
        name: "profile-billing-payments___de"
      }]
    }, {
      path: "/profil/tools",
      component: _60043ed5,
      children: [{
        path: "/profil/werkzeuge",
        component: _26b99198,
        name: "profile-tools___de"
      }, {
        path: "/profil/tools/merch-guide",
        component: _3a404b88,
        name: "profile-tools-merch-digest___de"
      }]
    }, {
      path: "/profile/ai-merch-maker",
      component: _3cb7c1f2,
      name: "profile-ai-merch-maker___de"
    }, {
      path: "/profile/buy-credits",
      component: _20729066,
      name: "profile-buy-credits___de"
    }, {
      path: "/profile/buy-design-request-credits",
      component: _3b2ede6a,
      name: "profile-buy-design-request-credits___de"
    }, {
      path: "/profile/design-tools",
      component: _702888fc,
      name: "profile-design-tools___de"
    }, {
      path: "/profile/my-designs",
      component: _40b4594a,
      children: [{
        path: "/profile/my-designs",
        component: _52a7404d,
        name: "profile-my-designs___de"
      }, {
        path: "/profile/my-designs/designer-projects",
        component: _431c0317,
        name: "profile-my-designs-designer-projects___de"
      }, {
        path: "/profile/my-designs/favorites",
        component: _1094e45c,
        name: "profile-my-designs-favorites___de"
      }, {
        path: "/profile/my-designs/my-requests",
        component: _97afb300,
        name: "profile-my-designs-my-requests___de"
      }, {
        path: "/profile/my-designs/my-tshirt-designs",
        component: _08edf334,
        name: "profile-my-designs-my-tshirt-designs___de"
      }]
    }, {
      path: "/profile/my-merch-store",
      component: _3b7a42a8,
      name: "profile-my-merch-store___de"
    }, {
      path: "/profile/my-plan",
      component: _32984fa4,
      name: "profile-my-plan___de"
    }, {
      path: "/profil/meine-anfragen/new",
      component: _930e3f60,
      name: "profile-my-requests-new___de"
    }]
  }, {
    path: "/profile",
    component: _ad7ae2ee,
    children: [{
      path: "cancellation",
      component: _312f97f9,
      children: [{
        path: "canceled",
        component: _47440c1f,
        name: "profile-cancellation-canceled___en"
      }, {
        path: "feedback",
        component: _2f0c1d0b,
        name: "profile-cancellation-feedback___en"
      }, {
        path: "reminder",
        component: _d7e29910,
        name: "profile-cancellation-reminder___en"
      }, {
        path: "/profile/cancellation",
        component: _b2c30088,
        name: "profile-cancellation___en"
      }, {
        path: "/profile/cancellation/reason",
        component: _2e5bcfca,
        name: "profile-cancellation-reason___en"
      }]
    }, {
      path: "/profile",
      component: _47a24a4c,
      name: "profile___en"
    }, {
      path: "/profile/account",
      component: _0aba15a7,
      name: "profile-account___en"
    }, {
      path: "/profile/ai-merch-maker",
      component: _3cb7c1f2,
      name: "profile-ai-merch-maker___en"
    }, {
      path: "/profile/billing",
      component: _46cc5216,
      children: [{
        path: "/profile/billing",
        component: _6f8491b8,
        name: "profile-billing___en"
      }, {
        path: "/profile/billing/invoice-history",
        component: _8e45674c,
        name: "profile-billing-invoice-history___en"
      }, {
        path: "/profile/billing/payments",
        component: _46dd0937,
        name: "profile-billing-payments___en"
      }]
    }, {
      path: "/profile/buy-credits",
      component: _20729066,
      name: "profile-buy-credits___en"
    }, {
      path: "/profile/buy-design-request-credits",
      component: _3b2ede6a,
      name: "profile-buy-design-request-credits___en"
    }, {
      path: "/profile/design-tools",
      component: _702888fc,
      name: "profile-design-tools___en"
    }, {
      path: "/profile/downloads",
      component: _8b8a1636,
      name: "profile-downloads___en"
    }, {
      path: "/profile/favorites",
      component: _f7e4ff5e,
      name: "profile-favorites___en"
    }, {
      path: "/profile/my-designs",
      component: _40b4594a,
      children: [{
        path: "/profile/my-designs",
        component: _52a7404d,
        name: "profile-my-designs___en"
      }, {
        path: "/profile/my-designs/designer-projects",
        component: _431c0317,
        name: "profile-my-designs-designer-projects___en"
      }, {
        path: "/profile/my-designs/favorites",
        component: _1094e45c,
        name: "profile-my-designs-favorites___en"
      }, {
        path: "/profile/my-designs/my-requests",
        component: _97afb300,
        name: "profile-my-designs-my-requests___en"
      }, {
        path: "/profile/my-designs/my-tshirt-designs",
        component: _08edf334,
        name: "profile-my-designs-my-tshirt-designs___en"
      }]
    }, {
      path: "/profile/my-merch-store",
      component: _3b7a42a8,
      name: "profile-my-merch-store___en"
    }, {
      path: "/profile/my-plan",
      component: _32984fa4,
      name: "profile-my-plan___en"
    }, {
      path: "/profile/my-requests",
      component: _2e16d082,
      name: "profile-my-requests___en"
    }, {
      path: "/profile/tools",
      component: _60043ed5,
      children: [{
        path: "/profile/tools",
        component: _26b99198,
        name: "profile-tools___en"
      }, {
        path: "/profile/tools/merch-digest",
        component: _3a404b88,
        name: "profile-tools-merch-digest___en"
      }]
    }, {
      path: "/profile/my-requests/new",
      component: _930e3f60,
      name: "profile-my-requests-new___en"
    }]
  }, {
    path: "/psd",
    component: _fcbacd38,
    name: "psd___en"
  }, {
    path: "/psd",
    component: _fcbacd38,
    name: "psd___de"
  }, {
    path: "/psd",
    component: _fcbacd38,
    name: "psd___es"
  }, {
    path: "/psd",
    component: _fcbacd38,
    name: "psd___pt"
  }, {
    path: "/purchase",
    component: _67af5f14,
    name: "purchase___en"
  }, {
    path: "/redeem-coupon",
    component: _26460d52,
    name: "redeem-coupon___en"
  }, {
    path: "/redeem-coupon",
    component: _26460d52,
    name: "redeem-coupon___de"
  }, {
    path: "/redeem-coupon",
    component: _26460d52,
    name: "redeem-coupon___es"
  }, {
    path: "/redeem-coupon",
    component: _26460d52,
    name: "redeem-coupon___pt"
  }, {
    path: "/sammlungen",
    component: _13994b38,
    name: "collections___de"
  }, {
    path: "/sobre-nos",
    component: _2a26929e,
    name: "about-us___pt"
  }, {
    path: "/sobre-nosotros",
    component: _2a26929e,
    name: "about-us___es"
  }, {
    path: "/spin-wheel",
    component: _61d7d640,
    name: "spin-wheel___en"
  }, {
    path: "/spin-wheel",
    component: _61d7d640,
    name: "spin-wheel___de"
  }, {
    path: "/spin-wheel",
    component: _61d7d640,
    name: "spin-wheel___es"
  }, {
    path: "/spin-wheel",
    component: _61d7d640,
    name: "spin-wheel___pt"
  }, {
    path: "/stilarten",
    component: _af920896,
    name: "styles___de"
  }, {
    path: "/styles",
    component: _af920896,
    name: "styles___en"
  }, {
    path: "/svg-png",
    component: _656c0323,
    name: "svg-png___en"
  }, {
    path: "/svg-png",
    component: _656c0323,
    name: "svg-png___de"
  }, {
    path: "/svg-png",
    component: _656c0323,
    name: "svg-png___es"
  }, {
    path: "/svg-png",
    component: _656c0323,
    name: "svg-png___pt"
  }, {
    path: "/tendencias-vectores",
    component: _7415383b,
    name: "trending-vectors___es"
  }, {
    path: "/terminos-y-condiciones",
    component: _21938044,
    name: "terms-and-conditions___es"
  }, {
    path: "/termos-condicoes",
    component: _21938044,
    name: "terms-and-conditions___pt"
  }, {
    path: "/terms-and-conditions",
    component: _21938044,
    name: "terms-and-conditions___en"
  }, {
    path: "/themen",
    component: _06715d12,
    name: "topics___de"
  }, {
    path: "/topicos",
    component: _06715d12,
    name: "topics___es"
  }, {
    path: "/topics",
    component: _06715d12,
    name: "topics___en"
  }, {
    path: "/topics",
    component: _06715d12,
    name: "topics___pt"
  }, {
    path: "/trending-vectors",
    component: _7415383b,
    name: "trending-vectors___en"
  }, {
    path: "/trending-vectors",
    component: _7415383b,
    name: "trending-vectors___de"
  }, {
    path: "/vectores-destacados",
    component: _43747704,
    name: "featured-vectors___es"
  }, {
    path: "/vectores-gratis",
    component: _2a2fb1c2,
    name: "free-vectors___es"
  }, {
    path: "/vetores-de-tendencia",
    component: _7415383b,
    name: "trending-vectors___pt"
  }, {
    path: "/vetores-em-destaque",
    component: _43747704,
    name: "featured-vectors___pt"
  }, {
    path: "/vetores-gratis",
    component: _2a2fb1c2,
    name: "free-vectors___pt"
  }, {
    path: "/zahlung-erfolgreich",
    component: _aacfd9de,
    name: "payment-successful___de"
  }, {
    path: "/design/featured-graphics",
    component: _d55f9968,
    name: "design-featured-graphics___en"
  }, {
    path: "/design/graficos-de-tendencia",
    component: _78c798f5,
    name: "design-trending-graphics___es"
  }, {
    path: "/design/graficos-de-tendencia",
    component: _78c798f5,
    name: "design-trending-graphics___pt"
  }, {
    path: "/design/graficos-destacados",
    component: _d55f9968,
    name: "design-featured-graphics___es"
  }, {
    path: "/design/graficos-em-destaque",
    component: _d55f9968,
    name: "design-featured-graphics___pt"
  }, {
    path: "/design/grafiken-im-trend",
    component: _78c798f5,
    name: "design-trending-graphics___de"
  }, {
    path: "/design/graphics",
    component: _05aa9c9f,
    name: "design-graphics___en"
  }, {
    path: "/design/graphics",
    component: _05aa9c9f,
    name: "design-graphics___de"
  }, {
    path: "/design/graphics",
    component: _05aa9c9f,
    name: "design-graphics___es"
  }, {
    path: "/design/graphics",
    component: _05aa9c9f,
    name: "design-graphics___pt"
  }, {
    path: "/design/hervorgehoben-grafiken",
    component: _d55f9968,
    name: "design-featured-graphics___de"
  }, {
    path: "/design/latest-vectors",
    component: _0ea863de,
    name: "design-latest-vectors___en"
  }, {
    path: "/design/neue-vektoren",
    component: _0ea863de,
    name: "design-latest-vectors___de"
  }, {
    path: "/design/novos-vetores",
    component: _0ea863de,
    name: "design-latest-vectors___pt"
  }, {
    path: "/design/trending-graphics",
    component: _78c798f5,
    name: "design-trending-graphics___en"
  }, {
    path: "/design/ultimos-vectores",
    component: _0ea863de,
    name: "design-latest-vectors___es"
  }, {
    path: "/editables/p",
    component: _defd265a,
    name: "editables-p___en"
  }, {
    path: "/editables/p",
    component: _defd265a,
    name: "editables-p___de"
  }, {
    path: "/editables/p",
    component: _defd265a,
    name: "editables-p___es"
  }, {
    path: "/editables/p",
    component: _defd265a,
    name: "editables-p___pt"
  }, {
    path: "/free-vectors/p",
    component: _195334e3,
    name: "free-vectors-p___de"
  }, {
    path: "/graphics/p",
    component: _2f067c3f,
    name: "graphics-p___de"
  }, {
    path: "/graphics/p",
    component: _2f067c3f,
    name: "graphics-p___es"
  }, {
    path: "/graphics/p",
    component: _2f067c3f,
    name: "graphics-p___pt"
  }, {
    path: "/icon/p",
    component: _e5b115a6,
    name: "icon-p___en"
  }, {
    path: "/icon/p",
    component: _e5b115a6,
    name: "icon-p___de"
  }, {
    path: "/icon/p",
    component: _e5b115a6,
    name: "icon-p___es"
  }, {
    path: "/icon/p",
    component: _e5b115a6,
    name: "icon-p___pt"
  }, {
    path: "/login/forgot",
    component: _0bc050d9,
    name: "login-forgot___en"
  }, {
    path: "/login/forgot",
    component: _0bc050d9,
    name: "login-forgot___de"
  }, {
    path: "/login/forgot",
    component: _0bc050d9,
    name: "login-forgot___es"
  }, {
    path: "/login/forgot",
    component: _0bc050d9,
    name: "login-forgot___pt"
  }, {
    path: "/login/forgot-callback",
    component: _6ef5b02e,
    name: "login-forgot-callback___en"
  }, {
    path: "/login/forgot-callback",
    component: _6ef5b02e,
    name: "login-forgot-callback___de"
  }, {
    path: "/login/forgot-callback",
    component: _6ef5b02e,
    name: "login-forgot-callback___es"
  }, {
    path: "/login/forgot-callback",
    component: _6ef5b02e,
    name: "login-forgot-callback___pt"
  }, {
    path: "/logos/p",
    component: _63d384ec,
    name: "logos-p___en"
  }, {
    path: "/logos/p",
    component: _63d384ec,
    name: "logos-p___de"
  }, {
    path: "/logos/p",
    component: _63d384ec,
    name: "logos-p___es"
  }, {
    path: "/logos/p",
    component: _63d384ec,
    name: "logos-p___pt"
  }, {
    path: "/merch/designs",
    component: _782a47c8,
    name: "merch-designs___en"
  }, {
    path: "/merch/designs",
    component: _782a47c8,
    name: "merch-designs___de"
  }, {
    path: "/merch/designs",
    component: _782a47c8,
    name: "merch-designs___es"
  }, {
    path: "/merch/designs",
    component: _782a47c8,
    name: "merch-designs___pt"
  }, {
    path: "/merch/designs-de-tendencia",
    component: _759859a0,
    name: "merch-trending-designs___pt"
  }, {
    path: "/merch/designs-em-destaque",
    component: _d6d9dc0e,
    name: "merch-featured-designs___pt"
  }, {
    path: "/merch/designs-im-trend",
    component: _759859a0,
    name: "merch-trending-designs___de"
  }, {
    path: "/merch/disenos-de-tendencia",
    component: _759859a0,
    name: "merch-trending-designs___es"
  }, {
    path: "/merch/disenos-destacados",
    component: _d6d9dc0e,
    name: "merch-featured-designs___es"
  }, {
    path: "/merch/featured-designs",
    component: _d6d9dc0e,
    name: "merch-featured-designs___en"
  }, {
    path: "/merch/hervorgehoben-designs",
    component: _d6d9dc0e,
    name: "merch-featured-designs___de"
  }, {
    path: "/merch/latest-designs",
    component: _5ad009dc,
    name: "merch-latest-designs___en"
  }, {
    path: "/merch/neue-designs",
    component: _5ad009dc,
    name: "merch-latest-designs___de"
  }, {
    path: "/merch/novos-designs",
    component: _5ad009dc,
    name: "merch-latest-designs___pt"
  }, {
    path: "/merch/p",
    component: _299ce3fa,
    name: "merch-p___en"
  }, {
    path: "/merch/p",
    component: _299ce3fa,
    name: "merch-p___de"
  }, {
    path: "/merch/p",
    component: _299ce3fa,
    name: "merch-p___es"
  }, {
    path: "/merch/p",
    component: _299ce3fa,
    name: "merch-p___pt"
  }, {
    path: "/merch/png",
    component: _49fc6e7c,
    name: "merch-png___en"
  }, {
    path: "/merch/png",
    component: _49fc6e7c,
    name: "merch-png___de"
  }, {
    path: "/merch/png",
    component: _49fc6e7c,
    name: "merch-png___es"
  }, {
    path: "/merch/png",
    component: _49fc6e7c,
    name: "merch-png___pt"
  }, {
    path: "/merch/psd",
    component: _9c72ddd8,
    name: "merch-psd___en"
  }, {
    path: "/merch/psd",
    component: _9c72ddd8,
    name: "merch-psd___de"
  }, {
    path: "/merch/psd",
    component: _9c72ddd8,
    name: "merch-psd___es"
  }, {
    path: "/merch/psd",
    component: _9c72ddd8,
    name: "merch-psd___pt"
  }, {
    path: "/merch/trending-designs",
    component: _759859a0,
    name: "merch-trending-designs___en"
  }, {
    path: "/merch/ultimos-disenos",
    component: _5ad009dc,
    name: "merch-latest-designs___es"
  }, {
    path: "/merch/vectors",
    component: _646899ba,
    name: "merch-vectors___en"
  }, {
    path: "/merch/vectors",
    component: _646899ba,
    name: "merch-vectors___de"
  }, {
    path: "/merch/vectors",
    component: _646899ba,
    name: "merch-vectors___es"
  }, {
    path: "/merch/vectors",
    component: _646899ba,
    name: "merch-vectors___pt"
  }, {
    path: "/psd/p",
    component: _5faabc05,
    name: "psd-p___en"
  }, {
    path: "/psd/p",
    component: _5faabc05,
    name: "psd-p___de"
  }, {
    path: "/psd/p",
    component: _5faabc05,
    name: "psd-p___es"
  }, {
    path: "/psd/p",
    component: _5faabc05,
    name: "psd-p___pt"
  }, {
    path: "/svg-png/p",
    component: _76c0b204,
    name: "svg-png-p___en"
  }, {
    path: "/svg-png/p",
    component: _76c0b204,
    name: "svg-png-p___de"
  }, {
    path: "/svg-png/p",
    component: _76c0b204,
    name: "svg-png-p___es"
  }, {
    path: "/svg-png/p",
    component: _76c0b204,
    name: "svg-png-p___pt"
  }, {
    path: "/templates/mockup-generator",
    component: _8619e1de,
    name: "templates-mockup-generator___en"
  }, {
    path: "/templates/mockup-generator",
    component: _8619e1de,
    name: "templates-mockup-generator___de"
  }, {
    path: "/templates/mockup-generator",
    component: _8619e1de,
    name: "templates-mockup-generator___es"
  }, {
    path: "/templates/mockup-generator",
    component: _8619e1de,
    name: "templates-mockup-generator___pt"
  }, {
    path: "/templates/t-shirt-maker",
    component: _6a19c044,
    name: "templates-t-shirt-maker___en"
  }, {
    path: "/templates/t-shirt-maker",
    component: _6a19c044,
    name: "templates-t-shirt-maker___de"
  }, {
    path: "/templates/t-shirt-maker",
    component: _6a19c044,
    name: "templates-t-shirt-maker___es"
  }, {
    path: "/templates/t-shirt-maker",
    component: _6a19c044,
    name: "templates-t-shirt-maker___pt"
  }, {
    path: "/design/graphics/p",
    component: _01bedc80,
    name: "design-graphics-p___de"
  }, {
    path: "/design/graphics/p",
    component: _01bedc80,
    name: "design-graphics-p___es"
  }, {
    path: "/design/graphics/p",
    component: _01bedc80,
    name: "design-graphics-p___pt"
  }, {
    path: "/merch/designs/p",
    component: _59ff3d2e,
    name: "merch-designs-p___de"
  }, {
    path: "/merch/designs/p",
    component: _59ff3d2e,
    name: "merch-designs-p___es"
  }, {
    path: "/merch/designs/p",
    component: _59ff3d2e,
    name: "merch-designs-p___pt"
  }, {
    path: "/merch/png/p",
    component: _78e3ab1d,
    name: "merch-png-p___de"
  }, {
    path: "/merch/png/p",
    component: _78e3ab1d,
    name: "merch-png-p___es"
  }, {
    path: "/merch/png/p",
    component: _78e3ab1d,
    name: "merch-png-p___pt"
  }, {
    path: "/merch/psd/p",
    component: _16af87b5,
    name: "merch-psd-p___de"
  }, {
    path: "/merch/psd/p",
    component: _16af87b5,
    name: "merch-psd-p___es"
  }, {
    path: "/merch/psd/p",
    component: _16af87b5,
    name: "merch-psd-p___pt"
  }, {
    path: "/merch/vectors/p",
    component: _45f46204,
    name: "merch-vectors-p___de"
  }, {
    path: "/merch/vectors/p",
    component: _45f46204,
    name: "merch-vectors-p___es"
  }, {
    path: "/merch/vectors/p",
    component: _45f46204,
    name: "merch-vectors-p___pt"
  }, {
    path: "/design/featured-graphics/p/:page",
    component: _b1ca7eee,
    name: "design-featured-graphics-p-page___en"
  }, {
    path: "/design/graficos-de-tendencia/p/:page",
    component: _eb61401c,
    name: "design-trending-graphics-p-page___es"
  }, {
    path: "/design/graficos-de-tendencia/p/:page",
    component: _eb61401c,
    name: "design-trending-graphics-p-page___pt"
  }, {
    path: "/design/graficos-destacados/p/:page",
    component: _b1ca7eee,
    name: "design-featured-graphics-p-page___es"
  }, {
    path: "/design/graficos-em-destaque/p/:page",
    component: _b1ca7eee,
    name: "design-featured-graphics-p-page___pt"
  }, {
    path: "/design/grafiken-im-trend/p/:page",
    component: _eb61401c,
    name: "design-trending-graphics-p-page___de"
  }, {
    path: "/design/graphics/p/:page",
    component: _01bedc80,
    name: "design-graphics-p___en"
  }, {
    path: "/design/graphics/p/:page",
    component: _6a80211c,
    name: "design-graphics-p-page___en"
  }, {
    path: "/design/graphics/p/:page",
    component: _6a80211c,
    name: "design-graphics-p-page___de"
  }, {
    path: "/design/graphics/p/:page",
    component: _6a80211c,
    name: "design-graphics-p-page___es"
  }, {
    path: "/design/graphics/p/:page",
    component: _6a80211c,
    name: "design-graphics-p-page___pt"
  }, {
    path: "/design/hervorgehoben-grafiken/p/:page",
    component: _b1ca7eee,
    name: "design-featured-graphics-p-page___de"
  }, {
    path: "/design/latest-vectors/p/:page",
    component: _2b29149b,
    name: "design-latest-vectors-p-page___en"
  }, {
    path: "/design/neue-vektoren/p/:page",
    component: _2b29149b,
    name: "design-latest-vectors-p-page___de"
  }, {
    path: "/design/novos-vetores/p/:page",
    component: _2b29149b,
    name: "design-latest-vectors-p-page___pt"
  }, {
    path: "/design/trending-graphics/p/:page",
    component: _eb61401c,
    name: "design-trending-graphics-p-page___en"
  }, {
    path: "/design/ultimos-vectores/p/:page",
    component: _2b29149b,
    name: "design-latest-vectors-p-page___es"
  }, {
    path: "/merch/designs-de-tendencia/p/:page",
    component: _6c95f76d,
    name: "merch-trending-designs-p-page___pt"
  }, {
    path: "/merch/designs-em-destaque/p/:page",
    component: _3dbeb014,
    name: "merch-featured-designs-p-page___pt"
  }, {
    path: "/merch/designs-im-trend/p/:page",
    component: _6c95f76d,
    name: "merch-trending-designs-p-page___de"
  }, {
    path: "/merch/designs/p/:page",
    component: _59ff3d2e,
    name: "merch-designs-p___en"
  }, {
    path: "/merch/designs/p/:page",
    component: _3bc1a605,
    name: "merch-designs-p-page___en"
  }, {
    path: "/merch/designs/p/:page",
    component: _3bc1a605,
    name: "merch-designs-p-page___de"
  }, {
    path: "/merch/designs/p/:page",
    component: _3bc1a605,
    name: "merch-designs-p-page___es"
  }, {
    path: "/merch/designs/p/:page",
    component: _3bc1a605,
    name: "merch-designs-p-page___pt"
  }, {
    path: "/merch/disenos-de-tendencia/p/:page",
    component: _6c95f76d,
    name: "merch-trending-designs-p-page___es"
  }, {
    path: "/merch/disenos-destacados/p/:page",
    component: _3dbeb014,
    name: "merch-featured-designs-p-page___es"
  }, {
    path: "/merch/featured-designs/p/:page",
    component: _3dbeb014,
    name: "merch-featured-designs-p-page___en"
  }, {
    path: "/merch/hervorgehoben-designs/p/:page",
    component: _3dbeb014,
    name: "merch-featured-designs-p-page___de"
  }, {
    path: "/merch/latest-designs/p/:page",
    component: _9ce0a262,
    name: "merch-latest-designs-p-page___en"
  }, {
    path: "/merch/neue-designs/p/:page",
    component: _9ce0a262,
    name: "merch-latest-designs-p-page___de"
  }, {
    path: "/merch/novos-designs/p/:page",
    component: _9ce0a262,
    name: "merch-latest-designs-p-page___pt"
  }, {
    path: "/merch/png/p/:page",
    component: _78e3ab1d,
    name: "merch-png-p___en"
  }, {
    path: "/merch/png/p/:page",
    component: _3cb6208e,
    name: "merch-png-p-page___en"
  }, {
    path: "/merch/png/p/:page",
    component: _3cb6208e,
    name: "merch-png-p-page___de"
  }, {
    path: "/merch/png/p/:page",
    component: _3cb6208e,
    name: "merch-png-p-page___es"
  }, {
    path: "/merch/png/p/:page",
    component: _3cb6208e,
    name: "merch-png-p-page___pt"
  }, {
    path: "/merch/psd/p/:page",
    component: _16af87b5,
    name: "merch-psd-p___en"
  }, {
    path: "/merch/psd/p/:page",
    component: _7f70cc51,
    name: "merch-psd-p-page___en"
  }, {
    path: "/merch/psd/p/:page",
    component: _7f70cc51,
    name: "merch-psd-p-page___de"
  }, {
    path: "/merch/psd/p/:page",
    component: _7f70cc51,
    name: "merch-psd-p-page___es"
  }, {
    path: "/merch/psd/p/:page",
    component: _7f70cc51,
    name: "merch-psd-p-page___pt"
  }, {
    path: "/merch/trending-designs/p/:page",
    component: _6c95f76d,
    name: "merch-trending-designs-p-page___en"
  }, {
    path: "/merch/ultimos-disenos/p/:page",
    component: _9ce0a262,
    name: "merch-latest-designs-p-page___es"
  }, {
    path: "/merch/vectors/p/:page",
    component: _45f46204,
    name: "merch-vectors-p___en"
  }, {
    path: "/merch/vectors/p/:page",
    component: _a294b2c0,
    name: "merch-vectors-p-page___en"
  }, {
    path: "/merch/vectors/p/:page",
    component: _a294b2c0,
    name: "merch-vectors-p-page___de"
  }, {
    path: "/merch/vectors/p/:page",
    component: _a294b2c0,
    name: "merch-vectors-p-page___es"
  }, {
    path: "/merch/vectors/p/:page",
    component: _a294b2c0,
    name: "merch-vectors-p-page___pt"
  }, {
    path: "/design/graphics/:search",
    component: _0b539b52,
    name: "design-graphics-search___en"
  }, {
    path: "/design/graphics/:search",
    component: _0b539b52,
    name: "design-graphics-search___de"
  }, {
    path: "/design/graphics/:search",
    component: _0b539b52,
    name: "design-graphics-search___es"
  }, {
    path: "/design/graphics/:search",
    component: _0b539b52,
    name: "design-graphics-search___pt"
  }, {
    path: "/editables/p/:page",
    component: _0d7a9d22,
    name: "editables-p-page___en"
  }, {
    path: "/editables/p/:page",
    component: _0d7a9d22,
    name: "editables-p-page___de"
  }, {
    path: "/editables/p/:page",
    component: _0d7a9d22,
    name: "editables-p-page___es"
  }, {
    path: "/editables/p/:page",
    component: _0d7a9d22,
    name: "editables-p-page___pt"
  }, {
    path: "/editables/preview/:id",
    component: _6bee0cd4,
    name: "editables-preview-id___en"
  }, {
    path: "/editables/preview/:id",
    component: _6bee0cd4,
    name: "editables-preview-id___de"
  }, {
    path: "/editables/preview/:id",
    component: _6bee0cd4,
    name: "editables-preview-id___es"
  }, {
    path: "/editables/preview/:id",
    component: _6bee0cd4,
    name: "editables-preview-id___pt"
  }, {
    path: "/featured-vectors/p/:page",
    component: _5d3cf641,
    name: "featured-vectors-p-page___en"
  }, {
    path: "/featured-vectors/p/:page",
    component: _5d3cf641,
    name: "featured-vectors-p-page___de"
  }, {
    path: "/free-vectors/p/:page",
    component: _195334e3,
    name: "free-vectors-p___en"
  }, {
    path: "/free-vectors/p/:page",
    component: _fbd70d02,
    name: "free-vectors-p-page___en"
  }, {
    path: "/free-vectors/p/:page",
    component: _fbd70d02,
    name: "free-vectors-p-page___de"
  }, {
    path: "/graphics/p/:page",
    component: _2f067c3f,
    name: "graphics-p___en"
  }, {
    path: "/graphics/p/:page",
    component: _d0707e4a,
    name: "graphics-p-page___en"
  }, {
    path: "/graphics/p/:page",
    component: _d0707e4a,
    name: "graphics-p-page___de"
  }, {
    path: "/graphics/p/:page",
    component: _d0707e4a,
    name: "graphics-p-page___es"
  }, {
    path: "/graphics/p/:page",
    component: _d0707e4a,
    name: "graphics-p-page___pt"
  }, {
    path: "/icon/p/:page",
    component: _142e8c6e,
    name: "icon-p-page___en"
  }, {
    path: "/icon/p/:page",
    component: _142e8c6e,
    name: "icon-p-page___de"
  }, {
    path: "/icon/p/:page",
    component: _142e8c6e,
    name: "icon-p-page___es"
  }, {
    path: "/icon/p/:page",
    component: _142e8c6e,
    name: "icon-p-page___pt"
  }, {
    path: "/logos/p/:page",
    component: _66d66cf0,
    name: "logos-p-page___en"
  }, {
    path: "/logos/p/:page",
    component: _66d66cf0,
    name: "logos-p-page___de"
  }, {
    path: "/logos/p/:page",
    component: _66d66cf0,
    name: "logos-p-page___es"
  }, {
    path: "/logos/p/:page",
    component: _66d66cf0,
    name: "logos-p-page___pt"
  }, {
    path: "/merch/designs/:search",
    component: _1a1eb700,
    name: "merch-designs-search___en"
  }, {
    path: "/merch/designs/:search",
    component: _1a1eb700,
    name: "merch-designs-search___de"
  }, {
    path: "/merch/designs/:search",
    component: _1a1eb700,
    name: "merch-designs-search___es"
  }, {
    path: "/merch/designs/:search",
    component: _1a1eb700,
    name: "merch-designs-search___pt"
  }, {
    path: "/merch/p/:page",
    component: _53f2d29f,
    name: "merch-p-page___en"
  }, {
    path: "/merch/p/:page",
    component: _53f2d29f,
    name: "merch-p-page___de"
  }, {
    path: "/merch/p/:page",
    component: _53f2d29f,
    name: "merch-p-page___es"
  }, {
    path: "/merch/p/:page",
    component: _53f2d29f,
    name: "merch-p-page___pt"
  }, {
    path: "/merch/png/:search",
    component: _3b5ad734,
    name: "merch-png-search___en"
  }, {
    path: "/merch/png/:search",
    component: _3b5ad734,
    name: "merch-png-search___de"
  }, {
    path: "/merch/png/:search",
    component: _3b5ad734,
    name: "merch-png-search___es"
  }, {
    path: "/merch/png/:search",
    component: _3b5ad734,
    name: "merch-png-search___pt"
  }, {
    path: "/merch/psd/:search",
    component: _5884e1cc,
    name: "merch-psd-search___en"
  }, {
    path: "/merch/psd/:search",
    component: _5884e1cc,
    name: "merch-psd-search___de"
  }, {
    path: "/merch/psd/:search",
    component: _5884e1cc,
    name: "merch-psd-search___es"
  }, {
    path: "/merch/psd/:search",
    component: _5884e1cc,
    name: "merch-psd-search___pt"
  }, {
    path: "/merch/vectors/:search",
    component: _664a84db,
    name: "merch-vectors-search___en"
  }, {
    path: "/merch/vectors/:search",
    component: _664a84db,
    name: "merch-vectors-search___de"
  }, {
    path: "/merch/vectors/:search",
    component: _664a84db,
    name: "merch-vectors-search___es"
  }, {
    path: "/merch/vectors/:search",
    component: _664a84db,
    name: "merch-vectors-search___pt"
  }, {
    path: "/nossos-vetores/p/:page",
    component: _9f27b688,
    name: "our-graphics-p-page___pt"
  }, {
    path: "/nuestros-vectores/p/:page",
    component: _9f27b688,
    name: "our-graphics-p-page___es"
  }, {
    path: "/our-graphics/p/:page",
    component: _9f27b688,
    name: "our-graphics-p-page___en"
  }, {
    path: "/our-graphics/p/:page",
    component: _9f27b688,
    name: "our-graphics-p-page___de"
  }, {
    path: "/png-svg/preview/:id",
    component: _88bca9f2,
    name: "png-svg-preview-id___en"
  }, {
    path: "/png-svg/preview/:id",
    component: _88bca9f2,
    name: "png-svg-preview-id___de"
  }, {
    path: "/png-svg/previsualizar/:id",
    component: _88bca9f2,
    name: "png-svg-preview-id___pt"
  }, {
    path: "/png-svg/vista-previa/:id",
    component: _88bca9f2,
    name: "png-svg-preview-id___es"
  }, {
    path: "/psd/p/:page",
    component: _6f27febe,
    name: "psd-p-page___en"
  }, {
    path: "/psd/p/:page",
    component: _6f27febe,
    name: "psd-p-page___de"
  }, {
    path: "/psd/p/:page",
    component: _6f27febe,
    name: "psd-p-page___es"
  }, {
    path: "/psd/p/:page",
    component: _6f27febe,
    name: "psd-p-page___pt"
  }, {
    path: "/psd/preview/:id",
    component: _21c67248,
    name: "psd-preview-id___en"
  }, {
    path: "/psd/preview/:id",
    component: _21c67248,
    name: "psd-preview-id___de"
  }, {
    path: "/psd/previsualizar/:id",
    component: _21c67248,
    name: "psd-preview-id___pt"
  }, {
    path: "/psd/vista-previa/:id",
    component: _21c67248,
    name: "psd-preview-id___es"
  }, {
    path: "/svg-png/p/:page",
    component: _40fc12c0,
    name: "svg-png-p-page___en"
  }, {
    path: "/svg-png/p/:page",
    component: _40fc12c0,
    name: "svg-png-p-page___de"
  }, {
    path: "/svg-png/p/:page",
    component: _40fc12c0,
    name: "svg-png-p-page___es"
  }, {
    path: "/svg-png/p/:page",
    component: _40fc12c0,
    name: "svg-png-p-page___pt"
  }, {
    path: "/tendencias-vectores/p/:page",
    component: _2e9b7490,
    name: "trending-vectors-p-page___es"
  }, {
    path: "/trending-vectors/p/:page",
    component: _2e9b7490,
    name: "trending-vectors-p-page___en"
  }, {
    path: "/trending-vectors/p/:page",
    component: _2e9b7490,
    name: "trending-vectors-p-page___de"
  }, {
    path: "/vectores-destacados/p/:page",
    component: _5d3cf641,
    name: "featured-vectors-p-page___es"
  }, {
    path: "/vectores-gratis/p/:page",
    component: _195334e3,
    name: "free-vectors-p___es"
  }, {
    path: "/vectores-gratis/p/:page",
    component: _fbd70d02,
    name: "free-vectors-p-page___es"
  }, {
    path: "/vectores/vista-previa/:id",
    component: _53b54dd7,
    name: "vectors-preview-id___es"
  }, {
    path: "/vectors/preview/:id",
    component: _53b54dd7,
    name: "vectors-preview-id___en"
  }, {
    path: "/vectors/preview/:id",
    component: _53b54dd7,
    name: "vectors-preview-id___de"
  }, {
    path: "/vetores-de-tendencia/p/:page",
    component: _2e9b7490,
    name: "trending-vectors-p-page___pt"
  }, {
    path: "/vetores-em-destaque/p/:page",
    component: _5d3cf641,
    name: "featured-vectors-p-page___pt"
  }, {
    path: "/vetores-gratis/p/:page",
    component: _195334e3,
    name: "free-vectors-p___pt"
  }, {
    path: "/vetores-gratis/p/:page",
    component: _fbd70d02,
    name: "free-vectors-p-page___pt"
  }, {
    path: "/vetores/previsualizar/:id",
    component: _53b54dd7,
    name: "vectors-preview-id___pt"
  }, {
    path: "/design/graphics/:search/p/:page",
    component: _3ac5ae58,
    name: "design-graphics-search-p-page___en"
  }, {
    path: "/design/graphics/:search/p/:page?",
    component: _3ac5ae58,
    name: "design-graphics-search-p-page___de"
  }, {
    path: "/design/graphics/:search/p/:page?",
    component: _3ac5ae58,
    name: "design-graphics-search-p-page___es"
  }, {
    path: "/design/graphics/:search/p/:page?",
    component: _3ac5ae58,
    name: "design-graphics-search-p-page___pt"
  }, {
    path: "/merch/designs/:search/p/:page",
    component: _1e63b4bd,
    name: "merch-designs-search-p-page___en"
  }, {
    path: "/merch/designs/:search/p/:page?",
    component: _1e63b4bd,
    name: "merch-designs-search-p-page___de"
  }, {
    path: "/merch/designs/:search/p/:page?",
    component: _1e63b4bd,
    name: "merch-designs-search-p-page___es"
  }, {
    path: "/merch/designs/:search/p/:page?",
    component: _1e63b4bd,
    name: "merch-designs-search-p-page___pt"
  }, {
    path: "/merch/png/:search/p/:page",
    component: _15e7eb1e,
    name: "merch-png-search-p-page___en"
  }, {
    path: "/merch/png/:search/p/:page?",
    component: _15e7eb1e,
    name: "merch-png-search-p-page___de"
  }, {
    path: "/merch/png/:search/p/:page?",
    component: _15e7eb1e,
    name: "merch-png-search-p-page___es"
  }, {
    path: "/merch/png/:search/p/:page?",
    component: _15e7eb1e,
    name: "merch-png-search-p-page___pt"
  }, {
    path: "/merch/psd/:search/p/:page",
    component: _6fddcf09,
    name: "merch-psd-search-p-page___en"
  }, {
    path: "/merch/psd/:search/p/:page?",
    component: _6fddcf09,
    name: "merch-psd-search-p-page___de"
  }, {
    path: "/merch/psd/:search/p/:page?",
    component: _6fddcf09,
    name: "merch-psd-search-p-page___es"
  }, {
    path: "/merch/psd/:search/p/:page?",
    component: _6fddcf09,
    name: "merch-psd-search-p-page___pt"
  }, {
    path: "/merch/vectors/:search/p/:page",
    component: _22c6ea58,
    name: "merch-vectors-search-p-page___en"
  }, {
    path: "/merch/vectors/:search/p/:page?",
    component: _22c6ea58,
    name: "merch-vectors-search-p-page___de"
  }, {
    path: "/merch/vectors/:search/p/:page?",
    component: _22c6ea58,
    name: "merch-vectors-search-p-page___es"
  }, {
    path: "/merch/vectors/:search/p/:page?",
    component: _22c6ea58,
    name: "merch-vectors-search-p-page___pt"
  }, {
    path: "/editables/preview/:id/:name",
    component: _08d62431,
    name: "editables-preview-id-name___en"
  }, {
    path: "/editables/preview/:id/:name",
    component: _08d62431,
    name: "editables-preview-id-name___de"
  }, {
    path: "/editables/previsualizar/:id/:name",
    component: _08d62431,
    name: "editables-preview-id-name___pt"
  }, {
    path: "/editables/vista-previa/:id/:name",
    component: _08d62431,
    name: "editables-preview-id-name___es"
  }, {
    path: "/png-svg/preview/:id/:name",
    component: _1df3863c,
    name: "png-svg-preview-id-name___en"
  }, {
    path: "/png-svg/preview/:id/:name",
    component: _1df3863c,
    name: "png-svg-preview-id-name___de"
  }, {
    path: "/png-svg/previsualizar/:id/:name",
    component: _1df3863c,
    name: "png-svg-preview-id-name___pt"
  }, {
    path: "/png-svg/vista-previa/:id/:name",
    component: _1df3863c,
    name: "png-svg-preview-id-name___es"
  }, {
    path: "/psd/preview/:id/:name",
    component: _daa2613a,
    name: "psd-preview-id-name___en"
  }, {
    path: "/psd/preview/:id/:name",
    component: _daa2613a,
    name: "psd-preview-id-name___de"
  }, {
    path: "/psd/previsualizar/:id/:name",
    component: _daa2613a,
    name: "psd-preview-id-name___pt"
  }, {
    path: "/psd/vista-previa/:id/:name",
    component: _daa2613a,
    name: "psd-preview-id-name___es"
  }, {
    path: "/vectores/vista-previa/:id/:name",
    component: _c92ef89c,
    name: "vectors-preview-id-name___es"
  }, {
    path: "/vectors/preview/:id/:name",
    component: _c92ef89c,
    name: "vectors-preview-id-name___en"
  }, {
    path: "/vectors/preview/:id/:name",
    component: _c92ef89c,
    name: "vectors-preview-id-name___de"
  }, {
    path: "/vetores/previsualizar/:id/:name",
    component: _c92ef89c,
    name: "vectors-preview-id-name___pt"
  }, {
    path: "/categoria/:id",
    component: _0d00493c,
    name: "category-id___es"
  }, {
    path: "/categoria/:id",
    component: _0d00493c,
    name: "category-id___pt"
  }, {
    path: "/category/:id",
    component: _0d00493c,
    name: "category-id___en"
  }, {
    path: "/category/:id",
    component: _0d00493c,
    name: "category-id___de"
  }, {
    path: "/colecciones/:slug",
    component: _4923fc73,
    name: "collections-slug___es"
  }, {
    path: "/colecoes/:slug",
    component: _4923fc73,
    name: "collections-slug___pt"
  }, {
    path: "/collections/:slug",
    component: _4923fc73,
    name: "collections-slug___en"
  }, {
    path: "/compra/:id",
    component: _aa4a6002,
    name: "purchase-id___es"
  }, {
    path: "/compra/:id",
    component: _aa4a6002,
    name: "purchase-id___pt"
  }, {
    path: "/design-abos/:keyword",
    component: _6e59defe,
    name: "design-plans-keyword___de"
  }, {
    path: "/design-plans/:keyword",
    component: _6e59defe,
    name: "design-plans-keyword___en"
  }, {
    path: "/editables/:search",
    component: _d0dc0f2c,
    name: "editables-search___en"
  }, {
    path: "/editables/:search",
    component: _d0dc0f2c,
    name: "editables-search___de"
  }, {
    path: "/editables/:search",
    component: _d0dc0f2c,
    name: "editables-search___es"
  }, {
    path: "/editables/:search",
    component: _d0dc0f2c,
    name: "editables-search___pt"
  }, {
    path: "/estilo/:slug",
    component: _51289b30,
    name: "styles-slug___pt"
  }, {
    path: "/estilos/:slug",
    component: _51289b30,
    name: "styles-slug___es"
  }, {
    path: "/free-vectors/:search",
    component: _a75d170c,
    name: "free-vectors-search___en"
  }, {
    path: "/free-vectors/:search",
    component: _a75d170c,
    name: "free-vectors-search___de"
  }, {
    path: "/graphics/:search",
    component: _4cf0c6d6,
    name: "graphics-search___en"
  }, {
    path: "/graphics/:search",
    component: _4cf0c6d6,
    name: "graphics-search___de"
  }, {
    path: "/graphics/:search",
    component: _4cf0c6d6,
    name: "graphics-search___es"
  }, {
    path: "/graphics/:search",
    component: _4cf0c6d6,
    name: "graphics-search___pt"
  }, {
    path: "/icon/:search",
    component: _6afa5578,
    name: "icon-search___en"
  }, {
    path: "/icon/:search",
    component: _6afa5578,
    name: "icon-search___de"
  }, {
    path: "/icon/:search",
    component: _6afa5578,
    name: "icon-search___es"
  }, {
    path: "/icon/:search",
    component: _6afa5578,
    name: "icon-search___pt"
  }, {
    path: "/kauf/:id",
    component: _aa4a6002,
    name: "purchase-id___de"
  }, {
    path: "/logos/:search",
    component: _35244c7a,
    name: "logos-search___en"
  }, {
    path: "/logos/:search",
    component: _35244c7a,
    name: "logos-search___de"
  }, {
    path: "/logos/:search",
    component: _35244c7a,
    name: "logos-search___es"
  }, {
    path: "/logos/:search",
    component: _35244c7a,
    name: "logos-search___pt"
  }, {
    path: "/merch-plans/:keyword",
    component: _81708e66,
    name: "merch-plans-keyword___en"
  }, {
    path: "/merch-plans/:keyword",
    component: _81708e66,
    name: "merch-plans-keyword___de"
  }, {
    path: "/merch-plans/:keyword",
    component: _81708e66,
    name: "merch-plans-keyword___es"
  }, {
    path: "/merch-plans/:keyword",
    component: _81708e66,
    name: "merch-plans-keyword___pt"
  }, {
    path: "/niches/:slug",
    component: _0767765c,
    name: "niches-slug___en"
  }, {
    path: "/nichos/:slug",
    component: _0767765c,
    name: "niches-slug___es"
  }, {
    path: "/nichos/:slug",
    component: _0767765c,
    name: "niches-slug___pt"
  }, {
    path: "/nischen/:slug",
    component: _0767765c,
    name: "niches-slug___de"
  }, {
    path: "/planes-design/:keyword",
    component: _6e59defe,
    name: "design-plans-keyword___es"
  }, {
    path: "/planos-design/:keyword",
    component: _6e59defe,
    name: "design-plans-keyword___pt"
  }, {
    path: "/psd/:search",
    component: _053b9a1c,
    name: "psd-search___en"
  }, {
    path: "/psd/:search",
    component: _053b9a1c,
    name: "psd-search___de"
  }, {
    path: "/psd/:search",
    component: _053b9a1c,
    name: "psd-search___es"
  }, {
    path: "/psd/:search",
    component: _053b9a1c,
    name: "psd-search___pt"
  }, {
    path: "/purchase-plan/:id",
    component: _0b2deb03,
    name: "purchase-plan-id___en"
  }, {
    path: "/purchase-plan/:id",
    component: _0b2deb03,
    name: "purchase-plan-id___de"
  }, {
    path: "/purchase-plan/:id",
    component: _0b2deb03,
    name: "purchase-plan-id___es"
  }, {
    path: "/purchase-plan/:id",
    component: _0b2deb03,
    name: "purchase-plan-id___pt"
  }, {
    path: "/purchase/:id",
    component: _aa4a6002,
    name: "purchase-id___en"
  }, {
    path: "/sammlungen/:slug",
    component: _4923fc73,
    name: "collections-slug___de"
  }, {
    path: "/stilarten/:slug",
    component: _51289b30,
    name: "styles-slug___de"
  }, {
    path: "/styles/:slug",
    component: _51289b30,
    name: "styles-slug___en"
  }, {
    path: "/svg-png/:search",
    component: _7eca564a,
    name: "svg-png-search___en"
  }, {
    path: "/svg-png/:search",
    component: _7eca564a,
    name: "svg-png-search___de"
  }, {
    path: "/svg-png/:search",
    component: _7eca564a,
    name: "svg-png-search___es"
  }, {
    path: "/svg-png/:search",
    component: _7eca564a,
    name: "svg-png-search___pt"
  }, {
    path: "/themen/:slug",
    component: _fc43d11c,
    name: "topics-slug___de"
  }, {
    path: "/topicos/:slug",
    component: _fc43d11c,
    name: "topics-slug___es"
  }, {
    path: "/topics/:slug",
    component: _fc43d11c,
    name: "topics-slug___en"
  }, {
    path: "/topics/:slug",
    component: _fc43d11c,
    name: "topics-slug___pt"
  }, {
    path: "/vectores-gratis/:search",
    component: _a75d170c,
    name: "free-vectors-search___es"
  }, {
    path: "/vetores-gratis/:search",
    component: _a75d170c,
    name: "free-vectors-search___pt"
  }, {
    path: "/editables/:search/p/:page?",
    component: _1fffb627,
    name: "editables-search-p-page___en"
  }, {
    path: "/editables/:search/p/:page?",
    component: _1fffb627,
    name: "editables-search-p-page___de"
  }, {
    path: "/editables/:search/p/:page?",
    component: _1fffb627,
    name: "editables-search-p-page___es"
  }, {
    path: "/editables/:search/p/:page?",
    component: _1fffb627,
    name: "editables-search-p-page___pt"
  }, {
    path: "/free-vectors/:search?/p/:page",
    component: _02d06e37,
    name: "free-vectors-search-p-page___de"
  }, {
    path: "/free-vectors/:search/p/:page",
    component: _02d06e37,
    name: "free-vectors-search-p-page___en"
  }, {
    path: "/graphics/:search/p/:page",
    component: _0e36ccda,
    name: "graphics-search-p-page___en"
  }, {
    path: "/graphics/:search/p/:page?",
    component: _0e36ccda,
    name: "graphics-search-p-page___de"
  }, {
    path: "/graphics/:search/p/:page?",
    component: _0e36ccda,
    name: "graphics-search-p-page___es"
  }, {
    path: "/graphics/:search/p/:page?",
    component: _0e36ccda,
    name: "graphics-search-p-page___pt"
  }, {
    path: "/icon/:search/p/:page?",
    component: _5a2cc481,
    name: "icon-search-p-page___en"
  }, {
    path: "/icon/:search/p/:page?",
    component: _5a2cc481,
    name: "icon-search-p-page___de"
  }, {
    path: "/icon/:search/p/:page?",
    component: _5a2cc481,
    name: "icon-search-p-page___es"
  }, {
    path: "/icon/:search/p/:page?",
    component: _5a2cc481,
    name: "icon-search-p-page___pt"
  }, {
    path: "/logos/:search/p/:page?",
    component: _6668a540,
    name: "logos-search-p-page___en"
  }, {
    path: "/logos/:search/p/:page?",
    component: _6668a540,
    name: "logos-search-p-page___de"
  }, {
    path: "/logos/:search/p/:page?",
    component: _6668a540,
    name: "logos-search-p-page___es"
  }, {
    path: "/logos/:search/p/:page?",
    component: _6668a540,
    name: "logos-search-p-page___pt"
  }, {
    path: "/psd/:search/p/:page?",
    component: _6c70994e,
    name: "psd-search-p-page___en"
  }, {
    path: "/psd/:search/p/:page?",
    component: _6c70994e,
    name: "psd-search-p-page___de"
  }, {
    path: "/psd/:search/p/:page?",
    component: _6c70994e,
    name: "psd-search-p-page___es"
  }, {
    path: "/psd/:search/p/:page?",
    component: _6c70994e,
    name: "psd-search-p-page___pt"
  }, {
    path: "/svg-png/:search?/p/:page",
    component: _2a433a58,
    name: "svg-png-search-p-page___en"
  }, {
    path: "/svg-png/:search?/p/:page",
    component: _2a433a58,
    name: "svg-png-search-p-page___de"
  }, {
    path: "/svg-png/:search?/p/:page",
    component: _2a433a58,
    name: "svg-png-search-p-page___es"
  }, {
    path: "/svg-png/:search?/p/:page",
    component: _2a433a58,
    name: "svg-png-search-p-page___pt"
  }, {
    path: "/vectores-gratis/:search/p/:page",
    component: _02d06e37,
    name: "free-vectors-search-p-page___es"
  }, {
    path: "/vetores-gratis/:search/p/:page",
    component: _02d06e37,
    name: "free-vectors-search-p-page___pt"
  }, {
    path: "/categoria/:id/:slug",
    component: _606d6777,
    name: "category-id-slug___es"
  }, {
    path: "/categoria/:id/:slug",
    component: _606d6777,
    name: "category-id-slug___pt"
  }, {
    path: "/category/:id/:slug",
    component: _606d6777,
    name: "category-id-slug___en"
  }, {
    path: "/category/:id/:slug",
    component: _606d6777,
    name: "category-id-slug___de"
  }, {
    path: "/colecciones/:slug/:page",
    component: _d70aff5c,
    name: "collections-slug-page___es"
  }, {
    path: "/colecoes/:slug/:page",
    component: _d70aff5c,
    name: "collections-slug-page___pt"
  }, {
    path: "/collections/:slug/:page",
    component: _d70aff5c,
    name: "collections-slug-page___en"
  }, {
    path: "/estilo/:slug/:page",
    component: _9feb4562,
    name: "styles-slug-page___pt"
  }, {
    path: "/estilos/:slug/:page",
    component: _9feb4562,
    name: "styles-slug-page___es"
  }, {
    path: "/niches/:slug/:page",
    component: _4c0b277b,
    name: "niches-slug-page___en"
  }, {
    path: "/nichos/:slug/:page",
    component: _4c0b277b,
    name: "niches-slug-page___es"
  }, {
    path: "/nichos/:slug/:page",
    component: _4c0b277b,
    name: "niches-slug-page___pt"
  }, {
    path: "/nischen/:slug/:page",
    component: _4c0b277b,
    name: "niches-slug-page___de"
  }, {
    path: "/purchase-plan/:id/:keyword",
    component: _8c279a68,
    name: "purchase-plan-id-keyword___en"
  }, {
    path: "/purchase-plan/:id/:keyword",
    component: _8c279a68,
    name: "purchase-plan-id-keyword___de"
  }, {
    path: "/purchase-plan/:id/:keyword",
    component: _8c279a68,
    name: "purchase-plan-id-keyword___es"
  }, {
    path: "/purchase-plan/:id/:keyword",
    component: _8c279a68,
    name: "purchase-plan-id-keyword___pt"
  }, {
    path: "/sammlungen/:slug/:page",
    component: _d70aff5c,
    name: "collections-slug-page___de"
  }, {
    path: "/stilarten/:slug/:page",
    component: _9feb4562,
    name: "styles-slug-page___de"
  }, {
    path: "/styles/:slug/:page",
    component: _9feb4562,
    name: "styles-slug-page___en"
  }, {
    path: "/themen/:slug/:page",
    component: _25e3bfde,
    name: "topics-slug-page___de"
  }, {
    path: "/topicos/:slug/:page",
    component: _25e3bfde,
    name: "topics-slug-page___es"
  }, {
    path: "/topics/:slug/:page",
    component: _25e3bfde,
    name: "topics-slug-page___en"
  }, {
    path: "/topics/:slug/:page",
    component: _25e3bfde,
    name: "topics-slug-page___pt"
  }, {
    path: "/categoria/:id/:slug/p/:page",
    component: _1fbb95f4,
    name: "category-id-slug-p-page___es"
  }, {
    path: "/categoria/:id/:slug/p/:page",
    component: _1fbb95f4,
    name: "category-id-slug-p-page___pt"
  }, {
    path: "/category/:id/:slug/p/:page",
    component: _1fbb95f4,
    name: "category-id-slug-p-page___en"
  }, {
    path: "/category/:id/:slug/p/:page",
    component: _1fbb95f4,
    name: "category-id-slug-p-page___de"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___es"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___pt"
  }, {
    path: "/:uid",
    component: _7068dbe1,
    name: "uid___en"
  }, {
    path: "/:uid",
    component: _7068dbe1,
    name: "uid___de"
  }, {
    path: "/:uid",
    component: _7068dbe1,
    name: "uid___es"
  }, {
    path: "/:uid",
    component: _7068dbe1,
    name: "uid___pt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
