<template>
  <div class="container-download-options position-relative">
    <DownloadTooltip
      v-show="showTooltip"
      v-bind="{ ...tooltipProps }"
      :position="tooltipPosition"
    />

    <form ref="formDownloadSvg" :action="`${$config.API_URL}download`" method="post">
      <input type="hidden" name="idVector" :value="actualVector.id" />
      <input id="downloadSize" type="hidden" name="size" value="1" />
      <input id="downloadType" type="hidden" name="type" value="png" />
    </form>

    <div v-if="actualContentSecondaryDownload">
      <a
        class="download-btn link mr-2 secondary"
        :href="actualContentSecondaryDownload.link"
        target="_blank"
        @click="secondaryDownloadClick"
      >
        <span class="d-flex align-items-center">
          {{ actualContentSecondaryDownload.name }}
        </span>
      </a>
    </div>

    <UiButton
      v-if="!showSvgOptions"
      :enabled="!disabledDownload"
      color="green"
      class="download-btn"
      min-width="164"
      min-height="45"
      height="45"
      @click="authorizeDownload({ type: 'regular' })"
    >
      <span v-if="!loading.regular" class="d-flex align-items-center">
        {{ $t('preview.download.main.download') }}
        <div class="download-icon-box">
          <div class="icon-arrow-svg">
            <Icon :width="20" :height="14" icon="down-arrow" />
          </div>
          <div class="download-icon-floor"></div>
        </div>
      </span>
      <b-spinner v-else small variant="light" class="ml-4"></b-spinner>
    </UiButton>

    <DownloadOptions
      v-else
      :disabled-download="disabledDownload"
      :edit-color="showEditColor"
      :loading="loading"
      @authorize-download="authorizeDownload($event)"
    />
    <MessageModal is-on-preview />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DownloadOptions from './DownloadOptions';
import DownloadTooltip from './DownloadTooltip';
import Icon from '@/components/UI/Icon';
import UiButton from '@/components/UI/Button';
import MessageModal from '@/components/MessageModal';

export default {
  name: 'DownloadBtn',
  components: {
    DownloadOptions,
    DownloadTooltip,
    Icon,
    UiButton,
    MessageModal
  },
  data() {
    return {
      showSvgOptions: false,
      loading: {
        regular: false,
        png: false,
        png512: false,
        png1920: false,
        png4500: false,
        eps: false,
        svg: false,
        psd: false
      },
      showTooltip: false,
      tooltipPosition: 'top',
      tooltipProps: {
        title: null,
        text: null,
        buttonText: null
      }
    };
  },
  computed: {
    ...mapState('vectors', ['actualVector', 'showEditColor']),
    disabledDownload() {
      let isDownloading = false;
      const keys = Object.keys(this.loading);
      for (const key of keys) {
        isDownloading = isDownloading || this.loading[key];
      }
      return isDownloading;
    },
    actualContentSecondaryDownload() {
      const data = this.actualVector.secondaryDownload
        ? this.actualVector.secondaryDownload[this.$i18n.locale]
        : false;
      const hasKeys = data ? data.name && data.link : false;
      return hasKeys ? data : false;
    }
  },
  methods: {
    secondaryDownloadClick() {
      this.$dataLayerPush('click_secondary_preview_button', {
        preview: {
          clickUrl: this.actualContentSecondaryDownload.link
        }
      });
    },
    async authorizeDownload({ size, type }) {
      const isSvg = this.actualVector.isSvg;
      const vectorId = this.actualVector.id;
      const downloadType = type.includes('png') ? 'png' : type;
      const vectorType =
        this.actualVector.type != 'AI' ? this.actualVector.type.toLowerCase() : 'vector';
      this.$emit('click');

      if (isSvg && !this.showSvgOptions) {
        this.showSvgOptions = true;
      } else {
        this.gaEvent(isSvg ? downloadType : vectorType);
        this.loading[type] = true;
        await this.$store.dispatch('downloads/CHECK_DOWNLOAD_PERMISSION', vectorId);
        const canDownload = this.$store.getters['downloads/canDownload'](vectorId);
        const elementReason = this.$store.getters['downloads/elementReason'](vectorId);

        if (!canDownload) {
          if (elementReason == 'free_trial') {
            this.showTooltip = true;
            this.tooltipProps.title = this.$t('preview.download.tooltip.titleFreeTrial');
            this.tooltipProps.text = this.$t('preview.download.tooltip.textFreeTrial');
            this.tooltipProps.buttonText = this.$t('preview.download.tooltip.buttonTextFreeTrial');
          } else {
            this.$emit('show-purchase-flow');
          }
          this.loading[type] = false;
        } else {
          let editedSvg = '';

          if (isSvg) {
            const downloadableSvg = document.getElementById('downloadableSvg');
            editedSvg = downloadableSvg ? downloadableSvg.getElementsByTagName('svg')[0] : '';
          }

          if (!this.$auth.loggedIn) {
            const downloadsCookie = this.$cookies.get('vx.downloads');
            const downloads = downloadsCookie ? downloadsCookie : 0;

            if (downloads >= 10) {
              this.$bvModal.show('user-modal');
            } else {
              this.downloadFree(isSvg, downloadType, size, editedSvg);
            }
          } else if (this.$auth.user.idPlan === 1) {
            this.downloadFree(isSvg, downloadType, size, editedSvg);
          } else {
            if (isSvg) {
              this.generateSvg({ type: downloadType, size, editedSvg, freeDownload: false });
            } else {
              this.download({
                eventType: vectorType,
                url: this.actualVector.urlDownload
              });
            }
          }
          this.loading[type] = false;
        }
      }
    },
    seasonalRedirectToPlans() {
      this.$bvModal.hide('preview-vector-modal');
      this.$router.push(this.$trailingSlash(this.localePath('merch-plans')));
    },
    generateSvg({ type, size, editedSvg, freeDownload }) {
      if (editedSvg) {
        if (type === 'svg') {
          const xs = new XMLSerializer().serializeToString(editedSvg);
          let dataUrl = `data:image/svg+xml,${encodeURIComponent(xs)}`;

          this.processSvg({ freeDownload, size, type, url: dataUrl });
        } else {
          let canvas = document.getElementById('canvas');
          canvas.width = size;
          canvas.height = size;
          let ctx = canvas.getContext('2d');
          let SVGdata = new XMLSerializer().serializeToString(editedSvg);
          let DOMURL = window.URL || window.webkitURL || window;
          let img = new Image();

          SVGdata = SVGdata.replace(/ width="[^"]*"/g, '');
          SVGdata = SVGdata.replace(/ height="[^"]*"/g, '');

          let svgBlob = new Blob([SVGdata], {
            type: 'image/svg+xml;charset=utf-8'
          });
          let dataUrl = DOMURL.createObjectURL(svgBlob);

          img.onload = () => {
            ctx.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(dataUrl);
            const imgURI = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

            this.processSvg({ freeDownload, size, type, url: imgURI });
          };

          img.src = dataUrl;
        }
      } else {
        this.processSvg({ freeDownload, size, type });
      }
    },
    processSvg({ freeDownload, size, type, url = '' }) {
      if (freeDownload) {
        this.$store.commit('downloads/SET_SVG_DOWNLOAD', { size, type, editedSvg: url });
      } else {
        this.download({ size, type, url, isSvg: true, editedSvg: url, eventType: type });
      }
    },
    async download({ type = 'vector', size, url, isSvg, editedSvg, eventType = 'vector' }) {
      const vector = this.actualVector;
      const { id, idType, name, isRequest, image, urlSite } = vector;
      const { tags, categories, styles, niches, collection, attributes } = vector;
      this.$dataLayerPush('download_file', {
        download: {
          type: eventType
        },
        user: this.$auth.loggedIn ? this.$auth.user : null,
        content: {
          id,
          idType,
          type: vector.type,
          downloadType: eventType,
          name,
          isRequest,
          isSvg: vector.isSvg,
          image,
          urlSite,
          tags: tags.map((tag) => tag.name),
          styles: styles.map((style) => style.name),
          niches: niches.map((niche) => niche.name),
          categories: categories.map((category) => category.name),
          collection: collection && collection.name,
          attributes: attributes.map((attribute) => attribute.key)
        }
      });
      if (type === 'vector' || editedSvg) {
        const name = this.actualVector.canonizedName;
        const data = { id, url, name, isSvg, type, size };
        await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', data);
      } else {
        document.getElementById('downloadSize').value = size;
        document.getElementById('downloadType').value = type;
        this.$store.commit('downloads/SET_DOWNLOADED', true);
        this.$refs.formDownloadSvg.submit();
        this.$store.dispatch('user/LOAD_VECTORS_STATS', [id]);
      }
    },
    gaEvent(type) {
      this.$dataLayerPush('click_download_button', {
        download: {
          type
        }
      });
    },
    downloadFree(isSvg, downloadType, size, editedSvg) {
      if (isSvg) {
        this.generateSvg({ type: downloadType, size, editedSvg, freeDownload: true });
      }
      this.$emit('show-purchase-flow');
    }
  }
};
</script>
<style lang="scss">
#preview-vector-modal .container-download-options .download-btn > .vexels-button {
  @include md {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.download-icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  margin-left: 8px;
  transform: scale(0.8);
}
.download-icon-floor {
  width: 16px;
  height: 2px;
  background-color: white;
  border-radius: 2px;
}
@keyframes bounce {
  0%,
  10%,
  40%,
  70%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-4px);
  }
}
.container-download-options {
  display: flex;
  justify-content: flex-end;
  height: max-content;
  margin-left: auto;
  &:hover .icon-arrow-svg {
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  @include md {
    width: 100%;
  }
  .download-btn {
    display: flex;
    align-items: center;
    &.link {
      color: white;
      background-color: #3cd699;
      height: 45px;
      min-width: 164px;
      min-height: 45px;
      font-size: 15px;
      cursor: pointer;
      border-radius: 23px;
      color: white;
      padding-right: 28px;
      padding-left: 28px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &.secondary {
      border: 1.5px solid #3dd699;
      background-color: white;
      color: #3dd699;
      &:hover {
        background-color: #3dd699;
        color: white;
        border: none;
      }
    }
  }
  .seasonal-image {
    max-height: 45px;
  }
}
button {
  background-color: #3cd699;
  border: none;
  outline: none;
  color: #fff;
}
button:disabled {
  background-color: #d2d2d2 !important;
  border-color: #d2d2d2 !important;
  cursor: not-allowed !important;
}
button:disabled:hover {
  filter: none !important;
}
</style>
