<template>
  <div v-if="list.length > 0" class="styles">
    <div class="wrapper">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="contentClasses(item.selected)"
        @click="clickItem(item)"
      >
        <div class="icon" :style="iconStyles(index)">
          <div class="overlay"></div>
        </div>
        <span class="name">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clickItem(item) {
      this.$dataLayerPush('search_bar_menu_click', {
        clickSearchBarMenu: {
          url: this.itemLink(item),
          item: 'Styles'
        }
      });
      const path = this.itemLink(item);
      this.$router.push({ path });
      this.$emit('open-close-bar', false);
    },
    itemLink(item) {
      return this.$urlBuilder('style', { slug: item.slug });
    },
    contentClasses(selected) {
      var fixed = 'content link';
      if (selected) fixed = fixed + ' selected';
      return fixed;
    },
    iconStyles(index) {
      return `background-image: url(${this.list[index].thumbnail});`;
    }
  }
};
</script>

<style lang="scss" scoped>
.styles {
  display: flex;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 10px;
}
.styles .content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  @include xs {
    justify-content: left;
    text-align: left;
    flex: 50%;
    margin-bottom: 5px;
    margin-right: 0;
  }
}
.styles .wrapper {
  width: 100%;
  display: flex;
  @include xs {
    flex-wrap: wrap;
  }
}
.styles .icon {
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid #707070;
  transition: 0.3s;
  background-size: cover;
}
.styles .name {
  color: #707070;
  font-size: 14px;
  transition: 0.3s;
}
.styles .link:hover .icon {
  border: 2px solid white;
}
.styles .link:hover {
  text-decoration: none;
}
.content.selected .icon {
  border: 2px solid white;
}
</style>
