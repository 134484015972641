var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
    () => {
      _vm.$emit('toggleCreateFolderActive', false);
      _vm.editMode = false;
      _vm.isOpen = false;
    }
  ),expression:"\n    () => {\n      $emit('toggleCreateFolderActive', false);\n      editMode = false;\n      isOpen = false;\n    }\n  "}],style:({ position: 'relative' })},[_c('div',{style:({ width: '200px' })},[_c('div',{ref:"dropdownButton",on:{"click":() => {
          if (!_vm.isAuthenticated) {
            _vm.$bvModal.show('user-modal');
          } else {
            _vm.toggleDropdown();
          }
        }}},[_vm._t("trigger",function(){return [_c('div',{staticClass:"dropdown-button cursor-pointer"},[_vm._v("\n          "+_vm._s(_vm.selected.text)+"\n          "),_c('Icon',{attrs:{"icon":"bootstrap-caret-down-fill","width":9,"height":9}})],1)]})],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"dropdownMenu",staticClass:"dropdown-container"},[_c('div',{staticClass:"dropdown-row"},[_c('div',{staticClass:"dropdown-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('profile.main.favorites.chooseFolder')))])]),_vm._v(" "),_c('div',{class:{ 'scrollable-container': _vm.scrollable }},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"cursor-pointer dropdown-option",class:{
              selected: option.value === _vm.selected
            },style:({ width: '200px' }),on:{"click":function($event){$event.preventDefault();return _vm.setSelected(option, option.value === _vm.selected)}}},[_c('span',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(_vm._s(option.text))])])}),0),_vm._v(" "),_c('div',[_c('div',{staticClass:"new-button cursor-pointer",style:(_vm.editMode && { background: '#fff' }),on:{"click":_vm.toggleEditMode}},[_c('Icon',{staticClass:"plus-svg",attrs:{"icon":"close","width":15,"height":15,"color":"grey","bold":true}}),_vm._v(" "),(_vm.editMode)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newFolderName),expression:"newFolderName"}],ref:"input",staticClass:"new-input",domProps:{"value":(_vm.newFolderName)},on:{"focus":() => _vm.$emit('toggleCreateFolderActive', true),"click":[function($event){$event.stopPropagation();},(e) => e.preventDefault()],"change":function($event){return _vm.handleFolderInputChange($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleCreateFolder($event)},"input":function($event){if($event.target.composing)return;_vm.newFolderName=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.editMode)?_c('button',{staticClass:"plain cursor-pointer",on:{"click":function($event){return _vm.handleCreateFolder($event)}}},[_c('Icon',{attrs:{"icon":"tick-v1","width":15,"height":15,"color":_vm.tickColor,"bold":true}})],1):_c('span',[_vm._v(_vm._s(_vm.addNewText))])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }