<template>
  <b-container class="pb-4 d-flex justify-content-center align-items-start h-100 position-relative">
    <div
      :class="`${loading ? 'd-flex' : 'd-none'} overlay justify-content-center align-items-center`"
    >
      <Loading />
    </div>
    <b-col
      :style="{ maxWidth: '350px', height: '260px', marginTop: '30px' }"
      :class="`${
        showEmail ? 'd-none' : 'd-flex'
      } flex-column justify-content-around align-items-center`"
    >
      <div class="continue-to-subscribe">{{ $t('profile.logIn.main.continueToSubscribe') }}</div>
      <div class="w-100">
        <Socials
          :is-login="true"
          :col="true"
          :purchase-mode="true"
          @login="login"
          @loginWithGoogle="loginWithGoogle"
          @loginWithFacebook="loginWithFacebook"
        />
        <div
          class="button d-flex justify-content-center align-items-center px-3 login-with-email my-2"
          @click="loginWithEmail"
        >
          <img
            src="https://cdn.vexels.com/img/envelope-icon.svg"
            width="16"
            height="16"
            class="mr-1"
          />
          <span>{{ $t('profile.logIn.main.continueWith') }} <span>Email</span></span>
        </div>
      </div>
    </b-col>
    <b-col
      v-if="showEmail"
      class="d-flex flex-column justify-content-center"
      :style="{ maxWidth: '350px', height: '350px', position: 'initial', minHeight: '500px' }"
    >
      <div
        v-show="!loading"
        role="button"
        class="position-absolute"
        :style="{ top: '0', left: '0', padding: '30px 40px' }"
        @click="emailBack"
      >
        <Icon class="invert" icon="arrow" color="black" :width="13" :height="13" />
        {{ $t('profile.logIn.main.back') }}
      </div>
      <LoginForm
        v-if="emailMode === 'login'"
        @loading="(status) => (loading = status)"
        @login="login"
      />
      <SignUpForm
        v-if="emailMode === 'signup'"
        @loading="(status) => (loading = status)"
        @signup="login"
      />
      <div v-if="emailMode === 'login' && !loading" class="text-center mt-4">
        {{ $t('profile.signUp.main.dontHaveAccount') }}
        <span role="button" class="text-primary" @click="() => (emailMode = 'signup')">{{
          $t('profile.signUp.main.title')
        }}</span>
      </div>
      <div v-if="emailMode === 'signup' && !loading" class="text-center mt-4">
        {{ $t('profile.logIn.main.alreadyHaveAccount') }}
        <span role="button" class="text-primary" @click="() => (emailMode = 'login')">{{
          $t('profile.logIn.main.title')
        }}</span>
      </div>
    </b-col>
  </b-container>
</template>

<script>
import Loading from 'UI/Loading';
import Socials from '@/components/User/Form/Socials';
import Icon from 'UI/Icon';
const LoginForm = () => import('@/components/User/Form/LoginForm');
const SignUpForm = () => import('@/components/User/Form/SignUpForm');
export default {
  components: {
    LoginForm,
    SignUpForm,
    Loading,
    Socials,
    Icon
  },
  props: {
    afterLogin: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      emailMode: 'login',
      showEmail: false,
      loading: false
    };
  },
  mounted() {
    this.$auth.init();
  },
  methods: {
    login() {
      this.setAfterLoginCookie();
      this.$emit('login');
    },
    setAfterLoginCookie() {
      if (this.afterLogin && this.afterLogin != '') {
        this.$cookies.set('afterLoginRedirect', this.afterLogin);
      }
    },
    loginWithGoogle() {
      this.$dataLayerPush('purchase_flow_login_with', {
        provider: 'google'
      });
    },
    loginWithFacebook() {
      this.$dataLayerPush('purchase_flow_login_with', {
        provider: 'facebook'
      });
    },
    loginWithEmail() {
      this.$dataLayerPush('purchase_flow_login_with', {
        provider: 'email'
      });
      this.showEmail = true;
    },
    emailBack() {
      this.$dataLayerPush('purchase_flow_email_back');
      this.showEmail = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.continue-to-subscribe {
  font-weight: bold;
}
.login-with-email {
  padding-top: 1em;
  padding-bottom: 1em;
}
.overlay {
  display: none;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
  position: absolute;
  margin-top: 24px;
  border-radius: 3px;
}
.button {
  color: gray;
  cursor: pointer;
  border-radius: 30px;
  background-color: $lightGray;
  span {
    font-size: 12px;
    white-space: nowrap;
  }
  &.login-with-email {
    span {
      color: black !important;
      font-size: 14px !important;
    }
  }
}
</style>
