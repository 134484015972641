<template>
  <span>
    <div class="light-background">
      <CommonOverlay v-if="hasLikeDownload" :data="data">
        <SvgMerchEffect :data="data" />
      </CommonOverlay>
      <div v-else class="overlay" :style="overlayStyles">
        <SvgMerchEffect :data="data" />
      </div>
    </div>
  </span>
</template>

<script>
import CommonOverlay from '@/components/Vectors/CommonOverlay';
import SvgMerchEffect from '@/components/Vectors/SvgMerchEffect';
export default {
  components: {
    CommonOverlay,
    SvgMerchEffect
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    hasLikeDownload: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    overlayStyles() {
      return `border-radius: ${this.data.borderRadius}px;`;
    }
  }
};
</script>

<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px !important;
}
.light-background {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
