const routeType = {
  design: [
    'design',
    'design-featured-graphics',
    'design-featured-graphics-p-page',
    'design-graphics',
    'design-graphics-p',
    'design-graphics-p-page',
    'design-graphics-search',
    'design-graphics-search-p-page',
    'design-latest-vectors',
    'design-latest-vectors-p-page',
    'design-plans',
    'design-plans-keyword',
    'design-trending-graphics',
    'design-trending-graphics-p-page',
    'topics',
    'topics-slug',
    'topics-slug-page',
    'editables',
    'editables-p',
    'editables-p-page',
    'editables-search',
    'editables-search-p-page',
    'free-vectors',
    'free-vectors-p',
    'free-vectors-p-page',
    'free-vectors-search',
    'free-vectors-search-p-page',
    'icon',
    'icon-p',
    'icon-p-page',
    'icon-search',
    'icon-search-p-page',
    'logos',
    'logos-p',
    'logos-p-page',
    'logos-search',
    'logos-search-p-page',
    'psd',
    'psd-p',
    'psd-p-page',
    'psd-search',
    'psd-search-p-page',
    'svg-png',
    'svg-png-p',
    'svg-png-p-page',
    'svg-png-search',
    'svg-png-search-p-page'
  ],
  merch: [
    'lifetime-plans',
    'merch',
    'merch-p',
    'merch-p-page',
    'merch-search',
    'merch-search-p-page',
    'merch-designs',
    'merch-designs-p',
    'merch-designs-p-page',
    'merch-designs-search',
    'merch-designs-search-p-page',
    'merch-featured-designs',
    'merch-featured-designs-p-page',
    'merch-latest-designs',
    'merch-latest-designs-p-page',
    'merch-p-page',
    'merch-plans',
    'merch-plans-keyword',
    'merch-png',
    'merch-png-p',
    'merch-png-p-page',
    'merch-png-search',
    'merch-png-search-p-page',
    'merch-psd',
    'merch-psd-p',
    'merch-psd-p-page',
    'merch-psd-search',
    'merch-psd-search-p-page',
    'merch-trending-designs',
    'merch-trending-designs-p-page',
    'merch-vectors',
    'merch-vectors-p',
    'merch-vectors-p-page',
    'merch-vectors-search',
    'merch-vectors-search-p-page',
    'niches',
    'niches-slug',
    'niches-slug-page',
    'choose-your-gift'
  ],
  default: [
    'about-us',
    'categories',
    'category-id',
    'category-id-slug',
    'category-id-slug-p-page',
    'collections',
    'collections-slug',
    'collections-slug-page',
    'enterprise',
    'featured-vectors',
    'featured-vectors-p-page',
    'graphics',
    'graphics-p',
    'graphics-p-page',
    'graphics-search',
    'graphics-search-p-page',
    'index',
    'home',
    'new-vexels',
    'our-graphics',
    'our-graphics-p-page',
    'spin-wheel',
    'styles',
    'styles-slug',
    'styles-slug-page',
    'terms-and-conditions',
    'trending-vectors',
    'trending-vectors-p-page',
    'login',
    'login-forgot',
    'login-forgot-callback',
    'payment-successful',
    'plans',
    'preview',
    'privacy-policy',
    'purchase',
    'purchase-id',
    'purchase-plan-id',
    'purchase-plan-id-keyword',
    'redeem-coupon',
    'vectors-preview-id-name',
    'png-svg-preview-id-name',
    'psd-preview-id-name',
    'editables-preview-id-name'
  ],
  dependsOnUserPlan: [
    'profile',
    'profile-tools',
    'profile-my-requests',
    'profile-my-requests-new',
    'profile-my-designs',
    'profile-billing',
    'profile-favorites',
    'profile-downloads',
    'profile-account'
  ]
};

export default function ({ app }) {
  app.router.beforeResolve((to, from, next) => {
    let name = app.getRouteBaseName();
    experienceByRoute(name)
      .then(() => next())
      .catch(() => {
        console.error('Experience error:', to.fullPath);
        next();
      });
  });

  const experienceByRoute = async (routeName) => {
    if (routeName !== 'uid') {
      const designExperience = app.store.getters['experience/getDesign'];
      const merchExperience = app.store.getters['experience/getMerch'];

      if (routeType.merch.includes(routeName)) {
        app.store.commit('experience/SET_USER_EXPERIENCE', merchExperience);
      } else if (routeType.design.includes(routeName)) {
        app.store.commit('experience/SET_USER_EXPERIENCE', designExperience);
      } else if (routeType.default.includes(routeName)) {
        app.store.commit('experience/SET_USER_EXPERIENCE', '');
      } else if (routeType.dependsOnUserPlan.includes(routeName)) {
        app.store.commit(
          'experience/SET_USER_EXPERIENCE',
          app.$auth.user.userPlanType || designExperience
        );
      } else {
        console.error(`Page wihout experience on ${routeName}`);
      }
    }
    return Promise.resolve();
  };
}
