<template>
  <div
    class="like-container"
    @mouseover="hover = true"
    @mouseleave="
      () => {
        if (!createFoldersActive) hover = false;
      }
    "
    @click.stop.prevent
  >
    <FoldersSelect
      :id-vector="vectorId"
      :hover="hover"
      :create-folders-active="createFoldersActive"
      @toggleHover="(state) => toggleHover(state)"
      @toggleCreateFolderActive="(state) => $emit('toggleCreateFolderActive', state)"
    >
      <template #trigger>
        <div class="icon-container">
          <Icon
            class="icon"
            color="#ffffff"
            :bold="hover"
            :height="hover ? 15 : 30"
            :width="hover ? 15 : 30"
            :icon="hover ? 'bootstrap-chevron-down' : likeIcon"
          />
        </div>
      </template>
    </FoldersSelect>
    <span class="text" @click.stop.prevent="likeVector(vectorId)">{{
      isLiked ? $t('main.vectors.commonOverlay.text5') : $t('main.vectors.commonOverlay.text3')
    }}</span>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import FoldersSelect from '@/components/Profile/FoldersSelect.vue';

export default {
  components: {
    Icon,
    FoldersSelect
  },
  props: {
    vectorId: {
      type: Number,
      required: true
    },
    createFoldersActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    isLiked() {
      return this.$store.getters['user/isVectorLiked'](parseInt(this.vectorId));
    },
    likeIcon() {
      return this.isLiked ? 'like-full' : 'like-empty';
    }
  },
  watch: {
    createFoldersActive(value) {
      if (!value) this.hover = value;
    }
  },
  methods: {
    likeVector(idVector) {
      this.$store.dispatch('user/SET_LIKE_VECTOR', {
        idVector,
        isLiked: this.isLiked,
        bvModal: this.$bvModal
      });
    },
    toggleHover(state) {
      this.hover = state;
    }
  }
};
</script>

<style lang="scss" scoped>
.like-container {
  width: 38px;
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 2;
  border-radius: 7px;
  margin-top: 10px;
  margin-right: 10px;
  transition: all 0.3s ease-out;
  box-sizing: border-box;

  &:hover {
    background-color: $merchColor;
    border-radius: 0px 7px 7px 0px;
  }
  &:hover .text {
    right: 37px;
    transform: scaleX(1);
    opacity: 1;
  }
  .icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 38px;
    height: 38px;
    .icon {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .text {
    font-size: 12px;
    min-width: 10px;
    padding: 10px;
    width: max-content;
    position: absolute;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    color: #fff;
    transform: scaleX(0);
    border-radius: 7px 0px 0px 7px;
    top: 0;
    right: 38px;
    opacity: 0;
    transform-origin: right center;
    z-index: -1;
    transition: inherit;
    box-sizing: border-box;
  }
}
</style>
