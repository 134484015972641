<template>
  <b-col class="paypal-container">
    <b-row>
      <b-col>
        <form
          ref="formPaypal"
          class="d-none"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" :value="openPlan.paypalButtonId" />
          <input type="hidden" name="lc" value="US" />
          <input type="hidden" name="no_note" value="1" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="item_name" :value="vectorName" />
          <input id="item_number" type="hidden" name="item_number" :value="vectorId" />
          <input type="hidden" name="custom" :value="custom" />
          <input type="hidden" name="business" value="billing@lateralcreativa.com" />
          <input type="hidden" value="2" name="rm" />
          <input type="hidden" name="return" :value="buildUrl('payment-successful')" />
          <input type="hidden" name="cancel_return" :value="buildUrl('paypal-cancelled')" />
          <input type="hidden" name="notify_url" :value="buildUrl('paypal-payment')" />
        </form>
      </b-col>
    </b-row>
    <b-row v-if="error">
      <b-col class="mx-4">
        <div class="text-danger small">
          {{ $t('contact.form.main.errorSubmiting') }}
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    default: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      custom: '',
      error: false
    };
  },
  computed: {
    ...mapState('plans', ['openPlan']),
    ...mapState('vectors', ['actualVector', 'actualVectorId']),
    vectorName() {
      return this.actualVector ? this.actualVector.name : this.openPlan.name;
    },
    vectorId() {
      return this.actualVectorId ? this.actualVectorId : '';
    },
    vectorUrlDownload() {
      return this.actualVector ? this.actualVector.urlDownload : '';
    },
    isSubscription() {
      return this.openPlan.recurrent ? 'yes' : 'no';
    },
    periodAv() {
      if (this.openPlan.chargeFrecuency == 'ANNUALLY') {
        return '/year';
      } else if (
        this.openPlan.chargeFrecuency == 'MONTHLY' ||
        this.openPlan.chargeFrecuency == 'MONTHLY_ANNUAL_COMMITMENT'
      ) {
        return '/mo';
      } else {
        return '';
      }
    }
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.setBuyFunction();
      }
    }
  },
  destroyed() {
    this.error = false;
    this.custom = '';
  },
  mounted() {
    if (this.default) {
      this.setBuyFunction();
    }
  },
  methods: {
    buildCustom() {
      if (this.$auth.user.idUser) {
        let customValues = {
          idPlan: this.openPlan.id,
          '@idVexelsUser': this.$auth.user.idUser,
          '@isSubscription': this.isSubscription,
          '@redirectVector': this.vectorId,
          '@vectorUrlDownload': this.vectorUrlDownload,
          '@idVector': this.vectorId,
          '@lang': this.$i18n.locale,
          '@country_code': this.$auth.user.countryCode,
          '@country_name': this.$auth.user.countryName,
          '@costPlan': this.openPlan.cost,
          '@tapfiliate_click_id': this.$cookies.get('vx.aff'),
          '@ga_client_id': this.$cookies.get('_ga')
        };

        let customTmp = '';

        for (var [key, value] of Object.entries(customValues)) {
          customTmp += key + ':' + value;
        }

        this.custom = customTmp;
      }
    },
    submitFormPayPal() {
      this.$auth.fetchUser(true);
      this.buildCustom();
      if (this.custom) {
        setTimeout(() => {
          this.$refs.formPaypal.submit();
        }, 300);
      } else {
        let vxId = this.$cookies.get('vx.id');
        let vxStrategy = this.$cookies.get('vx.strategy');
        let vxToken = this.$cookies.get(`vx.token.${vxStrategy}`);
        let dataError = { vxId, vxStrategy, vxToken };
        this.error = true;
        console.error(`Submit Form PayPal ${JSON.stringify(dataError)}`);
      }
    },
    buildUrl(url = '') {
      return window.location.origin + `/${url}`;
    },
    setBuyFunction() {
      this.$store.commit('payments/SET_BUY_NOW_FUNCTION', this.submitFormPayPal);
    }
  }
};
</script>

<style lang="scss" scoped>
.paypal-container {
  color: #bbbbbb;
}
.data-text {
  font-weight: 500;
}
</style>
