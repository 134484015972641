<template>
  <div class="header-margin">
    <div v-if="header.type == 'generic'">
      {{ $t('preview.relateds.groupers.genericBlockTitle') }}
    </div>
    <TagsHeader v-else-if="header.type == 'tags'" :data="header.data" />
    <NichesHeader v-else-if="header.type == 'niche'" :data="header.data" />
    <NichesSvgsHeader v-else-if="header.type == 'nicheSvgs'" :data="header.data" />
    <StyleHeader v-else-if="header.type == 'style'" :data="header.data" />
    <CollectionHeader v-else-if="header.type == 'collection'" :data="header.data" />
  </div>
</template>

<script>
import TagsHeader from '@/components/Preview/Relateds/TagsHeader.vue';
import NichesHeader from '@/components/Preview/Relateds/NichesHeader.vue';
import NichesSvgsHeader from '@/components/Preview/Relateds/NichesSvgsHeader.vue';
import StyleHeader from '@/components/Preview/Relateds/StyleHeader.vue';
import CollectionHeader from '@/components/Preview/Relateds/CollectionHeader.vue';

export default {
  components: {
    TagsHeader,
    NichesHeader,
    NichesSvgsHeader,
    CollectionHeader,
    StyleHeader
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  computed: {
    blockType() {
      return this.block.type;
    },
    isCriteriaDataOk() {
      const data = this.block.criteria;
      if (!data) {
        return false;
      }
      if (Array.isArray(data) && data.length == 0) {
        return false;
      }
      if (typeof data === 'object' && data !== null && Object.keys(data).length == 0) {
        return false;
      }
      return true;
    },
    header() {
      if (this.isCriteriaDataOk) {
        const data = this.block.criteria;
        if (
          this.blockType == 'groupByTagAndNiches' ||
          this.blockType == 'groupByTagOrNiches' ||
          this.blockType == 'groupMockupsByTag' ||
          this.blockType == 'groupAllSearch'
        ) {
          if (this.isNotEmptyArray(data)) {
            return { type: 'tags', data: { tags: data } };
          }
        } else if (this.blockType == 'groupSVGsTags') {
          if (this.isNotEmptyArray(data)) {
            return { type: 'tags', data: { tags: data, path: 'merch-png' } };
          }
        } else if (this.blockType == 'groupSVGs') {
          if (this.arrayHasFirstItem(data)) {
            return { type: 'nicheSvgs', data: data[0] };
          }
        } else if (this.blockType == 'groupByStyle') {
          if (this.arrayHasFirstItem(data)) {
            return { type: 'style', data: data[0] };
          }
        } else if (this.blockType == 'groupByCollection') {
          if (this.arrayHasFirstItem(data)) {
            return { type: 'collection', data: data[0] };
          }
        } else if (
          this.blockType == 'groupByNiches' ||
          this.blockType == 'groupByFirstNicheOrParentNiches' ||
          this.blockType == 'groupNiches' ||
          this.blockType == 'groupVectorsByNiches'
        ) {
          if (this.arrayHasFirstItem(data)) {
            return { type: 'niche', data: data[0] };
          }
        }
      }
      return { type: 'generic' };
    }
  },
  methods: {
    isNotEmptyArray(data) {
      return Array.isArray(data) && data.length >= 1;
    },
    arrayHasFirstItem(data) {
      return this.isNotEmptyArray(data) && data[0];
    }
  }
};
</script>
<style lang="scss" scoped>
.header-margin {
  margin: 0 5px;
}
</style>
