<template>
  <b-container>
    <form ref="formDownloadSvg" :action="`${$config.API_URL}download`" method="post">
      <input type="hidden" name="idVector" :value="actualVector.id" />
      <input id="downloadSize" type="hidden" name="size" :value="svgDownload.size" />
      <input id="downloadType" type="hidden" name="type" :value="svgDownload.type" />
    </form>
    <b-row v-if="!downloaded" class="my-5 position-relative">
      <div v-if="isDownloading" class="loading-container position-absolute w-100 h-100">
        <Loading />
      </div>
      <b-col lg="6" class="px-2">
        <WaitToDownload :url-download="urlDownload" @download="download" />
      </b-col>
      <b-col lg="6" class="px-2">
        <SubscribersCard />
      </b-col>
    </b-row>
    <DownloadSuccessful v-else />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

import DownloadSuccessful from './DownloadSuccessful.vue';
import Loading from 'UI/Loading';
import SubscribersCard from './SubscribersCard.vue';
import WaitToDownload from './WaitToDownload.vue';

export default {
  name: 'Download',
  components: {
    DownloadSuccessful,
    Loading,
    SubscribersCard,
    WaitToDownload
  },
  data: () => {
    return {
      downloaded: false,
      isDownloading: false,
      urlDownload: '#'
    };
  },
  computed: {
    ...mapGetters('downloads', ['svgDownload']),
    ...mapState('vectors', ['actualVector'])
  },
  beforeDestroy() {
    this.$store.commit('downloads/SET_SVG_DOWNLOAD', { size: 1920, type: 'png', editedSvg: '' });
  },
  methods: {
    async download() {
      const vector = this.actualVector;
      const { id, idType, name, isRequest, image, urlSite } = vector;
      const { tags, categories, styles, niches, collection, attributes } = vector;
      try {
        this.isDownloading = true;
        this.urlDownload = this.actualVector.urlDownload;
        if (this.actualVector.isSvg) {
          const { type, editedSvg } = this.svgDownload;
          if (editedSvg) {
            this.downloadEditedSvg({ type, editedSvg });
          } else {
            this.$store.commit('downloads/SET_DOWNLOADED', true);
            this.$refs.formDownloadSvg.submit();
            this.$store.dispatch('user/LOAD_VECTORS_STATS', [this.actualVector.id]);
          }
        } else {
          const data = { id, url: this.urlDownload };
          await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', data);
        }
        const typeDownloaded = this.actualVector.isSvg ? this.svgDownload.type : 'vector';
        this.$dataLayerPush('download_file', {
          download: {
            type: typeDownloaded
          },
          user: this.$auth.loggedIn ? this.$auth.user : null,
          content: {
            id,
            idType,
            type: vector.type,
            downloadType: typeDownloaded,
            name,
            isRequest,
            isSvg: vector.isSvg,
            image,
            urlSite,
            tags: tags.map((tag) => tag.name),
            styles: styles.map((style) => style.name),
            niches: niches.map((niche) => niche.name),
            categories: categories.map((category) => category.name),
            collection: collection.map((collection) => collection.name),
            attributes: attributes.map((attribute) => attribute.key)
          }
        });
        this.isDownloading = false;
        setTimeout(() => {
          this.downloaded = true;
        }, 2000);
      } catch ({ message }) {
        console.error(message);
      }
    },
    async downloadEditedSvg({ type, editedSvg }) {
      let data = {
        id: this.actualVector.id,
        name: this.actualVector.canonizedName,
        type,
        url: editedSvg,
        isSvg: true
      };
      await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', data);
    }
  }
};
</script>

<style lang="scss">
.loading-container {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
