export const mutations = {
  SET_NICHES(state, data) {
    state.niches = data;
  },
  SET_NICHE(state, data) {
    state.niche = data;
  },
  SET_NICHE_CONTENT(state, value = []) {
    state.nicheContent = value.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });
  },
  SET_LOADING_NICHE(state, value) {
    state.loadingNiche = value;
  },
  SET_NICHE_CURRENT_PAGE(state, value) {
    state.nicheCurrentPage = value;
  },
  SET_NICHE_TOTAL_PAGES(state, data) {
    state.nicheTotalPages = data;
  },
  SET_NICHES_CURRENT_PAGE(state, value) {
    state.nichesCurrentPage = value;
  },
  SET_NICHES_TOTAL_PAGES(state, data) {
    state.nichesTotalPages = data;
  },
  SET_NICHE_LISTING(state, { name, type, data }) {
    if (name && type && data.length) {
      data.map((el) => {
        el.title = el.name;
        el.image = el.image ? el.image : el.cover;
        el.link = this.$urlBuilder(type, {
          slug: el.slug
        });
      });
      state.nicheListings = { ...state.nicheListings, [name]: data };
    }
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  }
};

export const getters = {
  getNichesName(state) {
    return state.nichesName;
  },
  getTopicsName(state) {
    return state.topicsName;
  },
  listExist: (state) => (name) => {
    return name && state.nicheListings[name]?.length;
  },
  getNicheList: (state) => (name) => {
    return name ? state.nicheListings[name] : [];
  },
  getImageFirstResult(state) {
    return state.nicheContent.length ? state.nicheContent[0].image : '';
  }
};

export const actions = {
  async LOAD_NICHES({ commit, state }, data = {}) {
    try {
      commit('SET_LOADING_NICHE', true);
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : 12;

      if (data.page > state.nichesCurrentPage && state.nichesCurrentPage < state.nichesTotalPages) {
        const response = await this.$axios.post('/niche/', data);
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const niches = response.data.data.niches ? response.data.data.niches : [];
        commit('SET_NICHES', [...state.niches, ...niches]);
        commit('SET_NICHES_CURRENT_PAGE', data.page);
        commit('SET_NICHES_TOTAL_PAGES', totalPages);
      }
    } catch (error) {
      console.error('Niches | LOAD_NICHES:', error);
    } finally {
      commit('SET_LOADING_NICHE', false);
    }
  },
  async LOAD_NICHE({ commit }, { slug }) {
    try {
      const {
        data: { data = {} }
      } = await this.$axios.get(`/niche/${slug}/`);
      commit('SET_NICHE', data);
    } catch (error) {
      console.error('Niches | LOAD_NICHE:', error);
    }
  },
  async LOAD_NICHE_CONTENT({ commit, rootGetters, dispatch }, data) {
    try {
      commit('SET_LOADING', true);
      const searchAmount = this.$g('SEARCH_AMOUNT');
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : searchAmount;
      let exp = rootGetters['experience/getExperience'];
      if (!exp) {
        exp = rootGetters['experience/getMerch'];
      }
      const config = { headers: { Experience: exp } };
      const response = await this.$axios.post(`/niche/${data.slug}/contents/`, data, config);
      if (!response.data.error) {
        const vectors = response.data.data.vectors ? response.data.data.vectors : [];
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const currentPage = data.page;
        commit('SET_NICHE_CONTENT', vectors);
        commit('SET_NICHE_CURRENT_PAGE', currentPage);
        commit('SET_NICHE_TOTAL_PAGES', totalPages);
        dispatch('vectors/ADD_LISTING', vectors, { root: true });
        commit('SET_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOADING', false);
      console.error('Niches | LOAD_NICHE_CONTENT:', error);
    }
  },
  async LOAD_NICHE_LISTING({ commit, getters }, { name, type, ids }) {
    if (name && type && !getters.listExist(name)) {
      try {
        const { data } = await this.$axios.post('/niche/custom-list/', { ids });
        if (!data.error) {
          commit('SET_NICHE_LISTING', { name, type, data: data.data?.niches });
        } else {
          console.error('Niches | LOAD_NICHE_LISTING 1:', data.error);
        }
      } catch (error) {
        console.error('Niches | LOAD_NICHE_LISTING 2:', error);
      }
    } else if (name && type) {
      commit('SET_NICHE_LISTING', { name, type, data: getters.getNicheList(name) });
    }
  },
  CLEAN_NICHES({ commit }) {
    commit('SET_NICHE_CONTENT', []);
    commit('SET_NICHE_CURRENT_PAGE', -1);
    commit('SET_NICHE_TOTAL_PAGES', 0);
  }
};

export const state = () => ({
  nichesName: 'niches',
  topicsName: 'topics',
  niches: [],
  niche: {},
  nicheContent: [],
  loadingNiche: false,
  nicheCurrentPage: -1,
  nicheTotalPages: 0,
  nichesCurrentPage: -1,
  nichesTotalPages: 0,
  // LISTING
  nicheListings: {},
  isLoading: false
});
