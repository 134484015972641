<template>
  <div
    id="header-container"
    class="header-container"
    :class="{
      'vx-custom-color-background': hasCustomBackgroundColor,
      'low-z-index': searchBarBannerIsOpen
    }"
  >
    <div class="top-menu-container">
      <MenuOptions />
    </div>

    <div class="vx-nav" :style="{ boxShadow }">
      <b-col class="logo-section-container">
        <LogoSection />
      </b-col>
      <b-col class="col-md-6 search-bar-container">
        <div
          class="search-bar-wrapper"
          :class="{
            'hide-merch-design': !getShowingSearchBarOnTop,
            'hide-search-bar': !showSearchBar
          }"
        >
          <SearchBar :show-wrapper-border="false" />
        </div>
      </b-col>
      <div class="col d-flex justify-content-end align-items-center">
        <div class="col-auto d-lg-none p-1"><MenuMobile /></div>
      </div>
    </div>

    <BottomSearchBar class="show-lg" :show-filters="showFilters" />
    <ProfileMenu />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BottomSearchBar from './BottomSearchBar';
import LogoSection from './LogoSection';
import MenuOptions from './MenuOptions/index';
import ProfileMenu from './ProfileMenu';
import MenuMobile from '@/components/Header/MenuMobile/Index';

const SearchBar = () => import('@/components/SearchBar');

export default {
  name: 'HeaderApp',
  components: {
    BottomSearchBar,
    LogoSection,
    MenuOptions,
    ProfileMenu,
    SearchBar,
    MenuMobile
  },
  data: () => {
    return {
      showFilters: false,
      whiteNav: false,
      width: 0,
      scrollPosition: null,
      showSearchBar: true
    };
  },
  computed: {
    ...mapGetters('config', ['hasCustomBackgroundColor']),
    ...mapGetters('searchBar', ['isAnyBannerOpen', 'getShowingSearchBarOnTop']),
    ...mapGetters('config', ['isTransparentHeader']),
    searchBarBannerIsOpen() {
      return this.isAnyBannerOpen && !this.getShowingSearchBarOnTop;
    },
    boxShadow() {
      return this.isTransparentHeader ? 'none' : '0px 2px 11px 3px rgba(0, 0, 0, 0.1)';
    }
  },
  watch: {
    $route() {
      this.hideSearchBarIfPurchasePage();
    }
  },
  created: async function () {
    const isSearch = Boolean(this.$route.params.search);
    this.showFilters = isSearch;

    if (isSearch) {
      const keyword = this.$route.params.search ? this.$route.params.search.replace('-', ' ') : '';
      this.$store.commit('searchBar/SET_ACTUAL_SEARCH', keyword);
    }
    this.hideSearchBarIfPurchasePage();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.updateScroll);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    hideSearchBarIfPurchasePage() {
      try {
        const path = this.$route.fullPath;
        const regexPurchasePage = /^\/(purchase|kauf|compra)(\/\d+)?$/;
        if (regexPurchasePage.test(path)) {
          this.showSearchBar = false;
        } else {
          this.showSearchBar = true;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hide-search-bar {
  display: none !important;
}
.header-container {
  background-color: $white;
  z-index: 900;
  position: sticky;
  top: 0;
  width: 100%;
  transition: height 0.3s;
  &.low-z-index {
    z-index: 100;
  }
  @include lg {
    position: initial;
    width: initial;
    height: auto;
  }
  .logo-section-container {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  .search-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    @include lg {
      display: none;
    }
  }
  .search-bar-wrapper {
    display: flex;
    margin-left: 10px;
    width: 100%;
    @include lg {
      display: none;
    }
  }
  .left-side {
    display: flex;
  }
  .show-lg {
    display: none;
    @include lg {
      display: flex;
    }
  }
  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }
}
.vx-custom-color-background {
  background-color: #272727;
}
.vx-nav {
  display: flex;
  flex-grow: 1;
  height: 60px;
  justify-content: space-between;
  padding: 0px 5px;
}

.top-menu-container {
  background: #1d1d1d;
}
</style>
