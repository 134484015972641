<!-- eslint-disable max-len -->
<template>
  <div
    v-show="showBanner"
    class="flex flex-column bg-gray-100 mb-2 px-4 py-2 rounded-xl h-[185px] justify-end"
  >
    <div class="flex flex-wrap gap-2 overflow-hidden max-h-[150px]">
      <a
        v-for="result in results"
        :key="result.id"
        class="rounded-md overflow-hidden justify-center items-center flex-auto max-h-full h-[150px] flex relative"
        :style="{
          clipPath: 'inset(0 0 9px 0)'
        }"
        target="_blank"
        rel="sponsored"
        aria-label="Sponsored Images"
        :href="result.link"
      >
        <div class="rounded-md h-[130px] overflow-hidden">
          <img :src="result.images.large.url" :alt="result.description" class="h-[140px]" />
        </div>
      </a>
    </div>
    <div class="flex flex-resp justify-between text-gray-600 text-sm">
      <div class="flex flex-resp leading-4" :style="{ lineHeight: '16px' }">
        <div class="flex">
          <span class="whitespace-nowrap">{{ $t('preview.main.sponsored.sponsoredText') }}</span>
          <img
            src="https://cdn.vexels.com/img/shutterstock.svg"
            alt="Shutterstock Logo"
            width="120"
            height="13"
          />
        </div>
        <span class="whitespace-nowrap text-blue-500 font-bold">{{
          $t('preview.main.sponsored.sponsoredCode')
        }}</span>
      </div>
      <div>
        <a
          :href="showMoreLink"
          target="_blank"
          rel="sponsored"
          aria-label="Sponsored Link"
          class="flex items-center gap-1 text-gray-600 hover:gap-2 transition-all duration-300 mr-2 hover:mr-1"
        >
          {{ $t('preview.main.sponsored.showMore') }}
          <Icon icon="bootstrap-arrow-right" :height="16" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import { mapGetters } from 'vuex';
export default {
  name: 'SponsorBanner',
  components: {
    Icon
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    query: {
      type: String
    },
    page: {
      type: Number,
      default: 1
    },
    // eslint-disable-next-line vue/require-default-prop
    origin: {
      type: String
    }
  },
  data() {
    return {
      results: [],
      showMoreLink: '',
      showBanner: true
    };
  },
  async fetch() {
    if (this.query) await this.fetchSponsorResults();
  },
  computed: {
    ...mapGetters('vectors', ['actualVector', 'getQuerySponsor'])
  },
  watch: {
    query: {
      handler() {
        if (this.query) this.fetchSponsorResults();
        else this.results = [];
      }
    }
  },
  methods: {
    async fetchSponsorResults() {
      try {
        const response = await this.$axiosV3.get(
          `sponsor/search?query=${this.query}&page=${this.page == 0 ? 1 : this.page}&origin=${
            this.origin
          }`
        );
        const { data } = response?.data;
        if (data && data.results) {
          this.results = data.results;
          this.showMoreLink = data.showMoreLink;
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
      } catch (error) {
        this.showBanner = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.flex-1 {
  flex: 1 1 0%;
}
.w-auto {
  width: auto;
}
.h-\[130px\] {
  height: 130px;
}
.h-\[185px\] {
  height: 185px;
}
.overflow-hidden {
  overflow: hidden;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex-auto {
  flex: 1 1 auto;
}
.max-h-full {
  max-height: 100%;
}
.h-\[140px\] {
  height: 140px;
}
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.flex-wrap {
  flex-wrap: wrap;
}
.h-\[150px\] {
  height: 150px;
}
.max-h-\[150px\] {
  max-height: 150px;
}
.gap-2 {
  gap: 0.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.justify-between {
  justify-content: space-between;
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(67 67 67 / var(--tw-text-opacity));
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.gap-1 {
  gap: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.hover\:underline:hover {
  text-decoration-line: underline;
}
.hover\:gap-2:hover {
  gap: 0.5rem;
}
.hover\:mr-1:hover {
  margin-right: 0.25rem;
}
.justify-end {
  justify-content: flex-end;
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(63 128 241 / var(--tw-text-opacity));
}
.font-bold {
  font-weight: 700;
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.px-4 {
  padding-inline: 1rem;
}
.py-2 {
  padding-block: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.flex-resp {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}
</style>
