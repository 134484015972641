<template>
  <div>
    <div ref="container" class="grid-container invisible">
      <LinkWrapper
        v-for="item in processedItems"
        :key="item.id"
        :link="item.link"
        @click="clickItem($event, item, shownListing)"
      >
        <Item :data="item" :show-bottom-bar="showBottomBar" :border-radius="borderRadius">
          <slot :data="{ ...item, borderRadius }"></slot>
          <slot v-if="showBottomBar" name="bottomBar" :data="{ ...item, borderRadius }"></slot>
        </Item>
      </LinkWrapper>
    </div>
    <ResizeObserver @notify="handleChange" />
  </div>
</template>

<script>
import Item from './Item';
import LinkWrapper from './LinkWrapper';
import { ResizeObserver } from 'vue-resize';
import 'vue-resize/dist/vue-resize.css';

export default {
  components: { Item, LinkWrapper, ResizeObserver },
  props: {
    items: {
      type: Array,
      required: true
    },
    borderRadius: {
      type: Number,
      required: false,
      default: 4
    },
    hideUncompletedRows: {
      type: Boolean,
      required: false,
      default: true
    },
    showBottomBar: {
      type: Boolean,
      required: false,
      default: false
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 350
    },
    forcedHeight: {
      type: Number,
      required: false,
      default: 250
    },
    sendVectorClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      placeholderColor: this.$g('PLACE_HOLDER_COLOR'),
      processedItems: [],
      hiddenIds: [],
      marginRight: 5,
      marginLeft: 5
    };
  },
  computed: {
    cleanItems() {
      return this.items.filter((el) => {
        if (el.id && el.image && el.width && el.height) {
          return true;
        } else {
          console.error(`Image missing data ${el.id}`);
        }
      });
    },
    shownListing() {
      let vm = this;
      return this.items.filter((el) => {
        return !vm.hiddenIds.includes(el.id);
      });
    }
  },
  watch: {
    items() {
      this.processedItems = [];
      this.processItems();
      this.loadVectorStats();
    }
  },
  created() {
    for (let i = 0; i < this.items.length; i++) {
      var item = { ...this.items[i] };
      this.processedItems.push(item);
    }
    this.$nuxt.$on('resize-grid', () => {
      this.$nextTick(function () {
        this.processItems();
      });
    });
  },
  mounted() {
    this.processItems();
    this.$nextTick(function () {
      this.processItems();
    });
    this.loadVectorStats();
  },
  methods: {
    loadVectorStats() {
      const isMyDownloadsPage = this.$route.name.startsWith('profile-downloads');
      const isProfile = this.$route.name.startsWith('profile');
      if (!isMyDownloadsPage && !isProfile) {
        this.$store.dispatch(
          'user/LOAD_VECTORS_STATS',
          this.items.map((result) => result.id)
        );
      }
    },
    clickItem($event, item, shownListing) {
      if (this.sendVectorClick) {
        this.$store.dispatch('vectors/CLICK_VECTOR', { id: item.id });
      }
      this.$emit('item-click', { item, shownListing }, $event);
    },
    handleChange() {
      this.processItems();
    },
    processItems() {
      if (!this.$refs['container'] || this.$refs['container'].clientWidth <= 0) {
        return;
      }
      const containerWidth = this.$refs['container'].clientWidth;
      var resultProcessedItems = [];
      var actualRowWidth = 0;
      var actualRowItems = [];
      for (let i = 0; i < this.cleanItems.length; i++) {
        var item = { ...this.items[i] };
        item.width = item.width + this.marginRight + this.marginLeft;
        const actualItemWidth = (item.width * this.forcedHeight) / item.height;
        item.width = actualItemWidth;
        item.height = this.forcedHeight;
        actualRowItems.push(item);
        actualRowWidth += actualItemWidth;
        if (actualRowWidth >= containerWidth) {
          const finalHeight = (containerWidth * this.forcedHeight) / actualRowWidth;
          for (let j = 0; j < actualRowItems.length; j++) {
            var rowItem = { ...actualRowItems[j] };
            const finalWidth = (finalHeight * rowItem.width) / rowItem.height;
            rowItem.width = finalWidth;
            rowItem.height = finalHeight;
            resultProcessedItems.push(rowItem);
          }
          actualRowWidth = 0;
          actualRowItems = [];
        }
      }
      if (!this.hideUncompletedRows) {
        resultProcessedItems = resultProcessedItems.concat(actualRowItems);
      } else {
        this.hiddenIds = actualRowItems.map((el) => el.id);
      }
      resultProcessedItems = resultProcessedItems.map((el) => {
        el.width = Math.floor(el.width - this.marginRight - this.marginLeft);
        el.height = Math.floor(el.height);
        el.marginRight = this.marginRight;
        el.marginLeft = this.marginLeft;
        return el;
      });
      this.$emit('amount-shown', resultProcessedItems.length);
      this.processedItems = resultProcessedItems;
      this.$refs['container'].classList.remove('invisible');
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
</style>
