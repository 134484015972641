<template>
  <div id="app" :class="$expKey()" :style="expColor">
    <div v-show="overlayOpen" class="overlay"></div>
    <Header />
    <div class="main">
      <Nuxt />
    </div>
    <Footer />
    <PreviewModal />
    <UserFormModal @login="$bvModal.hide('user-modal')" @signup="$bvModal.hide('user-modal')" />
    <Contact />
    <PurchaseModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Contact from '@/components/Common/Contact';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import PreviewModal from 'Preview/Modal';
import PurchaseModal from '@/components/PurchaseFlow/Modal';
import UserFormModal from '@/components/User/FormModal';

export default {
  components: {
    Header,
    Footer,
    PreviewModal,
    UserFormModal,
    Contact,
    PurchaseModal
  },
  middleware: 'afterLogin',
  computed: {
    ...mapGetters('experience', ['getExperienceColor']),
    expColor() {
      return `--exp-color: ${this.getExperienceColor};`;
    },
    overlayOpen() {
      return this.$store.getters['searchBar/isAnyOpen'];
    },
    experience() {
      return this.$store.getters['experience/getExperience'];
    },
    merch() {
      return this.$store.getters['experience/getMerch'];
    }
  },
  watch: {
    $route() {
      this.$store.dispatch('searchBar/LOAD_DEFAULT_SUGGESTIONS');
    }
  },
  mounted() {
    this.$auth.fetchUser(false, this.fetchUserCallback);
    this.$store.dispatch('searchBar/LOAD_DEFAULT_SUGGESTIONS');
  },
  methods: {
    fetchUserCallback(status) {
      if (status) return;
      const googleProvider = this.$auth.getProvider('google');
      googleProvider.initOneTap(this.handleOneTapCallback);
    },
    async handleOneTapCallback({ credential: id_token }) {
      await this.$store.dispatch('auth/googleLogin', { id_token });
    }
  }
};
</script>

<style lang="scss" src="./layout.scss"></style>
