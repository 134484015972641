<template>
  <div>
    <b-modal
      id="preview-vector-modal"
      ref="preview-modal"
      :modal-class="$expKey()"
      tabindex="-1"
      role="dialog"
      hide-footer
      @shown="showModal"
      @hidden="hideModal"
    >
      <template #modal-header>
        <Nav
          ref="modal-preview-nav"
          class="w-100"
          :show-image="showImgNav || getDownloaded"
          :show-btn-download="showBtnDownload"
          :has-scrolled="hasScrolled"
          @show-purchase-flow="startPurchaseFlow"
        />
        <CloseButton modal-name="preview-vector-modal" />
      </template>

      <div v-if="showPurchaseFlow">
        <div class="close-container">
          <button
            v-if="purchaseFlowStatus == 'plans'"
            class="close"
            @click="() => toggleShowPuchaseFlow(false)"
          >
            ×
          </button>
        </div>
        <PurchaseFlow
          :show-top-padding="false"
          :show-plans="true"
          @start-free-download="startFreeDownload"
        />
      </div>

      <span v-else>
        <PreviewCarousel
          v-if="!getShowFreeDownload && !getDownloaded"
          ref="preview-carousel"
          carousel_id="modalCarousel_vexels"
          @show-purchase-flow="startPurchaseFlow"
        />

        <FreeDownload v-if="getShowFreeDownload" />

        <CollectionVector />

        <SponsorBanner v-if="!isPaidPlan" :query="getQuerySponsor" :origin="getVectorRouteName" />

        <Relateds />
      </span>
    </b-modal>
    <ChangeVector v-show="hasPrevVector" way="prev" @click="prevVector" />
    <ChangePage v-show="hasPrevPage" way="prev" @click="prevPage" />

    <ChangeVector v-show="hasNextVector" way="next" @click="nextVector" />
    <ChangePage v-show="hasNextPage" way="next" @click="nextPage" />
  </div>
</template>

<script>
import CloseButton from 'UI/CloseButton';
import { mapGetters } from 'vuex';
import Nav from '../Nav';
import PreviewCarousel from '../Carousel';
import ChangePage from 'Components/Preview/Modal/ChangePage';
import ChangeVector from 'Components/Preview/Modal/ChangeVector';
import PurchaseFlow from '@/components/PurchaseFlow';
import FreeDownload from '@/components/PurchaseFlow/FreeDownload';
import CollectionVector from '@/components/Preview/CollectionVector';
import Relateds from '@/components/Preview/Relateds';
import SponsorBanner from '@/components/SponsorBanner';

export default {
  name: 'PreviewModal',
  components: {
    Nav,
    PreviewCarousel,
    ChangePage,
    ChangeVector,
    PurchaseFlow,
    FreeDownload,
    CloseButton,
    Relateds,
    CollectionVector,
    SponsorBanner
  },
  data: () => {
    return {
      isShow: false,
      isSearch: false,
      showImgNav: false,
      showTagsContainer: true,
      showBtnDownload: false,
      hasScrolled: false
    };
  },
  head() {
    if (this.isShow && this.actualVector) {
      const { metaTitle, metaDescription } = this.actualVector.metadata;
      return {
        title: metaTitle,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: metaDescription
          }
        ]
      };
    }
  },
  computed: {
    ...mapGetters('redirect', ['getBrowserRedirect']),
    ...mapGetters('searchBar', ['getActualSearch']),
    ...mapGetters('plans', ['plans']),
    ...mapGetters('dimensions', ['getActualDimension']),
    ...mapGetters('search', ['getCurrentPage', 'isLastSearchPage']),
    ...mapGetters('vectors', [
      'isLoading',
      'purchaseFlowStatus',
      'actualVector',
      'showPurchaseFlow',
      'previewHasNext',
      'previewHasPrev',
      'getShowFreeDownload',
      'getQuerySponsor',
      'getVectorRouteName'
    ]),
    ...mapGetters('downloads', ['getDownloaded']),
    hasNextVector() {
      return this.isShow && this.previewHasNext;
    },
    hasPrevVector() {
      return this.isShow && this.previewHasPrev;
    },
    hasNextPage() {
      return this.isShow && !this.hasNextVector && this.isSearch && !this.isLastSearchPage;
    },
    hasPrevPage() {
      return this.isShow && !this.hasPrevVector && this.getCurrentPage > 1 && this.isSearch;
    },
    isPaidPlan() {
      return (
        this.$auth.user && this.$auth.user.idPlan !== 1 && !this.$auth.user.isFreeTrialSubscriptor
      );
    }
  },
  watch: {
    isShow(val) {
      if (val && this.actualVector) {
        const url = this.$urlBuilder('preview', {
          id: this.actualVector.id,
          canonizedName: this.actualVector.canonizedName,
          type: this.actualVector.idType
        });
        this.$sendUserActivity(url);
        this.updateIsSearch();
      }
    },
    actualVector(val) {
      if (val) {
        const url = this.$urlBuilder('preview', {
          id: val.id,
          canonizedName: val.canonizedName,
          type: val.idType
        });
        if (!this.getBrowserRedirect) {
          const actualUrl = this.$route.path;
          const purchaseUrls = ['/purchase', '/kauf', '/compra', '/compra'];
          let isPurchaseUrl = purchaseUrls.some((el) => actualUrl.startsWith(el));
          if (!isPurchaseUrl) {
            history.pushState({ preview: true }, null, url);
          }
          this.$store.commit('redirect/SET_BROWSER_REDIRECT', false);
        }
        this.$sendUserActivity(url);
        this.showImgNav = false;
        this.updateIsSearch();
      }
    },
    $route() {
      this.updateIsSearch();
    }
  },
  methods: {
    startFreeDownload() {
      this.$store.commit('vectors/SET_SHOW_PURCHASE_FLOW', false);
      this.$store.dispatch('vectors/SET_SHOW_FREE_DOWNLOAD', true);
    },
    toggleShowPuchaseFlow(show) {
      this.$store.commit('vectors/SET_SHOW_PURCHASE_FLOW', show);
    },
    updateIsSearch() {
      this.isSearch = this.$g('SEARCH_ROUTE_NAMES').some(
        (el) => this.$getGenericUrlName(this.$router.currentRoute.name) === el
      );
    },
    showModal() {
      this.isShow = this.showTagsContainer = true;
      this.showImgNav = this.showBtnDownload = this.hasScrolled = false;
      this.$store.dispatch('vectors/SET_SHOW_FREE_DOWNLOAD', false);

      this.toggleShowPuchaseFlow(false);
      document
        .getElementById('preview-vector-modal')
        .addEventListener('scroll', this.previewHeaderEffect);
      window.addEventListener('resize', this.onResize);
      window.addEventListener('keydown', this.clickKey);
    },
    hideModal() {
      this.toggleShowPuchaseFlow(false);
      this.isShow = false;
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('keydown', this.clickKey);
      this.$store.commit('vectors/SET_ACTUAL_VECTOR', { vector: null, vectorId: null });
      this.$store.dispatch('vectors/CLEAR_RELATEDS');
      if (!this.getBrowserRedirect) {
        history.pushState({}, null, this.$route.path);
      } else {
        this.$store.commit('redirect/SET_BROWSER_REDIRECT', false);
      }
    },
    clickKey(e) {
      switch (e.key) {
        case 'ArrowLeft':
          if (this.hasPrevVector) this.prevVector();
          if (this.hasPrevPage) this.prevPage();
          break;
        case 'ArrowRight':
          if (this.hasNextVector) this.nextVector();
          if (this.hasNextPage) this.nextPage();
          break;
      }
    },
    changePage(page) {
      this.showBtnDownload = this.hasScrolled = false;
      this.$store.dispatch('vectors/SET_SHOW_FREE_DOWNLOAD', false);
      this.toggleShowPuchaseFlow(false);
      const path = this.$urlBuilder('search', {
        path: this.getActualDimension.path,
        keyword: this.getActualSearch,
        page
      });
      this.$bvModal.hide('preview-vector-modal');
      this.$router.push({ path });
    },
    nextPage() {
      this.changePage(this.getCurrentPage + 1);
    },
    prevPage() {
      this.changePage(this.getCurrentPage - 1);
    },
    nextVector() {
      this.$store.dispatch('vectors/PREVIEW_NEXT');
      this.resetPreviewState();
    },
    prevVector() {
      this.$store.dispatch('vectors/PREVIEW_PREV');
      this.resetPreviewState();
    },
    resetPreviewState() {
      this.showBtnDownload = this.hasScrolled = false;
      this.$store.dispatch('vectors/SET_SHOW_FREE_DOWNLOAD', false);
      this.$store.dispatch('vectors/SET_SHOW_EDIT_COLOR', false);
      this.toggleShowPuchaseFlow(false);
      this.scrollTopModal();
    },
    scrollTopModal() {
      document.getElementById('preview-vector-modal').scrollTo(0, 0);
    },
    onResize(e) {
      this.previewHeaderEffect(e, true);
    },
    startPurchaseFlow() {
      this.showBtnDownload = this.hasScrolled = false;
      this.toggleShowPuchaseFlow(true);
      this.showImgNav = true;
    },
    previewHeaderEffect(e, isResize = false) {
      if (!this.showPurchaseFlow && !this.isLoading) {
        $nuxt.$emit('scroll-modal');
        const modal = document.getElementById('preview-vector-modal');
        this.hasScrolled = modal.scrollTop > 25;
        const previewCarousel = this.$refs['preview-carousel'];
        const previewImage = previewCarousel?.$refs['preview-image'];
        if (previewImage) {
          const scrollShowImg = modal.scrollTop > previewImage.clientHeight - 10;
          const scrollHideDesc = modal.scrollTop > previewImage.clientHeight - 10;

          if (scrollHideDesc) {
            if (scrollShowImg) {
              if (!this.showImgNav) {
                this.showImgNav = true;
              } else {
                if (isResize) {
                  $nuxt.$emit('resize-preview-nav');
                }
              }
            } else {
              this.showImgNav = false;
            }
          } else {
            this.showImgNav = false;
          }

          this.showBtnDownload = scrollShowImg;
        }
      } else if (this.isLoading) {
        this.hasScrolled = false;
        this.showBtnDownload = false;
        this.showImgNav = false;
      }
    }
  }
};
</script>

<style lang="scss">
#preview-vector-modal {
  padding: 0 !important;
  .modal-footer {
    display: none;
  }
  .loader-container {
    height: calc(100vh - 100px);
    background-color: coral;
  }
  .modal-dialog {
    width: 90%;
    max-width: initial;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    @include md {
      width: 100%;
      margin-top: 50px;
    }
    .modal-content {
      @include md {
        border-radius: 0;
      }
    }
    .modal-header {
      border-bottom: none;
      padding: 0;
      z-index: 200;
      background-color: #ffffff;
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      top: 0;
      display: flex;
      align-items: center;
      nav {
        overflow-x: clip;
      }
      .close {
        @include md {
          top: -25px;
          background: transparent;
          font-size: 40px;
        }
      }
    }
    .modal-body {
      padding: 5px 25px 5px 25px;
    }
  }
  .close-container {
    text-align: right;
    padding: 0 10px 0 0;
  }
  .close {
    float: none;
    font-weight: 400;
    font-size: 35px;
    outline: none;
  }
}
</style>
