<template>
  <div class="mt-3">
    <span>{{ $t('preview.relateds.groupers.tagsMore') }}</span>
    <nuxt-link
      v-if="data.tags.length > 0"
      class="exp-font"
      :to="buildUrl(data.tags[0]['slug'])"
      @click.native="$bvModal.hide('preview-vector-modal')"
      >{{ data.tags[0]['name'] }}</nuxt-link
    >
    <span v-if="data.tags.length > 1"> & </span>
    <nuxt-link
      v-if="data.tags.length > 1"
      class="exp-font"
      :to="buildUrl(data.tags[1]['slug'])"
      @click.native="$bvModal.hide('preview-vector-modal')"
      >{{ data.tags[1]['name'] }}</nuxt-link
    >
    <span>{{ $t('preview.relateds.groupers.tagsDesigns') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    buildUrl(tag) {
      const urlData = { path: this.data.path ? this.data.path : 'merch-designs', keyword: tag };
      return this.$urlBuilder('search', urlData);
    }
  }
};
</script>
