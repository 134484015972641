<template>
  <b-container v-if="purchaseFlowStatus != allStatus[0]" class="purchase-flow-container">
    <div class="my-4">
      <b-row class="pt-2">
        <b-col>
          <Header :status="purchaseFlowStatus" />
        </b-col>
      </b-row>
      <b-row class="login-pricing-container">
        <b-col class="col-lg-7 col-12 pb-3 no-padding-sm">
          <LoginForm
            v-if="loggedInState"
            class="gray-border"
            :force-toggle="true"
            :after-login="afterLoginPath"
            @login="login"
          />
          <Shimmer
            :loading="
              !loggedInState &&
              purchaseFlowStatus != allStatus[2] &&
              purchaseFlowStatus != allStatus[3]
            "
          >
            <Payments
              v-if="purchaseFlowStatus == allStatus[2]"
              @payment="payment"
              @methodChange="(method) => (activeMethod = method)"
            />
          </Shimmer>
          <PaymentSuccessful
            v-if="purchaseFlowStatus == allStatus[3]"
            :page="false"
            :vector="actualVector"
          />
        </b-col>
        <b-col class="col-lg-5 col-12 pb-3 no-padding-sm">
          <PricingInfo
            class="gray-border"
            :class="`${loggedInState && 'disabled-container'}`"
            :active-method="activeMethod"
          />
          <Purchase :logged-in-state="loggedInState" :active-method="activeMethod" />
          <div class="trusted-by">
            {{ $t('main.purchaseFlow.purchaseFlow.trustedBy') }}
            <img
              class="w-100"
              src="https://cdn.vexels.com/img/trusted-by.png"
              alt="Trusted by PODs"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
  <div v-else class="background" :class="{ 'pt-4': showTopPadding }">
    <b-container class="purchase-flow-container" :class="{ 'p-4': !alternativeLayout }">
      <Plans
        :custom-plans="customPlans"
        :individual-vector-plans="isPreviewPage"
        :see-more-link="seeMoreLink"
        :lifetime="lifetime"
        :cant-download-reason="cantDownloadReason"
        :prismic-props="prismicProps"
        :u-id="uId"
        :alternative-layout="alternativeLayout"
        @suscribe-click="plansSubscribeClick"
      />
      <div v-if="isPreviewPage" class="text-container mt-4">
        <nuxt-link
          v-if="shouldShowPlans"
          class="see-more"
          :to="{ path: seeMoreLink, hash: 'compare-plans' }"
          @click.native="$bvModal.hide('preview-vector-modal')"
        >
          {{ $t('main.purchaseFlow.purchaseFlow.text') }}
        </nuxt-link>
        <span v-else class="see-more cursor-pointer" @click="freeDownload()">
          {{ $t('preview.download.main.freeDownload') }}
        </span>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Payments from '@/components/PurchaseFlow/Payments';
import Plans from '@/components/PurchaseFlow/Plans';
import LoginForm from '@/components/PurchaseFlow/LoginForm';
import PaymentSuccessful from '@/components/PurchaseFlow/PaymentSuccessful';
import PricingInfo from '@/components/PurchaseFlow/PricingInfo';
import Purchase from '@/components/PurchaseFlow/Purchase';
import Shimmer from '@/components/PurchaseFlow/Plans/Shimmer';
import Header from '@/components/PurchaseFlow/Header';

export default {
  components: {
    Header,
    LoginForm,
    Payments,
    Plans,
    PaymentSuccessful,
    Purchase,
    PricingInfo,
    Shimmer
  },
  props: {
    showPlans: {
      type: Boolean,
      default: false,
      required: false
    },
    plansNewPage: {
      type: Boolean,
      default: false,
      required: false
    },
    showTopPadding: {
      type: Boolean,
      default: true
    },
    plansPage: {
      type: Boolean,
      default: false,
      required: false
    },
    lifetime: {
      type: Boolean,
      default: false,
      required: false
    },
    prismicProps: {
      type: Object,
      default: () => ({})
    },
    customPlans: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      activeMethod: '',
      allStatus: ['plans', 'account', 'payment', 'download'],
      cantDownloadReason: '',
      uId: this._uid
    };
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('plans', ['openPlan']),
    ...mapState('auth', ['user']),
    ...mapGetters('vectors', ['actualVector', 'purchaseFlowStatus']),
    ...mapGetters('downloads', ['canDownload', 'elementReason']),
    isFree() {
      return this.canDownload(this.actualVector.id);
    },
    isActualVectorMerchOnly() {
      return this.actualVector && this.actualVector.attributes.some((el) => el.key == 'merch_only');
    },
    seeMoreLink() {
      if (this.isActualVectorMerchOnly) {
        return this.$urlBuilder('merch-plans', { keyword: 'preview', comparePlans: true });
      } else {
        return this.$urlBuilder('plans', { keyword: 'preview', comparePlans: true });
      }
    },
    isPurchasePage() {
      return this.$route.name.startsWith('purchase');
    },
    afterLoginPath() {
      return this.plansPage ? this.$urlBuilder('purchase') : '';
    },
    isPreviewPage() {
      return !this.plansPage && !this.isPurchasePage;
    },
    alternativeLayout() {
      return this.prismicProps.alternativeLayout;
    },
    loggedInState() {
      if (this.isLoggedIn) {
        return false;
      } else {
        if (this.hasToken) {
          return false;
        } else {
          return this.purchaseFlowStatus === this.allStatus[1];
        }
      }
    },
    shouldShowPlans() {
      return !this.isFree || this.cantDownloadReason;
    },
    hasToken() {
      const strategy = this.$cookies.get('vx.strategy');
      if (!strategy) return false;
      const token = this.$cookies.get('vx.token.' + strategy);
      return token ? true : false;
    }
  },
  watch: {
    user: function (value) {
      if (value && !this.showPlans && this.purchaseFlowStatus != this.allStatus[3]) {
        this.changeStatus(this.allStatus[2]);
      }
    }
  },
  created() {
    this.setPlansToFetch();

    if (this.showPlans) {
      this.changeStatus(this.allStatus[0]);
    } else {
      if (!this.$auth.loggedIn) {
        this.changeStatus(this.allStatus[1]);
      } else {
        this.changeStatus(this.allStatus[2]);
      }
    }
  },
  mounted() {
    let vectorIdQueryParam = parseInt(this.$route.query.vector_id);
    if (!isNaN(vectorIdQueryParam)) {
      this.$store.dispatch('vectors/LOAD_VECTOR_DATA', vectorIdQueryParam);
      this.$store.commit('vectors/SET_ACTUAL_VECTOR_ID', vectorIdQueryParam);
    }

    if (this.purchaseFlowStatus !== this.allStatus[0]) {
      this.$dataLayerPush('purchase_flow_start', {
        startStatus: this.hasToken ? 'logged_in' : 'not_logged_in'
      });
    }
    var src =
      (process.env.BLUESNAP_DOMAIN_PATH
        ? process.env.BLUESNAP_DOMAIN_PATH
        : 'https://sandbox.bluesnap.com') + '/web-sdk/4/bluesnap.js';

    if (!document.querySelector('script[src="' + src + '"]')) {
      var s = document.createElement('script');
      s.setAttribute('src', src);
      document.body.appendChild(s);

      s = document.createElement('script');
      s.setAttribute('src', 'https://js.stripe.com/v3/');
      document.body.appendChild(s);
    }

    if (!this.plansPage && !this.isPurchasePage && this.actualVector?.id) {
      this.cantDownloadReason = this.elementReason(this.actualVector.id);
    }
  },
  methods: {
    async login() {
      if (this.$auth.loggedIn) {
        if (!this.isPurchasePage && this.actualVector?.id) {
          await this.$store.dispatch('downloads/CHECK_DOWNLOAD_PERMISSION', this.actualVector.id);
        }

        if (!this.isPurchasePage && this.canDownload(this.actualVector?.id)) {
          const id = this.actualVector.id;
          const data = { id, url: this.actualVector.urlDownload };
          await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', data);
          this.$store.commit('vectors/SET_SHOW_PURCHASE_FLOW', false);
          this.changeStatus();
          this.$bvModal.hide('purchase-modal');
        } else {
          this.changeStatus(this.allStatus[2]);
        }
      } else {
        this.changeStatus(this.allStatus[1]);
      }
    },
    payment() {
      this.changeStatus(this.allStatus[3]);
      this.$dataLayerPush('purchase_flow_success');
      const plan = this.$cookies.get('vx.plan');
      if (this.$auth.user.isFreeTrialSubscriptor) {
        this.$dataLayerPush('trial_starts', {
          userId: this.$auth.user.id,
          user: this.$auth.user,
          plan: plan
        });
      } else {
        this.$dataLayerPush('purchase', {
          purchase: this.openPlan,
          user: this.$auth.user
        });
      }
    },
    plansSubscribeClick() {
      if (this.plansPage) {
        this.$store.commit('vectors/SET_ACTUAL_VECTOR', { vector: null, vectorId: null });
      }
      if (this.plansNewPage) {
        this.$router.push(this.$urlBuilder('purchase'));
      } else {
        if (this.$auth.loggedIn) {
          this.changeStatus(this.allStatus[2]);
        } else {
          this.changeStatus(this.allStatus[1]);
        }
      }
    },
    setPlansToFetch() {
      let amount = 3;
      if (this.prismicProps?.primaryIds?.length) {
        amount = this.prismicProps?.primaryIds?.filter((elem) => {
          return elem ?? false;
        }).length;
      }
      this.$store.commit('plans/SET_PLANS_TO_FETCH', { uId: this.uId, amount });
    },
    freeDownload() {
      const downloadsCookies = this.$cookies.get('vx.downloads')
        ? this.$cookies.get('vx.downloads')
        : 0;
      if (!this.$auth.loggedIn && downloadsCookies >= 10) this.$bvModal.show('user-modal');
      else if (!this.$auth.loggedIn && downloadsCookies < 10)
        this.$cookies.set('vx.downloads', downloadsCookies + 1);
      this.$emit('start-free-download');
    },
    changeStatus(status = '') {
      const previousStatus = this.purchaseFlowStatus;
      this.$store.commit('vectors/SET_PURCHASE_FLOW_STATUS', status);
      if (this.showPlans && previousStatus === this.allStatus[0] && status !== this.allStatus[0]) {
        this.$dataLayerPush('purchase_flow_start', {
          startStatus: this.hasToken ? 'logged_in' : 'not_logged_in'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-pricing-container {
  margin-bottom: 15px;
  padding: 15px;
  @include sm {
    padding-top: 0;
  }
}
.gray-border {
  border: 2px solid #f1f1f1;
  border-radius: 6px;
}
.disabled-container {
  opacity: 0.25;
  pointer-events: none;
  &.gray-border {
    border: 2px solid #f1f1f1;
  }
}
.purchase-flow-container {
  border-radius: 5px;
  .see-more {
    color: #474747;
    padding: 0.4rem 1rem;
  }
  .text-container {
    margin: 0 auto;
    width: fit-content;
    cursor: pointer;
  }
  .no-padding-sm {
    @include lg {
      padding: 0;
    }
  }
}
.background {
  z-index: 2;
  position: relative;
}
.trusted-by {
  text-align: center;
  padding: 16px 0px;
  color: #515151;
  font-size: 12px;
  font-weight: bold;
}
</style>
