<template>
  <div class="carousel-wrapper">
    <div v-if="!isRendered" class="carousel-loader"></div>

    <div class="buttons-container" :style="containerButtonsStyle">
      <ButtonCarouselLeft v-if="showPrev" class="arrow-button prev-button" @click="prev" />
      <ButtonCarouselRight v-if="showNext" class="arrow-button next-button" @click="next" />
    </div>
    <div ref="container" class="carousel-container">
      <div v-show="showPrev" class="prev-fade"></div>
      <div v-show="showNext" class="next-fade"></div>
      <div v-hammer:swipe="(event) => onSwipe(event)" class="moving-area" :style="movingAreaStyle">
        <div
          v-for="(item, i) in processedItems"
          :key="item.id"
          :style="containerStyle"
          class="item"
          :class="{
            'item-shadow': showShadow,
            'item-border': showBorder,
            collection: isCollection,
            'item-custom': customImages
          }"
        >
          <LinkWrapper class="link" :link="urlItem(item)" @click="itemClick(item, $event)">
            <Item
              :data="item"
              :border-radius="borderRadius"
              :item-style="itemStyle(i)"
              :is-vector="isVector"
              :show-bottom-bar="showBottomBar"
            >
              <template #overlay>
                <slot v-if="item.image" name="overlay" :data="{ ...item, borderRadius }"></slot>
              </template>
              <template #bottomBar>
                <slot
                  v-if="item.title || showBottomBar"
                  name="bottomBar"
                  :data="{ ...item, borderRadius }"
                ></slot>
              </template>
            </Item>
          </LinkWrapper>
          <prismic-rich-text
            v-if="item.text && !layout2"
            :field="item.text"
            :style="itemTextStyles(i)"
            class="carousel-text"
          ></prismic-rich-text>
        </div>
        <div v-if="seeMoreEnabled" :class="{ 'item-border': showBorder }" class="item">
          <div class="seemore-container">
            <nuxt-link
              :to="seeMoreLink"
              class="seemore-text d-flex align-items-center"
              :style="seeMoreContainerStyle"
              @click.native="$emit('item-click', seeMoreLink)"
            >
              {{ seeMoreText }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Item from './Item';
import LinkWrapper from './LinkWrapper';
import Vue from 'vue';
import ButtonCarouselLeft from 'UI/ButtonsCarousel/ButtonCarouselLeft.vue';
import ButtonCarouselRight from 'UI/ButtonsCarousel/ButtonCarouselRight.vue';
if (process.browser) {
  const { VueHammer } = require('vue2-hammer');
  Vue.use(VueHammer);
}
export default {
  components: {
    LinkWrapper,
    Item,
    ButtonCarouselLeft,
    ButtonCarouselRight
  },
  props: {
    layout2: {
      type: Boolean
    },
    items: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((el) => el.id && el.image && el.link);
      }
    },
    padding: {
      type: Number,
      required: false,
      default: 0
    },
    marginTop: {
      type: Number,
      required: false,
      default: 5
    },
    marginBottom: {
      type: Number,
      required: false,
      default: 5
    },
    marginRight: {
      type: Number,
      required: false,
      default: 5
    },
    marginLeft: {
      type: Number,
      required: false,
      default: 5
    },
    borderRadius: {
      type: Number,
      required: false,
      default: 4
    },
    showShadow: {
      type: Boolean,
      required: false,
      default: false
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    seeMoreEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    seeMoreLink: {
      type: String,
      required: false,
      default: ''
    },
    seeMoreText: {
      type: String,
      required: false,
      default: '+'
    },
    height: {
      type: Number,
      required: true
    },
    showBottomBar: {
      type: Boolean,
      default: false
    },
    showBottomSeeMore: {
      type: Boolean,
      required: false,
      default: true
    },
    isGrouper: {
      type: Boolean,
      required: false,
      default: false
    },
    isCollection: {
      type: Boolean,
      required: false,
      default: false
    },
    customImages: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      currentDelta: 0,
      actualLineWidth: 0,
      allImagesWidth: 0,
      screenWidth: 0,
      startIndex: 0,
      processedItems: [],
      seeMoreWidth: 75,
      isRendered: false,
      grouperRatio: 1.25
    };
  },
  computed: {
    ...mapState('search', ['showFilters']),
    seeMoreContainerStyle() {
      return `background-color: ${this.$expColor()};
      margin: ${this.height ? (this.height - 75) / 2 : '50'}px ${this.marginRight}px ${
        this.marginBottom
      }px ${this.marginLeft}px;
      width: ${this.seeMoreWidth}px;`;
    },
    showNext() {
      return (
        this.currentDelta + this.screenWidth < this.allImagesWidth &&
        this.allImagesWidth > this.screenWidth
      );
    },
    showPrev() {
      return this.currentDelta !== 0;
    },
    movingAreaStyle() {
      return `transform: translateX(-${this.currentDelta}px);`;
    },
    containerButtonsStyle() {
      return `margin-top: ${this.marginTop}px;height: ${this.height + 20}px;`;
    },
    containerStyle() {
      return {
        marginTop: this.marginTop + 'px',
        marginBottom: this.marginBottom + 'px',
        marginRight: this.marginRight + 'px',
        marginLeft: this.marginLeft + 'px',
        maxWidth: this.addGrouperExtraWidth ? this.height * this.grouperRatio + 'px' : 'unset'
      };
    },
    processedItemsLength() {
      return this.processedItems.length;
    },
    addGrouperExtraWidth() {
      return this.showBottomSeeMore && this.showBottomBar && this.isGrouper;
    },
    isVector() {
      return !this.isGrouper && !this.customImages;
    }
  },
  watch: {
    showFilters() {
      setTimeout(() => this.onResize(), 500);
    },
    items() {
      this.buildCarousel();
      this.loadVectorStats();
    }
  },
  created() {
    this.setItems();
  },
  mounted() {
    this.loadVectorStats();
    window.addEventListener('resize', this.onResize);
    this.updateAllImagesWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    setItems() {
      for (let i = 0; i < this.items.length; i++) {
        const item = { ...this.items[i] };
        const imageHasData =
          item.id && item.image && (this.isGrouper || (item.width && item.height));
        if (this.customImages || imageHasData) {
          if (this.addGrouperExtraWidth) {
            item.width = this.height * this.grouperRatio;
          } else if (this.isGrouper) {
            item.width = this.height;
          } else {
            item.width = Math.floor((item.width * this.height) / item.height);
          }
          item.height = this.height;
          this.processedItems.push(item);
        } else {
          console.error('Common Carrousel, missing data');
        }
      }
    },
    itemTextStyles(i) {
      const item = this.processedItems[i];
      return {
        ...this.itemSize(i),
        '--link-color': item.linkColor || this.$expColor(),
        height: 'auto'
      };
    },

    itemSize(i) {
      const item = this.processedItems[i];
      return {
        width: item.width + 'px',
        height: item.height + 'px'
      };
    },
    itemStyle(i) {
      return {
        ...this.itemSize(i),
        padding: this.padding + 'px',
        borderRadius: this.borderRadius + 'px',
        objectFit: this.addGrouperExtraWidth ? 'cover' : 'unset'
      };
    },
    itemClick(item, $event) {
      this.$emit('item-click', item.link);
      $event.preventDefault();
      if (item.idType) {
        this.$emit('vector-click', { item, shownListing: this.items }, $event);
      } else if (!this.customImages || item.internalLink) {
        this.$router.push({ path: item.link });
      } else {
        if (item.link) {
          window.location.href = item.link;
        }
      }
    },
    urlItem(item) {
      if (item.link && item.link !== '/') return item.link;
      return this.customImages ? '' : '#';
    },
    onSwipe(e) {
      const isRight = e.direction == 2;
      const isLeft = e.direction == 4;
      if (isRight) {
        this.next();
      } else if (isLeft) {
        this.prev();
      }
    },
    onResize() {
      this.currentDelta = 0;
      this.startIndex = 0;
      this.updateAllImagesWidth();
    },
    updateAllImagesWidth() {
      this.isRendered = false;
      const containerEl = this.$refs['container'];
      this.screenWidth = containerEl.clientWidth;
      this.allImagesWidth = this.processedItems.reduce(
        (acc, obj) => acc + obj.width + this.marginRight + this.marginLeft,
        0
      );
      if (this.seeMoreEnabled) {
        this.allImagesWidth += this.seeMoreWidth + 10;
      }
      this.isRendered = true;
    },
    prev() {
      this.actualLineWidth = this.seeMoreButtonWidthShown();
      let firstElement = false;
      let passedScreenWidth = false;
      let i = this.startIndex;
      while (!passedScreenWidth && !firstElement) {
        const actualImage = this.processedItems[i];
        const actualImageWidth = actualImage.width + this.marginLeft + this.marginRight;
        this.actualLineWidth += actualImageWidth;
        if (this.actualLineWidth >= this.screenWidth && i > 0) {
          passedScreenWidth = true;
        } else if (i == 0) {
          firstElement = true;
        } else {
          i--;
        }
      }
      if (passedScreenWidth) {
        this.setPrevCurrentDelta(i);
      } else if (firstElement) {
        this.scrollToBeginning();
      }
    },
    seeMoreButtonWidthShown() {
      const missingButtonWidth = this.allImagesWidth - this.screenWidth - this.currentDelta;
      const size = this.seeMoreWidth + this.marginLeft + this.marginRight - missingButtonWidth;
      return this.seeMoreEnabled && size > 0 ? size : 0;
    },
    setPrevCurrentDelta(i) {
      const actualImageWidth = this.processedItems[i].width + this.marginLeft + this.marginRight;
      const remainingWidth = this.calculateRemainingWidthPrev(i);
      if (remainingWidth <= this.screenWidth) {
        this.currentDelta = 0;
        this.startIndex = 0;
      } else {
        if (i == this.startIndex) {
          const buttonWidth = this.seeMoreButtonWidthShown();
          if (buttonWidth) {
            this.setCarouselPosition(this.currentDelta - buttonWidth);
          } else {
            this.setCarouselPosition(this.currentDelta - this.actualLineWidth, i - 1);
          }
        } else {
          this.actualLineWidth -= actualImageWidth;
          this.setCarouselPosition(this.currentDelta - this.actualLineWidth, i);
        }
      }
    },
    calculateRemainingWidthPrev(i) {
      let remainingWidth = 0;
      for (let j = i; j >= 0; j--) {
        const remEl = this.processedItems[j];
        const remImageWidth = remEl.width + this.marginLeft + this.marginRight;
        remainingWidth += remImageWidth;
      }
      return remainingWidth;
    },
    scrollToBeginning() {
      this.currentDelta = 0;
      this.startIndex = 0;
    },
    next() {
      this.actualLineWidth = 0;
      const lastElementIndex = this.processedItemsLength - 1;
      if (this.startIndex < lastElementIndex) {
        let lastElement = false;
        let passedScreenWidth = false;
        let lastElementWidth = 0;
        let i = this.startIndex;
        while (!passedScreenWidth && !lastElement) {
          const actualImage = this.processedItems[i];
          const actualImageWidth = actualImage.width + this.marginLeft + this.marginRight;
          this.actualLineWidth += actualImageWidth;
          lastElementWidth = actualImageWidth;
          if (this.actualLineWidth >= this.screenWidth && i < lastElementIndex) {
            passedScreenWidth = true;
          } else if (i == lastElementIndex) {
            lastElement = true;
          } else {
            i++;
          }
        }
        if (passedScreenWidth) {
          this.setNextCurrentDelta(i);
        } else if (lastElement) {
          const onlyScrollLastImage =
            this.seeMoreEnabled && this.screenWidth < lastElementWidth + this.seeMoreWidth + 10;
          if (onlyScrollLastImage) {
            this.setCarouselPosition(
              this.currentDelta + this.actualLineWidth - lastElementWidth,
              lastElementIndex
            );
          } else {
            this.scrollToEnd();
          }
        }
      } else {
        this.scrollToEnd();
      }
    },
    setNextCurrentDelta(i) {
      const actualImageWidth = this.processedItems[i].width + this.marginLeft + this.marginRight;
      let remainingWidth = this.calculateRemainingWidthNext(i);
      const seeMoreDelta = 10;
      if (remainingWidth <= this.screenWidth + seeMoreDelta) {
        this.scrollToEnd();
      } else {
        if (i == this.startIndex) {
          this.setCarouselPosition(this.currentDelta + this.actualLineWidth, i + 1);
        } else {
          this.actualLineWidth -= actualImageWidth;
          this.setCarouselPosition(this.currentDelta + this.actualLineWidth, i);
        }
      }
    },
    calculateRemainingWidthNext(i) {
      let remainingWidth = 0;
      for (let j = i; j < this.processedItemsLength; j++) {
        const remEl = this.processedItems[j];
        const remImageWidth = remEl.width + this.marginLeft + this.marginRight;
        remainingWidth += remImageWidth;
      }
      if (this.seeMoreEnabled) {
        const remImageWidth = this.seeMoreWidth + this.marginLeft + this.marginRight;
        remainingWidth += remImageWidth;
      }
      return remainingWidth;
    },
    scrollToEnd() {
      this.setCarouselPosition(
        this.allImagesWidth - this.screenWidth,
        this.processedItemsLength - 1
      );
    },
    setCarouselPosition(delta, index) {
      this.currentDelta = delta > 0 ? delta : 0;
      if (index) {
        this.startIndex = index;
      }
    },
    buildCarousel() {
      this.setItems();
      this.updateAllImagesWidth();
    },
    loadVectorStats() {
      this.$store.dispatch(
        'user/LOAD_VECTORS_STATS',
        this.items.map((result) => result.id)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 2;
}
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
}
.carousel-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}
.moving-area {
  margin: 10px 0;
  touch-action: pan-y !important;
  .item:first-child {
    margin-left: 0 !important;
  }
}
.carousel-container,
.moving-area {
  width: 100%;
  display: flex;
  transition: transform 300ms ease-out;
}
.carousel-container {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.item {
  display: block;
  float: left;
  height: auto;
  position: relative;
  transition: all 0.3s linear;
}
.item-shadow {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10196);
  border-radius: 10px;
  transition: all 0.3s linear;
}
.item-border {
  border-radius: 10px;
  border: 0.5px solid #f3f3f3;
}
.arrow-button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3px 12px;
  z-index: 10;
  border-radius: 50%;
  color: white;
  border: none;
  font-size: 20px;
  @include lg {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .svg-icon-container {
    top: -1px;
    position: relative;
  }
}
.next-button {
  right: 0;
  .svg-icon-container {
    right: -1px;
  }
}
.prev-button {
  left: 0;
  .svg-icon-container {
    right: 1px;
  }
}
.next-button:focus,
.prev-button:focus {
  outline: 0;
}
.seemore-container {
  display: flex;
  justify-content: center;
  height: 100%;
}
.seemore-text {
  white-space: nowrap;
  color: white;
  width: 75px;
  justify-content: center;
  font-size: 35px;
  border-radius: 50%;
  height: 75px;
}
.item-text-link {
  color: black;
  padding: 3px 5px;
}
.link:hover {
  text-decoration: none;
}

.prev-fade {
  left: 0;
  background: -moz-linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}
.next-fade {
  right: 0;
  background: -moz-linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}

.vx-custom-background .prev-fade {
  background: -moz-linear-gradient(to right, #272727, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient(to right, #272727, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, #272727, rgba(255, 255, 255, 0));
}

.vx-custom-background .next-fade {
  background: -moz-linear-gradient(to left, #272727, rgba(255, 255, 255, 0));
  background: -webkit-linear-gradient(to left, #272727, rgba(255, 255, 255, 0));
  background: linear-gradient(to left, #272727, rgba(255, 255, 255, 0));
}

.next-fade,
.prev-fade {
  position: absolute;
  width: 5%;
  height: 100%;
  z-index: 5;
  @include sm {
    width: 15%;
  }
}
</style>
<style lang="scss">
.item:hover {
  &.collection {
    border-radius: 7px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10196);
    .bottom-bar .see-more {
      color: #fff;
      background-color: var(--exp-color);
      border: none;
    }
  }
}
.carousel-container .carousel-text {
  font-size: 15px;
  padding: 5px 15px 0 5px;
  a {
    color: var(--link-color);
  }
}
</style>
