import { dimensions } from '../store/dimensions';
import { uids } from '../landings.json';

export default (context, inject) => {
  const urlBuilder = (type, params) => {
    const exp = context.store.getters['experience/getExperience'];
    const { locale } = context.store.$i18n;
    if (type == 'preview') {
      const neededParams = ['id', 'canonizedName', 'type'];
      if (hasNeededParams(params, neededParams)) {
        switch (parseInt(params.type)) {
          case 6:
            return context.app.localePath({
              name: 'psd-preview-id-name',
              params: { id: params.id, name: params.canonizedName }
            });
          case 7:
            return context.app.localePath({
              name: 'png-svg-preview-id-name',
              params: { id: params.id, name: params.canonizedName }
            });
          case 8:
            return context.app.localePath({
              name: 'editables-preview-id-name',
              params: { id: params.id, name: params.canonizedName }
            });
          default:
            return context.app.localePath({
              name: 'vectors-preview-id-name',
              params: { id: params.id, name: params.canonizedName }
            });
        }
      }
    } else if (type == 'search') {
      const value = dimensions.find((el) => el.path === params.path);
      let path = value ? value.path : 'graphics';

      // eslint-disable-next-line no-prototype-builtins
      if (params.hasOwnProperty('path')) {
        const keyword = params.keyword
          .trim()
          .replace(/\s\s+/g, '-')
          .replace(/\s/g, '-')
          .toLowerCase();

        const page = params.page == 1 ? '' : params.page;
        let result;
        const { query } = params;
        if (keyword) {
          result = context.app.localePath({
            name: page ? `${path}-search-p-page` : `${path}-search`,
            params: { page, search: keyword }
          });
        } else {
          result = context.app.localePath({
            name: page ? `${path}-p-page` : `${path}`,
            params: { page }
          });
        }
        result += '/';

        if (query) {
          const routeQuery = context.route.query;
          const params = new URLSearchParams({ ...query, ...routeQuery });
          if (params.toString()) {
            result += `?${params.toString()}`;
          }
        }

        return result;
      } else {
        return `/${path}/`;
      }
    } else if (type == 'category') {
      const neededParams = ['id', 'slug'];
      const page = params.page == 1 ? '' : params.page;
      if (hasNeededParams(params, neededParams)) {
        const link = context.app.localePath({
          name: page ? 'category-id-slug-p-page' : 'category-id-slug',
          params: { id: params.id, slug: params.slug, page }
        });
        return trailingSlash(link);
      }
    } else if (type == 'collection') {
      const neededParams = ['slug'];
      const page = params.page == 1 ? '' : params.page;
      if (hasNeededParams(params, neededParams)) {
        const link = context.app.localePath({
          name: page ? 'collections-slug-page' : 'collections-slug',
          params: { slug: params.slug, page }
        });
        return trailingSlash(link);
      }
    } else if (type == 'style') {
      const neededParams = ['slug'];
      const page = params.page == 1 ? '' : params.page;
      if (hasNeededParams(params, neededParams)) {
        const link = context.app.localePath({
          name: page ? 'styles-slug-page' : 'styles-slug',
          params: { slug: params.slug, page }
        });
        return trailingSlash(link);
      }
    } else if (type == 'topics') {
      const neededParams = ['slug'];
      const page = params.page == 1 ? '' : params.page;
      if (hasNeededParams(params, neededParams)) {
        const link = context.app.localePath({
          name: page ? 'topics-slug-page' : 'topics-slug',
          params: { slug: params.slug, page }
        });
        return trailingSlash(link);
      }
    } else if (type == 'categories') {
      const neededParams = ['id', 'slug'];
      if (hasNeededParams(params, neededParams)) {
        params.slug = params.slug.replace(/ +(?= )/g, '').replace(' ', '-');
        return `/category/${params.id}/${params.slug}/`;
      }
    } else if (type == 'niches') {
      const neededParams = ['slug'];
      const page = params.page == 1 ? '' : params.page;
      if (hasNeededParams(params, neededParams)) {
        const link = context.app.localePath({
          name: page ? 'niches-slug-page' : 'niches-slug',
          params: { slug: params.slug, page }
        });
        return trailingSlash(link);
      }
    } else if (type == 'url') {
      const neededParams = ['url'];
      if (hasNeededParams(params, neededParams)) {
        return `${params.url}`;
      }
    } else if (type == 'purchase') {
      return context.app.localePath('purchase');
    } else if (type == 'merch-home') {
      return '/merch';
    } else if (type == 'design-home') {
      return '/design';
    } else if (type == 'merch-plans') {
      return planUrl(type, params);
    } else if (type == 'design-plans') {
      return planUrl(type, params);
    } else if (type == 'plans-profile-redirect') {
      const profilePage = context.route.path.startsWith('/profile');
      const loggedInFreeUser = context.store.getters['user/loggedInFreeUser'];
      return loggedInFreeUser && profilePage
        ? urlBuilder('merch-plans', params)
        : urlBuilder('plans', params);
    } else if (type == 'plans') {
      return urlBuilder('merch-plans', params);
    } else if (type == 'home') {
      if (exp == 'design') return urlBuilder('design-home');
      if (exp == 'merch') return urlBuilder('merch-home');
    } else if (type == 'payment-successful') {
      return context.app.localePath('payment-successful');
    } else if (type == 'niches-home') {
      return trailingSlash(context.app.localePath('niches'));
    } else if (type == 'topics-home') {
      return trailingSlash(context.app.localePath('topics'));
    } else if (type == 'landing') {
      const neededParams = ['uid'];
      if (hasNeededParams(params, neededParams)) {
        const { uid } = params;
        const landing = uids.find((el) => el.en === uid);
        return landing ? `/${trailingSlash(landing[locale])}` : '/';
      }
    } else if (type == 'maker') {
      const neededParams = ['slug', 'maker', 'id'];
      if (hasNeededParams(params, neededParams)) {
        const { slug, maker, id } = params;
        return maker === 'mockup'
          ? `https://mockups.vexels.com/mockup/${slug}/${id}`
          : `https://www.vexels.com/t-shirt-maker/${slug}`;
      }
    }
  };

  inject('urlBuilder', urlBuilder);
  inject('trailingSlash', trailingSlash);

  const planUrl = (type, params) => {
    const comparePlans = params?.comparePlans ? '#compare-plans' : '';
    // eslint-disable-next-line no-prototype-builtins
    return params && params.hasOwnProperty('keyword')
      ? `${trailingSlash(
          context.app.localePath({ name: `${type}-keyword`, params })
        )}${comparePlans}`
      : `${trailingSlash(context.app.localePath(type))}${comparePlans}`;
  };
};

const trailingSlash = (url) => {
  const excludedPaths = ['merch', '/merch', 'design', '/design'];
  return url ? (url.slice(-1) !== '/' && !excludedPaths.includes(url) ? url + '/' : url) : '/';
};

const hasNeededParams = (obj, params) => {
  return params.every((i) => {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(i) && obj[i];
  });
};
