import { handleError, newInstanceProps, setAuthorizationBearer } from '../utils/axiosConfig';

export default function ({ store, $axios }, inject) {
  const axiosGeo = $axios.create(newInstanceProps);

  axiosGeo.onRequest((config) => {
    if (config.headers.common) {
      config.headers.common['Experience'] = store.getters['experience/getExperience'];
      config.headers.common['Accept-Language'] = store.$i18n.locale;
      setAuthorizationBearer(process.server, config, store.$cookies);
    }
  });

  axiosGeo.onError((error) =>
    handleError(error, store.$cookies, store.dispatch, process.env.COOKIES_DOMAIN)
  );
  axiosGeo.setBaseURL(process.env.GEO_API_URL);

  inject('axiosGeo', axiosGeo);
}
