<template>
  <div>
    <slot name="overlay" :data="{ ...data, borderRadius }"></slot>
    <img
      v-if="!isVector"
      :src="data.image"
      class="carousel-img"
      :class="{ 'light-shadow': data.isSvg, 'placeholder-shimmer': !loadedImage }"
      :style="itemStyle"
      :alt="data.name"
      loading="lazy"
      @load="removePlaceholder()"
    />
    <figure v-else class="m-0" :class="{ 'placeholder-shimmer': !loadedImage }">
      <picture>
        <source :srcset="buildSrcWebP(data.image)" type="image/webp" />
        <source :srcset="data.image" :type="data.isSvg ? 'image/png' : 'image/jpeg'" />
        <img
          class="carousel-img"
          :src="data.image"
          :class="{ 'light-shadow': data.isSvg }"
          :style="itemStyle"
          loading="lazy"
          :alt="data.name"
          @load="removePlaceholder()"
        />
      </picture>
      <figcaption class="d-none">
        {{ data.name }}
      </figcaption>
    </figure>
    <slot name="bottomBar" :data="{ ...data, borderRadius }"></slot>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    itemStyle: {
      type: Object,
      required: true
    },
    borderRadius: {
      type: Number,
      required: false,
      default: 4
    },
    isVector: {
      type: Boolean,
      default: false
    },
    showBottomBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      marginTop: 5,
      marginBottom: 5,
      loadedImage: false
    };
  },
  methods: {
    removePlaceholder() {
      this.loadedImage = true;
    },
    buildSrcWebP(link) {
      return link + '?fmt=webp&h=250';
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-img {
  z-index: 1;
  position: relative;
  pointer-events: none;
  &.light-shadow {
    background-color: $backgroundGray;
  }
}
.placeholder-shimmer {
  border-radius: 4px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f9;
  background-image: linear-gradient(to right, #f6f7f9 20%, #e9ebee 40%, #f6f7f9 60%, #f6f7f9 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  .carousel-img.light-shadow {
    background-color: unset;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
