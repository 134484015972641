<template>
  <ul>
    <li
      v-for="(item, index) in list"
      :key="index"
      :class="itemClasses(item.selected)"
      @click="click(item)"
    >
      <span class="normal-part">{{ getNormalPart(item.value) }}</span
      ><span class="bold-part">{{ getBoldPart(item.value) }}</span>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    prismicKeyword: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapGetters('dimensions', ['getDimensionAll', 'getDimensionByName']),
    ...mapGetters('experience', ['getExperience']),
    actualDimension() {
      return this.$store.getters['dimensions/getActualDimension'];
    },
    actualSearch() {
      return this.$store.getters['searchBar/getActualSearch'];
    }
  },
  methods: {
    click(item) {
      if (this.prismicDimension) {
        this.$store.commit('searchBar/CLEAR_ALL_PRISMIC_SEARCH');
      } else {
        this.$dataLayerPush('search_bar_menu_click', {
          clickSearchBarMenu: {
            url: this.itemLink(item),
            item: 'Keywords'
          }
        });
      }
      const path = this.itemLink(item);
      this.$store.commit('search/SET_QUERY', {});
      this.$router.push({ path });
      const addedPrismicKeyword = this.prismicKeyword ? ` ${this.prismicKeyword}` : '';
      this.$store.commit('searchBar/SET_ACTUAL_SEARCH', item.value + addedPrismicKeyword);
      this.$emit('open-close-bar', status);
    },
    itemLink(item) {
      const dimension =
        this.prismicDimension ||
        this.getDimensionByName(this.getDimensionAll, this.getExperience).path;
      const addedPrismicKeyword = this.prismicKeyword ? ` ${this.prismicKeyword}` : '';
      return this.$urlBuilder('search', {
        path: dimension,
        keyword: item.value + addedPrismicKeyword
      });
    },
    getNormalPart(text) {
      const bold = this.getBoldPart(text.toLowerCase());
      return text.toLowerCase().replace(new RegExp(bold + '$'), '');
    },
    getBoldPart(text) {
      const indexOf = text.indexOf(this.actualSearch);
      text = text.substring(indexOf);
      const bold = text.toLowerCase().replace(this.actualSearch.trim().toLowerCase(), '');
      if (bold.length == text.length) {
        return text;
      } else {
        return bold;
      }
    },
    itemClasses(selected) {
      var fixed = 'item';
      if (selected) fixed = fixed + ' selected';
      return fixed;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  text-align: left;
}
ul .item {
  padding: 9px 20px;
  transition: 0.3s;
  color: #787878;
  cursor: pointer;
}
@media (max-width: 600px) {
  ul .item {
    padding-left: 20px;
  }
}
.item:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.item.selected {
  background-color: rgba(0, 0, 0, 0.06);
}
.link {
  text-decoration: none;
}
.bold-part {
  font-weight: 500;
}
.normal-part {
  text-transform: capitalize;
}
</style>
