<template>
  <div :class="{ 'grid-tag': gridTag }" class="tag-container">
    <div class="text-and-icon" :style="`--background-color: ${backgroundColor};`">
      <div class="tag-icon-container">
        <img :src="src" class="icon" :alt="alt" height="20" :width="width" loading="lazy" />
      </div>
      <span v-if="gridTag" class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: false,
      default: 20
    },
    gridTag: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'rgba(0, 0, 0, 0.5)'
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-container {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  position: relative;
  .text-and-icon {
    width: 38px;
    height: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    z-index: 2;
    border-radius: 7px;
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    .tag-icon-container {
      height: 20px;
      right: 0;
      width: 38px;
      display: flex;
      align-content: center;
    }
    .tag-icon-container > .icon {
      height: 100%;
      margin: auto;
    }
    .text {
      font-size: 10px;
      min-width: 10px;
      padding: 10px;
      width: 0;
      min-width: 0;
      transition: min-width 2s;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
      color: #fff;
      transform: scaleX(0);
      border-radius: 7px 0px 0px 7px;
      top: 0;
      right: 38px;
      opacity: 0;
      transform-origin: right center;
      z-index: -1;
      transition: inherit;
      box-sizing: border-box;
    }
  }
  &.grid-tag {
    .text-and-icon {
      border-radius: 0px 4px 4px 0px;
      .text {
        font-size: 12px;
        right: 38px;
        opacity: 1;
        transform: scaleX(1);
        position: absolute;
        width: max-content;
      }
      .tag-icon-container {
        position: absolute;
      }
    }
    &:hover {
      border-radius: 0px 4px 4px 0px;
      opacity: 1;
      .text {
        right: 38px;
        transform: scaleX(1);
        opacity: 1;
        min-width: 28px;
        width: max-content;
      }
    }
  }
}
</style>
