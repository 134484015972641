<template>
  <div id="app" :class="`${$expKey()} ${routeClass}`">
    <div v-show="overlayOpen" class="overlay"></div>
    <div class="main">
      <Nuxt />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  middleware: 'afterLogin',
  data() {
    return {
      routeClass: ''
    };
  },
  head() {
    return this.$seoPlugin();
  },
  computed: {
    ...mapGetters('redirect', ['getBrowserRedirect', 'getToPreview']),
    ...mapGetters('experience', ['getExperienceColor', 'isMerchActive']),
    expColor() {
      return `--exp-color: ${this.getExperienceColor};`;
    },
    overlayOpen() {
      return this.$store.getters['searchBar/isAnyOpen'];
    },
    showModalPurchase() {
      return (
        this.$urlNameChecker('purchase-plan-id') || this.$urlNameChecker('purchase-plan-id-keyword')
      );
    }
  },
  watch: {
    $route(val) {
      this.setPageNameAsClass(val.name);
      this.$store.dispatch('searchBar/LOAD_DEFAULT_SUGGESTIONS');
    },
    expColor(val) {
      document.getElementsByTagName('body')[0].setAttribute('style', val);
    }
  },
  created() {
    this.setPageNameAsClass(this.$route.name);
  },
  mounted() {
    this.$auth.fetchUser(false, this.fetchUserCallback);
    document.getElementsByTagName('body')[0].setAttribute('style', this.expColor);
    this.$store.dispatch('searchBar/LOAD_DEFAULT_SUGGESTIONS');
    this.browserPreviewNavigation();
    this.routerPreviewNavigation();
  },
  methods: {
    fetchUserCallback(status) {
      if (status) {
        const user = this.$auth.user;
        if (user.idPlan != 1) {
          this.$cookies.set('vx.hasPlan', true);
        }
      } else {
        const googleProvider = this.$auth.getProvider('google');
        googleProvider.initOneTap(this.handleOneTapCallback);
      }
    },
    async handleOneTapCallback({ credential: id_token }) {
      await this.$store.dispatch('auth/googleLogin', { id_token });
    },
    setPageNameAsClass(routeName) {
      if (routeName) {
        let [name] = routeName.toLowerCase().split('___');
        this.routeClass = 'page-' + name;
      }
    },
    browserPreviewNavigation() {
      window.addEventListener('popstate', (event) => {
        this.$store.commit('redirect/SET_BROWSER_REDIRECT', true);
        const toPreview = event.state.preview;
        if (!toPreview) {
          this.$bvModal.hide('preview-vector-modal');
        } else {
          this.$bvModal.show('preview-vector-modal');
        }
        this.$store.commit('redirect/SET_TO_PREVIEW', toPreview);
      });
    },
    routerPreviewNavigation() {
      this.$router.beforeEach((to, from, next) => {
        if (this.getBrowserRedirect) {
          this.$store.commit('redirect/SET_BROWSER_REDIRECT', false);
          if (!this.getToPreview) {
            return next();
          } else {
            this.$store.commit('redirect/SET_TO_PREVIEW', false);
          }
        } else {
          return next();
        }
      });
    }
  }
};
</script>

<style lang="scss" src="./layout.scss"></style>
