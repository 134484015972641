var undoStack = [];
var redoStack = [];

function setActualColors(processed) {
  var actualGradColors = [];
  for (var i = 0; i < processed.length; i++) {
    var actualId = '';
    for (var j = 0; j < processed[i].ids.length; j++) {
      actualId += processed[i].ids[j];
    }
    var actualColors = [];
    for (var j = 0; j < processed[i].colors.length; j++) {
      actualColors.unshift(processed[i].colors[j]);
    }
    actualGradColors.push({
      id: actualId,
      colors: actualColors.reverse()
    });
  }
  document.actualGradColors = actualGradColors;
}

function addIdToColorGradAndRet(id, colors, processed) {
  for (var i = processed.length - 1; i >= 0; i--) {
    var actualGradColors = processed[i].colors;
    if (gradHasColors(id, actualGradColors)) {
      processed[i].ids.push(id);
      return processed;
    }
  }
  return processed;
}

function rgb2hex(rgb) {
  rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return rgb && rgb.length === 4
    ? '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
    : '';
}

function addNewGradColors(id, colors, processed) {
  processed.push({
    ids: [id],
    colors: colors
  });
  return processed;
}

function getColors(gradId) {
  var colors = [];
  var stops = document.querySelectorAll('#' + gradId + ' stop');
  for (var i = stops.length - 1; i >= 0; i--) {
    var actualStopColor = getComputedStyle(stops[i], null).getPropertyValue('stop-color');
    colors.push(rgb2hex(actualStopColor));
  }
  colors = colors.reverse();
  return colors;
}

function gradHasColors(gradId, colors) {
  var gradColors = getColors(gradId);

  if (gradColors.length != colors.length) {
    return false;
  }
  for (var i = gradColors.length - 1; i >= 0; i--) {
    if (gradColors[i] != colors[i]) {
      return false;
    }
  }
  return true;
}

function isGradColorsProcessed(gradId, processed) {
  for (var i = processed.length - 1; i >= 0; i--) {
    var actualGradColors = processed[i].colors;
    if (gradHasColors(gradId, actualGradColors)) {
      return true;
    }
  }
  return false;
}

function getGradsIds() {
  var gradients = document.querySelectorAll(
    '.canvas-container linearGradient, .canvas-container radialGradient'
  );
  var ids = [];
  for (var i = gradients.length - 1; i >= 0; i--) {
    ids.push(gradients[i].id);
  }
  return ids;
}

function getProcessedGrads() {
  var gradsIds = getGradsIds();
  var processed = [];

  for (var i = gradsIds.length - 1; i >= 0; i--) {
    var actualGradId = gradsIds[i];
    if (isGradColorsProcessed(actualGradId, processed)) {
      processed = addIdToColorGradAndRet(actualGradId, getColors(actualGradId), processed);
    } else {
      processed = addNewGradColors(actualGradId, getColors(actualGradId), processed);
    }
  }

  processed = processed.sort(function (a, b) {
    return a.ids.length - b.ids.length;
  });

  setActualColors(processed);

  return processed;
}

function hex2rgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  var aux = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
  return 'rgb(' + aux.r + ', ' + aux.g + ', ' + aux.b + ')';
}

function setColorToStop(obj, color) {
  obj.style.setProperty('stop-color', hex2rgb(color));
}

function joinBy(array, step, between = '') {
  for (var i = 0; i < array.length; i++) {
    if (array[i].includes('rgba')) {
      array[i] = array[i].replace('rgba', 'rgb');
      array[i + 3] = array[i + 3].replace(' 0)', '');
      array[i + 2] = array[i + 2] + ')';
    }
  }
  var result = [];
  for (var i = 0; i < array.length; i = i + step) {
    var actualJoin = '';
    for (var j = 0; j < step; j++) {
      actualJoin += array[i + j] + between;
    }
    actualJoin = actualJoin.slice(0, between.length * -1);
    result.push(actualJoin);
  }
  return result;
}

function joinArray(array, separator = '') {
  var result = '';
  for (var i = 0; i < array.length; i++) {
    result += array[i] + separator;
  }
  result = result.slice(0, separator.length * -1);
  return result;
}

function setColorToGradientBox(color, index, gradientBox) {
  var actualGrad = gradientBox.style.background
    .replace(' repeat scroll 0% 0%', '')
    .replace('rgba(0, 0, 0, 0)', '');
  var newGrad = 'linear-gradient(to right, ';
  var colors = joinBy(actualGrad.replace(newGrad, '').slice(0, -1).split(','), 3, ',');
  color = hex2rgb(color);
  colors[index] = color;
  newGrad += joinArray(colors, ',') + ')';
  gradientBox.style.background = newGrad;
}

function updateActualColor(id, index, color) {
  for (var i = 0; i < document.actualGradColors.length; i++) {
    if (document.actualGradColors[i].id == id.replace('vex', '')) {
      document.actualGradColors[i].colors[index] = color;
    }
  }
}

function setColorGrad(obj, log = false, color = null) {
  if (!color) {
    color = obj.value + '';
  }
  var idElement = obj.id.replace('vex', '');
  var parent = obj.parentElement;
  var ids = JSON.parse(obj.dataset.gradsinfo);
  var colorIndex = ids[0];
  ids.shift();
  for (var i = ids.length - 1; i >= 0; i--) {
    var stop = document.querySelectorAll('#' + ids[i] + ' stop')[colorIndex];
    setColorToStop(stop, color);
    setColorToGradientBox(color, colorIndex, parent);
  }
  if (log) {
    updateActualColor(obj.dataset.groupid, colorIndex, color);
  }
}

function getActualColor(id, index) {
  for (var i = 0; i < document.actualGradColors.length; i++) {
    if (document.actualGradColors[i].id == id.replace('vex', '')) {
      return document.actualGradColors[i].colors[index].replace('#', '');
    }
  }
  return null;
}

function isColor(element) {
  var result = !element.includes('url') && !element.includes('none');
  return result;
}

function isColorInArray(array, color) {
  for (var i = array.length - 1; i >= 0; i--) {
    var actualColor = array[i];
    if (actualColor.value == color) {
      return true;
    }
  }
  return false;
}

function getPlusOneInArrayColor(array, element) {
  var color = getComputedStyle(element, null).getPropertyValue('fill');
  color = rgb2hex(color);
  for (var i = array.length - 1; i >= 0; i--) {
    var actualColor = array[i];
    if (actualColor.value == color) {
      array[i].amount++;
      array[i].elements.push(element);
      break;
    }
  }
  return array;
}

function getProcessedColors() {
  var n = 1;
  var elements = document.querySelectorAll(
    '.canvas-container path, .canvas-container polygon, .canvas-container circle, .canvas-container rect, .canvas-container ellipse, .canvas-container line, .canvas-container polyline, .canvas-container text'
  );
  var processed = [];
  var actualColors = [];
  for (var i = elements.length - 1; i >= 0; i--) {
    var actualElement = elements[i];
    var actualColor = getComputedStyle(actualElement, null).getPropertyValue('fill');
    if (isColor(actualColor)) {
      actualColor = rgb2hex(actualColor);
      if (isColorInArray(processed, actualColor)) {
        processed = getPlusOneInArrayColor(processed, actualElement);
      } else {
        processed.push({
          value: actualColor,
          elements: [actualElement],
          id: 'jsColor' + n
        });
        actualColors['jsColor' + n] = actualColor.replace('#', '');
        n++;
      }
    }
  }

  processed = processed.sort(function (a, b) {
    return a.elements.length - b.elements.length;
  });

  document.colors = processed;
  document.actualColors = actualColors;
  return processed;
}

export function moveChangeGrad(color) {
  setColorGrad(document.getElementById(document.actualPicker), false, color.replace('#', ''));
}

export function moveChangeColor(color) {
  setColorPolygon(document.getElementById(document.actualPicker), false, color.replace('#', ''));
}

export function changeColorEvent(color) {
  changeColor(document.getElementById(document.actualPicker), color.replace('#', ''));
}

function logChangeColor(element, from, to) {
  var element = {
    self: 'changeColor-' + element + '-' + from + '-' + to,
    inverse: 'changeColor-' + element + '-' + to + '-' + from
  };
  log(element);
}

function log(action) {
  undoStack.push(action);
  redoStack = [];
}

function changeColor(obj, color = null) {
  var id = obj.dataset.colorsinfo;
  if (!color) {
    color = obj.value.replace('#', '');
  }
  var from = document.actualColors[id].replace('#', '');
  document.actualColors[id] = color;
  logChangeColor(id, from, color);
  setColorPolygon(obj, false, color);
}

function setColorPolygon(obj, log = false, color = null) {
  if (!color) {
    color = obj.value.replace('#', '');
  }
  var id = obj.dataset.colorsinfo;
  var colors = document.colors;
  for (var i = colors.length - 1; i >= 0; i--) {
    if (colors[i].id == id) {
      var elements = colors[i].elements;
      for (var idx = elements.length - 1; idx >= 0; idx--) {
        setColorToPolygon(elements[idx], color);
      }
    }
  }
  if (log) {
    document.actualColors[id] = color;
  }
}

function setColorToPolygon(obj, color) {
  obj.style.fill = '#' + color;
}

function execute(command) {
  if (command.includes('changeColor')) {
    var data = command.split('-');
    var id = data[1];
    document.getElementById(id).value = '#' + data[3];
    setColorPolygon(document.getElementById(id), true);
  } else if (command.includes('changeGradient')) {
    var data = command.split('-');
    var id = 'vex' + data[1];
    document.getElementById(id).value = '#' + data[4];
    setColorGrad(document.getElementById(id), true);
  }
}

export function reset() {
  while (undoStack.length > 0) {
    undo();
  }
  redoStack = [];
}

export function undo() {
  if (undoStack.length > 0) {
    var last = undoStack.pop();
    redoStack.push(last);
    execute(last.inverse);
  }
}

export function redo() {
  if (redoStack.length > 0) {
    var last = redoStack.pop();
    undoStack.push(last);
    execute(last.self);
  }
}

function logGradientChangeColor(element, index, from, to) {
  var element = {
    self: 'changeGradient-' + element + '-' + index + '-' + from + '-' + to,
    inverse: 'changeGradient-' + element + '-' + index + '-' + to + '-' + from
  };
  log(element);
}

export function changeGrad(obj) {
  var idElement = obj.id.replace('vex', '');
  var ids = JSON.parse(obj.dataset.gradsinfo);
  var colorIndex = ids[0];
  var actualColor = getActualColor(obj.dataset.groupid, colorIndex);
  var color = obj.value.replace('#', '');
  updateActualColor(obj.dataset.groupid, colorIndex, color);
  logGradientChangeColor(idElement, colorIndex, actualColor, color);
  setColorGrad(obj);
}

export function displayGradients(id) {
  var processedGrads = getProcessedGrads();
  document.processedGrads = processedGrads;
  return processedGrads;
}

export function displayColors(id) {
  var processedColors = getProcessedColors();
  return processedColors;
}

export function showEditColor() {
  displayGradients('gradients');
}

function getIdPickerByColor(color) {
  color = rgb2hex(color).replace('#', '').toLowerCase();
  var colors = document.actualColors;
  for (var key in colors) {
    var value = colors[key];
    if (value.toLowerCase() == color) {
      return key;
    }
  }
}

export function initEditColor() {
  if (undoStack || redoStack) {
    undoStack = [];
    redoStack = [];
  }

  var elements = document.querySelectorAll(
    '.canvas-container path, .canvas-container polygon, .canvas-container circle, .canvas-container rect, .canvas-container ellipse, .canvas-container line, .canvas-container polyline, .canvas-container text'
  );
  elements.forEach(function (element) {
    element.addEventListener('click', function (e) {
      var target = e.target;
      var actualColor = getComputedStyle(target, null).getPropertyValue('fill');
      if (isColor(actualColor)) {
        document.getElementById(getIdPickerByColor(actualColor)).click();
        return false;
      } else {
        var id = actualColor.replace('url("', '').replace('")', '');
        var groups = document.getElementsByClassName('gradientGroup');
        for (var i = 0; i < groups.length; i++) {
          var idG = groups[i].id.replace('vex', '');
          if (id.includes(idG)) {
            var firstBtn = groups[i].firstChild;
            document.getElementById(firstBtn.id).click();
            return false;
          }
        }
      }
    });
  });
}
