<template>
  <div class="container-download-svg-options">
    <DownloadPngOptions
      :disabled-download="disabledDownload"
      :show-more-sizes="showMoreSizes"
      :loading="loading"
      @toggle-more-sizes="toggleMoreSizes"
      @authorize-download="authorizeDownload($event)"
    />

    <button
      class="btn-file b-radius"
      :disabled="disabledDownload"
      @click="$emit('authorize-download', { size: vectorDefaultSize, type: 'svg' })"
    >
      <span v-if="!loading.svg">{{ $t('preview.download.main.svg') }}</span>
      <b-spinner v-else small variant="light" class="mx-2"></b-spinner>
    </button>

    <button
      :disabled="disabledDownload"
      class="btn-file b-radius"
      @click="$emit('authorize-download', { size: vectorDefaultSize, type: 'psd' })"
    >
      <span v-if="!loading.psd">{{ $t('preview.download.main.psd') }}</span>
      <b-spinner v-else small variant="light" class="ml-2"></b-spinner>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownloadPngOptions from './DownloadPngOptions';
export default {
  components: {
    DownloadPngOptions
  },
  props: {
    disabledDownload: {
      type: Boolean,
      required: true
    },
    editColor: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      vectorDefaultSize: 1,
      showMoreSizes: false
    };
  },
  computed: {
    ...mapState('vectors', ['actualVector'])
  },
  methods: {
    toggleMoreSizes() {
      this.showMoreSizes = !this.showMoreSizes;
    },
    authorizeDownload(e) {
      this.$emit('authorize-download', e);
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  background-color: #3cd699;
  border: none;
  outline: none;
  color: #fff;
}
button:disabled {
  background-color: #d2d2d2 !important;
  border-color: #d2d2d2 !important;
  cursor: not-allowed !important;
  color: #fff;
}
button:disabled:hover {
  filter: none !important;
}
.container-download-svg-options {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.3rem;
  width: 100%;
  @include min-md {
    flex-direction: row;
    justify-content: end;
  }
}
.btn-file {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  font-size: 18px;
  padding: 12px 35px;
  margin-left: 7px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #3cd699;
  border: 2px solid #3cd699;
  @include md {
    margin-left: 0;
    margin-bottom: 7px;
    flex: 1;
    max-height: 45px;
  }
  &.b-radius {
    border-radius: 23px;
  }
  &:hover {
    background-color: #3cd699;
    color: white;
  }
}
</style>
