import { displayColors, displayGradients, initEditColor } from '../utils/editColor';

export const mutations = {
  SET_VECTOR_DATA(state, vector) {
    vector.images = [];
    vector.images.push(vector.preview2);
    vector.images.push(vector.list);
    vector.images.push(vector.img);
    state.actualVector = vector;
    state.actualVectorId = vector.id;
  },
  SET_VECTOR_COLLECTION(state, data = []) {
    state.actualVectorCollection = data;
  },
  SET_VECTOR_COLLECTIONS(state, data) {
    data = data.filter((item) => item.id != state.actualVectorId);
    data.map((item) => {
      item.url = this.$urlBuilder('preview', {
        id: item.id,
        canonizedName: item.canonizedName,
        type: item.idType
      });
    });
    state.actualVectorCollectionsVectors = data;
  },
  SET_PREVIEW_MODAL_VISIBLE(state, value) {
    state.isPreviewModalOpen = value;
  },
  SET_ACTUAL_PREVIEW_LIST(state, value) {
    state.actualPreviewList = value;
  },
  SAVE_VECTOR(state, vector) {
    state.loadedVectors.push(vector);
  },
  SET_ACTUAL_VECTOR(state, { vector, vectorId }) {
    state.actualVector = vector;
    state.actualVectorId = vectorId;
  },
  SET_ACTUAL_VECTOR_ID(state, vectorId) {
    state.actualVectorId = vectorId;
  },
  SET_VECTOR_LISTING(state, { name, data }) {
    const { elements } = data;
    if (name && elements) {
      elements.map((el) => {
        el.link = this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        });
      });
      state.listings = { ...state.listings, [name]: elements };
    }
  },
  SET_ACTUAL_VECTOR_RELATEDS(state, data = []) {
    if (data.relateds?.length) {
      data.relateds.map((item) => {
        item.content.map((el) => {
          el.link = this.$urlBuilder('preview', {
            id: el.id,
            canonizedName: el.canonizedName,
            type: el.idType
          });
        });
        item.seeMore.map((el) => {
          el.link = this.$urlBuilder('preview', {
            id: el.id,
            canonizedName: el.canonizedName,
            type: el.idType
          });
        });
      });
    }

    state.actualVectorRelateds = data;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_LOADING_SVG(state, loading) {
    state.isLoadingSVG = loading;
  },
  SET_SHOW_EDIT_COLOR(state, show) {
    state.showEditColor = show;
  },
  SET_PURCHASE_FLOW_STATUS(state, status) {
    state.purchaseFlowStatus = status;
  },
  SET_SHOW_PURCHASE_FLOW(state, show) {
    state.showPurchaseFlow = show;
  },
  SET_SHOW_FREE_DOWNLOAD(state, show) {
    state.showFreeDownload = show;
  },
  SET_EDIT_COLOR_PALETTE(state, colors) {
    state.editColorPalette = colors;
  },
  SET_EDIT_COLOR_GRADIENTS(state, gradients) {
    state.editColorGradients = gradients;
  },
  SET_SVG_CONTENT(state, content) {
    state.actualVector.svg = content;
  }
};

export const getters = {
  previewHasNext(state) {
    const actualIndex = state.actualPreviewList.findIndex((el) => el.id == state.actualVectorId);
    return actualIndex < state.actualPreviewList.length - 1;
  },
  previewHasPrev(state) {
    const actualIndex = state.actualPreviewList.findIndex((el) => el.id == state.actualVectorId);
    return actualIndex > 0;
  },
  actualRelateds(state) {
    return state.actualVectorRelateds.relateds;
  },
  listExist: (state) => (name) => {
    return name && state.listings[name]?.length;
  },
  getVectorsList: (state) => (name) => {
    return name && state.listings[name] ? state.listings[name] : [];
  },
  isLoading(state) {
    return state.isLoading;
  },
  showPurchaseFlow(state) {
    return state.showPurchaseFlow;
  },
  purchaseFlowStatus(state) {
    return state.purchaseFlowStatus;
  },
  actualVector(state) {
    return state.actualVector;
  },
  getShowFreeDownload(state) {
    return state.showFreeDownload;
  },
  getQuerySponsor(state) {
    return state.actualVector?.name;
  },
  getVectorRouteName(state) {
    switch (parseInt(state?.actualVector?.idType)) {
      case 6:
        return 'psd-preview-id-name';
      case 7:
        return 'png-svg-preview-id-name';
      case 8:
        return 'editables-preview-id-name';
      default:
        return 'vectors-preview-id-name';
    }
  }
};

export const actions = {
  ADD_LISTING(store, vectors) {
    if (vectors && vectors.length) {
      const resultsIds = vectors.map((el) => el.id);
      this.$axiosGeo.post('v1/vector/add-listing/', { ids: resultsIds }).catch((error) => {
        console.error('Vectors | ADD_LISTING:', error);
      });
    }
  },
  async LOAD_VECTOR_DATA({ commit, state }, id) {
    const loaded = state.loadedVectors.find((el) => el.id == id);
    if (loaded) {
      commit('SET_VECTOR_DATA', loaded);
    } else {
      try {
        commit('SET_LOADING', true);
        const {
          data: { data: response }
        } = await this.$axiosV2.get(`content/content/${id}`);
        commit('SET_VECTOR_DATA', response);

        commit('SET_VECTOR_COLLECTION', response.collection);

        if (response.collection.length) {
          let dataCollection = { page: 1, perPage: 20, slug: response.collection.slug };
          const { data: collectionsVector } = await this.$axios.post(
            `/collection/${dataCollection.slug}/contents/`,
            dataCollection
          );

          if (!collectionsVector.error) {
            const vectors = collectionsVector.data.vectors ? collectionsVector.data.vectors : [];
            commit('SET_VECTOR_COLLECTIONS', vectors);
          }
        }
        commit('SAVE_VECTOR', response);
        commit('SET_LOADING', false);
      } catch (error) {
        console.error('Vectors | LOAD_VECTOR_DATA:', error);
      }
    }
  },
  async LOAD_VECTOR_RELATEDS({ commit, dispatch }, id) {
    try {
      dispatch('CLEAR_RELATEDS');

      const response = await this.$axios.get(`vector/related/${id}/`);
      if (!response.data.error) {
        const data = response.data.data.blocks.filter((el) => el.content.length > 0);
        const relateds = data.map((b, i) => {
          var blockData = {
            id: i + 1,
            ...b
          };
          return blockData;
        });
        const relatedData = { id, relateds };
        commit('SET_ACTUAL_VECTOR_RELATEDS', relatedData);
      } else {
        console.error('Vectors | LOAD_VECTOR_RELATEDS 1:', response.data.error);
      }
    } catch (error) {
      console.error('Vectors | LOAD_VECTOR_RELATEDS 2:', error);
    }
  },
  async LOAD_VECTOR_LISTING({ commit }, { name, ids }) {
    try {
      const { data } = await this.$axios.post('/vector/custom-list/', { ids });
      if (!data.error) {
        commit('SET_VECTOR_LISTING', { name, data: data.data });
      } else {
        console.error('Vectors | LOAD_VECTOR_LISTING 1:', data.error);
      }
    } catch (error) {
      console.error('Vectors | LOAD_VECTOR_LISTING 2:', error);
    }
  },
  async OPEN_PREVIEW({ commit, dispatch }, { item, list = [] }) {
    const { id, type, isSvg } = item;
    commit('SET_LOADING', true);
    commit('SET_ACTUAL_VECTOR', { vector: null, vectorId: null });
    commit('SET_PREVIEW_MODAL_VISIBLE', true);
    commit('SET_ACTUAL_PREVIEW_LIST', list);
    await this.$loadTranslation('preview');
    await Promise.all([dispatch('LOAD_VECTOR_DATA', id), dispatch('LOAD_VECTOR_RELATEDS', id)]);
    commit('SET_LOADING', false);
    const vectorType = type != 'AI' ? type.toLowerCase() : 'vector';
    this.$dataLayerPush('stock_preview', { content: { id, type: isSvg ? 'svg' : vectorType } });
  },
  CLOSE_PREVIEW({ commit }) {
    commit('SET_PREVIEW_MODAL_VISIBLE', false);
  },
  PREVIEW_NEXT({ dispatch, state }) {
    const actualIndex = state.actualPreviewList.findIndex((el) => el.id == state.actualVectorId);
    const nextIsMaker = state.actualPreviewList[actualIndex + 1]?.isMaker;
    const steps = nextIsMaker ? 2 : 1;
    if (actualIndex < state.actualPreviewList.length - 1) {
      const newId = state.actualPreviewList[actualIndex + steps].id;
      dispatch('CHANGE_PREVIEW_VECTOR', newId);
    }
  },
  PREVIEW_PREV({ dispatch, state }) {
    const actualIndex = state.actualPreviewList.findIndex((el) => el.id == state.actualVectorId);
    const prevIsMaker = state.actualPreviewList[actualIndex - 1]?.isMaker;
    const steps = prevIsMaker ? 2 : 1;
    if (actualIndex > 0) {
      const newId = state.actualPreviewList[actualIndex - steps].id;
      dispatch('CHANGE_PREVIEW_VECTOR', newId);
    }
  },
  CHANGE_PREVIEW_VECTOR({ dispatch, commit }, id) {
    commit('SET_SHOW_FREE_DOWNLOAD', false);
    commit('SET_SHOW_EDIT_COLOR', false);
    commit('SET_ACTUAL_VECTOR', { vector: null, vectorId: null });
    commit('downloads/SET_DOWNLOADED', false, { root: true });
    dispatch('LOAD_VECTOR_DATA', id);
    dispatch('LOAD_VECTOR_RELATEDS', id);
  },
  SET_SHOW_EDIT_COLOR({ commit }, show) {
    commit('SET_SHOW_EDIT_COLOR', show);
  },
  EDIT_COLOR_DATA({ commit }) {
    const colors = displayColors();
    commit('SET_EDIT_COLOR_PALETTE', colors);
    const gradients = displayGradients('gradients');
    commit('SET_EDIT_COLOR_GRADIENTS', gradients);
    initEditColor();
  },
  SET_SHOW_FREE_DOWNLOAD({ commit }, show) {
    commit('SET_SHOW_FREE_DOWNLOAD', show);
  },
  CLICK_VECTOR(store, { id }) {
    this.$axiosGeo.post('v1/vector/add-click/', { id });
  },
  CHANGE_PURCHASE_FLOW_STATUS({ commit }, status) {
    commit('SET_PURCHASE_FLOW_STATUS', status);
  },
  CLEAR_RELATEDS({ commit }) {
    commit('SET_ACTUAL_VECTOR_RELATEDS', {});
    commit('SET_VECTOR_COLLECTION', []);
    commit('SET_VECTOR_COLLECTIONS', []);
  },
  async LOAD_SVG_CONTENT({ commit }, id) {
    try {
      commit('SET_LOADING_SVG', true);
      const response = await this.$axios.get(`vector/${id}/?includeContent=true`);
      if (!response.data.error && response.data.data.svg) {
        commit('SET_SVG_CONTENT', response.data.data.svg);
        commit('SET_LOADING_SVG', false);
      }
    } catch (error) {
      console.error('Vectors | LOAD_SVG_CONTENT:', error);
    }
  }
};

export const state = () => ({
  loadedVectors: [],
  // MODAL
  actualVector: null,
  actualVectorId: null,
  isPreviewModalOpen: false,
  actualPreviewList: [],
  isLoading: false,
  isLoadingSVG: false,
  showEditColor: false,
  editColorPalette: [],
  editColorGradients: [],
  showPurchaseFlow: false,
  purchaseFlowStatus: '',
  showFreeDownload: false,
  // RELATEDS
  actualVectorRelateds: [],
  actualVectorCollection: null,
  actualVectorCollectionsVectors: [],
  // LISTING
  listings: {}
});
