<template>
  <div class="payment-method-container">
    <div class="method-tab cursor-pointer" @click="$emit('click')">
      <PurpleCheckbox :checked="active">
        {{ $t('plans.common.cards.forMerchandiseUse') }}
      </PurpleCheckbox>
      <template v-if="paymentMethod == 'paypal'">
        <img
          src="https://cdn.vexels.com/img/paypal-logo.png"
          :alt="$t('purchase.payment.common.paypalAlt')"
          class="my-1 paypal-logo"
          loading="lazy"
        />
      </template>
      <template v-else-if="paymentMethod == 'stripe'">
        <i class="ph-bold ph-credit-card" :style="{ fontSize: '30px', color: '#7069FE' }"></i>
        <span class="ml-2 tab-text">{{ $t('purchase.payment.common.stripe') }}</span>
        <img
          src="https://cdn.vexels.com/img/stripe.png"
          :alt="$t('purchase.payment.common.paypalAlt')"
          class="my-2 stripe-logo"
          loading="lazy"
        />
      </template>
      <template v-else-if="paymentMethod == 'stripe-elements'">
        <i class="ph-bold ph-credit-card" :style="{ fontSize: '30px', color: '#7069FE' }"></i>
        <span class="ml-2 tab-text">{{ $t('purchase.payment.common.stripe') }}</span>
        <img
          src="https://cdn.vexels.com/img/stripe.png"
          :alt="$t('purchase.payment.common.paypalAlt')"
          class="my-2 stripe-logo"
          loading="lazy"
        />
      </template>
      <template v-else>
        <i class="ph-bold ph-credit-card" :style="{ fontSize: '30px', color: '#676767' }"></i>
        <span class="ml-2 tab-text">{{ $t('purchase.payment.common.creditCard') }}</span>
      </template>
    </div>
    <slot name="content-payment"></slot>
  </div>
</template>

<script>
import PurpleCheckbox from 'UI/PurpleCheckbox';

export default {
  components: {
    PurpleCheckbox
  },
  props: {
    paymentMethod: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-method-container {
  border: 2px solid #f1f1f1;
  border-radius: 6px;

  padding: 20px;
  &:not(:first-child) {
    margin: 30px 0 15px;
  }
}
.method-tab {
  display: flex;
  align-items: center;
}
.tab-text {
  font-size: 15px;
  color: #676767;
}
.paypal-logo {
  height: 50px;
}
.stripe-logo {
  height: 30px;
}
</style>
