<template>
  <div
    v-if="!isLoading && !hasPlan"
    class="component-banner-b text-light"
    :class="buildComponentClasses"
    data-lang="en"
    :style="buildComponentStyles"
    @click="goToLink"
  >
    <div class="position-absolute cursor-pointer button-close" @click="close">
      <img
        id="close-banner"
        src="https://images.vexels.com/files/assets/white-close.svg"
        width="16"
        height="16"
        alt="Close"
      />
    </div>
    <div class="h-100 position-relative"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      link: null,
      imageUrl: null,
      backgroundColor: null,
      isVisible: null,
      hasClosed: false,
      delayToShowBanner: 150000,
      idInterval: null,
      pagesToExclude: ['plans', 'planos', 'abos', 'planes', 'purchase', 'kauf', 'compra'],
      landingsToExclude: ['exclusiveoffer35off'],
      isLoading: true
    };
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    buildComponentStyles() {
      return `
        --background-color: ${this.backgroundColor};
        --background-image: url("${this.imageUrl}");
      `;
    },
    buildComponentClasses() {
      return this.showBanner ? 'show-banner' : 'hidden-banner';
    },
    showBanner() {
      return !this.hasClosed && this.isVisible;
    },
    hasPlan() {
      return (this.isLoggedIn && this.isLoggedIn.idPlan !== 1) || this.$cookies.get('vx.hasPlan');
    }
  },
  watch: {
    $route() {
      this.setVisibleBanner();
    },
    '$auth.user.isLogged'() {
      this.isVisible = !this.hasPlan;
    }
  },
  async created() {
    try {
      const {
        data: {
          data: { link, image, background_color }
        }
      } = await this.$axiosUtils.get('configurations/?config=banner_b');
      this.link = link || this.link;
      this.imageUrl = image || this.imageUrl;
      this.backgroundColor = background_color || this.backgroundColor;
    } catch (error) {
      console.error('Error on get data for BannerB', error);
    }
  },
  mounted() {
    const timerCookie = this.$cookies.get('vx.timerBannerBB');
    if (!timerCookie) {
      const startingMs = new Date().getTime();
      const timeDelayInMiliseconds = this.delayToShowBanner;
      const endingMs = startingMs + timeDelayInMiliseconds;
      this.$cookies.set('vx.timerBannerBB', endingMs);
      this.idInterval = setInterval(this.setVisibleAfterDelay, 1000);
    } else {
      this.setVisibleBanner();
    }
    this.setLoaded();
  },
  methods: {
    setLoaded() {
      if (this.isVisible) this.isLoading = false;
    },
    setVisibleBanner() {
      if (!this.mustHideFromPage() && !this.hasClosed) {
        this.setVisibleAfterDelay();
      } else {
        this.isVisible = false;
      }
    },
    setVisibleAfterDelay() {
      const bannerEndTimerMs = this.$cookies.get('vx.timerBannerBB');
      const nowMs = new Date().getTime();
      const differenceMs = nowMs - bannerEndTimerMs;
      if (differenceMs > 0) {
        if (this.idInterval) clearInterval(this.idInterval);
        this.isVisible = true;
        this.setLoaded();
      }
    },
    goToLink(event) {
      const id = event.target.id;
      if (id !== 'close-banner') {
        this.$dataLayerPush('click_banner_b');
        location.href = this.link;
      }
    },
    close() {
      this.hasClosed = true;
      this.setLoaded();
    },
    mustHideFromPage() {
      const page = window.location.href;
      const pagesToExclude = this.pagesToExclude.concat(this.landingsExcluded);
      for (var i = 0; i < pagesToExclude.length; i++) {
        if (page.indexOf(pagesToExclude[i]) > -1) return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.component-banner-b {
  bottom: 50px;
  left: 0%;
  transform: translateX(-100%);
  position: fixed;
  background-image: var(--background-image);
  background-size: var(--background-size, 100%);
  background-position: var(--background-position, 0);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  height: 185px;
  width: 210px;
  border-radius: 0px 10px 10px 0px;
  padding: 18px 20px;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.5rem;
  font-weight: 100;
  z-index: 1000;
  cursor: pointer;

  .button-close {
    position: absolute;
    cursor: pointer;
    display: flex;
    z-index: 99;
    top: 14px;
    right: 14px;
    height: 16px;
    width: 16px;
  }
  @media all and (max-width: 768px) {
    height: 150px;
    width: 170px;
    padding: 18px;
  }
}
.show-banner {
  animation: show-animation ease-out 0.9s forwards;
}
.hidden-banner {
  animation: hidden-animation ease-out 0.5s forwards;
}
@keyframes hidden-animation {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes show-animation {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
</style>
