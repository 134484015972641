export const mutations = {
  SET_USER_EXPERIENCE(state, experience) {
    state.userExperience = experience;
  }
};

export const getters = {
  getExperience(state) {
    return state.userExperience;
  },
  getExperienceColor(state) {
    return '#3f80f1';
  },
  getSearchBarExperiences(state) {
    return state.experiences;
  },
  getMerch(state) {
    return state.experiences[1].name;
  },
  getDesign(state) {
    return state.experiences[0].name;
  },
  getDefault() {
    return 'default';
  },
  isDesignActive(state) {
    return state.userExperience == state.experiences[0].name;
  },
  isMerchActive(state) {
    return state.userExperience == state.experiences[1].name;
  }
};

export const actions = {
  SET_USER_EXPERIENCE({ commit }, experience) {
    if (experience?.toLowerCase() == 'merch') {
      experience = 'merch';
    } else if (experience?.toLowerCase() == 'design') {
      experience = 'design';
    } else {
      experience = '';
    }
    commit('SET_USER_EXPERIENCE', experience);
  }
};

export const state = () => ({
  userExperience: '',
  experiences: [
    { id: 0, name: 'design' },
    { id: 1, name: 'merch' }
  ]
});
