export default (context, inject) => {
  inject('bluesnapLoadFields', bluesnapLoadFields(context));
  inject('bluesnapSubmitFields', bluesnapSubmitFields(context));
};

const bluesnapLoadFields = (ctx) => (onComplete, onError, onType, onValid) => {
  const i18n = ctx.app.i18n;
  var bsObj = {
    '3DS': true,
    token: ctx.store.state.payments.bluesnapToken,
    onFieldEventHandler: {
      setupComplete: function () {
        onComplete();
      },
      onError: function (tagId, errorCode, errorDescription) {
        console.error('BLUESNAP ERROR:', JSON.stringify({ tagId, errorCode, errorDescription }));
        const errorMessages = {
          invalidCcNumber: i18n.t('purchase.bluesnap.errors.creditCardValid'),
          invalidCvv: i18n.t('purchase.bluesnap.errors.verificationCodeValid'),
          invalidExpDate: i18n.t('purchase.bluesnap.errors.expirationDateValid'),
          tokenNotFount: i18n.t('purchase.bluesnap.errors.tokenError')
        };
        const message = errorMessages[errorDescription];
        if (message) {
          onError(message);
        } else {
          onError('purchase.bluesnap.errors.tokenError');
        }
      },
      onType: function (tagId, cardType) {
        if (cardType == 'AMEX') {
          onType('https://files.readme.io/97e7acc-Amex.png');
        } else if (cardType == 'CarteBleue') {
          onType('https://files.readme.io/5da1081-cb.png');
        } else if (cardType == 'DINERS') {
          onType('https://files.readme.io/8c73810-Diners_Club.png');
        } else if (cardType == 'DISCOVER') {
          onType('https://files.readme.io/caea86d-Discover.png');
        } else if (cardType == 'JCB') {
          onType('https://files.readme.io/e076aed-JCB.png');
        } else if (cardType == 'MaestroUK') {
          onType('https://files.readme.io/daeabbd-Maestro.png');
        } else if (cardType == 'MASTERCARD') {
          onType('https://files.readme.io/5b7b3de-Mastercard.png');
        } else if (cardType == 'Solo') {
          onType('https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png');
        } else if (cardType == 'VISA') {
          onType('https://files.readme.io/9018c4f-Visa.png');
        } else {
          onType(false);
        }
      },
      onValid: function () {
        onValid();
      },
      onFocus: () => {},
      onBlur: () => {}
    },
    style: {
      ':focus': {
        color: '#4194ff'
      },
      input: {
        color: '#4194ff',
        'font-size': '15px'
      },
      '.invalid': {
        color: 'red'
      }
    },
    ccnPlaceHolder: i18n.t('purchase.bluesnap.form.cardNumberPlaceholder'),
    cvvPlaceHolder: i18n.t('purchase.bluesnap.form.securityCodePlaceholder'),
    expPlaceHolder: i18n.t('purchase.bluesnap.form.expDatePlaceholder')
  };
  window.bluesnap.hostedPaymentFieldsCreate(bsObj);
};

const bluesnapSubmitFields = (ctx) => (threeDSObj, onSuccess, onError) => {
  const i18n = ctx.app.i18n;
  window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
    if (callback.statusCode == '14040') {
      onError(i18n.t('purchase.bluesnap.errors.tokenExpired'));
    } else {
      if (null != callback.cardData) {
        onSuccess(callback);
      } else {
        if (callback.error) {
          onError('PAYMENT ERROR');
        } else {
          if (callback.threeDSecure) {
            switch (callback.threeDSecure.authResult) {
              case 'AUTHENTICATION_BYPASSED':
              case 'AUTHENTICATION_SUCCEEDED':
              case 'AUTHENTICATION_UNAVAILABLE':
                onSuccess(callback);
                break;
              case 'AUTHENTICATION_FAILED':
                onError('PAYMENT ERROR');
                break;
              default:
                onError('PAYMENT ERROR');
                break;
            }
          }
        }
      }
    }
  }, threeDSObj);
};
