import { handleError, newInstanceProps, setAuthorization } from '../utils/axiosConfig';

export default function ({ store, $axios }, inject) {
  const axiosUtils = $axios.create(newInstanceProps);

  axiosUtils.onRequest((config) => {
    if (config.headers.common) {
      setAuthorization(process.server, config, store.$cookies);
      config.headers.common['Accept-Language'] = store.$i18n.locale;
    }
  });

  axiosUtils.onError((error) =>
    handleError(error, store.$cookies, store.dispatch, process.env.COOKIES_DOMAIN)
  );
  axiosUtils.setBaseURL(process.env.API_URL_UTILS);

  inject('axiosUtils', axiosUtils);
}
