<template>
  <b-modal id="message-modal" hide-footer tabindex="-1" role="dialog">
    <template #modal-header>
      <CloseButton modal-name="message-modal" />
    </template>
    <div class="body-content">
      <div class="d-flex">
        <div class="mr-2">
          <div
            class="alert-circle d-flex align-items-center justify-content-center"
            :style="iconStyle"
          >
            <span>!</span>
          </div>
        </div>
        <div class="px-2 d-flex align-items-center justify-content-center">
          <p class="message">{{ messageModal.title }}</p>
        </div>
      </div>
      <div
        v-if="messageModal.buttonText && messageModal.buttonAction"
        class="w-100 mt-5 d-flex justify-content-center"
      >
        <Button
          shape="full"
          color="green"
          @click="messageModal.buttonAction"
          @click.native="closeModals"
        >
          {{ messageModal.buttonText }}
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

import Button from 'UI/Button';
import CloseButton from 'UI/CloseButton';

export default {
  components: {
    Button,
    CloseButton
  },
  props: {
    isOnPreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('user', ['messageModal']),
    iconStyle() {
      const typeColor = this.messageModal.type === 'error' ? '#ff4747' : '#3cd699';
      return `background-color: ${typeColor}`;
    }
  },
  methods: {
    closeModals() {
      this.$store.commit('user/SET_SHOW_MESSAGE_MODAL', {
        title: '',
        buttonText: '',
        buttonAction: null,
        type: ''
      });
      this.$bvModal.hide('message-modal');
      if (!this.isOnPreview) this.$bvModal.hide('preview-vector-modal');
    }
  }
};
</script>

<style lang="scss">
.modal-open #message-modal.modal {
  display: flex !important;
  align-items: center;
}
#message-modal {
  .modal-dialog {
    width: auto;
    max-width: max-content;
    .modal-header {
      padding: 0;
      border: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.body-content {
  padding: 50px 100px;
  width: 700px;
  @include lg {
    padding: 30px 80px;
    width: 500px;
  }
  @include md {
    padding: 30px 50px;
    width: 100%;
  }
  @include sm {
    padding: 30px;
  }
  .alert-circle {
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 200%;
  }
  .message {
    color: #4d4d4d;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
</style>
