<template>
  <div class="bubbles-container" @click="disableBubbles()">
    <div v-for="(b, i) in bubbles" :key="i" class="bubble">
      <span class="text">{{ b }}</span>
      <div>
        <div class="cross" @click="removeBubble($event, i)">
          <Icon :height="14" :width="14" color="#737373" icon="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import { mapGetters } from 'vuex';
export default {
  components: {
    Icon
  },
  props: {
    isBanner: {
      type: Boolean,
      required: false,
      default: false
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    searchBarUid: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('experience', ['getExperience']),
    ...mapGetters('searchBar', ['getPrismicSearch']),
    textFromInput() {
      const prismicSearchById = this.getPrismicSearch.find((el) => el.id === this.searchBarUid);
      return this.prismicDimension ? prismicSearchById?.value || '' : this.actualSearch;
    },
    bubbles() {
      return this.bubbleMaker(this.bubbleToUrlString(this.textFromInput));
    },
    actualSearch() {
      return this.$store.getters['searchBar/getActualSearch'];
    },
    actualDimension() {
      return this.$store.getters['dimensions/getActualDimension'];
    }
  },
  methods: {
    disableBubbles() {
      this.$emit('disable');
    },
    bubbleMaker(text) {
      if (this.isEmptyBubble(text)) return [];
      const fixedBubbles = this.$g('BUBBLES_KEEP_TOGETHER');
      for (var i = 0; i < fixedBubbles.length; i++) {
        const bubbleInUrl = this.bubbleToUrlString(fixedBubbles[i]);
        if (bubbleInUrl == text) return [fixedBubbles[i]];
        if (this.textContainsBubble(text, bubbleInUrl)) {
          const parts = this.bubbleParts(text, bubbleInUrl);
          const first = this.bubbleMaker(parts[0]);
          const medium = fixedBubbles[i];
          const last = this.bubbleMaker(parts[2]);
          return [...first, medium, ...last];
        }
      }
      return text.split('-');
    },
    isEmptyBubble(text) {
      return text.trim().length == 0;
    },
    bubbleToUrlString(bubble) {
      return bubble.trim().replace(/\s+/g, '-').toLowerCase();
    },
    textContainsBubble(text, bubble) {
      return text.indexOf(bubble) != -1;
    },
    bubbleParts(text, bubble) {
      var first = text.substring(0, text.indexOf(bubble)).replace(/-$/g, '');
      var medium = bubble;
      var last = text
        .substring(text.indexOf(bubble) + bubble.length, text.length)
        .replace(/^-/g, '');
      return [first, medium, last];
    },
    removeBubble(e, i) {
      e.preventDefault();
      e.stopPropagation();
      var newBubbles = [...this.bubbles];
      newBubbles.splice(i, 1);
      const newSearch = newBubbles.join('-');
      this.$store.commit('searchBar/SET_ACTUAL_SEARCH', newSearch);
      this.$store.commit('search/SET_QUERY', {});
      const path = this.$urlBuilder('search', {
        path: this.actualDimension.path,
        keyword: newSearch
      });
      this.$router.push({ path });
    }
  }
};
</script>

<style scoped>
.bubbles-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}
.bubble {
  background-color: #f3f3f3;
  margin-right: 12px;
  color: #737373;
  border-radius: 21px;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 400;
  position: relative;
}
.cross {
  position: absolute;
  top: -8px;
  right: -6px;
  background-color: #e8e8e8;
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
  color: #707070;
  font-weight: 400;
  text-align: center;
  box-shadow: 1px 1px 4px #00000017;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.cross:hover {
  cursor: pointer;
  background-color: #dcdcdc;
}
</style>
