export default (context, inject) => {
  inject('getImageHeight', getImageHeight);
};

const heights = [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000];

const getImageHeight = (height) => {
  var i = heights.length;
  while (heights[--i] > height);
  return heights[++i];
};
