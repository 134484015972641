<template>
  <div class="overlay" :class="{ 'is-svg': data.isSvg }" :style="overlayStyles">
    <div class="overlay-bg" :style="overlayStyles"></div>
    <div v-if="data.attributesText" class="position-absolute">
      <span
        v-for="(attributeText, index) in data.attributesText"
        :key="index"
        class="attribute-text"
      >
        {{ attributeText }}
      </span>
    </div>
    <div class="tags-container">
      <EditColorBtn v-if="data.isSvg" />
      <div class="d-flex">
        <Tooltip
          v-if="isPremium && !isMerchOnly"
          :no-margin-top="true"
          background-color="rgba(0, 0, 0, 0.5)"
          :icon-tag="true"
        >
          <template #element>
            <IconTag
              :text="$t('preview.main.content.premium')"
              src="https://cdn.vexels.com/img/icons/crown.svg"
              :alt="$t('preview.main.content.crownAlt')"
            />
          </template>
          <template>
            <p class="tag-description">
              {{ $t('preview.main.content.premium') }}
            </p>
          </template>
        </Tooltip>

        <LikeTag :vector-id="data.id" />

        <Tooltip
          v-if="isMerchOnly"
          :no-margin-top="true"
          background-color="rgba(0, 0, 0, 0.5)"
          :icon-tag="true"
        >
          <template #element>
            <IconTag
              :text="$t('preview.main.content.forMerch')"
              src="https://cdn.vexels.com/img/icons/merch_only.svg"
              :alt="$t('preview.main.content.merchOnlyIconAlt')"
              :width="28"
            />
          </template>
          <template>
            <p class="tag-description">
              {{ $t('preview.main.content.forMerch') }}
            </p>
          </template>
        </Tooltip>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import IconTag from '@/components/IconTag';
import LikeTag from '@/components/LikeTag';
import EditColorBtn from '@/components/Preview/EditColor/EditColorBtn';
import Tooltip from '@/components/Common/Tooltip';

export default {
  name: 'OverlayPreview',
  components: {
    IconTag,
    LikeTag,
    EditColorBtn,
    Tooltip
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    overlayStyles() {
      return `border-radius: ${this.data.borderRadius}px;`;
    },
    isPremium() {
      if (!this.data.attributes) return false;
      return this.data.attributes.some((el) => Number(el.id) == this.$g('PREMIUM_ATTRIBUTE_CODE'));
    },
    isMerchOnly() {
      if (!this.data.attributes) return false;
      return this.data.attributes.some(
        (el) => Number(el.id) == this.$g('MERCH_ONLY_ATTRIBUTE_CODE')
      );
    }
  },
  mounted() {
    this.fireGTMViewItem(this.data);
  },
  methods: {
    fireGTMViewItem(data) {
      this.$dataLayerPush('view_item', {
        viewItem: data
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  top: 0;
  right: 0;
  &.is-svg {
    width: 100%;
  }
  div {
    z-index: 100;
  }
  .attribute-text {
    margin-top: 20px;
    margin-left: 10px;
    padding: 5px 10px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000017;
    -webkit-box-shadow: 0px 3px 6px #00000017;
    -moz-box-shadow: 0px 3px 6px #00000017;
    display: flex;
    font-size: 14px;
  }
}
.tags-container {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  .tag-description {
    font-size: 11px;
  }
}
</style>
