<template>
  <a v-if="link" :href="link" @click="$emit('click', $event)">
    <slot></slot>
  </a>
  <div v-else @click="$emit('click', $event)">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: ''
    }
  }
};
</script>

<style></style>
