<template>
  <div class="position-relative">
    <div
      v-click-outside="hideDropdown"
      class="switcher-languages cursor-pointer"
      @click="toggleDropdown"
    >
      <p class="d-flex">
        <Icon class="mr-1" :width="25" :height="25" color="white" icon="world" />
        {{ currentLanguage }}
        <Icon
          icon="bootstrap-chevron-down"
          :width="16"
          :height="16"
          class="ml-auto"
          :class="{ invert: showDropdown }"
        />
      </p>
    </div>
    <ul v-show="showDropdown" class="switcher-options position-absolute">
      <li v-for="locale in $i18n.locales" :key="locale.code" class="col-12 col-md-6">
        <a id="lang-link" :href="link(locale.code)">{{ locale.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  },
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    currentLanguage() {
      let currentLang = this.$i18n.locale.toUpperCase();
      return currentLang === 'PT' ? 'BR' : currentLang;
    }
  },
  methods: {
    link(code) {
      let domain = this.$i18n.locales.find((item) => item.code == code).domain;
      return `https://${domain}`;
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown(e) {
      const mobile = window.matchMedia('(max-width: 576px)').matches;
      if (e.target.id !== 'lang-link' || !mobile) {
        this.showDropdown = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
.switcher-languages {
  border-radius: 26px;
  border: 1px solid #474747;
  padding: 10px 17px;
  p {
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.switcher-options {
  top: 60px;
  padding: 15px 0px;
  border-radius: 5px;
  color: #646464;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;
}
a {
  color: #646464;
  display: block;
  &:hover {
    color: #000000;
  }
}
</style>
