export const mutations = {
  SET_BAR_OPEN(state, { id, status, isBanner = false }) {
    state.isOpen[id.toString()] = status ? status : false;
    state.isOpen = Object.assign({}, state.isOpen);
    if (isBanner && state.bannerIds.indexOf(id) === -1) state.bannerIds.push(id);
  },
  CLEAR_BAR_OPEN(state) {
    state.isOpen = {};
  },
  SET_ACTUAL_SEARCH(state, search) {
    state.actualSearch = search;
  },
  CLEAR_ALL_PRISMIC_SEARCH(state) {
    state.prismicSearch = [];
  },
  SET_PRISMIC_SEARCH(state, { id, value }) {
    const idx = state.prismicSearch.findIndex((el) => el.id === id);
    if (idx > -1) {
      const aux = state.prismicSearch;
      aux[idx] = { id, value };
      state.prismicSearch = [...aux];
    } else {
      const prismicSearch = state.prismicSearch.concat([{ id, value }]);
      state.prismicSearch = prismicSearch;
    }
  },
  SET_HAS_SELECTED_SUGGESTION(state, value) {
    state.hasSuggestionSelected = value;
  },
  SET_SHOWING_DROPDOWN(state, { id, value }) {
    const idx = state.showingDropdown.findIndex((el) => el.id === id);
    if (idx > -1) {
      const aux = state.showingDropdown;
      aux[idx] = { id, value };
      state.showingDropdown = [...aux];
    } else {
      const showingDropdown = state.showingDropdown.concat([{ id, value }]);
      state.showingDropdown = showingDropdown;
    }
  },
  SET_ELEMENT_SELECTED(state, value) {
    state.elementSelected = value;
  },
  SET_DEFAULT_SUGGESTIONS(state, { exp, values }) {
    if (exp == 'design') {
      state.defaultSuggestionsDesign = values;
    } else if (exp == 'merch') {
      state.defaultSuggestionsMerch = values;
    } else {
      state.defaultSuggestions = values;
    }
  },
  SET_TYPING_SUGGESTIONS(state, value) {
    if (state.typingSuggestions.some((el) => el.key.trim() == value.key.trim())) {
      state.typingSuggestions = state.typingSuggestions.filter(
        (el) => el.key.trim() != value.key.trim()
      );
    }
    state.typingSuggestions.push(value);
  },
  SET_BAR_HOME_TOP(state, value) {
    state.showingSearchBarOnTop = value;
  }
};

export const getters = {
  isOpen: (state) => (id) => {
    return state.isOpen[id.toString()] ? state.isOpen[id.toString()] : false;
  },
  isAnyOpen: (state) => {
    return Object.keys(state.isOpen).some((k) => state.isOpen[k]);
  },
  isAnyBannerOpen: (state, getters) => {
    return state.bannerIds.some((id) => getters.isOpen(id));
  },
  getFiltersVisibility(state) {
    return state.showFilters;
  },
  getActualSearch(state) {
    return state.actualSearch;
  },
  getPrismicSearch(state) {
    return state.prismicSearch;
  },
  hasSuggestionSelected(state) {
    return state.hasSuggestionSelected;
  },
  getElementSelected(state) {
    return state.elementSelected;
  },
  getShowingDropdown(state) {
    return state.showingDropdown;
  },
  getDefaultSuggestions(state, getters, rootState, rootGetters) {
    const exp = rootGetters['experience/getExperience'];
    if (exp == 'design') {
      return state.defaultSuggestionsDesign;
    } else if (exp == 'merch') {
      return state.defaultSuggestionsMerch;
    } else {
      return state.defaultSuggestions;
    }
  },
  getPrismicTypingSuggestions: (state) => (id) => {
    const showingDropdownItem = state.prismicSearch.find((el) => el.id === id);
    const value = showingDropdownItem?.value;

    const result = state.typingSuggestions.find((el) => el.key.trim() == value?.trim());
    return result ? result.values : null;
  },
  getTypingSuggestions(state) {
    const result = state.typingSuggestions.find((el) => el.key.trim() == state.actualSearch.trim());
    return result ? result.values : null;
  },
  getShowingSearchBarOnTop(state) {
    return state.showingSearchBarOnTop;
  }
};

export const actions = {
  async LOAD_DEFAULT_SUGGESTIONS({ state, commit }) {
    const exp = this.$expKey();
    if (exp == 'design' && state.defaultSuggestionsDesign != null) return;
    if (exp == 'merch' && state.defaultSuggestionsMerch != null) return;
    try {
      const response = await this.$axios.get('search/autocomplete/');
      const values = {
        keywords: response?.data?.data?.results ? response?.data?.data?.results : [],
        collections: response?.data?.data?.packs ? response?.data?.data?.packs : [],
        styles: response?.data?.data?.styles ? response?.data?.data?.styles : []
      };
      commit('SET_DEFAULT_SUGGESTIONS', { exp, values });
    } catch (error) {
      console.error('Search Bar | LOAD_DEFAULT_SUGGESTIONS:', error);
    }
  },
  async LOAD_TYPING_SUGGESTIONS({ commit, state }, typed) {
    typed = decodeURIComponent(typed);
    typed = typed.replace(/\s/g, '-');
    typed = typed.trim();
    if (state.typingSuggestions.some((el) => el.key == typed)) {
      return true;
    }
    try {
      const response = await this.$axios.get('search/autocomplete/' + typed + '/');
      const values = {
        keywords: response?.data?.data?.results ? response?.data?.data?.results : [],
        collections: response?.data?.data?.packs ? response?.data?.data?.packs : [],
        styles: response?.data?.data?.styles ? response?.data?.data?.styles : []
      };
      commit('SET_TYPING_SUGGESTIONS', { key: typed, values });
      return true;
    } catch (error) {
      console.error('Search Bar | LOAD_TYPING_SUGGESTIONS:', error);
      return false;
    }
  }
};

export const state = () => ({
  isOpen: {},
  bannerIds: [],
  hasSuggestionSelected: false,
  actualSearch: '',
  prismicSearch: [],
  elementSelected: null,
  defaultSuggestionsMerch: null,
  defaultSuggestionsDesign: null,
  defaultSuggestions: null,
  typingSuggestions: [],
  showingSearchBarOnTop: false,
  showingDropdown: []
});
