export const mutations = {
  SET_DOWNLOAD_PERMISSION(state, { data, id }) {
    if (data) {
      data.id = id;
      state.downloadPermissions.push(data);
    } else {
      state.downloadPermissions = [];
    }
  },
  SET_SVG_DOWNLOAD(state, data) {
    state.svgDownload = data;
  },
  SET_DOWNLOADED(state, data) {
    state.downloaded = data;
  }
};

export const getters = {
  canDownload: (state) => (id) => {
    const element = state.downloadPermissions.find((el) => el?.id == id);
    return element ? element.authorized : false;
  },
  elementReason: (state) => (id) => {
    const element = state.downloadPermissions.find((el) => el?.id == id);
    return element ? element.reason : '';
  },
  svgDownload(state) {
    return state.svgDownload;
  },
  getDownloaded(state) {
    return state.downloaded;
  }
};

export const actions = {
  async CHECK_DOWNLOAD_PERMISSION({ commit, state }, id) {
    if (id && !state.downloadPermissions.find((el) => el.id == id)) {
      try {
        const response = await this.$axios.get(`vector/authorize-download/${id}/`);
        if (response.data) {
          const { data } = response.data;
          commit('SET_DOWNLOAD_PERMISSION', {
            data: data,
            id: id
          });
        }
      } catch (error) {
        console.error('Downloads | CHECK_DOWNLOAD_PERMISSION:', error);
      }
    }
  },
  async DOWNLOAD_VECTOR({ getters, dispatch, commit }, data) {
    const { id, url, isSvg = false, name = '', type = '' } = data;

    await dispatch('CHECK_DOWNLOAD_PERMISSION', id);
    const canDownload = getters.canDownload(id);

    if (canDownload) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;

      if (isSvg) {
        a.download = `${name}-by_vexels.${type}`;
      }

      a.click();
      document.body.removeChild(a);
      commit('SET_DOWNLOADED', true);
      dispatch('user/LOAD_VECTORS_STATS', [id], { root: true });
    }
  }
};

export const state = () => ({
  downloadPermissions: [],
  svgDownload: {},
  downloaded: false
});
