var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"switch-margin"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"switch-container",style:({ '--background': _vm.backgroundColor, '--mobile-background': _vm.mobileBackgroundcolor })},[_c('button',{staticClass:"rounded-pill px-3 py-2 text-center border-0",style:({
          backgroundColor: _vm.switchOnAnnual ? _vm.pillColor : _vm.activePillColor,
          color: _vm.switchOnAnnual ? _vm.inactivePillTextColor : _vm.activePillTextColor
        }),on:{"click":function($event){return _vm.$emit('switch-plans', false)}}},[_vm._t("left-text",function(){return [_vm._v("\n          "+_vm._s(_vm.$t('plans.common.cards.monthly'))+"\n        ")]})],2),_vm._v(" "),_c('button',{staticClass:"rounded-pill px-3 py-2 text-center border-0",style:({
          backgroundColor: !_vm.switchOnAnnual ? _vm.pillColor : _vm.activePillColor,
          color: !_vm.switchOnAnnual ? _vm.inactivePillTextColor : _vm.activePillTextColor
        }),on:{"click":function($event){return _vm.$emit('switch-plans', true)}}},[_vm._t("right-text",function(){return [_vm._v(" "+_vm._s(_vm.$t('plans.common.cards.annual')))]}),_vm._v(" "),_vm._t("annual-discount")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }