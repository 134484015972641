<template>
  <b-row class="switch-margin">
    <b-col class="d-flex align-items-center justify-content-center">
      <div
        class="switch-container"
        :style="{ '--background': backgroundColor, '--mobile-background': mobileBackgroundcolor }"
      >
        <button
          :style="{
            backgroundColor: switchOnAnnual ? pillColor : activePillColor,
            color: switchOnAnnual ? inactivePillTextColor : activePillTextColor
          }"
          class="rounded-pill px-3 py-2 text-center border-0"
          @click="$emit('switch-plans', false)"
        >
          <slot name="left-text">
            {{ $t('plans.common.cards.monthly') }}
          </slot>
        </button>
        <button
          :style="{
            backgroundColor: !switchOnAnnual ? pillColor : activePillColor,
            color: !switchOnAnnual ? inactivePillTextColor : activePillTextColor
          }"
          class="rounded-pill px-3 py-2 text-center border-0"
          @click="$emit('switch-plans', true)"
        >
          <slot name="right-text"> {{ $t('plans.common.cards.annual') }}</slot>
          <slot name="annual-discount"></slot>
        </button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    switchOnAnnual: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: '#f6f6f6'
    },
    pillColor: {
      type: String,
      default: 'transparent'
    },
    activePillColor: {
      type: String,
      default: '#3cd699'
    },
    activePillTextColor: {
      type: String,
      default: '#fff'
    },
    inactivePillTextColor: {
      type: String,
      default: '#545454'
    },
    mobileBackgroundcolor: {
      type: String,
      default: '#f6f6f6'
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  margin: 5px;
  min-width: 95px;
}
.switch-margin {
  margin-top: 14px;
}
.switch-container {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50rem;
  background-color: var(--background);
  @include sm {
    background-color: var(--mobile-background);
  }
}
</style>
