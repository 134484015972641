<template>
  <div>
    <footer v-lazy:background-image="'https://cdn.vexels.com/img/bg-footer.jpg'">
      <div>
        <b-container>
          <b-row>
            <div class="col-12 col-sm-6 col-lg mt-lg-0">
              <ul>
                <li>
                  <h4>{{ $t('main.footer.content.title') }}</h4>
                </li>
                <li>
                  <a href="https://www.vexels.com/blog/" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.content.option') }}
                  </a>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('niches'))">
                    {{ $t('main.footer.content.option5Merch') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('styles'))">
                    {{ $t('main.footer.content.option3') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('collections'))">
                    {{ $t('main.footer.content.option4') }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$urlBuilder('landing', { uid: 'psd-t-shirt-designs' })">{{
                    $t('main.footer.content.option6')
                  }}</nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$urlBuilder('landing', { uid: 'commercial-use-vectors' })">{{
                    $t('main.footer.content.option7')
                  }}</nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$urlBuilder('landing', { uid: 't-shirt-designs' })">{{
                    $t('main.footer.content.option8')
                  }}</nuxt-link>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-6 col-lg mt-4 mt-lg-0">
              <ul>
                <li>
                  <h4>{{ $t('main.footer.legal.title') }}</h4>
                </li>
                <li>
                  <a :href="$trailingSlash(localePath('terms-and-conditions'))">{{
                    $t('main.footer.legal.option')
                  }}</a>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('privacy-policy'))">{{
                    $t('main.footer.legal.option2')
                  }}</nuxt-link>
                </li>
                <li>
                  <a href="https://vexels.crisp.help/en/" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.legal.option3') }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-6 col-lg mt-4 mt-lg-0">
              <ul>
                <li>
                  <h4>{{ $t('main.footer.company.title') }}</h4>
                </li>
                <li>
                  <a href="/merch-plans/footer/">{{ $t('main.footer.company.option') }}</a>
                </li>
                <li>
                  <a
                    href="https://www.vexels.com/merch-for-creators/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t('main.footer.company.option6') }}
                  </a>
                </li>
                <li>
                  <nuxt-link :to="$urlBuilder('landing', { uid: 'affiliate-program' })">{{
                    $t('main.footer.company.option4')
                  }}</nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('about-us'))">{{
                    $t('main.footer.company.option2')
                  }}</nuxt-link>
                </li>
                <li>
                  <nuxt-link :to="$trailingSlash(localePath('enterprise'))">{{
                    $t('main.footer.company.option3')
                  }}</nuxt-link>
                </li>
                <li>
                  <span class="cursor-pointer" @click="$bvModal.show('contact-modal')">{{
                    $t('main.footer.company.option5')
                  }}</span>
                </li>
                <li>
                  <a href="https://feedback.vexels.com/" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.company.option7') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-6 col-lg mt-4 mt-lg-0">
              <ul>
                <li>
                  <h4>{{ $t('main.footer.makerstools.title') }}</h4>
                </li>
                <li>
                  <a :href="designerUrl" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.makerstools.option5') }}
                  </a>
                </li>
                <li>
                  <a href="https://quotes.vexels.com" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.makerstools.option4') }}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.vexels.com/t-shirt-maker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ $t('main.footer.makerstools.option') }}
                  </a>
                </li>
                <li>
                  <a href="https://mockups.vexels.com/" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.makerstools.option3') }}
                  </a>
                </li>
                <li>
                  <a href="https://logomaker.vexels.com/" target="_blank" rel="noopener noreferrer">
                    {{ $t('main.footer.makerstools.option2') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-12 mt-4 col-lg mt-lg-0">
              <ul>
                <li class="mb-4">
                  <Button
                    shape="full"
                    size="medium"
                    :with-gradient="true"
                    gradient="#5388F2"
                    :use-nuxt-link="false"
                    to="/merch-plans/footer/"
                    :extra-data="{ event: '' }"
                    width="full"
                    >{{ $t('main.footer.options.button') }}</Button
                  >
                </li>
                <li v-if="!$auth.loggedIn" class="mb-4">
                  <Button
                    v-b-modal.user-modal
                    shape="blue-outline"
                    size="medium"
                    width="full"
                    @click="setSignupMode"
                    >{{ $t('main.footer.options.button2') }}</Button
                  >
                </li>
                <li class="mb-4">
                  <SwitcherLanguages />
                </li>
              </ul>
            </div>
          </b-row>
          <hr />
          <div>
            <div class="row">
              <div class="col-12 col-sm-6">
                <p class="copytight-text text-center text-md-left my-3 my-md-0">
                  {{ $t('main.footer.options.text') }} © {{ showYear }}
                  <img
                    src="https://cdn.vexels.com/img/logo-variants/grey-logo-variant.svg"
                    :alt="$t('main.footer.options.smallLogoAlt')"
                    height="14"
                    width="79"
                    class="my-2 mx-md-2 mx-auto img-fluid d-block d-md-inline"
                    loading="lazy"
                  />
                  {{ $t('main.footer.options.text2') }}
                </p>
              </div>
              <div class="col-12 col-sm-6">
                <ul class="d-flex justify-content-center justify-content-md-end">
                  <li class="mr-2">
                    <a
                      href="https://www.instagram.com/vexelsdesign/"
                      target="_blank"
                      rel="noopener noreferrer"
                      :aria-label="$t('main.footer.options.instagramAriaLabel')"
                    >
                      <Icon
                        :width="iconSize"
                        :height="iconSize"
                        icon="icon-instagram"
                        :as-original="true"
                        :opacity="true"
                      />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a
                      href="https://www.facebook.com/vexelsgraphics/"
                      target="_blank"
                      rel="noopener noreferrer"
                      :aria-label="$t('main.footer.options.facebookAriaLabel')"
                    >
                      <Icon
                        :width="iconSize"
                        :height="iconSize"
                        icon="icon-facebook"
                        :as-original="true"
                        :opacity="true"
                      />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a
                      href="https://www.pinterest.com/vexels/"
                      target="_blank"
                      rel="noopener noreferrer"
                      :aria-label="$t('main.footer.options.pinterestAriaLabel')"
                    >
                      <Icon
                        :width="iconSize"
                        :height="iconSize"
                        icon="icon-pinterest"
                        :as-original="true"
                        :opacity="true"
                      />
                    </a>
                  </li>
                  <li class="mx-2">
                    <a
                      href="https://www.youtube.com/@Vexels"
                      target="_blank"
                      rel="noopener noreferrer"
                      :aria-label="$t('main.footer.options.youtubeAriaLabel')"
                    >
                      <Icon
                        :width="iconSize"
                        :height="iconSize"
                        icon="icon-youtube"
                        :opacity="true"
                        :as-original="true"
                      />
                    </a>
                  </li>
                  <li class="ml-2">
                    <a
                      href="https://uy.linkedin.com/company/vexels"
                      target="_blank"
                      rel="noopener noreferrer"
                      :aria-label="$t('main.footer.options.linkedinAriaLabel')"
                    >
                      <Icon
                        :width="iconSize"
                        :height="iconSize"
                        icon="icon-linkedin"
                        :opacity="true"
                        :as-original="true"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </footer>
  </div>
</template>

<script>
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import SwitcherLanguages from '@/components/Footer/SwitcherLanguages';

export default {
  components: {
    Button,
    Icon,
    SwitcherLanguages
  },
  data() {
    return {
      iconSize: 30
    };
  },
  computed: {
    showYear() {
      return new Date().getFullYear();
    },
    designerUrl() {
      if (this.$i18n.locale === 'es') {
        return 'https://es.vexels.com/designer/';
      } else if (this.$i18n.locale === 'pt') {
        return 'https://br.vexels.com/designer/';
      } else if (this.$i18n.locale === 'de') {
        return 'https://de.vexels.com/designer/';
      }
      return 'https://www.vexels.com/designer/';
    }
  },
  methods: {
    setSignupMode() {
      this.$store.dispatch('user/CHANGE_MODAL_MODE', 'signup');
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  padding-top: 94px;
  padding-bottom: 50px;
  background-size: cover;
  background-color: $seasonalColor;
}
h4 {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
}
ul {
  list-style: none;
  padding: 0;
  li {
    font-size: 15px;
    line-height: 30px;
    a,
    span {
      color: #707070;
      transition: all 0.1s linear;
      &:hover {
        transition: all 0.1s linear;
        color: #ffffff;
      }
    }
  }
}
hr {
  margin-top: 100px;
  margin-bottom: 30px;
  border-color: #252525;
}
.copytight-text {
  font-size: 13px;
  color: #b8b8b8;
  margin: 0;
}
</style>
