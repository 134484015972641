export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_STRATEGY(state, strategy) {
    state.strategy = strategy;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_STATUS(state, status) {
    state.status = status;
  },
  BANNER_B_COOKIE(state, idPlan) {
    if (idPlan != 1) {
      this.$cookies.set('vx.hasPlan', true);
    } else {
      this.$cookies.remove('vx.hasPlan');
    }
  }
};

export const getters = {
  loggedIn: (state) => {
    return state.user && state.token;
  },
  token: (state) => {
    return state.token;
  },
  user: (state) => {
    return state.user;
  },
  strategy: (state) => {
    return state.strategy;
  },
  getStatus: (state) => {
    return state.status;
  }
};

export const actions = {
  async localLogin({ state, commit, dispatch }, data) {
    if (!state.user) {
      const { username, password, captchaToken } = data;
      if (!username || !password || !captchaToken) {
        return false;
      }
      try {
        commit('SET_STATUS', 'loading');
        const { status, data } = await this.$axiosGeo.post('v1/auth/login/', {
          username,
          password,
          captchaToken
        });
        const token = data.accessToken;
        if (status === 200 && !data.error) {
          const domain = process.env.COOKIES_DOMAIN;
          const path = '/';

          commit('SET_STRATEGY', 'local');
          commit('SET_TOKEN', token);
          this.$cookies.set('vx.token.local', token, { domain, path });
          this.$cookies.set('vx.strategy', 'local', { domain, path });
          const { status, data } = await this.$axios.get('user/me/', {
            headers: { Authorization: token }
          });
          if (status === 200) {
            this.$dataLayerPush('login', {
              auth: { method: 'local', user: data }
            });
            commit('SET_USER', data);
            commit('SET_STATUS', 'loaded');
            dispatch('PLAN_ID_COOKIE');
            commit('BANNER_B_COOKIE', data.idPlan);
            dispatch('ACTIVE_USER_COOKIE');
            await dispatch('user/GET_FOLDERS', '', { root: true });
            return true;
          }
        }
        commit('SET_STATUS', 'initial');
        return false;
      } catch (err) {
        commit('SET_STATUS', 'initial');
        const res = JSON.parse(err.request.response).message || 'invalid login';
        throw res;
      }
    }
  },
  async googleLogin({ state, commit, dispatch, rootGetters }, data) {
    if (!state.user) {
      const { id_token } = data;
      if (!id_token) {
        return false;
      }
      try {
        commit('SET_STATUS', 'loading');
        const { status, data } = await this.$axiosGeo.post('v1/auth/login/google/', {
          id_token
        });
        const token = data.accessToken;
        const isSignUp = data.isSignUp;
        if (status === 200 && !data.error) {
          const domain = process.env.COOKIES_DOMAIN;
          const path = '/';

          commit('SET_STRATEGY', 'google');
          commit('SET_TOKEN', token);
          this.$cookies.set('vx.token.google', token, { domain, path });
          this.$cookies.set('vx.strategy', 'google', { domain, path });
          const { status, data } = await this.$axios.get('user/me/', {
            headers: { Authorization: token }
          });
          if (status === 200) {
            this.$dataLayerPush(isSignUp ? 'sign_up' : 'login', {
              auth: { method: 'google', user: data }
            });
            commit('SET_USER', data);
            commit('SET_STATUS', 'loaded');
            dispatch('PLAN_ID_COOKIE');
            commit('BANNER_B_COOKIE', data.idPlan);
            dispatch('ACTIVE_USER_COOKIE');
            await dispatch('user/GET_FOLDERS', '', { root: true });
            if (rootGetters['vectors/purchaseFlowStatus'] === 'account') {
              dispatch('vectors/CHANGE_PURCHASE_FLOW_STATUS', 'payment', { root: true });
            }
            if (process.client) {
              window.$nuxt.refresh();
            }
            return true;
          }
        }
        commit('SET_STATUS', 'initial');
        return false;
      } catch (err) {
        console.error('Auth | googleLogin:', err);
        commit('SET_STATUS', 'initial');
        return false;
      }
    }
  },
  async facebookLogin({ state, commit, dispatch, rootGetters }, data) {
    if (!state.user) {
      const { accessToken } = data;
      if (!accessToken) {
        return false;
      }
      try {
        commit('SET_STATUS', 'loading');
        const { status, data } = await this.$axiosGeo.post('v1/auth/login/facebook/', {
          accessToken
        });
        const token = data.accessToken;
        const isSignUp = data.isSignUp;
        if (status === 200 && !data.error) {
          const domain = process.env.COOKIES_DOMAIN;
          const path = '/';

          commit('SET_STRATEGY', 'facebook');
          commit('SET_TOKEN', token);
          this.$cookies.set('vx.token.facebook', token, { domain, path });
          this.$cookies.set('vx.strategy', 'facebook', { domain, path });
          const { status, data } = await this.$axios.get('user/me/', {
            headers: { Authorization: token }
          });
          if (status === 200) {
            this.$dataLayerPush(isSignUp ? 'sign_up' : 'login', {
              auth: { method: 'facebook', user: data }
            });
            commit('SET_USER', data);
            commit('SET_STATUS', 'loaded');
            dispatch('PLAN_ID_COOKIE');
            commit('BANNER_B_COOKIE', data.idPlan);
            dispatch('ACTIVE_USER_COOKIE');
            await dispatch('user/GET_FOLDERS', '', { root: true });
            if (rootGetters['vectors/purchaseFlowStatus'] === 'account') {
              dispatch('vectors/CHANGE_PURCHASE_FLOW_STATUS', 'payment', { root: true });
            }
            if (process.client) {
              window.$nuxt.refresh();
            }
            return true;
          }
        }
        commit('SET_STATUS', 'initial');
        return false;
      } catch (err) {
        console.error('Auth | facebookLogin:', err);
        commit('SET_STATUS', 'initial');
        return false;
      }
    }
  },
  async loadUser({ state, commit, dispatch }, data) {
    const { token, strategy } = data;
    const forceLoad = data.forceLoad || false;
    const domain = process.env.COOKIES_DOMAIN;
    const path = '/';

    commit('SET_STRATEGY', strategy);
    commit('SET_TOKEN', token);
    this.$cookies.set(`vx.token.${strategy}`, token, { domain, path });
    this.$cookies.set('vx.strategy', strategy, { domain, path });
    if (!state.user || forceLoad) {
      commit('SET_STATUS', 'loading');
      try {
        const { status, data } = await this.$axios.get('user/me/', {
          headers: {
            Authorization: token
          }
        });
        if (status === 200) {
          this.$dataLayerPush('identify', {
            auth: { user: data }
          });
          commit('SET_USER', data);
          commit('SET_STATUS', 'loaded');
          dispatch('PLAN_ID_COOKIE');
          commit('BANNER_B_COOKIE', data.idPlan);
          dispatch('ACTIVE_USER_COOKIE');
          await dispatch('user/GET_FOLDERS', '', { root: true });
        } else {
          commit('SET_STATUS', 'initial');
          return false;
        }
      } catch (err) {
        commit('SET_STATUS', 'initial');
        console.error('Auth | loadUser:', err);
      }
    }

    this.$cookies.set('vx.user_id', state.user.id, { domain, path });
  },
  logout({ state, commit, dispatch }) {
    const domain = process.env.COOKIES_DOMAIN;
    const path = '/';
    let strategy = this.$cookies.get('vx.strategy');

    if (strategy === '' || !strategy) {
      strategy = state.strategy;
    }
    this.$cookies.remove('vx.strategy', { domain, path });
    this.$cookies.remove(`vx.token.${strategy}`, { domain, path });
    this.$cookies.remove('vx.user_id', { domain, path });
    this.$cookies.remove('vx.active_user', { domain, path });
    dispatch('REMOVE_PLAN_ID_COOKIE');
    commit('SET_TOKEN', false);
    commit('SET_STRATEGY', false);
    commit('SET_USER', false);
    this.$dataLayerPush('logout');
  },
  PLAN_ID_COOKIE({ state, rootGetters }) {
    const domain = process.env.COOKIES_DOMAIN;
    const path = '/';

    const merch = rootGetters['experience/getMerch'];
    const design = rootGetters['experience/getDesign'];
    const userPlanType = this.$auth.user.userPlanType;
    if (userPlanType == merch) {
      this.$cookies.set('vx.plan_merch', 'plan_merch', { domain, path });
    } else if (userPlanType == design) {
      this.$cookies.set('vx.plan_design', 'plan_design', { domain, path });
    }

    let planId = parseInt(state.user.idPlan);
    let nameSetted = '';

    if (planId) {
      nameSetted = 'u_p_i' + planId;
      this.$cookies.set(nameSetted, '1', { domain, path });
    }
    for (let i = 0; i < 100; i++) {
      let name = 'u_p_i' + i;
      if (name != nameSetted) {
        this.$cookies.remove(name);
      }
    }
  },
  REMOVE_PLAN_ID_COOKIE() {
    const domain = process.env.COOKIES_DOMAIN;
    const path = '/';
    this.$cookies.remove('vx.plan_merch', { domain, path });
    this.$cookies.remove('vx.plan_design', { domain, path });
  },
  ACTIVE_USER_COOKIE({ state }) {
    const domain = process.env.COOKIES_DOMAIN;
    const path = '/';

    const purchaseDate = new Date(state.user.datePlanPurchase);
    const millisecsInMonth = 1000 * 60 * 60 * 24 * 30;
    const today = new Date();
    const aMonthHasPassed =
      purchaseDate.getTime() != 0
        ? today.getTime() - purchaseDate.getTime() > millisecsInMonth
        : false;

    this.$cookies.set('vx.active_user', aMonthHasPassed, { domain, path });
  }
};

export const state = () => ({
  user: false,
  token: false,
  strategy: false,
  status: 'initial'
});
