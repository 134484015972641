<template>
  <div>
    <Modal :id-modal="idModal"></Modal>
    <button
      :aria-label="$t('main.header.nav.menuToggle')"
      class="d-lg-none bg-transparent border-0"
      @click="$bvModal.show(idModal)"
    >
      <i class="ph-bold ph-list" :style="{ fontSize: '24px' }"></i>
    </button>
  </div>
</template>

<script>
import Modal from '@/components/Header/MenuMobile/Modal/index.vue';
export default {
  components: {
    Modal
  },
  data: () => {
    return {
      idModal: 'menu-mobile-modal'
    };
  }
};
</script>
