<template>
  <b-row class="d-block" :class="{ 'd-md-none': !forceToggle }" align-h="start">
    <b-col class="d-flex align-items-center justify-content-between mt-3">
      <div class="container">
        <ul>
          <li class="login cursor-pointer" @click="$emit('choose-login')">
            <a>{{ $t('profile.logIn.main.title') }}</a>
          </li>
          <li
            class="signup cursor-pointer"
            :class="{ selected: !isLogin }"
            @click="$emit('choose-signup')"
          >
            <a>{{ $t('profile.signUp.main.title') }}</a>
          </li>
          <hr class="option" />
        </ul>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      deafault: false
    },
    forceToggle: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  li {
    display: inline;
    text-align: center;
    a {
      display: inline-block;
      width: 48%;
      padding: 0.75rem 0;
      margin: 0;
      text-decoration: none;
      color: $gray;
    }
  }
  hr.option {
    height: 1px;
    width: 50%;
    margin: 0;
    background: $gray;
    border: none;
    transition: 0.3s ease-in-out;
  }
}
li.signup.selected ~ hr.option {
  margin-left: 50%;
}
</style>
