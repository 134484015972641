export const mutations = {
  SET_STYLES(state, data) {
    state.styles = data;
  },
  SET_STYLE(state, data) {
    state.style = data;
  },
  SET_STYLE_CONTENT(state, value = []) {
    state.styleContent = value.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });
  },
  SET_LOADING_STYLE(state, value) {
    state.loadingStyle = value;
  },
  SET_STYLE_CURRENT_PAGE(state, value) {
    state.styleCurrentPage = value;
  },
  SET_STYLE_TOTAL_PAGES(state, data) {
    state.styleTotalPages = data;
  },
  SET_STYLES_CURRENT_PAGE(state, value) {
    state.stylesCurrentPage = value;
  },
  SET_STYLES_TOTAL_PAGES(state, data) {
    state.stylesTotalPages = data;
  },
  SET_STYLE_LISTING(state, { name, data }) {
    if (name && data.length) {
      data.map((el) => {
        el.title = el.name;
        el.image = el.image ? el.image : el.cover;
        el.link = this.$urlBuilder('style', {
          slug: el.slug
        });
      });
      state.styleListings = { ...state.styleListings, [name]: data };
    }
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  }
};

export const getters = {
  getStylesName(state) {
    return state.styleName;
  },
  listExist: (state) => (name) => {
    return name && state.styleListings[name]?.length;
  },
  getStyleList: (state) => (name) => {
    return name ? state.styleListings[name] : [];
  },
  getImageFirstResult(state) {
    return state.styleContent.length ? state.styleContent[0].image : '';
  }
};

export const actions = {
  async LOAD_STYLES({ commit, state }, data = {}) {
    try {
      commit('SET_LOADING_STYLE', true);
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : 12;

      if (data.page > state.stylesCurrentPage && state.stylesCurrentPage < state.stylesTotalPages) {
        const response = await this.$axios.post('/style/', data);
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const styles = response.data.data.styles ? response.data.data.styles : [];
        commit('SET_STYLES', [...state.styles, ...styles]);
        commit('SET_STYLES_CURRENT_PAGE', data.page);
        commit('SET_STYLES_TOTAL_PAGES', totalPages);
      }
    } catch (error) {
      console.error('Styles | LOAD_STYLES:', error);
    } finally {
      commit('SET_LOADING_STYLE', false);
    }
  },
  async LOAD_STYLE({ commit }, data) {
    try {
      const response = await this.$axios.get(`/style/${data.slug}/`);
      const style = response.data.data ? response.data.data : null;
      commit('SET_STYLE', style);
    } catch (error) {
      console.error('Styles | LOAD_STYLE:', error);
    }
  },
  async LOAD_STYLE_CONTENT({ commit, dispatch }, data) {
    try {
      commit('SET_LOADING', true);
      const searchAmount = this.$g('SEARCH_AMOUNT');
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : searchAmount;
      const response = await this.$axios.post(`/style/${data.slug}/contents/`, data);
      if (!response.data.error) {
        const vectors = response.data.data.vectors ? response.data.data.vectors : [];
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const currentPage = data.page;
        commit('SET_STYLE_CONTENT', vectors);
        commit('SET_STYLE_CURRENT_PAGE', currentPage);
        commit('SET_STYLE_TOTAL_PAGES', totalPages);
        dispatch('vectors/ADD_LISTING', vectors, { root: true });
        commit('SET_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOADING', false);
      console.error('Styles | LOAD_STYLE_CONTENT:', error);
    }
  },
  async LOAD_STYLE_LISTING({ commit, getters }, { name, ids }) {
    if (name && !getters.listExist(name)) {
      try {
        const { data } = await this.$axios.post('/style/custom-list/', { ids });
        if (!data.error) {
          commit('SET_STYLE_LISTING', { name, data: data.data?.styles });
        } else {
          console.error('Styles | LOAD_STYLE_LISTING:', data.error);
        }
      } catch (error) {
        console.error('Styles | LOAD_STYLE_LISTING:', error);
      }
    }
  },
  CLEAN_STYLES({ commit }) {
    commit('SET_STYLE_CONTENT', []);
    commit('SET_STYLE_CURRENT_PAGE', -1);
    commit('SET_STYLE_TOTAL_PAGES', 0);
  }
};

export const state = () => ({
  styleName: 'style',
  styles: [],
  style: null,
  styleContent: [],
  loadingStyle: false,
  styleTotalPages: 0,
  styleCurrentPage: -1,
  stylesTotalPages: 0,
  stylesCurrentPage: -1,
  // LISTING
  styleListings: {},
  isLoading: false
});
