<template>
  <div class="stripe-form-wrapper">
    <Loading v-if="getLoadingPayment && !errorMessage"></Loading>
    <FreeTrialUsedModal />
    <form :class="{ processingPayment }">
      <div id="payment-element"></div>
      <div v-show="showSubscribeButton" class="subscribe-button-wrapper">
        <span class="mb-2">
          <MessageError v-if="errorMessage">{{ errorMessage }}</MessageError>
        </span>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import Loading from 'UI/Loading';
import MessageError from 'UI/MessageError';
import FreeTrialUsedModal from 'UI/FreeTrialUsedModal';
export default {
  components: {
    Loading,
    MessageError,
    FreeTrialUsedModal
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    default: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      processingPayment: false,
      showSubscribeButton: false,
      stripe: false,
      elements: false,
      internalErrorMessage: false,
      resizeObserver: null
    };
  },
  computed: {
    ...mapState('vectors', ['actualVector', 'actualVectorId']),
    ...mapState('plans', ['openPlan']),
    ...mapState('payments', [
      'stripeClientSecret',
      'stripeMode',
      'freeTrialUsed',
      'updateSubscription'
    ]),
    ...mapGetters('payments', ['getLoadingPayment', 'getFreeTrialUsed']),
    errorMessage() {
      if (this.getLoadingPayment) {
        return false;
      }
      return this.internalErrorMessage
        ? this.internalErrorMessage
        : this.stripeClientSecret === false
        ? this.$t('purchase.payment.processError.generalErrorMessage')
        : false;
    },
    vectorUrlDownload() {
      return this.actualVector ? this.actualVector.urlDownload : '';
    }
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.setBuyFunction();
      }
    },
    stripeClientSecret() {
      if (this.stripeClientSecret === '' || this.stripeClientSecret === false) {
        return;
      } else {
        this.initStripe();
      }
    },
    updateSubscription(newValue) {
      if (newValue) {
        this.createSubscription();
      }
    }
  },
  mounted() {
    this.createSubscription();
  },
  methods: {
    async initStripe() {
      this.stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY);
      const options = {
        clientSecret: this.stripeClientSecret,
        appearance: {
          theme: 'flat'
        }
      };

      this.elements = this.stripe.elements(options);
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');

      const container = document.getElementById('payment-element');
      this.resizeObserver = new ResizeObserver(() => {
        if (container.clientHeight <= 2) return;
        this.$store.commit('payments/SET_LOADING_PAYMENT', false);
        this.showSubscribeButton = true;
        this.resizeObserver.disconnect();
      });
      this.resizeObserver.observe(container);
    },
    async subscribeClick() {
      this.$store.commit('payments/SET_LOADING_PAYMENT', true);

      this.processingPayment = true;
      const host = window.location.protocol + '//' + window.location.host;
      var return_url = `${host}/payment-successful`;
      var params = {};
      if (this.actualVectorId) {
        params['vector_id'] = this.actualVectorId;
      }
      if (this.vectorUrlDownload) {
        params['download_url'] = this.vectorUrlDownload;
      }
      const query = new URLSearchParams(params);
      return_url = return_url + '?' + query.toString();

      const paymentMethod = 'confirmPayment';

      const { error } = await this.stripe[paymentMethod]({
        elements: this.elements,
        confirmParams: { return_url }
      });

      this.processingPayment = false;
      this.$store.commit('payments/SET_LOADING_PAYMENT', false);
      if (error) {
        if (error.type != 'validation_error') {
          this.internalErrorMessage = error.message;
        }
      }
    },
    setBuyFunction() {
      this.$store.commit('payments/SET_BUY_NOW_FUNCTION', this.subscribeClick);
    },
    createSubscription() {
      document.getElementById('payment-element').innerHTML = '';
      this.$store.commit('payments/SET_LOADING_PAYMENT', true);

      const data = {
        lookup_key: this.openPlan.id.toString(),
        vector_id: this.actualVectorId ? this.actualVectorId.toString() : undefined,
        affiliate_click_id: this.$cookies.get('vx.aff'),
        ga_client_id: this.$cookies.get('_ga')
      };

      this.$store.dispatch('payments/CREATE_STRIPE_CHECKOUT_PAYMENT_INTENT', data);

      if (this.default) {
        this.setBuyFunction();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.stripe-form-wrapper {
  @include md {
    margin: auto;
  }

  position: relative;

  form {
    padding: 10px;
  }

  form.processingPayment {
    display: none;
  }
}

.subscribe-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1em;
}
</style>
