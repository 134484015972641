export const mutations = {
  SET_RESULT_LIST(state, result) {
    const {
      result: { data },
      exp,
      name,
      type
    } = result;
    data.forEach((el) => {
      el.title = el.name;
      el.image = el.image || el.cover;
      el.link = this.$urlBuilder(type, {
        id: el.id,
        canonizedName: el.canonizedName,
        slug: el.slug,
        type: el.idType
      });
    });
    state.listingsResult = { ...state.listingsResult, [`${exp}_${name}`]: data };
  }
};

export const getters = {
  listExist: (state, getters, rootState, rootGetters) => (list, exp) => {
    exp = exp || rootGetters['experience/getDefault'];
    const listName = `${exp}_${list}`;
    return state.listingsResult[listName] && state.listingsResult[listName].length > 0;
  },
  list: (state, getters, rootState, rootGetters) => (list, exp) => {
    exp = exp || rootGetters['experience/getDefault'];
    const listName = `${exp}_${list}`;
    return state.listingsResult[listName];
  }
};

export const actions = {
  async GET_LIST({ commit, state, dispatch, rootGetters }, data) {
    const { name, type } = data;
    const exp = data.exp || rootGetters['experience/getDefault'];
    const listingLoaded = state.listingsResult[`${exp}_${name}`];

    if (!listingLoaded) {
      try {
        const config = { headers: { Experience: exp } };
        const response = await this.$axios.get('listings/' + name + '/', config);
        if (response.data) {
          commit('SET_RESULT_LIST', {
            result: response.data,
            name,
            type,
            exp
          });
        }
      } catch (error) {
        console.error('Listings | GET_LIST:', error);
      }
    }
  }
};

export const state = () => ({
  listingsResult: {}
});
