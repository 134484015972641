<template>
  <b-col v-if="openPlan" class="pricing-info-container justify-content-center">
    <div class="d-flex flex-column">
      <div class="title">{{ openPlan.name }}</div>
      <div class="subtitle">{{ openPlan.description }}</div>
      <div class="frequency">{{ chargeFrequencyText }}</div>
      <div class="divider d-none d-md-block"></div>
      <p class="contact-text d-none d-md-block" @click="needHelp">
        {{ $t('purchase.price.info.contactUs') }}
      </p>
      <div class="divider d-none d-md-block"></div>
      <div class="gray-text terms-and-cond d-none d-md-block">
        <p v-if="openPlan.isMonthlyAnnualCommitment">
          {{ $t('purchase.price.info.subscriptionRenewMonthlyAnnualCommitment') }}
        </p>
        <p v-else>
          {{ $t('purchase.price.info.subscriptionRenew') }}
        </p>
        <p>
          <span class="gray-text">{{ $t('purchase.price.info.byPurchasing') }}</span>
          <a :href="$trailingSlash(localePath('terms-and-conditions'))" target="_blank">{{
            $t('purchase.price.info.termsAndConditions')
          }}</a>
        </p>
      </div>

      <div class="divider"></div>

      <div v-if="openPlan.hasFreeTrial && !freeTrialUsed">
        <div class="gray-text terms-and-cond">
          <p>
            {{ $t('purchase.price.info.freeTrialDetail') }}
          </p>
        </div>

        <div class="divider"></div>
      </div>

      <div v-if="activeMethod === 'stripe-elements' || openPlan.couponCode">
        <div
          v-if="activeMethod === 'stripe-elements' && !openPlan.couponCode"
          class="coupon-container"
        >
          <h2 class="coupon-title gray-text">{{ $t('purchase.price.info.couponTitle') }}</h2>
          <div
            class="input-wrapper"
            :class="{
              'is-focused': isInputFocused,
              'has-error':
                !getLoadingPayment &&
                !getLoadingCoupon &&
                !isInputFocused &&
                !noError &&
                (stripeError || (totals && !totals.coupon)) &&
                code !== ''
            }"
          >
            <input
              v-model="couponCode"
              type="text"
              :placeholder="$t('purchase.price.info.couponPlaceholder')"
              class="input-field"
              :disabled="getLoadingPayment || getLoadingCoupon"
              @focus="isInputFocused = true"
              @blur="isInputFocused = false"
              @keyup.enter="applyPromotionCode"
            />
            <Button
              class="add-button"
              :enabled="!getLoadingPayment && !getLoadingCoupon && couponCode !== ''"
              @click="applyPromotionCode"
            >
              <span class="is-desktop">{{ $t('purchase.price.info.couponButton') }}</span>
              <span class="is-mobile">{{ $t('purchase.price.info.couponButtonShort') }}</span>
            </Button>
          </div>
          <div
            v-if="
              !getLoadingPayment &&
              !getLoadingCoupon &&
              !noError &&
              (stripeError || (totals && !totals.coupon)) &&
              code !== ''
            "
            class="coupon-error d-flex text-danger"
          >
            <Icon :width="14" :height="14" icon="info-error" />
            <span v-if="stripeError">{{ $t('purchase.price.info.couponErrorInvalid') }}</span>
            <span v-else-if="totals && !totals.coupon">{{
              $t('purchase.price.info.couponError')
            }}</span>
          </div>
          <div class="divider"></div>
        </div>
        <div v-if="openPlan.couponCode || totals.coupon">
          <div
            v-if="openPlan.couponCode"
            class="coupon-section d-flex align-items-center justify-content-between"
          >
            <div class="coupon-badge grid gap-3 py-2">
              <span class="font-weight-bold">{{ openPlan.couponCode }}</span>
              <span>(-{{ openPlan.discount }}%)</span>
            </div>
          </div>
          <div
            v-if="activeMethod === 'stripe-elements' && totals.coupon"
            class="coupon-section d-flex align-items-center justify-content-between"
          >
            <div class="coupon-badge grid gap-3 py-2">
              <span class="font-weight-bold">{{ totals.coupon.code }}</span>
              <span class="mr-3">(-{{ totals.coupon.percentOff }}%)</span>
              <!-- eslint-disable -->
              <button class="remove-coupon btn text-dark p-0" @click="removePromotionCode">
                ✕
              </button>
              <!-- eslint-enable -->
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
      <div class="flex justify-content-between m-0 row">
        <div>
          <div class="total-due">{{ $t('purchase.price.info.totalDue') }}</div>
          <p v-if="openPlan.hasFreeTrial && !freeTrialUsed" class="free-trial gray-text">
            {{ $t('purchase.price.info.freeTrial') }}
            <span v-if="openPlan.couponCode || totals.coupon">
              <del v-if="openPlan.couponCode" class="text-danger"
                >${{ Math.ceil(openPlan.originalCostWithoutDiscount) }}</del
              >
              <del v-else-if="totals.coupon">${{ openPlan.originalCost }}</del>
              ${{ currentPrice }}
            </span>
            <span v-else>${{ openPlan.originalCost }}</span>
          </p>
        </div>
        <div class="price">
          <span v-if="openPlan.hasFreeTrial && !freeTrialUsed" class="amount">$0</span>
          <span v-else>
            <span v-if="openPlan.couponCode || totals.coupon">
              <del v-if="openPlan.couponCode" class="text-danger"
                >${{ Math.ceil(openPlan.originalCostWithoutDiscount) }}</del
              >
              <del
                v-else-if="activeMethod === 'stripe-elements' && totals.coupon"
                class="text-danger"
                >${{ openPlan.originalCost }}</del
              >
              <span class="amount">${{ currentPrice }}</span>
            </span>
            <span v-else class="amount">${{ openPlan.originalCost }}</span>
          </span>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    Button,
    Icon
  },
  props: {
    activeMethod: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      isInputFocused: false,
      couponCode: '',
      noError: true
    };
  },
  computed: {
    ...mapState('plans', ['openPlan']),
    ...mapState('payments', [
      'freeTrialUsed',
      'totals',
      'stripeError',
      'code',
      'updateSubscription'
    ]),
    ...mapGetters('payments', ['getLoadingPayment', 'getLoadingCoupon']),
    chargeFrequencyText() {
      const frequency = this.openPlan.chargeFrecuency;
      return frequency === 'ANNUALLY'
        ? this.$t('purchase.price.info.billedAnnually')
        : frequency === 'MONTHLY' || frequency === 'MONTHLY_ANNUAL_COMMITMENT'
        ? this.$t('purchase.price.info.billedMonthly')
        : frequency === 'LIFETIME'
        ? this.$t('purchase.price.info.billedOneTime')
        : '';
    },
    currentPrice() {
      if (this.activeMethod === 'stripe-elements' && Object.keys(this.totals).length > 0)
        return this.totals.total;
      return this.openPlan.originalCost;
    }
  },
  watch: {
    stripeError() {
      if (this.noError && this.stripeError) {
        this.noError = false;
      }
    },
    totals() {
      if (this.noError && this.totals && !this.totals.coupon) {
        this.noError = false;
      }
    }
  },
  methods: {
    needHelp() {
      try {
        window.$crisp.push(['do', 'chat:open']);
      } catch (error) {
        console.log(error);
      }
    },
    applyPromotionCode() {
      this.isInputFocused = false;
      this.$store.commit('payments/SET_LOADING_COUPON', true);
      this.$store.commit('payments/SET_CODE', this.couponCode);
      this.$store.commit('payments/SET_UPDATE_SUBSCRIPTION', true);
      this.couponCode = '';
    },
    removePromotionCode() {
      this.$store.commit('payments/SET_CODE', '');
      this.$store.commit('payments/SET_UPDATE_SUBSCRIPTION', true);
      this.applyPromotionCode();
    }
  }
};
</script>

<style lang="scss" scoped>
.pricing-info-container {
  padding: 15px 25px;

  @include lg {
    padding: 30px;
  }

  .title {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 13px;
  }

  .frequency {
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 15px;
    color: #515151;
  }

  .contact-text {
    font-size: 13px;
  }

  .divider {
    border-top: 2px solid #f1f1f1;
    padding: 10px 0;
  }

  .gray-text {
    color: #515151;
  }

  .terms-and-cond {
    font-size: 12px;
  }

  .price {
    font-size: 15px;
    text-align: right;
  }

  .total-due {
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .amount {
    font-weight: 500;
  }

  .currency {
    font-size: 12px;
  }

  .free-trial {
    font-size: 13px;
  }
}

.coupon-container {
  width: 100%;
  transition: all 0.15s ease;
}

.coupon-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  background: #f1f1f1;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 0px auto 16px auto;
}

.input-wrapper.is-focused {
  box-shadow: 0 0 0 3px #007bff40, 0 0 0 1px #007aff;
}

.input-wrapper.has-error {
  box-shadow: 0 0 0 2px #df1b41;
}

.input-field {
  display: flex;
  width: 100%;
  border: none;
  background: transparent;
  padding: 8px;
  font-size: 15px;
  color: #30313d;
  font-family: inherit;
  box-sizing: border-box;
}

.input-field::placeholder {
  color: #6c6d79;
}

.input-field:focus {
  outline: none;
}

.add-button {
  width: 100%;
  color: white;
  border: none;
  border-radius: 100px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: end;
}

.add-button:disabled {
  background: #3f80f166;
  cursor: not-allowed;
}

.is-desktop {
  display: block;
}

.is-mobile {
  display: none;
}

@include lg {
  .is-desktop {
    display: none;
  }

  .is-mobile {
    display: block;
  }
}

.coupon-section {
  margin: 0px auto 20px auto;
  font-size: 15px;
  font-weight: 400;
}

.coupon-badge {
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #1d1d1d33;
  border-radius: 5px;
}

.remove-coupon:hover {
  opacity: 0.7;
  text-decoration: none;
}

.remove-coupon:focus {
  outline: none;
}

.remove-coupon {
  font-size: 1em;
  line-height: 1em;
}

.coupon-error {
  font-size: 12px;
  margin-bottom: 16px;
  gap: 5px;
}
</style>
