<template>
  <div class="description" :class="{ 'show-all-description': showAllDescription }">
    <p>
      {{ actualVector.description }}
      <button
        v-show="showAllDescription"
        class="more-description rectangular x-small gray effect-color close-description"
        @click="$emit('show-all-description')"
      >
        <Icon :height="17" :width="17" color="white" icon="close" />
      </button>
    </p>
    <button
      v-show="!showAllDescription"
      class="more-description rectangular x-small gray effect-color"
      @click="$emit('show-all-description')"
    >
      ...
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  props: {
    showAllDescription: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('vectors', ['actualVector'])
  }
};
</script>

<style lang="scss" scoped>
.description {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex: 1;
  min-width: 25%;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 25px;
  position: relative;
  transition: width 0.5s, max-height 1.5s;
  @include sm {
    flex: auto;
  }
  p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    background: white;
    color: #b3b3b3;
    display: -webkit-box;
    font-size: 12px;
    margin: 0;
    overflow: hidden;
    position: relative;
  }
  &.show-all-description p {
    display: block;
    line-height: 20px;
    padding: 5px 0 7px;
  }
}
.more-description {
  align-items: center;
  background-color: #707070;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 10px;
  margin: 7px;
  overflow: visible;
  padding: 4px 10px;
  position: relative;
  text-align: center;
  transition: background-color 0.3s;
  width: auto;
  &.close-description {
    margin: 0 0 0 3px;
    padding: 3px;
    position: relative;
    top: 5px;
  }
}
.more-description:hover {
  background-color: #918d8d;
}
@media all and (max-width: 1500px) {
  .description {
    width: 40%;
  }
}
@media all and (max-width: 1000px) {
  .description {
    flex: 1;
    padding: 0px !important;
    width: auto;
  }
}
</style>
