import { handleError, newInstanceProps, setAuthorizationBearer } from '../utils/axiosConfig';

export default function ({ store, $axios }, inject) {
  const axiosV2 = $axios.create(newInstanceProps);

  axiosV2.onRequest((config) => {
    if (config.headers.common) {
      config.headers.common['Accept-Language'] = store.$i18n.locale;
      setAuthorizationBearer(process.server, config, store.$cookies);
    }
  });

  axiosV2.onError((error) =>
    handleError(error, store.$cookies, store.dispatch, process.env.COOKIES_DOMAIN)
  );
  axiosV2.setBaseURL(process.env.API_URL_V2);

  inject('axiosV2', axiosV2);
}
