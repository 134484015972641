<template>
  <a v-if="hasLink && isExternal" :href="link" class="tag" target="_blank">{{ text }}</a>
  <nuxt-link v-else-if="hasLink && !isExternal" :to="link" class="tag">{{ text }}</nuxt-link>
  <span v-else class="tag">{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    hasLink: {
      type: Boolean,
      required: false,
      default: false
    },
    isExternal: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 4px;
  padding: 1px;
  padding-right: 7px;
  padding-left: 7px;
  font-size: 12px;
  color: #828282;
  font-weight: 400;
  transition: 0.2s;
  margin-right: 2px;
  margin-left: 2px;
  background: #f6f6f6;
}
.tag:hover {
  color: white;
  background-color: #d0d0d0;
  text-decoration: none;
}
</style>
