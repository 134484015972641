<template>
  <div
    class="button d-flex justify-content-center align-items-center px-3 py-input my-2"
    @click="$emit('click')"
  >
    <img
      src="https://cdn.vexels.com/img/google-icon.svg"
      width="25"
      :alt="$t('profile.logIn.main.googleIconAlt')"
      srcset=""
      class="mr-1"
    />
    <span v-if="login" :class="purchaseMode ? 'purchase-mode' : ''"
      >{{
        purchaseMode ? $t('profile.logIn.main.continueWith') : $t('profile.logIn.main.logInWith')
      }}
      <span :class="purchaseMode ? '' : 'font-weight-bold'">Google</span></span
    >
    <span v-else :class="purchaseMode ? 'purchase-mode' : ''"
      >{{ $t('profile.signUp.main.signUpWith') }}
      <span :class="purchaseMode ? '' : 'font-weight-bold'">Google</span></span
    >
  </div>
</template>

<script>
export default {
  props: {
    login: {
      type: Boolean,
      required: false,
      default: false
    },
    purchaseMode: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  color: gray;
  cursor: pointer;
  border-radius: 30px;
  background-color: $lightGray;
  span {
    font-size: 12px;
    white-space: nowrap;
    &.purchase-mode {
      color: black !important;
      font-size: 14px !important;
      span {
        color: black !important;
        font-size: 14px !important;
      }
    }
  }
}
</style>
