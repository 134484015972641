export const mutations = {
  SET_WILDCARD(state, wildcard) {
    state.wildcard = wildcard;
  },
  SET_METADATA(state, metadata) {
    state.metadata = metadata;
  },
  SET_SEARCH_RESULTS(state, data = []) {
    let contents = data.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });

    const results = [];

    let makersRelatedsIndex = 0;
    for (let i = 0; i < contents.length; i++) {
      results.push(contents[i]);

      if ((i + 1) % 3 === 0 && makersRelatedsIndex < state.makersRelateds.length) {
        results.push(state.makersRelateds[makersRelatedsIndex]);
        makersRelatedsIndex++;
      }
    }

    results.splice(contents.length, 0, ...state.makersRelateds.slice(makersRelatedsIndex));

    state.searchResult = results;
  },
  SET_TOTAL_RESULTS(state, data = []) {
    state.total_results = data;
  },
  SET_TOTAL_RESULTS_BY_DIMENSION(state, data) {
    state.totalResultsDimensions = data;
  },
  SET_SEARCHING(state, status) {
    state.searching = status;
  },
  SET_TOTAL_PAGES(state, data = []) {
    state.totalPages = data;
  },
  SET_CURRENT_PAGE(state, data = []) {
    state.currentPage = data;
  },
  SET_SEARCH_TITLE(state, title = '') {
    state.searchTitle = title;
  },
  SET_SEARCH_DESCRIPTION(state, description = '') {
    state.searchDescription = description;
  },
  SET_RELATED_TAGS(state, tags = []) {
    tags = tags || [];
    state.relatedTags = tags;
  },
  SET_NICHES_BY_RELATIONS(state, data) {
    state.nichesByRelationsResult = data;
  },
  SET_MAKERS_RELATEDS(state, data = []) {
    data.map((el) => {
      el.image = el.image || '';
      el.link = this.$urlBuilder('maker', {
        id: el.id,
        slug: el.slug,
        maker: el.isMockup ? 'mockup' : 't-shirt'
      });
      el.isMaker = true;
      el.width = el.isMockup ? 300 : 300;
      el.height = el.isMockup ? 200 : 300;
    });
    state.makersRelateds = data;
  },
  TOGGLE_FILTERS_VISIBILITY(state) {
    state.showFilters = !state.showFilters;
  },
  SET_FILTERS_VISIBILITY(state, data) {
    state.showFilters = data;
  },
  SET_MULTIPLE_SEARCH_RESULTS(state, data = {}) {
    const { result, name } = data;
    const resultProcessed = result.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });

    state.multipleSearchResult = { ...state.multipleSearchResult, [name]: resultProcessed };
  },
  SET_SILENT_SEARCH(state, data) {
    state.silentSearch = data;
  },
  SET_QUERY(state, value) {
    state.query = value;
  },
  SET_TEMPLATES(state, data) {
    state.templates = data;
  }
};

export const getters = {
  nichesResult(state) {
    const data = state.nichesByRelationsResult;
    return data || [];
  },
  getSearchTitle(state) {
    return state.searchTitle;
  },
  getSearchDescription(state) {
    return state.searchDescription;
  },
  getSearchRelatedTags(state) {
    return state.relatedTags.filter((el) => el.hasLink == undefined || el.hasLink);
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
  getMultipleSearchResult: (state) => (name) => {
    return name && state.multipleSearchResult[name] ? state.multipleSearchResult[name] : [];
  },
  isSearching(state) {
    return state.searching;
  },
  isLastSearchPage(state) {
    return state.currentPage == state.totalPages;
  },
  getTotalResultsDimensions(state) {
    return state.totalResultsDimensions;
  },
  getImageFirstResult(state) {
    return state.searchResult.length ? state.searchResult[0].image : '';
  },
  getSilentSearch(state) {
    return state.silentSearch;
  },
  getQuery(state) {
    return state.query;
  },
  templates(state) {
    return state.templates;
  }
};

export const actions = {
  async MAKE_SEARCH({ commit, rootGetters, dispatch, state }, data) {
    let requestData = {};
    const searchAmount = this.$g('SEARCH_AMOUNT');
    const actualDimension = rootGetters['dimensions/getActualDimension'];
    commit('searchBar/CLEAR_BAR_OPEN', {}, { root: true });
    if (!data.sort) {
      commit('searchBar/SET_ACTUAL_SEARCH', data.criteria, { root: true });
      requestData.dimension = rootGetters['dimensions/getDimensionIdByPath'](actualDimension.path);
      requestData.criteria = data.criteria;
      const query =
        Object.keys(state.query).length != 0 ? state.query : this.$router.currentRoute.query;

      if (query?.sort === 'latest') {
        requestData.sort = query.sort;
      }
    } else {
      requestData.sort = data.sort;
    }
    if (!data.attributes || !data.excludedAttributes) {
      commit('searchBar/SET_ACTUAL_SEARCH', data.criteria || '', { root: true });
      requestData.dimension = rootGetters['dimensions/getDimensionIdByPath'](actualDimension.path);
      requestData.criteria = data.criteria;
      const query =
        Object.keys(state.query).length != 0 ? state.query : this.$router.currentRoute.query;

      requestData.attributes = query?.attributes ? query.attributes.split(',') : data.attributes;
      requestData.excludedAttributes = query?.excludedAttributes
        ? query.excludedAttributes.split(',')
        : data.excludedAttributes;
    } else {
      requestData.attributes = data.attributes;
      requestData.excludedAttributes = data.excludedAttributes;
    }
    if (requestData.excludedAttributes?.includes('ai')) {
      requestData.excludedAttributes.push('ai_generated');
    }

    let excludeTshirtMaker = false;
    if (requestData.excludedAttributes?.includes('tshirt_maker')) {
      requestData.excludedAttributes = requestData.excludedAttributes.filter(
        (el) => el !== 'tshirt_maker'
      );
      excludeTshirtMaker = true;
    }

    if (requestData.attributes !== undefined && requestData.attributes.length === 0) {
      delete requestData.attributes;
    }

    if (
      requestData.excludedAttributes !== undefined &&
      requestData.excludedAttributes.length === 0
    ) {
      delete requestData.excludedAttributes;
    }

    requestData.page = data.page || 1;
    requestData.perPage = data.perPage || searchAmount;
    requestData.url = 'search';
    const { experience } = data;
    const config = experience ? { headers: { Experience: experience } } : {};
    var response = null;
    var results = [];
    var totalResults = 0;
    var totalPages = 0;
    var currentPage = 0;
    var searchTitle = 'Browse this vexelent contents!';
    var searchDescription = 'Browse all of our vexelent contents.';
    var relatedTags = [];
    var nichesByRelationsResult = [];
    commit('SET_SEARCHING', true);
    try {
      response = await this.$axiosGeo.post('v1/search/', requestData, config);
      var responseData = response.data.data;
      if (!response.error) {
        const { wildcard, metadata, makersRelateds } = responseData;
        results = responseData.results || [];
        const otherDimensions = responseData.otherDimensions || [];
        results = Array.isArray(results) ? results : [results];
        totalResults = parseInt(responseData.total || 0);
        totalPages = parseInt(
          responseData ? Math.ceil(parseInt(responseData.total) / responseData.perPage) : 0
        );
        currentPage = responseData.page;
        searchTitle = responseData.metadata.h1[0];
        searchDescription = responseData.metadata.h2[0];
        relatedTags = responseData.relateds || [];
        nichesByRelationsResult = responseData.nichesRecomend;

        commit('SET_WILDCARD', wildcard);
        commit('SET_METADATA', metadata);
        commit('SET_MAKERS_RELATEDS', excludeTshirtMaker ? [] : makersRelateds);
        commit('SET_SEARCH_RESULTS', results);
        commit('SET_TOTAL_RESULTS_BY_DIMENSION', otherDimensions);
        commit('SET_TOTAL_RESULTS', totalResults);
        commit('SET_TOTAL_PAGES', totalPages);
        commit('SET_CURRENT_PAGE', currentPage);
        commit('SET_SEARCH_TITLE', searchTitle);
        commit('SET_SEARCH_DESCRIPTION', searchDescription);
        commit('SET_RELATED_TAGS', relatedTags);
        commit('SET_NICHES_BY_RELATIONS', nichesByRelationsResult);

        dispatch('vectors/ADD_LISTING', results, { root: true });
      } else {
        console.error('Search | MAKE_SEARCH 1:', responseData);
      }
    } catch (error) {
      console.error('Search | MAKE_SEARCH 2:', error);
    }
    commit('SET_SEARCHING', false);
  },
  async MAKE_SIMPLE_SEARCH({ commit, getters, rootGetters }, data) {
    let requestData = {};
    commit('searchBar/CLEAR_BAR_OPEN', {}, { root: true });
    if (data.enableSort) {
      requestData.sort = data.prismicSort;
      requestData.experience = data.experience;
    } else {
      requestData.dimension = rootGetters['dimensions/getDimensionIdByPath'](data.dimension);
      requestData.criteria = data.criteria;
    }
    const name = `${data.dimension}_${data.criteria}`;
    const multipleSearchResult = getters['getMultipleSearchResult'](name);
    if (!multipleSearchResult.length) {
      requestData.perPage = data.perPage || 20;
      requestData.page = 1;
      var response = null;
      var results = [];
      var dataResults = {};
      commit('SET_SEARCHING', true);
      try {
        response = await this.$axiosGeo.post('v1/search/', requestData);
        var responseData = response.data.data;
        if (!response.error) {
          results = responseData.results || [];
          dataResults.result = Array.isArray(results) ? results : [results];
          dataResults.name = name;

          commit('SET_MULTIPLE_SEARCH_RESULTS', dataResults);
        } else {
          console.error('Search | MAKE_SIMPLE_SEARCH 1:', responseData);
        }
      } catch (error) {
        console.error('Search | MAKE_SIMPLE_SEARCH 2:', error);
      }
    }

    commit('SET_SEARCHING', false);
  },
  async CLEAN_SEARCH({ commit }) {
    commit('searchBar/SET_ACTUAL_SEARCH', '', { root: true });
    commit('SET_WILDCARD', false);
    commit('SET_METADATA', null);
    commit('SET_MAKERS_RELATEDS', []);
    commit('SET_SEARCH_RESULTS', []);
    commit('SET_TOTAL_RESULTS_BY_DIMENSION', []);
    commit('SET_TOTAL_RESULTS', 0);
    commit('SET_TOTAL_PAGES', 0);
    commit('SET_CURRENT_PAGE', 0);
    commit('SET_SEARCH_TITLE', 'Browse this vexelent contents!');
    commit('SET_SEARCH_DESCRIPTION', 'Browse all of our vexelent contents.');
    commit('SET_RELATED_TAGS', []);
  },
  async LOAD_TEMPLATES({ commit }, { searchQuery, page, endpoint = 'tshirts/template' }) {
    let url = new URL(`https://makersapi.vexels.com/v1/${endpoint}`);
    let params = new URLSearchParams();
    params.append('page_size', 90);

    if (searchQuery) {
      params.append('search', searchQuery);
    }

    if (page) {
      params.append('page', page);
    }

    url.search = params.toString();
    try {
      const response = await this.$axios.get(url);
      const isMockup = endpoint.includes('mockup');

      const parsedResults = response.data.results.map((el) => {
        return {
          ...el,
          image: isMockup ? el.preview2 : el.preview,
          isSvg: isMockup ? el.preview2.includes('.svg') : el.preview.includes('.svg'),
          width: 300,
          height: isMockup ? 200 : 300,
          isMaker: true,
          link: this.$urlBuilder('maker', {
            id: el.id,
            slug: el.slug,
            maker: isMockup ? 'mockup' : 't-shirt'
          }),
          name: el.name || el.title,
          title: el.title || el.name,
          isMockup
        };
      });
      commit('SET_TEMPLATES', { ...response.data, results: parsedResults });
    } catch (error) {
      console.error('Search | LOAD_TEMPLATES:', error);
    }
  }
};

export const state = () => ({
  showFilters: false,
  searching: true,
  searchResult: [],
  query: {},
  multipleSearchResult: {},
  wildcard: false,
  metadata: null,
  nichesByRelationsResult: [],
  total_results: 0,
  totalResultsDimensions: [],
  totalPages: 0,
  currentPage: 0,
  searchTitle: 'Browse this vexelent contents!',
  searchDescription: 'Browse all of our vexelent contents.',
  relatedTags: [],
  silentSearch: false,
  makersRelateds: [],
  templates: {
    count: 0,
    next: '',
    page: 1,
    previous: '',
    results: []
  }
});
