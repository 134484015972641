<template>
  <div class="purchase-container" :class="{ 'disabled-container': loggedInState }">
    <Button :enabled="enablePaymentContainer" width="full" color="green" @click="subscribeClick">{{
      $t('purchase.price.info.buyNow')
    }}</Button>
    <div class="mt-3">
      <img
        src="https://cdn.vexels.com/img/icons/ssl-secured.svg"
        class="mr-1"
        :alt="$t('purchase.price.info.encryption')"
        height="40"
        width="100"
        loading="lazy"
      />
      <span class="encryption-text">{{ $t('purchase.price.info.encryption') }}</span>
    </div>
    <p class="contact-text d-md-none">
      {{ $t('purchase.price.info.contactUs') }}
      <a href="mailto:info@vexels.com">info@vexels.com</a>
      {{ $t('purchase.price.info.liveChat') }}
    </p>
    <div class="gray-text terms-and-cond d-md-none">
      <p>
        {{ $t('purchase.price.info.subscriptionRenew') }}
      </p>
      <p>
        <span class="gray-text">{{ $t('purchase.price.info.byPurchasing') }}</span>
        <a :href="$trailingSlash(localePath('terms-and-conditions'))">{{
          $t('purchase.price.info.termsAndConditions')
        }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'UI/Button';

export default {
  components: {
    Button
  },
  props: {
    loggedInState: {
      type: Boolean,
      required: true
    },
    activeMethod: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('payments', ['getBuyNowFunction', 'getLoadingPayment']),
    enablePaymentContainer() {
      if (this.activeMethod == 'paypal') {
        return true;
      }
      return !this.getLoadingPayment && !this.loggedInState;
    }
  },
  methods: {
    subscribeClick() {
      this.getBuyNowFunction();
    }
  }
};
</script>

<style lang="scss" scoped>
.purchase-container {
  margin-top: 20px;

  .disabled-container {
    opacity: 0.25;
  }
  .encryption-text {
    font-size: 12px;
  }
  .contact-text {
    margin: 15px 0 20px;
    font-size: 13px;
  }
  .gray-text {
    color: #b5b5b5;
  }
  .terms-and-cond {
    font-size: 12px;
  }
}
</style>
