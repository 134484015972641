<template>
  <div class="w-100 h-100 count-container p-5 text-center">
    <p>{{ $t('preview.freeDownload.waitDownload.waitDownloadTitle') }}</p>
    <p class="count">{{ count }}</p>
    <p class="if-not">
      {{ $t('preview.freeDownload.waitDownload.ifNot') }}
      <a
        ref="download-link"
        :href="urlDownload"
        class="cursor-pointer"
        :class="{ disabled: count > 0 }"
        download
        @click="$emit('download')"
      >
        {{ $t('preview.freeDownload.waitDownload.clickHere') }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    urlDownload: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      count: 10,
      interval: 0
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.count--;

      if (!this.count) {
        clearInterval(this.interval);
        this.$emit('download');
      }
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.count-container {
  border-radius: 8px;
  background-color: #f6f6f6;
  color: #000000;
  .count {
    font-size: 55px;
  }
  .if-not {
    font-size: 15px;
    a.disabled {
      pointer-events: none;
    }
  }
}
</style>
