<template>
  <div class="svg-icon-container">
    <span class="svg-icon" :class="[`icon-${icon}`, experience]" :style="style"></span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      required: false,
      default: 20
    },
    width: {
      type: Number,
      required: false,
      default: 20
    },
    color: {
      type: String,
      required: false,
      default: ''
    },
    exp: {
      type: String,
      required: false,
      default: ''
    },
    expActive: {
      type: Boolean,
      required: false,
      default: false
    },
    asOriginal: {
      type: Boolean,
      required: false,
      default: false
    },
    stroke: {
      type: Boolean,
      required: false,
      default: true
    },
    bold: {
      type: Boolean,
      required: false,
      default: false
    },
    opacity: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    style() {
      var style = `width: ${this.width}px; font-size: ${this.height}px;`;
      if (this.color != '') {
        style += `--custom-color: ${this.color}; color: ${this.color};`;
      }
      if (this.bold) {
        style += 'font-weight: bold';
      }
      return style;
    },
    experience() {
      var classes = `${this.color}`;
      if (this.opacity) {
        classes += 'opacity';
      }
      if (this.asOriginal) {
        return classes;
      }
      if (this.color) {
        classes += ' custom-color ';
      }
      if (this.stroke) {
        classes += ' custom-color-stroke ';
      }
      if (!this.stroke) {
        classes += ' no-stroke ';
      }
      if (this.expActive == true) {
        classes += `svg-${this.$expKey()}`;
      } else if (this.exp == 'merch') {
        classes += 'svg-merch-hover';
      } else if (this.exp == 'design') {
        classes += 'svg-design-hover';
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
.svg-icon-container {
  display: inline-block;
}
.svg-icon {
  display: flex;
  line-height: normal;

  &.opacity {
    opacity: 0.5;
    transition: all 0.1s linear;
    &:hover {
      transition: all 0.1s linear;
      opacity: 1;
    }
  }
  &.custom-color::before {
    color: var(--custom-color);
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

.svg-merch.black svg path {
  fill: #474747;
  stroke: #474747;
}

.svg-merch::before {
  color: $defaultColor;
}

.svg-merch-hover:hover {
  &::before {
    color: $defaultColor;
  }
}

.svg-design::before {
  color: $defaultColor;
}

.svg-design-hover:hover {
  &::before {
    color: $defaultColor;
  }
}

.no-stroke {
  svg path,
  svg polygon,
  svg rect,
  svg circle {
    stroke: none !important;
  }
}
.like-icon,
.like-icon.liked.hover {
  span::before {
    color: white !important;
  }
}
.like-icon.liked span::before {
  color: $defaultColor !important;
}
</style>
