<!-- eslint-disable max-len -->
<template>
  <div class="icon-wrapper">
    <Tooltip
      background-color="#2d2d2d"
      triangle-styles="border-color: #2d2d2d transparent;"
      tooltip-styles="background-color: rgba(45, 45, 45, 0.5);"
    >
      <template #element>
        <Icon :width="iconSize" :height="iconSize" icon="sparkle" color="#fff" />
      </template>
      <template #title>
        <p class="tooltip-title">{{ $t('main.preview.content.AiGenerated') }}</p>
      </template>
    </Tooltip>
  </div>
</template>
<script>
import Icon from 'UI/Icon';
import Tooltip from '@/components/Common/Tooltip';

export default {
  name: 'AIIcon',
  components: {
    Icon,
    Tooltip
  },
  data() {
    return {
      iconSize: 20
    };
  }
};
</script>
<style scoped>
.icon-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin: 7px;
  border-radius: 7px;
  background: linear-gradient(to bottom right, #e364e6, #8974ed, #5b7bef);
}

.tooltip-title {
  font-size: 14px !important;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
</style>
