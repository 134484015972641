<template>
  <div class="d-flex flex-column align-items-center justify-content-center" :class="status">
    <div class="check">
      <div class="out-circle">
        <div class="in-circle d-flex align-items-center justify-content-center">
          <Icon
            v-if="status == 'done'"
            color="white"
            icon="bootstrap-check"
            :width="23"
            :height="23"
          />
          <Icon
            v-if="status == 'actual'"
            color="#9B86F7"
            icon="bootstrap-dot"
            :width="100"
            :height="100"
          />
        </div>
      </div>
    </div>
    <div class="text"><slot></slot></div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  },
  props: {
    status: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
}
.text {
  font-size: 12px;
  color: #7a7a7a;
}
.check {
  .out-circle {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 20px;
  }
  .in-circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f0f0f0;
  }
}
</style>
