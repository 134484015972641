<template>
  <b-row :class="`${col ? 'flex-column' : ''}`">
    <b-col>
      <GoogleLogin :login="isLogin" :purchase-mode="purchaseMode" @click="googleLogin" />
    </b-col>
  </b-row>
</template>

<script>
import GoogleLogin from 'UI/Login/Google';
export default {
  components: {
    GoogleLogin
  },
  props: {
    col: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      deafult: true
    },
    purchaseMode: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: '308868272535767',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },
  methods: {
    googleLogin() {
      this.$emit('loginWithGoogle');
      this.$emit('login');
      this.$auth.loginWith('google');
    },
    facebookLogin() {
      this.$emit('loginWithFacebook');
      this.$emit('login');
      this.$auth.loginWith('facebook');
    }
  }
};
</script>
