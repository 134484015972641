<template>
  <Icon
    icon="bootstrap-chevron-right"
    class="change-vector-container"
    :class="[way === 'next' ? 'next' : 'prev invert']"
    :width="50"
    :height="37"
    @click.native="$emit('click')"
  />
</template>

<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  },
  props: {
    way: {
      type: String,
      required: false,
      default: 'next'
    }
  }
};
</script>

<style lang="scss" scoped>
.change-vector-container {
  position: fixed;
  top: 50%;
  z-index: 2000;
  cursor: pointer;
  color: #ffffffd2;
  font-size: 4em;
  &:hover {
    color: white;
  }
  @include md {
    display: none;
  }
}
.change-vector-container.next {
  right: 1%;
}
.change-vector-container.prev {
  left: calc(1% - 10px);
}
</style>
