<template>
  <div class="w-100 h-100 see-plans-container position-relative overflow-hidden">
    <b-row class="h-100 position-relative justify-content-end">
      <b-col sm="12" md="7" class="h-100 p-5 d-flex align-items-center justify-content-center">
        <div class="text-center d-flex align-items-center flex-column">
          <p>{{ $t('preview.freeDownload.waitDownload.avoidWaits') }}</p>
          <Button
            color="white"
            font-color="#00d784"
            :to="$urlBuilder('plans')"
            @click.native="$bvModal.hide('preview-vector-modal')"
          >
            {{ $t('preview.freeDownload.waitDownload.seePlans') }}
          </Button>
        </div>
      </b-col>
    </b-row>
    <img
      src="https://cdn.vexels.com/img/download-girl.png"
      :alt="$t('preview.freeDownload.waitDownload.downloadGirlAlt')"
      class="position-absolute absolute-img"
    />
  </div>
</template>

<script>
import Button from 'UI/Button';

export default {
  components: {
    Button
  }
};
</script>

<style lang="scss" scoped>
.see-plans-container {
  border-radius: 8px;
  background-image: url('https://cdn.vexels.com/img/download-gradient.png'),
    linear-gradient(#00d784, #00d784);
  .row {
    z-index: 2;
  }
  p {
    color: white;
    font-weight: 500;
    font-size: 29px;
    @include md {
      font-size: 22px;
    }
  }
  .absolute-img {
    top: -35%;
    height: 250%;
    left: -230px;
    @include xl {
      left: -270px;
    }
    @include lg {
      left: -150px;
    }
    @include md {
      left: -200px;
    }
    @include sm {
      display: none;
    }
  }
}
</style>
