<template>
  <div class="w-100">
    <div class="overlay" :style="overlayStyles">
      <div>
        <div class="overlay-bg" :style="overlayStyles"></div>
        <div class="tags-container">
          <IconTag
            :text="
              isMockup
                ? $t('main.vectors.makerContentOverlay.text')
                : $t('main.vectors.makerContentOverlay.text2')
            "
            src="https://cdn.vexels.com/img/icons/makers_icon.svg"
            alt="$t('main.vectors.makerContentOverlay.text3')"
            :width="28"
            :grid-tag="true"
            :background-color="isMockup ? '#3f80f1' : '#ff5e71'"
          />
        </div>

        <div class="title-container">
          <h3 class="text">{{ data.title }}</h3>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import IconTag from '@/components/IconTag';

export default {
  components: {
    IconTag
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hoveringDiv: false
    };
  },
  computed: {
    overlayStyles() {
      return `border-radius: ${this.data.borderRadius}px;`;
    },
    isMockup() {
      return this.data.isMockup;
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  overflow: hidden;
  z-index: 2;
  div {
    z-index: 100;
  }
  &:hover {
    .title-container,
    .tags-container {
      opacity: 1;
    }
    .overlay-bg {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
      height: 70%;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.tags-container {
  opacity: 0;
}

.title-container {
  opacity: 0;
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  color: white;
  font-weight: 500;
  transition: 0.4s ease;
  width: 74%;
  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media all and (max-width: 767px) {
  .title-container {
    font-size: 15px;
  }
}
@media all and (max-width: 550px) {
  .title-container {
    font-size: 13px;
  }
}
</style>
