<template>
  <div class="vexels-loading-container">
    <div class="wrapper" :style="sizing">
      <div class="outer-square" :style="`borderColor: ${color ? color : getExperienceColor}`">
        <div class="stick-wrapper" :style="bgColor">
          <div class="stick" :style="bgColor"></div>
        </div>
      </div>
      <div class="ball" :style="bgColor"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    size: {
      type: Number,
      default: 0.4
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('experience', ['getExperienceColor']),
    sizing() {
      return { transform: `scale(${this.size})` };
    },
    bgColor() {
      return { backgroundColor: `${this.color ? this.color : this.getExperienceColor}` };
    }
  }
};
</script>
<style scoped>
* {
  box-sizing: content-box;
}
.vexels-loading-container {
  width: 80px;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.wrapper {
  height: 200px;
  width: 200px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outer-square {
  height: 120px;
  width: 120px;
  border: 20px solid #2488ed;
  border-radius: 44px;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  top: 50%;
  left: 50%;
  animation-name: squareAnim;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.6, 0.01, 0.26, 1);
  animation-iteration-count: infinite;
}
.stick-wrapper {
  width: 20px;
  height: 80px;
  background-color: #2488ed;
  top: 0;
  left: -10px;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: top center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stick {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #2488ed;
}
.ball {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: #2488ed;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  animation-name: ballAnim;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
@keyframes squareAnim {
  0% {
    transform: translate(-50%, -50%) rotate(-245deg) scale(0);
  }
  20% {
    transform: translate(-50%, -50%) rotate(-240deg) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) rotate(45deg) scale(1);
  }
  62% {
    transform: translate(-50%, -50%) rotate(45deg) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-645deg) scale(0);
  }
}
@keyframes ballAnim {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.5);
  }
  35% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
