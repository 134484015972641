import { handleError, newInstanceProps, setAuthorizationBearer } from '../utils/axiosConfig';

export default function ({ store, $axios }, inject) {
  const axiosV3 = $axios.create(newInstanceProps);

  axiosV3.onRequest((config) => {
    if (config.headers.common) {
      config.headers.common['Accept-Language'] = store.$i18n.locale;
      setAuthorizationBearer(process.server, config, store.$cookies);
    }
  });

  axiosV3.onError((error) =>
    handleError(error, store.$cookies, store.dispatch, process.env.COOKIES_DOMAIN)
  );
  axiosV3.setBaseURL(process.env.API_URL_V3);

  inject('axiosV3', axiosV3);
}
