<template>
  <div v-if="list.length > 0" class="collections">
    <div v-for="(item, index) in list" :key="index" class="wrapper" @click="click(item)">
      <div class="item-button" :style="selectedStyles(index)">
        <span class="item-text text-center" :class="{ selected: item.selected }">{{
          item.name
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    click(item) {
      this.$dataLayerPush('search_bar_menu_click', {
        clickSearchBarMenu: {
          url: this.itemLink(item),
          item: 'Collections'
        }
      });
      const path = this.itemLink(item);
      this.$router.push({ path });
      this.$emit('open-close-bar', false);
    },
    itemLink(item) {
      return this.$urlBuilder('collection', { slug: item.slug });
    },
    selectedStyles(index) {
      return `background-image: url(${this.list[index].cover});`;
    }
  }
};
</script>

<style lang="scss" scoped>
.collections {
  display: flex;
  padding-bottom: 1rem;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 10px;
  @include xs {
    padding-bottom: 0.5rem;
    flex-wrap: wrap;
  }
}
.collections .wrapper {
  position: relative;
  width: 200px;
  margin-right: 10px;
  cursor: pointer;
  @include xs {
    width: auto;
    margin: 0;
    flex: 50%;
    margin-bottom: 5px;
    &:nth-child(2n) .item-button {
      margin-left: 5px;
    }
  }
}
.collections .item-link {
  text-decoration: none;
}
.collections .item-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  transition: background-color 0.3s;
  background-size: cover;
}
.collections .item-text {
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  transition: 0.3s;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collections .item-text:hover {
  background: rgba(0, 0, 0, 0.2);
}
.collections .item-text.selected {
  background: rgba(0, 0, 0, 0.2);
}
</style>
