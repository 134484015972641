export const mutations = {
  SET_PURCHASE_METHODS(state, data) {
    state.purchaseMethods = data;
  },
  SET_BLUESNAP_TOKEN(state, token) {
    state.bluesnapToken = token;
  },
  SET_BLUESNAP_ERROR(state, error) {
    state.bluesnapError = error;
  },
  SET_BLUESNAP_ERROR_SHOW_PAYPAL(state, status) {
    state.bluesnapErrorShowPaypalButton = status;
  },
  SET_STRIPE_ERROR(state, status) {
    state.stripeError = status;
  },
  SET_PAYPAL_ERROR(state, status) {
    state.paypalError = status;
  },
  SET_STRIPE_CLIENT_SECRET(state, secret) {
    state.stripeClientSecret = secret;
  },
  SET_STRIPE_MODE(state, mode) {
    state.stripeMode = mode;
  },
  SET_BUY_NOW_FUNCTION(state, func) {
    state.buyNowFunction = func;
  },
  SET_LOADING_PAYMENT(state, status) {
    state.loadingPayment = status;
  },
  SET_LOADING_COUPON(state, status) {
    state.loadingCoupon = status;
  },
  SET_FREE_TRIAL_USED(state, status) {
    state.freeTrialUsed = status;
  },
  SET_IS_FREE_TRIAL_PLAN(state, status) {
    state.isFreeTrialPlan = status;
  },
  SET_TOTALS(state, data) {
    state.totals = { ...state.totals, ...data };
  },
  SET_CODE(state, code) {
    state.code = code;
  },
  SET_UPDATE_SUBSCRIPTION(state, status) {
    state.updateSubscription = status;
  }
};

export const getters = {
  getPurchaseMethods(state) {
    return state.purchaseMethods;
  },
  getBuyNowFunction(state) {
    return state.buyNowFunction;
  },
  getLoadingPayment(state) {
    return state.loadingPayment;
  },
  getLoadingCoupon(state) {
    return state.loadingCoupon;
  },
  getFreeTrialUsed(state) {
    return state.freeTrialUsed;
  }
};

export const actions = {
  async GET_PURCHASE_METHODS({ commit }, { plan }) {
    try {
      commit('SET_PURCHASE_METHODS', { options: [], default: '' });
      const response = await this.$axiosGeo.get('v1/purchases/methods/', {
        params: { plan }
      });
      if (!response.data.error && !response.error) {
        const { data } = response.data;
        commit('SET_PURCHASE_METHODS', data);
      }
    } catch (error) {
      const data = {
        options: ['paypal', 'bluesnap'],
        default: 'paypal'
      };
      commit('SET_PURCHASE_METHODS', data);
      console.error('Payments | GET_PURCHASE_METHODS:', error);
    }
  },
  async GET_BLUESNAP_TOKEN({ commit }, data) {
    var hasError = false;
    try {
      const response = await this.$axios.post('purchases/bluesnap/validate/', data);
      const token = response.data.data.token || false;
      if (token) {
        commit('SET_BLUESNAP_TOKEN', token);
        commit('SET_BLUESNAP_ERROR_SHOW_PAYPAL', false);
        commit('SET_BLUESNAP_ERROR', false);
      } else {
        hasError = true;
      }
    } catch (error) {
      hasError = true;
    }
    if (hasError) {
      commit('SET_BLUESNAP_ERROR', 'purchase.bluesnap.errors.cannotCommunicate');
      commit('SET_BLUESNAP_ERROR_SHOW_PAYPAL', true);
    }
  },
  async PROCESS_BLUESNAP_PAYMENT({ commit }, data) {
    var result = false;
    commit('SET_LOADING_PAYMENT', true);
    try {
      const response = await this.$axios.post('purchases/bluesnap/payment/', data, {
        withCredentials: true
      });
      if (response.data.error) {
        commit('SET_BLUESNAP_ERROR', 'purchase.bluesnap.errors.paymentError');
        commit('SET_BLUESNAP_ERROR_SHOW_PAYPAL', true);
      } else {
        this.$auth.fetchUser(true);
        commit('SET_BLUESNAP_ERROR', false);
        commit('SET_BLUESNAP_ERROR_SHOW_PAYPAL', false);
        result = true;
      }
    } catch (error) {
      console.error('Payments | PROCESS_BLUESNAP_PAYMENT:', error);
    }
    commit('SET_LOADING_PAYMENT', false);
    return result;
  },
  async PROCESS_STRIPE_CHECKOUT_SESSION({ commit }, data) {
    try {
      const response = await this.$axios.post('/stripe/process-checkout-session/', data, {
        withCredentials: true
      });
      if (!response.data.error && !response.error) {
        this.$auth.fetchUser(true);
        commit('SET_STRIPE_ERROR', false);
      } else {
        commit('SET_STRIPE_ERROR', true);
      }
    } catch (error) {
      console.error('Payments | PROCESS_STRIPE_CHECKOUT_SESSION:', error);
      commit('SET_STRIPE_ERROR', true);
    }
  },
  async PROCESS_PAYPAL_PAYMENT({ commit }, data) {
    try {
      const response = await this.$axios.post('purchases/payment/', data, {
        withCredentials: true
      });
      if (!response.data.error && !response.error) {
        this.$auth.fetchUser(true);
        commit('SET_PAYPAL_ERROR', false);
      } else {
        commit('SET_PAYPAL_ERROR', true);
      }
    } catch (error) {
      console.error('Payments | PROCESS_PAYPAL_PAYMENT:', error);
      commit('SET_PAYPAL_ERROR', true);
    }
  },
  async CREATE_STRIPE_CHECKOUT_PAYMENT_INTENT({ commit, state }, data) {
    commit('SET_STRIPE_CLIENT_SECRET', '');

    try {
      const {
        data: { data: response }
      } = await this.$axiosGeo.post(
        'v1/stripe/create-checkout-client-secret/',
        { ...data, code: state.code },
        {
          withCredentials: true
        }
      );
      const clientSecret = response.clientSecret || false;
      const mode = response.mode || false;
      commit('SET_FREE_TRIAL_USED', response.hadFreeTrial);
      commit('SET_IS_FREE_TRIAL_PLAN', response.freeTrial);

      commit('SET_TOTALS', response.discounts);
      commit('SET_STRIPE_ERROR', false);

      if (clientSecret) {
        commit('SET_STRIPE_CLIENT_SECRET', clientSecret);
        commit('SET_STRIPE_MODE', mode);
      } else {
        commit('SET_STRIPE_CLIENT_SECRET', false);
        commit('SET_STRIPE_MODE', false);
      }
    } catch (error) {
      commit('SET_STRIPE_CLIENT_SECRET', false);
      commit('SET_STRIPE_MODE', false);
    } finally {
      commit('SET_UPDATE_SUBSCRIPTION', false);
      commit('SET_LOADING_COUPON', false);
    }
  }
};

export const state = () => ({
  purchaseMethods: {},
  bluesnapToken: false,
  bluesnapError: false,
  bluesnapErrorShowPaypalButton: false,
  stripeError: false,
  paypalError: false,
  stripeClientSecret: '',
  stripeMode: false,
  buyNowFunction: {},
  loadingPayment: false,
  code: '',
  loadingCoupon: false,
  freeTrialUsed: false,
  price: 0,
  isFreeTrialPlan: false,
  totals: {},
  updateSubscription: false
});
