export const mutations = {
  setStep(state, step) {
    state.step = step;
  },
  setTotalSteps(state, totalSteps) {
    state.totalSteps = totalSteps;
  },
  setOnCancel(state, onCancel) {
    state.onCancel = onCancel;
  },
  setOnNext(state, onNext) {
    state.onNext = onNext;
  },
  setFormData(state, formData) {
    state.formData = formData;
  }
};

export const actions = {
  async submitForm({ state, dispatch }) {
    try {
      const payload = {
        cancelReasons: this.$g('CANCELLATION_REASONS')
          .map((reason) => Number(reason.value === state.formData.reason))
          .join(','),
        otherReasonText: state.formData?.reasonDescription || '',
        cancelFeedback: `${state.formData.satisfactionScale}, ${
          state.formData?.satisfactionDescription || ''
        }`,
        technicalReason: 'USER_CANCELLED'
      };

      const response = await dispatch('user/SEND_CANCELLATION', payload, { root: true });
      if (response.error && response?.message) {
        throw new Error(`main.profile.cancellation.${response.message}`);
      }
    } catch (error) {
      if (error.message) {
        throw error;
      }
      throw new Error('Something went wrong');
    }
  }
};

export const getters = {
  getState(state) {
    return state;
  }
};

export const state = () => ({
  step: 1,
  totalSteps: 4,
  onCancel: () => null,
  onNext: () => null,
  formData: {}
});
