<template>
  <b-container>
    <b-col>
      <div :class="{ loading }" class="overlay justify-content-center align-items-center">
        <Loading />
      </div>
      <Toggle
        :force-toggle="forceToggle"
        :is-login="isLogin"
        @choose-login="setMode('login')"
        @choose-signup="setMode('signup')"
      />
      <Title v-if="!forceToggle && !isLoginPage" :title="title" />
      <b-row v-if="!forceToggle && isLoginPage" class="d-none d-md-block title mt-4">
        <b-col>
          <h1>{{ title }}</h1>
        </b-col>
      </b-row>
      <div class="mt-4">
        <Socials :is-login="isLogin" @login="login" />
      </div>
      <Separator />
      <LoginForm v-if="isLogin" @loading="(status) => (loading = status)" @login="login" />
      <SignUpForm v-else @loading="(status) => (loading = status)" @signup="signup" />
      <BottomMessage
        v-if="!forceToggle"
        :is-login="isLogin"
        @choose-signup="setMode('signup')"
        @choose-login="setMode('login')"
      />
    </b-col>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import Loading from 'UI/Loading';
import Socials from '@/components/User/Form/Socials';
import Separator from '@/components/User/Form/Separator';
import Toggle from '@/components/User/Form/Toggle';
import BottomMessage from '@/components/User/Form/BottomMessage';
const LoginForm = () => import('@/components/User/Form/LoginForm');
const SignUpForm = () => import('@/components/User/Form/SignUpForm');
import Title from '@/components/User/Form/Title';
export default {
  components: {
    LoginForm,
    SignUpForm,
    Loading,
    Socials,
    Separator,
    Toggle,
    BottomMessage,
    Title
  },
  props: {
    forceToggle: {
      type: Boolean,
      required: false,
      default: false
    },
    afterLogin: {
      type: String,
      required: false,
      default: ''
    },
    isLoginPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('user', ['modalMode']),
    isLogin() {
      return this.isLoginPage ? true : this.modalMode == 'login';
    },
    title() {
      return this.isLogin
        ? this.$t('profile.logIn.main.title')
        : this.$t('profile.signUp.main.title');
    }
  },
  mounted() {
    this.$auth.init();
  },
  methods: {
    setMode(mode) {
      this.$store.dispatch('user/CHANGE_MODAL_MODE', mode);
      this.isLoginPage && this.$emit('show-signup');
    },
    login() {
      this.setAfterLoginCookie();
      this.$emit('login');
    },
    signup() {
      this.setAfterLoginCookie();
      this.$emit('signup');
    },
    setAfterLoginCookie() {
      if (this.afterLogin && this.afterLogin != '') {
        this.$cookies.set('afterLoginRedirect', this.afterLogin);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;
  z-index: 10;
}
.overlay.loading {
  display: flex;
}
h1 {
  color: gray;
  font-size: 1.5em;
  font-weight: 500;
}
</style>
