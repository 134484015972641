export const mutations = {
  SET_MERCH_DIGEST(state, data) {
    state.merchDigests = data;
  }
};

export const actions = {
  async LOAD_MERCH_DIGEST({ commit }) {
    try {
      const response = await this.$axiosUtils.get('merch-digest/');
      if (response.data) {
        var data = response.data.data;
        data = data.sort((a, b) => b.id - a.id);
        commit('SET_MERCH_DIGEST', data);
      }
    } catch (error) {
      console.error('Tools | LOAD_MERCH_DIGEST:', error);
    }
  }
};

export const state = () => ({
  merchDigests: []
});
