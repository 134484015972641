<template>
  <div class="pretty-select">
    <button type="button" class="flex dimensions-button" @click="toggleOptions">
      {{ getActualDimensionName }}
      <i class="ph-bold ph-caret-down" :style="{ fontSize: '9px' }"></i>
    </button>
    <ul v-if="showOptions" v-click-outside="closeOptions">
      <li
        v-for="(item, index) in options"
        :key="index"
        :class="{
          selected: getExperience && getActualDimensionName == item.name
        }"
        @click="makeSearch(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      showOptions: false
    };
  },
  computed: {
    ...mapGetters('experience', ['getExperience']),
    ...mapGetters('dimensions', [
      'getActualDimensionName',
      'getFilterDimensionsByExperience',
      'getDimensionByName'
    ]),
    ...mapGetters('searchBar', ['getActualSearch']),
    ...mapGetters('search', ['getQuery']),
    options() {
      return this.getFilterDimensionsByExperience('merch');
    },
    query() {
      return { ...this.$route.query, ...this.getQuery };
    }
  },
  methods: {
    async makeSearch({ name: dimension, experience }) {
      this.$dataLayerPush('search_bar_menu_click', {
        clickSearchBarMenu: {
          url: this.$route.fullPath,
          item: 'Filter'
        }
      });
      this.closeOptions();
      if (dimension != this.getActualDimensionName) {
        this.$store.dispatch('dimensions/ACTUAL_DIMENSION', dimension);
      }
      this.$router.push({
        path: this.$urlBuilder('search', {
          path: this.getDimensionByName(dimension, experience).path,
          keyword: this.getActualSearch
        }),
        query: this.query
      });
    },
    closeOptions() {
      this.showOptions = false;
    },
    toggleOptions() {
      this.showOptions = !this.showOptions;
    }
  }
};
</script>

<style lang="scss" scoped>
.pretty-select {
  display: inline-block;
  height: 20px !important;
  margin: 0px 5px 0px 20px;
  border-right: 1px solid #dfdedc;
  padding-right: 15px;
  min-width: max-content;
  width: auto;
  position: relative;
}
.dimensions-button {
  min-width: max-content;
  min-width: -moz-max-content;
  white-space: nowrap;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  color: #757575;
}
.dimensions-button .svg-icon-container {
  font-size: 8px;
  margin-left: 7px;
  margin-bottom: 2px;
}
.pretty-select > ul {
  height: auto;
  margin: 0;
  margin-top: 18px;
  padding: 0px;
  background-color: #ffffff;
  min-width: 110px;
  width: max-content;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 2;
  text-align: left;
  left: -5px;
  border-radius: 15px;
}
.pretty-select > ul.show {
  display: block;
}
.pretty-select > ul > li {
  width: 100%;
  padding: 8px 15px;
  z-index: 2;
  list-style: none;
  min-width: max-content;
  font-size: 13px;
  color: gray;
  cursor: pointer;
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 12px;
  }
}
.pretty-select > ul > li::first-letter,
.pretty-select > .dimensions-button::first-letter {
  text-transform: uppercase;
}
.pretty-select > ul > li:hover,
ul li.selected {
  background-color: $lightGray;
  &:first-child {
    border-radius: 15px 15px 0 0;
  }
  &:last-child {
    border-radius: 0 0 15px 15px;
  }
}
.dimensions-button:focus {
  outline: none;
}
.pretty-select > ul > li.init {
  cursor: pointer;
}
</style>
