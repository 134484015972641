export default function ({ $cookies, app }, inject) {
  app.router.beforeResolve((to, from, next) => {
    try {
      let url = to.fullPath;
      sendUserActivity(url);
      next();
    } catch (error) {
      next();
    }
  });

  function sendUserActivity(url) {
    try {
      let vxId = $cookies.get('vx.id');

      if (!vxId) {
        const domain = process.env.COOKIES_DOMAIN || '.vexels.live';
        const path = '/';
        vxId = Math.random().toString(36).substring(2, 12);
        $cookies.set('vx.id', vxId, { maxAge: 2147483647, domain, path });
      }

      let userId = app.$auth.user ? app.$auth.user.idUser : '';

      const data = {
        url,
        vxId,
        userId
      };

      const apiUrl = process.env.DATA_URL || 'https://api.vexels.live';
      app.$axios.post(`${apiUrl}/frontend/activity`, data);
    } catch (error) {
      console.error('Plugin User Activity:', error);
    }
  }

  inject('sendUserActivity', (url) => sendUserActivity(url));
}
