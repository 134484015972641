<template>
  <b-row>
    <b-col>
      <div class="payment-tabs-container">
        <PaymentMethodTab
          v-for="(option, index) in options"
          :key="index"
          :payment-method="option"
          :active="isActive(option)"
          @click="
            () => {
              $dataLayerPush('purchase_flow_payment_method_change', {
                paymentMethod: option
              });
              active = option;
            }
          "
        >
          <template #content-payment>
            <div v-show="isActive(option)">
              <PayPal v-if="option == paymentMethods[0]" v-bind="optionProps(option)" />
              <Stripe v-if="option == paymentMethods[1]" v-bind="optionProps(option)" />
              <CreditCard
                v-if="option == paymentMethods[2]"
                v-bind="optionProps(option)"
                @show-paypal="active = 'paypal'"
                @payment="payment"
              />
              <StripeElements v-if="option == paymentMethods[3]" v-bind="optionProps(option)" />
            </div>
          </template>
        </PaymentMethodTab>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PaymentMethodTab from '@/components/PurchaseFlow/PaymentMethodTab';
import PayPal from '@/components/PurchaseFlow/Methods/PayPal';
import CreditCard from '@/components/PurchaseFlow/Methods/CreditCard';
import Stripe from '@/components/PurchaseFlow/Methods/Stripe';
import StripeElements from '@/components/PurchaseFlow/Methods/StripeElements';
export default {
  components: {
    PaymentMethodTab,
    PayPal,
    CreditCard,
    Stripe,
    StripeElements
  },
  data() {
    return {
      active: '',
      paymentMethods: ['paypal', 'stripe', 'bluesnap', 'stripe-elements'],
      options: [],
      default: ''
    };
  },
  computed: {
    ...mapState('plans', ['openPlan']),
    ...mapGetters('payments', ['getPurchaseMethods']),
    paymentName() {
      return this.active == this.paymentMethods[0]
        ? 'PayPal'
        : this.active == this.paymentMethods[1]
        ? 'Stripe'
        : this.active == this.paymentMethods[2]
        ? 'BlueSnap'
        : this.active == this.paymentMethods[3]
        ? 'Stripe elements'
        : 'Other';
    }
  },
  watch: {
    active() {
      this.$emit('methodChange', this.active);
    }
  },
  async mounted() {
    this.$dataLayerPush('purchase_flow_payment_start');
    let { options, default: defaultMethod } = this.$route.query;
    options = options?.split(',').filter((option) => this.paymentMethods.includes(option));

    if (options?.length) {
      this.options = options;
      this.default = this.options.includes(defaultMethod) ? defaultMethod : this.options[0];
    } else {
      await this.$store.dispatch('payments/GET_PURCHASE_METHODS', { plan: this.openPlan.id });
      this.options = this.getPurchaseMethods.options;
      this.default = this.options.includes(this.getPurchaseMethods.default)
        ? this.getPurchaseMethods.default
        : this.options[0];
    }

    if (this.openPlan?.hasFreeTrial) {
      this.options = this.options.filter(
        (option) => option == 'stripe-elements' || option == 'stripe'
      );
      this.default = this.options[0];
    }

    this.active = this.default;
    this.fireGTMPaymentInfo(this.openPlan);
  },
  methods: {
    payment() {
      this.$emit('payment');
    },
    ShowPaymentMethod(pyamentMethod) {
      return this.options?.includes(pyamentMethod);
    },
    fireGTMPaymentInfo(data) {
      if (!data) return;
      this.$dataLayerPush('add_payment_info', {
        addPaymentInfo: data
      });
    },
    isActive(option) {
      return this.active === option;
    },
    isDefault(option) {
      return this.default === option;
    },
    optionProps(option) {
      return {
        active: this.isActive(option),
        default: this.isDefault(option)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 22px;
  font-weight: 500;
  color: #777777;
}
.payment-tabs-container {
  position: relative;
  flex-direction: column;
}
</style>
