<template>
  <div
    class="container-tooltip position-absolute"
    :class="
      position == 'top' ? 'container-tooltip-position-top' : 'container-tooltip-position-bottom'
    "
  >
    <div class="d-flex flex-column align-items-center">
      <p class="tooltip-title">{{ title }}</p>
      <p class="tooltip-text">
        {{ text }}
      </p>
      <Button color="green" @click="showModalCancelFreeTrial">{{ buttonText }}</Button>
    </div>
  </div>
</template>

<script>
import Button from 'UI/Button';

export default {
  name: 'DownloadTooltip',
  components: {
    Button
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  methods: {
    showModalCancelFreeTrial() {
      this.$store.commit('user/SET_SHOW_MESSAGE_MODAL', {
        title: this.$t('main.profile.fullAccessFreeTrialMessage.text'),
        buttonText: this.$t('main.profile.fullAccessFreeTrialMessage.button'),
        buttonAction: this.sendCancellationFreeTrial,
        type: 'successful'
      });
      this.$bvModal.show('message-modal');
    },
    async sendCancellationFreeTrial() {
      this.loading = true;
      this.error = '';
      try {
        let response = await this.$store.dispatch('user/SEND_CANCELLATION_FREE_TRIAL');
        if (response.error) {
          this.error = response.message;
          this.loading = false;
        } else {
          this.$router.go();
        }
      } catch (error) {
        this.error = 'There was an error trying to submit form. Please try again later';
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-tooltip {
  max-width: 367px;
  z-index: 100;
  padding: 25px;
  width: 100%;
  text-align: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px #00000029;
  border-radius: 15px;

  &-position-top {
    bottom: 75px;
  }

  &-position-bottom {
    top: 75px;
  }

  .tooltip-title {
    font-size: 16px;
    font-weight: 400;
    color: #242424;
  }

  .tooltip-text {
    font-size: 14px;
    color: #afafaf;
  }
}
</style>
