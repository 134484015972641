<template>
  <div class="w-100">
    <SvgGridOverlay v-if="data.isSvg" :data="data" />
    <MakerContentOverlay v-else-if="data.isMaker" :data="data" />
    <CommonOverlay v-else :data="data" />
  </div>
</template>

<script>
import CommonOverlay from '@/components/Vectors/CommonOverlay';
import SvgGridOverlay from '@/components/Vectors/SvgGridOverlay';
import MakerContentOverlay from 'UI/Content/MakerContentOverlay';

export default {
  components: {
    CommonOverlay,
    SvgGridOverlay,
    MakerContentOverlay
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
