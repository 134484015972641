<template>
  <b-modal id="contact-modal" centered hide-footer size="lg" @hide="resetData">
    <template #modal-header>
      <CloseButton modal-name="contact-modal" />
    </template>
    <div class="m-auto w-75">
      <h5 class="text-center my-5">{{ $t('contact.form.main.howCanWeHelp') }}</h5>
      <b-form @submit.prevent="onSubmit">
        <b-form-row>
          <b-form-group
            id="input-group-name"
            class="col-lg-6"
            :label="$t('contact.form.main.name')"
            label-for="input-name"
          >
            <b-form-input id="input-name" v-model.trim="name" required></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-email"
            :label="$t('contact.form.main.email')"
            label-for="input-email"
            class="col-lg-6"
          >
            <b-form-input id="input-email" v-model="email" type="email" required></b-form-input>
          </b-form-group>
        </b-form-row>

        <b-form-group
          id="input-group-subject"
          :label="$t('contact.form.main.subject')"
          label-for="input-subject"
          class="mb-4"
        >
          <div class="select-options-container">
            <div class="select-options-contact" @click="toggle">
              <p class="select-default d-flex align-items-center">
                {{ subject
                }}<span class="ml-auto pr-3 d-flex">
                  <Icon
                    icon="bootstrap-chevron-down"
                    color="#3b74d4"
                    :width="16"
                    :height="16"
                    :bold="true"
                    :style="displayOptions && 'transform: rotate(180deg)'"
                  />
                </span>
              </p>
              <ul v-show="displayOptions">
                <li
                  v-for="(item, index) in optionsSubject"
                  :key="index"
                  @click="selectOption(index)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-textarea"
          :label="$t('contact.form.main.message')"
          label-for="input-textarea"
        >
          <b-form-textarea
            id="input-textarea"
            v-model.trim="message"
            class="textarea"
            rows="8"
          ></b-form-textarea>
        </b-form-group>
        <recaptcha class="row justify-content-center" />
        <SubmitButton v-bind="buttonProps" />
        <p class="text-center mt-4 mb-5">{{ $t('contact.form.main.callUs') }}</p>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import Icon from 'UI/Icon';
import CloseButton from 'UI/CloseButton';
import SubmitButton from 'UI/SubmitButton';

export default {
  components: { CloseButton, SubmitButton, Icon },
  data() {
    return {
      displayOptions: false,
      subject: '',
      message: '',
      email: '',
      name: '',
      error: '',
      loading: false,
      success: false,
      successText: this.$t('contact.form.main.sentSuccessfully')
    };
  },
  computed: {
    optionsSubject() {
      return this.$g('SUBJECTS_CONTACT').map((option) => {
        return this.$t(option);
      });
    },
    buttonProps() {
      return {
        success: this.success,
        error: this.error,
        loading: this.loading,
        submitText: this.$t('contact.form.main.send'),
        successText: this.successText
      };
    }
  },
  methods: {
    resetData() {
      this.success = false;
      this.subject = '';
      this.message = '';
      this.email = '';
      this.name = '';
      this.error = '';
    },
    toggle() {
      this.displayOptions = !this.displayOptions;
    },
    selectOption(index) {
      this.subject = this.optionsSubject[index];
    },
    hideModal() {
      this.$bvModal.hide('contact-modal');
      this.resetData();
    },
    async onSubmit() {
      this.loading = true;
      const defaultErrorText = this.$t('contact.form.main.errorSubmiting');
      try {
        const captchaToken = await this.$recaptcha.getResponse();
        let data = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message,
          captchaToken
        };
        const response = await this.$axios.post('/contact/', data);
        if (response.data.error) {
          this.error = defaultErrorText;
        } else {
          this.success = true;
          this.error = '';
          setTimeout(this.hideModal, 1500);
        }
        this.loading = false;
        try {
          await this.$recaptcha.reset();
        } catch (error) {
          console.error('CONTACT', error);
        }
      } catch (error) {
        this.error = defaultErrorText;
        this.loading = false;
        console.error('CONTACT', error);
      }
    }
  }
};
</script>

<style lang="scss">
#input-group-subject {
  position: relative;
  height: 60px;
}
.select-options-container {
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  .select-default {
    color: #848484;
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 15px;
    cursor: pointer;
    margin: 0;
  }
}
.select-options-contact {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #2c6fd5;
  border-left: 1px solid #2c6fd5;
  border-right: 1px solid #2c6fd5;
  color: #848484;
  border-radius: 20px;
  box-shadow: 0px 2px 57px #00000030;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li,
    .select-default {
      &:hover {
        background-color: #0000002d;
      }
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 15px;
      cursor: pointer;
    }
  }
}
#contact-modal {
  color: #ffffff;
  .modal-header {
    border: none;
  }
}
#contact-modal___BV_modal_content_ {
  background-image: url('https://cdn.vexels.com/img/bg-contact.png');
  background-size: cover;
  background-color: #2c6fd5;
}
#contact-modal h5 {
  font-size: 41px;
  font-weight: 500;
}
#contact-modal .form-group label {
  font-size: 12px;
  font-weight: normal;
}

#contact-modal form {
  input,
  select,
  textarea {
    outline: none;
    border: none;
    border-radius: 20px;
    color: #848484;
  }
  .submit-button {
    background-color: #51d5ad;
  }
  .text-response {
    color: #fff;
  }
  .textarea {
    height: 100px !important;
  }
}
</style>
