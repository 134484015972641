<template>
  <div class="container-download-png">
    <button
      :disabled="disabledDownload"
      class="btn-file"
      @click="$emit('authorize-download', { size: pngDefaultSize, type: 'png' })"
    >
      <div v-if="!loading.png">
        <span>{{ $t('preview.download.main.png') }}</span>
        <Icon icon="arrow-download" class="ml-1" :width="18" :height="18" />
      </div>
      <b-spinner v-else small variant="light" class="mx-4"></b-spinner>
    </button>

    <button :disabled="disabledDownload" class="btn-size" @click="$emit('toggle-more-sizes')">
      <span>{{ pngDefaultSize }} {{ $t('preview.download.main.px') }}</span>
      <Icon
        icon="arrow"
        :class="showMoreSizes ? 'rotate-270' : 'rotate-90'"
        class="ml-1"
        :width="11"
        :height="11"
      />
    </button>

    <div
      v-show="showMoreSizes"
      class="container-sizes-options b-radius"
      :class="{ disabled: disabledDownload }"
    >
      <div class="container-sizes">
        <button
          v-for="(resolution, idx) in pngResolutions"
          :key="idx"
          :disabled="disabledDownload"
          :class="{ 'text-center': disabledDownload && loading[`png${resolution}`] }"
          @click="
            $emit('authorize-download', {
              size: resolution,
              type: `png${resolution}`
            })
          "
        >
          <span v-if="!loading[`png${resolution}`]"
            >{{ resolution }} {{ $t('preview.download.main.px') }}</span
          >
          <b-spinner v-else small variant="light" class="ml-2 p-0"></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  props: {
    disabledDownload: {
      type: Boolean,
      required: true
    },
    showMoreSizes: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pngDefaultSize: 4500,
      pngResolutions: [512, 1920, 4500]
    };
  }
};
</script>

<style lang="scss" scoped>
button {
  background-color: #3cd699;
  border: none;
  outline: none;
  color: #fff;
  &:disabled {
    background-color: #d2d2d2 !important;
    border-color: #d2d2d2 !important;
    cursor: not-allowed !important;
  }
  &:disabled:hover {
    filter: none !important;
  }
}

.btn-file {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
  font-size: 18px;
  padding: 12px 35px 12px 50px;
  margin-left: 10px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include md {
    margin-left: 0;
    margin-bottom: 7px;
    flex: 1;
    max-height: 45px;
  }
}

.btn-size {
  width: 95px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #34bf88;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
  font-size: 13px;
  padding: 8px;
  padding-right: 10px;
  flex: none;
  .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .rotate-270 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  @include md {
    height: 45px;
  }
}
.container-download-png {
  position: relative;
  display: flex;
  z-index: 5;
}
.container-sizes-options {
  position: absolute;
  right: 0;
  display: flex;
  z-index: 1;
  border-radius: 6px;
  flex-direction: column;
  height: auto;
  top: 52px;
  width: 107px;
  overflow: hidden;
  border: 1px solid #e6e6e6;
  &.b-radius {
    border-radius: 23px;
  }
  &.disabled {
    background-color: #d2d2d2;
  }
  .btn-size {
    border-radius: 0;
    @include lg {
      width: 100%;
    }
  }
}
.container-sizes {
  display: flex;
  align-items: center;
  color: white;
  flex: none;
  font-size: 14px;
  flex-direction: column;
  span,
  label,
  p {
    padding: 0 5px;
  }
  button {
    color: #212529;
    background: #fff;
    padding: 0 15px;
    width: 100%;
    height: 40px;
    text-align: right;
    &:disabled {
      color: #fff;
    }
    &:first-of-type {
      padding-top: 10px;
      padding-bottom: 5px;
      height: 45px;
    }
    &:last-child {
      padding-top: 5px;
      padding-bottom: 10px;
      height: 45px;
    }
    &:hover {
      background: $backgroundGray;
    }
  }
}

input[type='text'] {
  border-radius: 4px;
  width: 60px;
  padding: 5px;
}
input {
  border: none;
  outline: none;
  height: 30px;
  width: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
