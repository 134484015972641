import Vue from 'vue';
import VueLazyload from 'vue-lazyload';

const PLACEHOLDER =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

Vue.use(VueLazyload, {
  preload: 2,
  error: PLACEHOLDER,
  loading: PLACEHOLDER,
  observer: true,
  observerOptions: {
    rootMargin: '350px',
    threshold: 0.1
  },
  filter: {
    webp(listener, options) {
      if (!options.supportWebp) return;
      const isCDN = /cdn.vexels.com|images.vexels.live|images.vexels.com/;
      const src = listener.src;
      if (isCDN.test(src) && !listener.el.hasAttribute('no-webp') && !src.includes('fmt=webp')) {
        if (src.includes('?')) {
          listener.src += '&fmt=webp';
        } else {
          listener.src += '?fmt=webp';
        }
      }
    }
  }
});
