export const mutations = {
  SET_ACTUAL_CONTENT(state, data) {
    const { uid = '' } = data;
    state.actualContent = uid;
  },
  SET_CUSTOM_HTML(state, data) {
    let { data: slices } = data;

    if (slices) {
      slices.forEach((slice) => {
        const position = slice.primary.position;
        const dimension = slice.primary.dimension;
        if (position === 'top') {
          state.topContent[dimension] = state.topContent[dimension]
            ? state.topContent[dimension]
            : [];
          state.topContent[dimension] = [...state.topContent[dimension], ...[slice]];
        } else {
          state.bottomContent[dimension] = state.bottomContent[dimension]
            ? state.bottomContent[dimension]
            : [];
          state.bottomContent[dimension] = [...state.bottomContent[dimension], ...[slice]];
        }
      });
    } else {
      state.topContent = {};
      state.bottomContent = {};
    }
  }
};

export const getters = {
  actualContent: (state) => {
    return state.actualContent;
  },
  existContent: (state) => (content) => {
    return Object.entries(state[content]).length;
  },
  topContent: (state) => (dimension) => {
    return state.topContent[dimension] ? state.topContent[dimension] : [];
  },
  bottomContent: (state) => (dimension) => {
    return state.bottomContent[dimension] ? state.bottomContent[dimension] : [];
  }
};

export const actions = {
  async LOAD_CUSTOM_HTML({ commit, getters }, data) {
    const { uid } = data;

    if (getters.actualContent != uid) {
      try {
        commit('SET_CUSTOM_HTML', {});
        const languages = this.$prismic.api.data.languages;
        const language = languages.find((item) => item.id.indexOf(this.$i18n.locale) != -1);
        const response = await this.$prismic.api.getByUID('custom_html', uid, {
          lang: language.id
        });
        if (response && !response.error) {
          commit('SET_CUSTOM_HTML', { data: response.data.body });
          commit('SET_ACTUAL_CONTENT', { uid });
        } else {
          console.error('Custom HTML | LOAD_CUSTOM_HTML 1:', response);
        }
      } catch (error) {
        console.error('Custom HTML | LOAD_CUSTOM_HTML 2:', error);
      }
    }
  }
};

export const state = () => ({
  actualContent: '',
  bottomContent: {},
  topContent: {}
});
