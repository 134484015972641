<template>
  <div class="bottom-searchbar" :class="{ fixed: isFixed }">
    <FiltersToggle v-if="showFilters" />
    <SearchBar :show-wrapper-border="false" />
  </div>
</template>

<script>
import FiltersToggle from '@/components/SearchResults/FiltersToggle';
import SearchBar from '@/components/SearchBar';
export default {
  components: {
    FiltersToggle,
    SearchBar
  },
  props: {
    showFilters: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      scrolled: 0
    };
  },
  computed: {
    isFixed() {
      return this.scrolled > 60;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
  }
};
</script>

<style lang="scss">
.bottom-searchbar {
  padding: 8px 16px;
  @include sm {
    padding: 8px 14px;
  }
  .search-tags {
    z-index: 10;
    display: none;
    @include lg {
      display: flex;
    }
    @include sm {
      display: none;
    }
  }
}
.bottom-searchbar.fixed {
  background-color: $white;
  position: fixed;
  top: 0;
  z-index: 300;
  left: 0;
  right: 0;
  height: 70px;
}
</style>
