export const mutations = {
  SET_COLLECTIONS(state, data) {
    state.collections = data;
  },
  SET_COLLECTION(state, data) {
    state.collection = data;
  },
  SET_COLLECTION_CONTENT(state, value = []) {
    state.collectionContent = value.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });
  },
  SET_LOADING_COLLECTION(state, value) {
    state.loadingCollection = value;
  },
  SET_COLLECTION_CURRENT_PAGE(state, value) {
    state.collectionCurrentPage = value;
  },
  SET_COLLECTION_TOTAL_PAGES(state, data) {
    state.collectionTotalPages = data;
  },
  SET_COLLECTIONS_CURRENT_PAGE(state, value) {
    state.collectionsCurrentPage = value;
  },
  SET_COLLECTIONS_TOTAL_PAGES(state, data) {
    state.collectionsTotalPages = data;
  },
  SET_COLLECTION_LISTING(state, { name, data }) {
    if (name && data.length) {
      data.map((el) => {
        el.title = el.name;
        el.image = el.image ? el.image : el.cover;
        el.link = this.$urlBuilder('collection', {
          slug: el.slug
        });
      });
      state.collectionListings = { ...state.collectionListings, [name]: data };
    }
  },
  SET_LATEST_COLLECTIONS(state, { collections, exclude }) {
    if (collections.length) {
      collections.map((el) => {
        if (el.slug !== exclude) {
          el.title = el.name;
          el.image = el.image ? el.image : el.cover;
          el.link = this.$urlBuilder('collection', {
            slug: el.slug
          });
        }
      });
      state.latestCollections = collections;
    }
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  }
};

export const getters = {
  listExist: (state) => (name) => {
    return name && state.collectionListings[name]?.length;
  },
  getCollectionList: (state) => (name) => {
    return name ? state.collectionListings[name] : [];
  },
  getImageFirstResult(state) {
    return state.collectionContent.length ? state.collectionContent[0].image : '';
  },
  getCollectionName(state) {
    return state.collectionName;
  }
};

export const actions = {
  async LOAD_COLLECTIONS({ commit, state }, data = {}) {
    try {
      commit('SET_LOADING_COLLECTION', true);
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : 12;

      if (
        data.page > state.collectionsCurrentPage &&
        state.collectionsCurrentPage < state.collectionsTotalPages
      ) {
        const response = await this.$axios.post('/collection/', data);
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const collections = response.data.data.packs ? response.data.data.packs : [];
        commit('SET_COLLECTIONS', [...state.collections, ...collections]);
        commit('SET_COLLECTIONS_CURRENT_PAGE', data.page);
        commit('SET_COLLECTIONS_TOTAL_PAGES', totalPages);
      }
    } catch (error) {
      console.error('Collections | LOAD_COLLECTIONS:', error);
    } finally {
      commit('SET_LOADING_COLLECTION', false);
    }
  },
  async LOAD_COLLECTION({ commit }, { slug }) {
    try {
      const response = await this.$axios.get(`/collection/${slug}/`);
      const data = response.data.data ? response.data.data : {};
      commit('SET_COLLECTION', data);
    } catch (error) {
      console.error('Collections | LOAD_COLLECTION:', error);
    }
  },
  async LOAD_COLLECTION_CONTENT({ commit, dispatch }, data) {
    try {
      commit('SET_LOADING', true);
      const searchAmount = this.$g('SEARCH_AMOUNT');
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : searchAmount;
      const response = await this.$axios.post(`/collection/${data.slug}/contents/`, data);
      if (!response.data.error) {
        const vectors = response.data.data.vectors ? response.data.data.vectors : null;
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const currentPage = data.page;
        commit('SET_COLLECTION_CONTENT', vectors);
        commit('SET_COLLECTION_CURRENT_PAGE', currentPage);
        commit('SET_COLLECTION_TOTAL_PAGES', totalPages);
        dispatch('vectors/ADD_LISTING', vectors, { root: true });
        commit('SET_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOADING', false);
      console.error('Collections | LOAD_COLLECTION_CONTENT:', error);
    }
  },
  async LOAD_COLLECTION_LISTING({ commit, getters }, { name, ids }) {
    if (name && !getters.listExist(name)) {
      try {
        const { data } = await this.$axios.post('/collection/custom-list/', { ids });
        if (!data.error) {
          commit('SET_COLLECTION_LISTING', { name, data: data.data?.packs });
        } else {
          console.error('Collections | LOAD_COLLECTION_LISTING 1:', data.error);
        }
      } catch (error) {
        console.error('Collections | LOAD_COLLECTION_LISTING 2:', error);
      }
    }
  },
  CLEAN_COLLECTIONS({ commit }) {
    commit('SET_COLLECTION_CONTENT', []);
    commit('SET_COLLECTION_CURRENT_PAGE', -1);
    commit('SET_COLLECTION_TOTAL_PAGES', 0);
  },
  async LOAD_LATEST_COLLECTIONS({ commit }, { exclude }) {
    try {
      const response = await this.$axios.post('/collection/latest');
      const collections = response.data.data ? response.data.data : [];
      commit('SET_LATEST_COLLECTIONS', { collections, exclude });
    } catch (error) {
      console.error('Collections | LOAD_LATEST_COLLECTIONS:', error);
    }
  }
};

export const state = () => ({
  collectionName: 'collection',
  collections: [],
  collection: {},
  collectionContent: [],
  loadingCollection: false,
  collectionTotalPages: 0,
  collectionCurrentPage: -1,
  collectionsTotalPages: 0,
  collectionsCurrentPage: -1,
  // LISTING
  collectionListings: {},
  isLoading: false,
  latestCollections: []
});
