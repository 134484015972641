<template>
  <div class="exp-bg change-page-container" :class="way" @click="$emit('click')">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    way: {
      type: String,
      required: false,
      default: 'next'
    }
  },
  computed: {
    text() {
      return this.way == 'next' ? 'next page' : 'prev page';
    }
  }
};
</script>

<style lang="scss" scoped>
.change-page-container {
  position: fixed;
  top: 50%;
  padding: 10px;
  z-index: 2000;
  cursor: pointer;
  color: white;
  @include md {
    display: none;
  }
}
.change-page-container.next {
  right: 0;
  border-radius: 10px 0px 0px 10px;
}
.change-page-container.prev {
  left: 0;
  border-radius: 0px 10px 10px 0px;
}
</style>
