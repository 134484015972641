<template>
  <div v-if="data.slug && data.name" class="mt-3">
    {{ $t('preview.relateds.groupers.moreFrom') }}
    <nuxt-link
      class="exp-font"
      :to="$urlBuilder('style', { slug: data.slug })"
      @click.native="$bvModal.hide('preview-vector-modal')"
    >
      {{ data.name }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
