<template>
  <div class="main-options" :class="!hideMenuSeasonal ? 'option-gradient' : ''">
    <div class="navbar-select-row">
      <NavOptions />
    </div>
    <div class="right-side-container">
      <UIButton
        :with-gradient="true"
        gradient="#5388F2"
        height="28"
        size="14"
        shape="full"
        :use-nuxt-link="false"
        to="/merch-plans/top-menu/"
        @click="clickPricingButton"
      >
        {{ $t('main.header.tags.pricing') }}
      </UIButton>
      <span class="menu-separator" />
      <UserSection class="user-section" />
    </div>
  </div>
</template>

<script>
import UIButton from 'UI/Button';
import UserSection from './UserSection';
import NavOptions from './NavOptions';

export default {
  components: {
    UIButton,
    UserSection,
    NavOptions
  },
  props: {
    hideMenuSeasonal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickPricingButton() {
      this.gaEvent(this.$urlBuilder('plans-profile-redirect', { keyword: 'top-menu' }));
    },
    gaEvent(url) {
      this.$dataLayerPush('top_menu_click', {
        menuClick: { clickUrl: url, clickItem: 'Pricing' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-options {
  display: flex;
  align-items: center;
  padding: 8px;
  min-height: 40px;
  justify-content: flex-end;
}
.navbar-select-row {
  position: absolute;
  width: 100%;
  min-height: 36px;
  @include lg {
    display: none;
  }
  @include xl {
    padding-left: 20px;
  }
}
.menu-separator {
  border-radius: 2px;
  margin-left: 16px;
  margin-right: 16px;
  height: 24px;
  width: 1px;
  background: $white;
}
.right-side-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  @include lg {
    width: 100%;
  }
}
</style>

<style lang="scss">
.option-gradient {
  background-color: transparent !important;
}
.seasonal-text {
  color: $white;
  @include xl2 {
    font-size: 18px !important;
  }
  @include lg {
    font-size: 13px !important;
  }
  @include md {
    display: none !important;
  }
}
</style>
