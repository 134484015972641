<template>
  <div class="icons-preview">
    <Tooltip v-for="(icon, i) in icons" :key="i">
      <template #element>
        <Icon :width="25" :height="25" :icon="icon.icon" />
      </template>
      <template #title>
        <p class="tooltip-title">{{ iconTitle(icon) }}</p>
        <p class="tooltip-description">{{ iconDescription(icon) }}</p>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import Tooltip from '@/components/Common/Tooltip';
export default {
  components: {
    Icon,
    Tooltip
  },
  props: {
    icons: {
      type: Array,
      required: true
    }
  },
  methods: {
    iconTitle(icon) {
      return this.$t(`preview.icons.title.${icon.icon}`);
    },
    iconDescription(icon) {
      return this.$t(`preview.icons.description.${icon.attribute}-${icon.icon}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.icons-preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  margin-right: 15px;
  gap: 0.25rem;
  border: 1px solid #e2e2e2;
  border-width: 0px 1px 0px 0px;
  @include md {
    border: 0px;
    height: unset;
  }
  .tooltip-title {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 6px;
  }
  .tooltip-description {
    letter-spacing: 0.3px;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
