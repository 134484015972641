<template>
  <span @click="handleClose"> <Icon icon="close" :height="40" color="white" /></span>
</template>
<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  },
  props: {
    modalName: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClose() {
      this.$bvModal.hide(this.modalName);
    }
  }
};
</script>
<style lang="css" scoped>
span {
  cursor: pointer;
  transform: translate(-2px, -20px);
}
</style>
