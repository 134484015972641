<template>
  <div class="vector-details-row">
    <div class="image-link detail-block">
      <Icon class="mr-1" :width="25" :height="25" icon="hd-images" />
      <a
        :href="vectorImage"
        rel="noopener noreferrer"
        target="_blank"
        :style="{ textDecoration: 'none', color: '#C9C9C9' }"
      >
        {{ $t('preview.attributes.tagsTitle.imageLink') }}
      </a>
    </div>
    <div class="date detail-block">
      <Icon :width="25" :height="25" icon="calendar" />
      {{ dateAdded }}
    </div>
    <div v-if="fileTypeDisguise" class="file-type detail-block">
      <Icon :width="25" :height="25" :icon="fileTypeDisguise" />
      {{ fileType }}
    </div>
    <div v-show="editorialAttribuition" class="licence-type detail-block">
      <Tooltip>
        <template #element>
          <Icon color="red" :width="25" :height="25" icon="license" />
        </template>
        <template #title>
          <p class="tooltip-description">
            {{ $t('preview.attributes.tagsDescription.editorial') }}
          </p>
        </template>
      </Tooltip>
      {{ $t('preview.attributes.tagsTitle.editorial') }}
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import Tooltip from '@/components/Common/Tooltip';
export default {
  components: {
    Icon,
    Tooltip
  },
  props: {
    vectorImage: {
      type: String,
      required: true
    },
    dateAdded: {
      type: String,
      required: true
    },
    fileType: {
      type: String,
      required: true
    },
    licence: {
      type: String,
      required: true
    }
  },
  computed: {
    editorialAttribuition() {
      return this.licence === 'editorial';
    },
    fileTypeDisguise() {
      return this.fileType === 'AI' || this.fileType === 'PSD'
        ? `${this.fileType.toLowerCase()}-file`
        : this.fileType === 'Transparent PNG/SVG'
        ? 'transparent-png'
        : null;
    }
  }
};
</script>

<style lang="scss" scoped>
.vector-details-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #c9c9c9;
  font-size: 14px;
  .detail-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #b3b3b3;
  }
  .licence-type {
    color: red;
  }
  .tooltip-title {
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 3px;
  }
  .tooltip-description {
    letter-spacing: 0.2px;
    font-weight: 400;
  }
  @include md {
    margin: 5px 0;
  }
}
</style>
