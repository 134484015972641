<template>
  <b-modal :id="idModal" :static="true" hide-footer centered>
    <template #modal-header>
      <ButtonClose :modal-name="idModal" />
    </template>
    <Menu class="d-block d-xl-none p-4" />
  </b-modal>
</template>
<script>
import Menu from 'Components/Header/MenuMobile/Modal/Menu.vue';
import ButtonClose from 'Components/Header/MenuMobile/Modal/ButtonClose.vue';

export default {
  name: 'HeaderApp',
  components: {
    Menu,
    ButtonClose
  },
  props: {
    idModal: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  @include xl {
    #menu-mobile-modal___BV_modal_header_ {
      border: none;
      position: absolute;
      right: -6px;
      top: -40px;
      @media screen and (max-height: 640px) {
        right: 0px;
        top: 0px;
      }
    }
    #menu-mobile-modal___BV_modal_content_ {
      border: none;
    }
  }
}
</style>
