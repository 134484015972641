<template>
  <div class="w-100">
    <NotFound v-if="error.statusCode === 404" />
  </div>
</template>

<script>
export default {
  components: {
    NotFound: () => import('@/components/Error/404')
  },
  layout: 'error',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  mounted() {
    this.$auth.fetchUser(false, this.fetchUserCallback);
  },
  methods: {
    fetchUserCallback(status) {
      if (status) return;
      const googleProvider = this.$auth.getProvider('google');
      googleProvider.initOneTap(this.handleOneTapCallback);
    },
    async handleOneTapCallback({ credential: id_token }) {
      await this.$store.dispatch('auth/googleLogin', { id_token });
    }
  }
};
</script>
