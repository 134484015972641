export default ({ store }, inject) => {
  inject('expColor', expColor(store));
  inject('expKey', expKey(store));
};

const expColor = (store) => () => {
  const expColor = store.getters['experience/getExperienceColor'];
  return expColor;
};

const expKey = (store) => () => {
  const expKey = store.getters['experience/getExperience'];
  return expKey;
};
