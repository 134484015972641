<template>
  <div
    v-click-outside="
      () => {
        $emit('toggleCreateFolderActive', false);
        editMode = false;
        isOpen = false;
      }
    "
    :style="{ position: 'relative' }"
  >
    <div :style="{ width: '200px' }">
      <div
        ref="dropdownButton"
        @click="
          () => {
            if (!isAuthenticated) {
              $bvModal.show('user-modal');
            } else {
              toggleDropdown();
            }
          }
        "
      >
        <slot name="trigger">
          <div class="dropdown-button cursor-pointer">
            {{ selected.text }}
            <Icon icon="bootstrap-caret-down-fill" :width="9" :height="9" />
          </div>
        </slot>
      </div>

      <div v-show="isOpen" ref="dropdownMenu" class="dropdown-container">
        <div class="dropdown-row">
          <div class="dropdown-title">
            <span>{{ $t('profile.main.favorites.chooseFolder') }}</span>
          </div>
          <div :class="{ 'scrollable-container': scrollable }">
            <div
              v-for="(option, index) in options"
              :key="index"
              :style="{ width: '200px' }"
              class="cursor-pointer dropdown-option"
              :class="{
                selected: option.value === selected
              }"
              @click.prevent="setSelected(option, option.value === selected)"
            >
              <span class="d-flex justify-content-between align-items-center">{{
                option.text
              }}</span>
            </div>
          </div>

          <div>
            <div
              class="new-button cursor-pointer"
              :style="editMode && { background: '#fff' }"
              @click="toggleEditMode"
            >
              <Icon
                icon="close"
                :width="15"
                :height="15"
                class="plus-svg"
                color="grey"
                :bold="true"
              />
              <input
                v-if="editMode"
                ref="input"
                v-model="newFolderName"
                class="new-input"
                @focus="() => $emit('toggleCreateFolderActive', true)"
                @click.stop
                @change="handleFolderInputChange($event.target.value)"
                @keyup.enter="handleCreateFolder($event)"
                @click="(e) => e.preventDefault()"
              />
              <button
                v-if="editMode"
                class="plain cursor-pointer"
                @click="handleCreateFolder($event)"
              >
                <Icon icon="tick-v1" :width="15" :height="15" :color="tickColor" :bold="true" />
              </button>
              <span v-else>{{ addNewText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
import { mapState, mapGetters } from 'vuex';
import { createPopper } from '@popperjs/core';

export default {
  components: {
    Icon
  },
  props: {
    idVector: {
      type: Number,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    createFoldersActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      editMode: false,
      newFolderName: '',
      folder: undefined,
      popperInstance: null,
      showFolders: false
    };
  },
  computed: {
    ...mapState('user', ['folders', 'favorites']),
    ...mapGetters('user', ['getFolderValue']),
    allFavorites() {
      return { text: this.$t('profile.main.favorites.allFavorites'), value: null };
    },
    options() {
      const parsedFolders = this.folders
        .map((folder) => ({
          text: folder.name,
          value: folder.id
        }))
        .sort((a, b) => b.value - a.value);
      return [this.allFavorites, ...parsedFolders];
    },
    addNewText() {
      return this.$t('profile.main.favorites.createNewFolder');
    },
    isAuthenticated() {
      return !!this.$auth.user;
    },
    scrollable() {
      return this.folders.length > 3;
    },
    isLiked() {
      return this.$store.getters['user/isVectorLiked'](parseInt(this.idVector));
    },
    isLikedDefaultFolder() {
      return this.isLiked && this.folder === null;
    },
    selected() {
      return this.folder ? this.folder : this.isLikedDefaultFolder ? null : undefined;
    },
    tickColor() {
      return this.newFolderName.length > 1 ? '#0FBA7C' : 'grey';
    }
  },
  watch: {
    createFoldersActive(value) {
      if (value) this.isOpen = true;
      else this.isOpen = this.hover;
    }
  },
  methods: {
    setSelected(folder, isCurrentSelected) {
      this.$emit('toggleCreateFolderActive', false);
      if (isCurrentSelected) {
        this.$store.dispatch('user/SET_LIKE_VECTOR', {
          idVector: this.idVector,
          isLiked: true
        });
        this.folder = undefined;
      } else {
        this.$store.dispatch('user/SET_LIKE_VECTOR', {
          idVector: this.idVector,
          isLiked: false,
          folder: folder.value
        });
        this.folder = folder.value;
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    handleFolderInputChange(value) {
      this.newFolderName = value;
    },
    async handleCreateFolder(e) {
      e.preventDefault();
      if (!this.newFolderName) return;
      this.$emit('toggleCreateFolderActive', false);
      await this.$store.dispatch('user/CREATE_FOLDER', {
        name: this.newFolderName
      });

      let idFolder = this.folders.filter((folder) => folder.name === this.newFolderName)[0].id;
      if (idFolder) {
        await this.$store.dispatch('user/SET_LIKE_VECTOR', {
          idVector: this.idVector,
          isLiked: false,
          folder: idFolder
        });
        this.folder = idFolder;
      }
      this.editMode = false;
      this.newFolderName = '';
      e.target.blur();
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.folder = this.getFolderValue(parseInt(this.idVector));
        this.createPopperInstance();
      } else {
        this.$emit('toggleHover', false);
        this.destroyPopperInstance();
      }
    },
    destroyPopperInstance() {
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
    createPopperInstance() {
      this.popperInstance = createPopper(this.$refs.dropdownButton, this.$refs.dropdownMenu, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start']
            }
          },
          {
            name: 'offset',
            options: {
              offset: [-80, 18]
            }
          },
          { name: 'eventListeners', enabled: false },
          {
            name: 'preventOverflow',
            options: {
              padding: 15
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  background-color: #fff !important;
  border-color: #c9c9c9 !important;
  border-radius: 20px !important;
  height: 38px !important;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  color: grey;
  border: 1px solid #ccc;

  .svg-icon-container {
    position: absolute;
    right: 27px;
    bottom: 14px;
  }
}

.dropdown-container {
  padding-top: 5px;
  padding-bottom: 5px;
  .dropdown-row {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    height: auto;
    padding: 0px;
    text-align: left;
  }

  .dropdown-title {
    padding: 8px 15px;
    color: grey;
    font-weight: 600;
    border-radius: 15px 15px 0 0;
  }

  .dropdown-option {
    padding: 8px 15px;
    color: grey;
    font-weight: 400;
    &.selected {
      span::after {
        width: 14px;
        height: 14px;
        background: url('https://cdn.vexels.com/img/folders/check.svg') no-repeat center;
        background-size: contain;
        content: '';
      }
    }
    &:hover {
      background: #ececec;
      &.selected {
        background: #ececec;
        span::after {
          background: url('https://cdn.vexels.com/img/folders/cross.svg') no-repeat center;
        }
      }
    }
  }
}

.last-option-rounded {
  border-radius: 0 0 15px 15px;
}

.first-option-rounded {
  border-radius: 15px 15px 0 0;
}

.scrollable-container {
  overflow-y: scroll;
  max-height: 140px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.new-input {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.plain {
  background: none;
  border: none;
  box-sizing: border;
  padding: 0;
  margin: 0;
}

.new-button {
  border-radius: 0 0 15px 15px;
  border: none;
  width: 100%;
  border-top: 1px solid #ccc;
  background: #fff;
  padding: 8px 15px;
  color: grey;
  display: flex;
  justify-content: start;
  gap: 4px;
  align-items: center;
  position: sticky;
  bottom: 0;
  &:hover {
    background-color: #ececec;
  }
  max-width: 200px;
}

.plus-svg {
  transform: rotate(45deg);
  margin-right: 5px;
  color: grey;
  border: 1px solid grey;
  border-radius: 50%;
}

.dropdown-container[v-show='true'] {
  display: block;
}
</style>
