export const mutations = {
  SET_CATEGORIES_RESULT(state, res) {
    let { data } = res;
    data.map((el) => {
      el.url = this.$urlBuilder('categories', { id: el.id, slug: el.slug });
    });
    state.categoriesResult = data;
  },
  SET_METADATA(state, metadata = null) {
    state.metadata = metadata;
  },
  SET_TITLE_DESCRIPTION(state, { title, description }) {
    state.title = title;
    state.description = description;
  },
  SET_CATEGORIES_MAIN_RESULT(state, res) {
    let { data } = res;
    if (data.length) {
      data.map((el) => {
        el.url = this.$urlBuilder('categories', { id: el.id, slug: el.slug });
      });
    }
    state.mainCategoriesResult = data;
  },
  SET_CATEGORY_NICHES(state, res) {
    let { data, index } = res;
    if (data.length) {
      data.map((el) => {
        el.url = this.$urlBuilder('niches', { slug: el.slug });
      });
    }
    state.mainCategoriesResult[index - 1].niches = data;
  },
  SET_CATEGORY(state, data) {
    state.category = data;
  },
  SET_CATEGORY_CONTENT(state, value = []) {
    state.categoryContent = value.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });
  },
  SET_CATEGORY_CURRENT_PAGE(state, value) {
    state.categoryCurrentPage = value;
  },
  SET_CATEGORY_TOTAL_PAGES(state, data) {
    state.categoryTotalPages = data;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  }
};

export const actions = {
  async LOAD_CATEGORIES({ commit, state }) {
    if (!state.categoriesResult.length) {
      try {
        const response = await this.$axios.get('category/');
        if (response.data) {
          commit('SET_CATEGORIES_RESULT', {
            data: response.data.data
          });
        }
      } catch (error) {
        console.error('Categories | LOAD_CATEGORIES:', error);
      }
    }
  },
  async LOAD_MAIN_CATEGORIES({ commit, state, rootGetters }) {
    if (!state.mainCategoriesResult.length) {
      try {
        const merch = rootGetters['experience/getMerch'];
        const config = { headers: { Experience: merch } };
        const response = await this.$axios.get('listings/categories_list/', config);
        if (response.data) {
          commit('SET_CATEGORIES_MAIN_RESULT', {
            data: response.data.data
          });
        }
      } catch (error) {
        console.error('Categories | LOAD_MAIN_CATEGORIES:', error);
      }
    }
  },
  async LOAD_CATEGORY_NICHES({ commit, state, rootGetters }, { index }) {
    const categoryList = state.mainCategoriesResult[index - 1];
    const categoryListNiches = categoryList && categoryList.niches;
    if (index && !categoryListNiches) {
      try {
        const merch = rootGetters['experience/getMerch'];
        const config = { headers: { Experience: merch } };
        const response = await this.$axios.get(`listings/categories_niches_${index}/`, config);
        if (response.data) {
          commit('SET_CATEGORY_NICHES', {
            data: response.data.data,
            index
          });
        }
      } catch (error) {
        console.error('Categories | LOAD_CATEGORY_NICHES:', error);
      }
    }
  },
  async LOAD_CATEGORY({ commit, rootGetters }, { id }) {
    try {
      const merch = rootGetters['experience/getMerch'];
      const config = { headers: { Experience: merch } };
      const response = await this.$axios.get(`/category/${id}/`, config);
      const data = response.data.data ? response.data.data : null;
      commit('SET_CATEGORY', data);
    } catch (error) {
      console.error('Categories | LOAD_CATEGORY:', error);
    }
  },
  async LOAD_CATEGORY_CONTENT({ commit, dispatch }, data) {
    try {
      commit('SET_LOADING', true);
      const searchAmount = this.$g('SEARCH_AMOUNT');
      data.page = data.page ? data.page : 1;
      data.perPage = data.perPage ? data.perPage : searchAmount;
      const response = await this.$axios.post(`/category/${data.id}/contents/`, data);
      if (!response.data.error) {
        const vectors = response.data.data.vectors ? response.data.data.vectors : null;
        const metadata = response.data.data.metadata ? response.data.data.metadata : null;
        const title = response.data.data.title;
        const description = response.data.data.description;
        const totalPages = parseInt(
          response ? Math.ceil(parseInt(response.data.data.total) / data.perPage) : 0
        );
        const currentPage = data.page;
        commit('SET_CATEGORY_CONTENT', vectors);
        commit('SET_METADATA', metadata);
        commit('SET_TITLE_DESCRIPTION', { title, description });
        commit('SET_CATEGORY_CURRENT_PAGE', currentPage);
        commit('SET_CATEGORY_TOTAL_PAGES', totalPages);
        dispatch('vectors/ADD_LISTING', vectors, { root: true });
        commit('SET_LOADING', false);
      }
    } catch (error) {
      commit('SET_LOADING', false);
      console.error('Categories | LOAD_CATEGORY_CONTENT:', error);
    }
  },
  CLEAN_CATEGORIES({ commit }) {
    commit('SET_CATEGORY_CONTENT', []);
    commit('SET_METADATA', null);
    commit('SET_CATEGORY_CURRENT_PAGE', 0);
    commit('SET_CATEGORY_TOTAL_PAGES', 0);
  }
};

export const getters = {
  getMetadata(state) {
    return state.metadata;
  },
  getTitle(state) {
    return state.title;
  },
  getDescription(state) {
    return state.description;
  },
  getImageFirstResult(state) {
    return state.categoryContent.length ? state.categoryContent[0].image : '';
  }
};

export const state = () => ({
  categoriesResult: [],
  mainCategoriesResult: [],
  metadata: null,
  title: '',
  description: '',
  categories: [],
  category: null,
  categoryContent: [],
  categoryTotalPages: 0,
  categoryCurrentPage: 0,
  isLoading: false
});
