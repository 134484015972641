import VueMeta from 'vue-meta';

const defaultLocale = 'en';
const COMPONENT_OPTIONS_KEY = 'nuxtI18n';
const LOCALE_CODE_KEY = 'code';
const LOCALE_ISO_KEY = 'iso';
const MODULE_NAME = 'nuxt-i18n';
const STRATEGIES = {
  PREFIX: 'prefix',
  PREFIX_EXCEPT_DEFAULT: 'prefix_except_default',
  PREFIX_AND_DEFAULT: 'prefix_and_default',
  NO_PREFIX: 'no_prefix'
};
const strategy = 'prefix_except_default';

export default (context, inject) => {
  let name;
  context.app.router.beforeResolve((to, from, next) => {
    name = to.name && context.store.getters['seo/validName'](to.name);
    if (name) {
      loadSEOData(context.app.i18n.locale, name)
        .then(() => next())
        .catch(() => {
          console.error('SEO error', to.fullPath);
          next();
        });
    } else {
      next();
    }
  });

  async function loadSEOData(lang, name) {
    await context.store.dispatch('seo/LOAD_DATA_SEO', { name, lang });
    return Promise.resolve();
  }

  function seoPlugin(options = {}) {
    try {
      let enableHreflang = options.hasOwnProperty('hreflang') ? options.hreflang : true;
      let enableCanonical = options.hasOwnProperty('canonical') ? options.canonical : true;
      let enableSlash = options.hasOwnProperty('slash') ? options.slash : false;
      const canonicalLink = options.hasOwnProperty('canonicalLink') ? options.canonicalLink : '';

      let regexPages = /((-p)$|(-p-page)|(-page)|(-search)|(graphics)|(svg-png))/;
      let slugName = context.app.getRouteBaseName().match(regexPages);

      if (slugName?.length) enableHreflang = false;

      if (
        !(VueMeta.hasMetaInfo ? VueMeta.hasMetaInfo(this) : this._hasMetaInfo) ||
        !this.$i18n ||
        !this.$i18n.locale ||
        !this.$i18n.locales ||
        this.$options[COMPONENT_OPTIONS_KEY] === false ||
        (this.$options[COMPONENT_OPTIONS_KEY] && this.$options[COMPONENT_OPTIONS_KEY].seo === false)
      ) {
        return {};
      }

      const metaObject = {
        htmlAttrs: {},
        link: [],
        meta: []
      };

      const currentLocale = this.$i18n.locales.find((l) => codeFromLocale(l) === this.$i18n.locale);
      const currentLocaleIso = isoFromLocale(currentLocale);

      if (currentLocale && currentLocaleIso) {
        metaObject.htmlAttrs.lang = currentLocaleIso;
      }

      if (enableHreflang) {
        addHreflangLinks.bind(this)(
          this.$i18n.locales,
          this.$i18n.__baseUrl,
          metaObject.link,
          enableSlash
        );
      }
      if (enableCanonical) {
        addCanonicalLinks.bind(this)(currentLocale, metaObject.link, enableSlash, canonicalLink);
      }
      addCurrentOgLocale.bind(this)(currentLocale, currentLocaleIso, metaObject.meta);
      addAlternateOgLocales.bind(this)(this.$i18n.locales, currentLocaleIso, metaObject.meta);

      if (context.store.getters['seo/dataSeoExist'](name)) {
        const data = context.store.state.seo.dataSeo[name];

        if (data.canonical) {
          metaObject.meta = [];
          metaObject.link = [
            {
              hid: 'i18n-can',
              rel: 'canonical',
              href: data.canonical
            }
          ];
        }

        metaObject.title = data.metatitle;
        metaObject.meta = [
          ...metaObject.meta,
          {
            hid: 'description',
            name: 'description',
            content: data.metadescription
          }
        ];

        metaObject.meta.push(
          {
            hid: 'og:title',
            name: 'og:title',
            content: data.opengraph_title || data.metatitle
          },
          {
            hid: 'og:type',
            name: 'og:type',
            content: 'website'
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: data.opengraph_description || data.metadescription
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: data.opengraph_title || data.metatitle
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: data.opengraph_description || data.metadescription
          }
        );

        if (data.opengraph_image) {
          metaObject.meta.push(
            {
              hid: 'og:image',
              name: 'og:image',
              content: data.opengraph_image
            },
            {
              hid: 'twitter:card',
              name: 'twitter:card',
              content: 'summary_large_image'
            },
            {
              hid: 'twitter:image',
              name: 'twitter:image',
              content: data.opengraph_image
            }
          );
        }

        const currentRoute = this.$route.path;
        let url = data.canonical || `https://${currentLocale.domain}${currentRoute}`;

        metaObject.meta.push(
          {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: url
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content: url
          }
        );
      }

      return metaObject;
    } catch (error) {
      return {};
    }
  }
  function hrefLangPlugin(slugs, slash = false) {
    let hrefLangs = [];
    const locales = context.store.$i18n.locales;

    for (var [key, value] of Object.entries(slugs)) {
      let actualDomain = locales.find((item) => key.startsWith(item.iso) == true);
      let hrefLink = `https://${actualDomain.domain}${value}`;
      if (slash) {
        hrefLink += '/';
      }
      let hrefLangActual = {
        hid: `i18n-alt-${key}`,
        rel: 'alternate',
        href: hrefLink,
        hreflang: key
      };
      hrefLangs.push(hrefLangActual);
    }

    let actualDomain = locales.find((item) => 'en'.startsWith(item.iso) == true);
    let hrefLink = `https://${actualDomain.domain}${slugs['en']}`;
    if (slash) hrefLink += '/';
    let hrefLangActual = {
      hid: 'i18n-xd',
      rel: 'alternate',
      href: hrefLink,
      hreflang: 'x-default'
    };

    hrefLangs.push(hrefLangActual);

    return hrefLangs;
  }
  function canonicalPlugin(slug, lang, slash = false, canonicalLink) {
    const locales = context.store.$i18n.locales;
    let actualDomain = locales.find((item) => lang.startsWith(item.iso) == true);
    if (!canonicalLink) {
      canonicalLink = `https://${actualDomain.domain}${slug}`;
    }
    if (slash) canonicalLink += '/';
    const canonical = {
      hid: 'i18n-can',
      rel: 'canonical',
      href: canonicalLink
    };

    return canonical;
  }
  function openGraphPlugin(title, type, url, description, image, lang, slash = false) {
    const locales = context.store.$i18n.locales;
    let actualDomain = locales.find((item) => lang.startsWith(item.iso) == true);
    let openGraphUrl = `https://${actualDomain.domain}${url}`;
    if (slash) openGraphUrl += '/';

    return [
      {
        hid: 'og:title',
        name: 'og:title',
        content: title
      },
      {
        hid: 'og:type',
        name: 'og:type',
        content: type
      },
      {
        hid: 'og:url',
        name: 'og:url',
        content: openGraphUrl
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: description
      },
      {
        hid: 'og:image',
        name: 'og:image',
        content: image
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        hid: 'twitter:url',
        name: 'twitter:url',
        content: openGraphUrl
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: title
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: description
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: image
      }
    ];
  }

  inject('seoPlugin', seoPlugin);
  inject('hrefLangPlugin', hrefLangPlugin);
  inject('canonicalPlugin', canonicalPlugin);
  inject('openGraphPlugin', openGraphPlugin);
};

function addHreflangLinks(locales, baseUrl, link, slash = false) {
  if (strategy === STRATEGIES.NO_PREFIX) {
    return;
  }

  const localeMap = new Map();

  for (const locale of locales) {
    const localeIso = isoFromLocale(locale);

    if (!localeIso) {
      // eslint-disable-next-line no-console
      console.warn(`[${MODULE_NAME}] Locale ISO code is required to generate alternate link`);
      continue;
    }

    const [language, region] = localeIso.split('-');

    if (language && region && (locale.isCatchallLocale || !localeMap.has(language))) {
      localeMap.set(language, locale);
    }

    localeMap.set(localeIso, locale);
  }

  for (const [iso, mapLocale] of localeMap.entries()) {
    let alternateLink = this.switchLocalePath(mapLocale.code);
    if (slash) alternateLink += '/';
    link.push({
      hid: `i18n-alt-${iso}`,
      rel: 'alternate',
      href: alternateLink,
      hreflang: iso
    });
  }

  if (defaultLocale) {
    let alternateLink = this.switchLocalePath(defaultLocale);
    if (slash) alternateLink += '/';
    link.push({
      hid: 'i18n-xd',
      rel: 'alternate',
      href: alternateLink,
      hreflang: 'x-default'
    });
  }
}

function addCanonicalLinks({ domain }, link, slash = false, canonicalLink) {
  const currentRoute = this.localeRoute({
    ...this.$route,
    name: this.getRouteBaseName()
  });
  let canonicalPath = currentRoute ? currentRoute.path : null;

  if (!canonicalPath) {
    return;
  }

  if (slash) canonicalPath += '/';

  link.push({
    hid: 'i18n-can',
    rel: 'canonical',
    href: canonicalLink ? canonicalLink : 'https://' + domain + canonicalPath
  });
}

function addCurrentOgLocale(currentLocale, currentLocaleIso, meta) {
  const hasCurrentLocaleAndIso = currentLocale && currentLocaleIso;

  if (!hasCurrentLocaleAndIso) {
    return;
  }

  meta.push({
    hid: 'i18n-og',
    property: 'og:locale',
    // Replace dash with underscore as defined in spec: language_TERRITORY
    content: underscoreIsoFromLocale(currentLocale)
  });
}

function addAlternateOgLocales(locales, currentLocaleIso, meta) {
  const localesWithoutCurrent = locales.filter((locale) => {
    const localeIso = isoFromLocale(locale);
    return localeIso && localeIso !== currentLocaleIso;
  });

  const alternateLocales = localesWithoutCurrent.map((locale) => ({
    hid: `i18n-og-alt-${isoFromLocale(locale)}`,
    property: 'og:locale:alternate',
    content: underscoreIsoFromLocale(locale)
  }));

  meta.push(...alternateLocales);
}

function isoFromLocale(locale) {
  return locale[LOCALE_ISO_KEY];
}

function underscoreIsoFromLocale(locale) {
  return isoFromLocale(locale).replace(/-/g, '_');
}

function codeFromLocale(locale) {
  return locale[LOCALE_CODE_KEY];
}
