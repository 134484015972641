<template>
  <div>
    <label class="checkbox">
      <input :id="_uid" v-model="propModel" type="checkbox" />
      <span :style="{ backgroundColor }"></span>
    </label>
    <label class="checkbox-text" :for="_uid">
      <span><slot></slot></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    backgroundColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
label.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  top: 2px;
  span {
    width: 21px;
    height: 21px;
    border: 1px solid $gray;
    border-radius: 4px;
    display: inline-block;
    transition: all linear 0.3s;
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 8px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      height: 10px;
      width: 4px;
      transform: rotate(45deg);
      visibility: hidden;
    }
  }
  input {
    display: none;
    &:checked ~ span {
      border: 1px solid var(--exp-color);
      background: var(--exp-color);
      &:after {
        visibility: visible;
      }
    }
  }
}
.checkbox-text {
  cursor: pointer;
  color: $mediumDarkGray;
}
</style>
