<template>
  <div class="search-tags">
    <div
      id="search-filter"
      class="vx-icon filter"
      :class="{ merch: isMerchActive, design: isDesignActive }"
      @click="toggleFilters"
    >
      <span class="icon-filter">
        <span class="line" />
        <span class="line" />
        <span class="line" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('experience', ['isDesignActive', 'isMerchActive']),
    isDesignActive() {
      return this.$store.getters['experience/getExperience'] == 'design';
    }
  },
  methods: {
    toggleFilters() {
      this.$store.commit('search/TOGGLE_FILTERS_VISIBILITY');
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-filter {
  width: 100%;
  span.line:nth-child(2)::after {
    right: 0;
    left: auto;
  }
  span.line::after {
    content: ' ';
    font-size: 5px;
    line-height: 9.5px;
    font-weight: 500;
    position: absolute;
    top: -4px;
    background-color: $defaultColor;
    margin: 0px 1px;
    border: 3px solid white;
    left: 0;
    width: 10px;
    transition: all 0.5s ease;
    border-radius: 103%;
    height: 10px;
    transform: scale(0.7);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
  span.line {
    position: relative;
    display: block;
    width: 40%;
    height: 2px;
    background-color: #ffffff;
    margin: 7px auto;
    padding-top: -5px;
  }
}
.vx-icon {
  display: flex;
  align-items: center;
  height: 50px;
  min-width: 50px;
  background-color: $defaultColor;
  border-radius: 40px;
  color: #ffffff;
  margin: 0px 7px 0 0;
  cursor: pointer;
  transition: width 0.5s, min-width 0.5s;
  &:hover .icon-filter span.line::after {
    margin-left: calc(100% - 8px);
  }
  &:hover .icon-filter span.line:nth-child(2)::after {
    margin-right: calc(100% - 8px);
  }
  &.filter {
    width: 50px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  }
  &.design {
    background-color: $defaultColor;
    .icon-filter span.line::after {
      background-color: $defaultColor;
    }
  }
  &.merch {
    background-color: $defaultColor;
    .icon-filter span.line::after {
      background-color: $defaultColor;
    }
  }
}

.vx-icon > span {
  margin: auto;
}
</style>
