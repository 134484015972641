import Vue from 'vue';

import {
  LayoutPlugin,
  TablePlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  ModalPlugin,
  SpinnerPlugin,
  ButtonPlugin,
  ImagePlugin,
  CarouselPlugin,
  AvatarPlugin,
  CollapsePlugin,
  InputGroupPlugin,
  TooltipPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  SkeletonPlugin
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(TablePlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ModalPlugin);
Vue.use(SpinnerPlugin);
Vue.use(ButtonPlugin);
Vue.use(ImagePlugin);
Vue.use(CarouselPlugin);
Vue.use(AvatarPlugin);
Vue.use(CollapsePlugin);
Vue.use(InputGroupPlugin);
Vue.use(TooltipPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(SkeletonPlugin);
