<template>
  <b-row class="steps-container text-center">
    <hr class="span-full" :style="`--bar-width:${accountConnector ? '110px' : '250px'}`" />
    <div class="cursor-pointer step-container" @click="changeStatus('plans')">
      <IndividualStep :status="planStatus">{{
        $t('purchase.header.steps.choosePlan')
      }}</IndividualStep>
    </div>
    <Divider :status="planConnector" />
    <div class="step-container">
      <IndividualStep :status="accountStatus">{{
        $t('purchase.header.steps.createAccount')
      }}</IndividualStep>
    </div>
    <Divider :status="accountConnector" />
    <div class="step-container">
      <IndividualStep :status="paymentStatus">{{
        $t('purchase.header.steps.payment')
      }}</IndividualStep>
    </div>
  </b-row>
</template>

<script>
import IndividualStep from '@/components/PurchaseFlow/Header/Steps/IndividualStep';
import Divider from '@/components/PurchaseFlow/Header/Steps/Divider';
import { mapState } from 'vuex';
export default {
  components: {
    IndividualStep,
    Divider
  },
  props: {
    status: {
      type: String,
      required: false,
      default: 'account'
    }
  },
  computed: {
    ...mapState('vectors', ['actualVector']),
    ...mapState('plans', ['firstStepAction']),
    planStatus() {
      if (this.status == 'plans') {
        return 'actual';
      } else {
        return 'done';
      }
    },
    accountStatus() {
      if (this.status == 'account') {
        return 'actual';
      } else if (this.status == 'plans') {
        return 'disabled';
      } else {
        return 'done';
      }
    },
    paymentStatus() {
      if (this.status == 'payment') {
        return 'actual';
      } else if (['plans', 'account'].includes(this.status)) {
        return 'disabled';
      } else {
        return 'done';
      }
    },
    planConnector() {
      return this.status == 'plan' ? 'next' : '';
    },
    accountConnector() {
      return this.status == 'account' ? 'next' : '';
    }
  },
  methods: {
    changeStatus(status) {
      if (this.firstStepAction == 'closeModal') {
        this.$bvModal.hide('purchase-modal');
      } else if (!this.actualVector) {
        this.$router.push(this.$urlBuilder('merch-plans', { keyword: 'change-plan' }));
      } else {
        this.$store.commit('vectors/SET_PURCHASE_FLOW_STATUS', status);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.steps-container {
  position: relative;
  @include sm {
    justify-content: center;
  }
}
.step-container {
  width: 100px;
  margin-right: 10px;
  z-index: 1;
}
.span-full {
  width: var(--bar-width);
  z-index: 0;
  position: absolute;
  top: -1px;
  left: 65px;
  border-top: 1px dotted #e8e8e8;
}
</style>
