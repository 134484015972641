<template>
  <div class="logout cursor-pointer" @click="logout">
    <Icon :width="20" :height="20" icon="logout" color="#4d4d4d" />

    <span>{{ $t('main.header.logOutSection.text') }}</span>
  </div>
</template>

<script>
import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  methods: {
    logout() {
      this.$auth.logout();
      this.$bvModal.hide('profile-menu-modal');
      this.$store.commit('downloads/SET_DOWNLOAD_PERMISSION', []);
      this.$store.commit('user/SET_LIKED_VECTORS', []);
      this.$store.commit('user/SET_DOWNLOADED_VECTORS', []);
      this.$cookies.remove('vx.hasPlan');
    }
  }
};
</script>

<style lang="scss" scoped>
.logout {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top-width: 1px;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    background-color: #e4e4e4;
    color: #434343;
    border-radius: 0 0 15px 15px;
  }
}
</style>
