<template>
  <div>
    <div class="header-options">
      <UserAvatar :width="40" @click="goProfile()" />
      <div class="cursor-pointer" @click="goProfile()">
        <span class="username font-weight-normal"
          >{{ $t('profile.main.header.hi') }} {{ username }}</span
        >
      </div>
    </div>
    <div class="profile-options">
      <ul class="options">
        <li class="option cursor-pointer font-weight-normal">
          <nuxt-link
            :to="localePath('profile-my-plan')"
            class="link d-flex align-items-center"
            @click.native="$bvModal.hide('profile-menu-modal')"
          >
            <span class="icon mr-2"
              ><Icon :width="20" :height="20" color="#4d4d4d" icon="user-circle-gear"
            /></span>
            <span>{{ $t('profile.navSide.sector.option10') }}</span>
          </nuxt-link>
        </li>

        <li class="option cursor-pointer font-weight-normal">
          <nuxt-link
            :to="localePath('profile-my-designs')"
            class="link d-flex align-items-center"
            @click.native="$bvModal.hide('profile-menu-modal')"
          >
            <span class="icon mr-2"
              ><Icon :width="20" :height="20" icon="images-square" color="#4d4d4d"
            /></span>
            <span>{{ $t('profile.navSide.sector.option11') }}</span>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAvatar from 'UI/UserAvatar';
import Icon from 'UI/Icon';
export default {
  components: {
    UserAvatar,
    Icon
  },
  computed: {
    ...mapGetters('user', ['username', 'userId'])
  },
  methods: {
    goProfile() {
      this.$bvModal.hide('profile-menu-modal');
      this.$router.push(this.localePath('profile-my-plan'));
    }
  }
};
</script>

<style lang="scss" scoped>
.header-options {
  align-items: center;
  color: #121212;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;

  border-radius: 0px 0px 5px 5px;
  .text {
    margin-left: 10px;
    flex: 1;
    .username {
      font-size: 16px;
    }
  }
}
.option {
  padding: 8px 25px;
  list-style: none;
  font-size: 18px;
  color: #121212;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  .link {
    color: #121212;
  }
  &:hover {
    background-color: #e4e4e4;
    color: #434343;
  }
  &:last-child {
    padding-bottom: 10px;
  }
}
.profile-options {
  border-radius: 0px 0px 5px 5px;
  .options {
    padding: 0;
  }
}
span.icon {
  color: $gray;
  .svg-icon-container {
    display: block;
  }
}
</style>
