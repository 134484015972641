<template>
  <b-modal id="user-modal" hide-footer size="lg">
    <template #modal-header>
      <CloseButton modal-name="user-modal" />
    </template>
    <b-container fluid>
      <b-row>
        <b-col v-if="modalMode == 'signup'" cols="5" class="d-none d-lg-block p-0">
          <Sidebar />
        </b-col>
        <b-col>
          <Form @login="$emit('login')" @signup="$emit('signup')" />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import CloseButton from 'UI/CloseButton';
import { mapState } from 'vuex';
import Sidebar from '@/components/User/Form/Sidebar';
import Form from '@/components/User/Form';
export default {
  components: {
    Sidebar,
    Form,
    CloseButton
  },
  computed: {
    ...mapState('user', ['modalMode'])
  }
};
</script>

<style lang="scss">
#user-modal {
  display: flex !important;
  align-items: center;
  height: 100%;
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border: none;
  }
  .modal-header {
    border: none;
    padding: 0;
  }
  .modal-footer {
    display: none;
  }
  .close {
    outline: none;
    position: absolute;
    right: -20px;
    top: 15px;
    background-color: #ffffff40;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
    height: 30px;
    display: flex;
    text-align: center;
    align-items: center;
    color: white;
    opacity: 1;
    font-weight: 400;
    z-index: 100;
    @include md {
      right: 20px;
      top: 20px;
      background-color: #505050;
    }
  }
}
</style>
