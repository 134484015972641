const pagesByGroup = {
  preview: [
    'vectors-preview-id',
    'vectors-preview-id-name',
    'psd-preview-id-name',
    'psd-preview-id',
    'png-svg-preview-id',
    'png-svg-preview-id-name',
    'editables-preview-id',
    'editables-preview-id-name'
  ],
  'landing-tools': ['templates-mockup-generator', 'templates-t-shirt-maker'],
  search: [
    'svg-png',
    'svg-png-p',
    'svg-png-p-page',
    'svg-png-search',
    'svg-png-search-p-page',
    'psd',
    'psd-p',
    'psd-p-page',
    'psd-search',
    'psd-search-p-page',
    'our-graphics',
    'our-graphics-p-page',
    'merch-latest-designs',
    'merch-latest-designs-p-page',
    'design-latest-vectors',
    'design-latest-vectors-p-page',
    'logos',
    'logos-p',
    'logos-p-page',
    'logos-search',
    'logos-search-p-page',
    'icon',
    'icon-p',
    'icon-p-page',
    'icon-search',
    'icon-search-p-page',
    'graphics',
    'graphics-p',
    'graphics-p-page',
    'graphics-search',
    'graphics-search-p-page',
    'editables',
    'editables-p',
    'editables-p-page',
    'editables-search',
    'editables-search-p-page',
    'featured-vectors',
    'featured-vectors-p-page',
    'design-featured-graphics',
    'design-featured-graphics-p-page',
    'merch-featured-designs',
    'merch-featured-designs-p-page',
    'free-vectors',
    'free-vectors-p',
    'free-vectors-p-page',
    'free-vectors-search',
    'free-vectors-search-p-page',
    'trending-vectors',
    'trending-vectors-p-page',
    'design-trending-graphics',
    'design-trending-graphics-p-page',
    'merch-trending-designs',
    'merch-trending-designs-p-page',
    'design-graphics',
    'design-graphics-p',
    'design-graphics-p-page',
    'design-graphics-search',
    'design-graphics-search-p-page',
    'merch-p',
    'merch-p-page',
    'merch-search',
    'merch-search-p-page',
    'merch-designs',
    'merch-designs-p',
    'merch-designs-p-page',
    'merch-designs-search',
    'merch-designs-search-p-page',
    'merch-vectors',
    'merch-vectors-p',
    'merch-vectors-p-page',
    'merch-vectors-search',
    'merch-vectors-search-p-page',
    'merch-psd',
    'merch-psd-p',
    'merch-psd-p-page',
    'merch-psd-search',
    'merch-psd-search-p-page',
    'merch-png',
    'merch-png-p',
    'merch-png-p-page',
    'merch-png-search',
    'merch-png-search-p-page'
  ],
  groupers: [
    'styles',
    'styles-slug',
    'styles-slug-page',
    'topics',
    'topics-slug',
    'topics-slug-page',
    'niches',
    'niches-slug',
    'niches-slug-page',
    'collections',
    'collections-slug',
    'collections-slug-page'
  ],
  profile: [
    'profile-account',
    'profile-ai-merch-maker',
    'profile-billing-invoice-history',
    'profile-billing-payments',
    'profile-billing',
    'profile-buy-credits',
    'profile-buy-design-request-credits',
    'profile-cancellation-canceled',
    'profile-cancellation-feedback',
    'profile-cancellation-reason',
    'profile-cancellation-reminder',
    'profile-cancellation',
    'profile-design-tools',
    'profile-downloads',
    'profile-favorites',
    'profile-my-designs-favorites',
    'profile-my-designs-my-requests',
    'profile-my-designs-my-tshirt-designs',
    'profile-my-designs-designer-projects',
    'profile-my-designs',
    'profile-my-merch-store',
    'profile-my-plan',
    'profile-my-requests-new',
    'profile-my-requests',
    'profile-my-store-analytics',
    'profile-my-store-create',
    'profile-my-store-payments',
    'profile-my-store-products',
    'profile-my-store-sales',
    'profile-my-store',
    'profile-tools-merch-digest',
    'profile-tools',
    'profile'
  ],
  plans: ['design-plans', 'design-plans-keyword', 'merch-plans-keyword', 'merch-plans'],
  category: ['category-id', 'category-id-slug', 'category-id-slug-p-page'],
  forgot: ['login-forgot', 'login-forgot-callback'],
  purchase: ['purchase-id'],
  'purchase-plan': ['purchase-plan-id', 'purchase-plan-id-keyword'],
  index: ['index', 'home']
};

const ignorePages = ['login'];

export default function ({ app }, inject) {
  app.i18n.loadedLanguages = [];
  app.i18n.loadedLanguagesPages = [];

  app.router.beforeResolve((to, from, next) => {
    let name = app.getRouteBaseName();
    loadLanguageAsync(app.i18n.locale, name)
      .then(() => next())
      .catch(() => {
        console.error('Translation error:', to.fullPath);
        next();
      });
  });

  async function loadLanguageAsync(lang, name) {
    if (app.i18n.loadedLanguagesPages.includes(name) || ignorePages.includes(name)) {
      return Promise.resolve();
    }

    let inGroup = false;

    Object.keys(pagesByGroup).some(function (k) {
      if (pagesByGroup[k].includes(name)) {
        name = k;
        inGroup = true;
      }
    });

    if (inGroup) {
      app.i18n.loadedLanguagesPages.push(...pagesByGroup[name]);
    } else {
      app.i18n.loadedLanguagesPages.push(name);
    }

    const { data } = await app.$axios.get(
      `${process.env.IMAGES_URL}translations/${lang}/${name}.json`
    );

    app.i18n.loadedLanguages.push(lang);
    app.i18n.mergeLocaleMessage(lang, data);
    return Promise.resolve();
  }

  inject('loadTranslation', (view) => loadLanguageAsync(app.i18n.locale, view));
}
