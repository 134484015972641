<template>
  <span class="error-message"><slot></slot></span>
</template>

<script>
export default {};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 13px;
}
</style>
