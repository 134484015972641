<template>
  <span class="divider-container">
    <div v-show="status == 'next'" :class="status">
      <Icon icon="bootstrap-chevron-right" color="#E9E9E9" :width="13" :height="13" />
    </div>
    <div v-show="status == 'disable'" :class="status"></div>
  </span>
</template>

<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  },
  props: {
    status: {
      type: String,
      required: false,
      default: 'disable'
    }
  }
};
</script>

<style lang="scss" scoped>
.divider-container {
  margin-top: 5px;
  width: 25px;
  @include sm {
    display: none;
  }
}
</style>
