<template>
  <picture
    v-if="src"
    class="component-thumbnail"
    :class="{ active: isActive }"
    @click="$emit('click')"
  >
    <img width="200" height="100" :src="src" :alt="alt" class="image" />
  </picture>
</template>
<script>
export default {
  name: 'Thumbnail',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.component-thumbnail {
  --color-active: transparent;
  --border-radius: 6px;

  border-radius: var(--border-radius);
  border: 2px solid var(--color-active);
  padding: 0.1em;
  cursor: pointer;
  .image {
    border-radius: var(--border-radius);
    background: #f3f3f3 0% 0% no-repeat padding-box;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  &.active {
    --color-active: #{$defaultColor};
  }
}
</style>
