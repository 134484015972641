var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container",class:{
    'vx-custom-color-background': _vm.hasCustomBackgroundColor,
    'low-z-index': _vm.searchBarBannerIsOpen
  },attrs:{"id":"header-container"}},[_c('div',{staticClass:"top-menu-container"},[_c('MenuOptions')],1),_vm._v(" "),_c('div',{staticClass:"vx-nav",style:({ boxShadow: _vm.boxShadow })},[_c('b-col',{staticClass:"logo-section-container"},[_c('LogoSection')],1),_vm._v(" "),_c('b-col',{staticClass:"col-md-6 search-bar-container"},[_c('div',{staticClass:"search-bar-wrapper",class:{
          'hide-merch-design': !_vm.getShowingSearchBarOnTop,
          'hide-search-bar': !_vm.showSearchBar
        }},[_c('SearchBar',{attrs:{"show-wrapper-border":false}})],1)]),_vm._v(" "),_c('div',{staticClass:"col d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"col-auto d-lg-none p-1"},[_c('MenuMobile')],1)])],1),_vm._v(" "),_c('BottomSearchBar',{staticClass:"show-lg",attrs:{"show-filters":_vm.showFilters}}),_vm._v(" "),_c('ProfileMenu')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }