<template>
  <nav
    id="preview-nav"
    class="preview-nav d-flex align-items-center w-100"
    :class="{ 'overflow-hidden': hasButton, 'has-scrolled': hasScrolled }"
  >
    <NavSkeleton v-if="!actualVector" />
    <div v-else class="preview-nav d-flex align-items-center w-100">
      <NavImage :show-image="showImage" />
      <div class="content" :class="{ 'with-btn': hasButton }">
        <NavTitle class="nav-title" />
        <NavTags :show-more-tags="showTags" @show-more-tags="showMoreTags" />
        <NavDescription
          v-if="!showBtnDownload && actualVector.description && !getDownloaded"
          :show-all-description="showDescription"
          @show-all-description="showAllDescription"
        />
        <DownloadBtn
          v-if="showBtnDownload && !getDownloaded"
          @show-purchase-flow="$emit('show-purchase-flow')"
        />
        <NavDownloadedOptions v-if="getDownloaded" />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

import DownloadBtn from '@/components/Preview/Download/DownloadBtn';
import NavDescription from '@/components/Preview/Nav/NavDescription';
import NavDownloadedOptions from '@/components/Preview/Nav/NavDownloadedOptions';
import NavImage from '@/components/Preview/Nav/NavImage';
import NavSkeleton from '@/components/Preview/Nav/NavSkeleton';
import NavTags from '@/components/Preview/Nav/NavTags';
import NavTitle from '@/components/Preview/Nav/NavTitle';

export default {
  name: 'PreviewContent',
  components: {
    DownloadBtn,
    NavDescription,
    NavDownloadedOptions,
    NavImage,
    NavSkeleton,
    NavTags,
    NavTitle
  },
  props: {
    showImage: {
      type: Boolean,
      default: false
    },
    showBtnDownload: {
      type: Boolean,
      default: false
    },
    hasScrolled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showTags: false,
      showDescription: false,
      isShowingTags: false,
      isShowingDescription: false
    };
  },
  computed: {
    ...mapGetters('vectors', ['actualVector']),
    ...mapGetters('downloads', ['getDownloaded']),
    hasButton() {
      return this.showBtnDownload || this.getDownloaded;
    }
  },
  mounted() {
    document.getElementById('preview-vector-modal')?.addEventListener('scroll', this.collapseNav);
  },
  beforeDestroy() {
    this.$store.commit('downloads/SET_DOWNLOADED', false);
  },
  methods: {
    resizePreview() {
      const imgPreview = this.$refs['img-preview-nav'];
      if (imgPreview !== undefined) {
        imgPreview.style.display = 'none';
      }
    },
    showMoreTags() {
      this.isShowingTags = true;
      this.showTags = !this.showTags;
      this.showDescription = false;
      setTimeout(() => {
        this.isShowingTags = false;
      }, 400);
    },
    showAllDescription() {
      this.isShowingDescription = true;
      this.showDescription = !this.showDescription;
      this.showTags = false;
      setTimeout(() => {
        this.isShowingDescription = false;
      }, 100);
    },
    collapseNav() {
      if (
        (this.showTags && !this.isShowingTags) ||
        (this.showDescription && !this.isShowingDescription)
      ) {
        this.showTags = false;
        this.showDescription = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.preview-nav {
  height: auto;
  min-height: 65px;
  transition: padding-left 0.3s;
  width: inherit;
  &.has-scrolled {
    box-shadow: 0 2px 11px 3px rgba(0, 0, 0, 0.1);
  }
  .content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    min-height: 55px;
    padding: 10px 25px 5px 25px;
    @include md {
      display: block;
      padding-top: 15px;
    }
    @include sm {
      flex-flow: wrap;
    }
    &.with-btn {
      @include md {
        display: flex !important;
      }
      @include sm {
        .vector-tags {
          display: none;
        }
      }
    }
    > .title {
      display: inline-flex;
      padding-right: 20px;
      .text {
        width: max-content;
        margin-bottom: 0;
        font-size: 20px;
      }
    }
    > .description {
      flex: 1;
      align-items: center;
      display: inline-flex;
      padding-left: 20px;
      padding-right: 25px;
      min-width: 25%;
      overflow: hidden;
      transition: width 0.5s, max-height 1.5s;
      cursor: pointer;
      position: relative;
      @include sm {
        flex: auto;
      }
      p {
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        position: relative;
        color: #cfcfcf;
        font-size: 12px;
        background: white;
        color: #b3b3b3;
      }
      &.show-description p {
        padding: 5px 0 7px;
        display: block;
        line-height: 20px;
      }
    }
  }
}

.container-download-options {
  display: flex;
  justify-content: flex-end;
  height: max-content;
  align-items: center;
  margin-left: auto;
  @include md {
    width: 100%;
  }
}
.download-btn {
  display: flex;
  align-items: center;
  text-align: center;
  @include sm {
    width: 100%;
  }
}

.like-container {
  width: 38px;
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 2;
  border-radius: 7px;
  margin-top: 2px;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  @include lg {
    display: none;
  }
}
.nav-title {
  max-width: 100%;
}
.vector-preview > .preview-nav {
  background-color: #fff;
  left: 0;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  width: calc(100% + 50px);
  z-index: 20;
}
@media all and (max-width: 1300px) {
  .preview-nav {
    min-height: 65px;
  }
}
@media all and (max-width: 576px) {
  .preview-nav {
    padding-left: 0 !important;
    align-items: start !important;
  }
}
</style>
