import { parseKeysToCamelCase, updateKeyNames } from '@/utils/common';

export const getters = {
  parsePlan: () => (plan) => {
    const planCamelKeys = parseKeysToCamelCase(plan);
    const planCompatibleKeys = updateKeyNames(planCamelKeys, {
      price: 'cost',
      priceDescription: 'costDescription'
    });
    return {
      ...planCompatibleKeys,
      isAnnually: planCompatibleKeys.chargeFrecuency === 'ANNUALLY',
      isMonthlyAnnualCommitment: planCompatibleKeys.chargeFrecuency === 'MONTHLY_ANNUAL_COMMITMENT',
      specs: planCompatibleKeys?.specs?.map((spec) => {
        return updateKeyNames(spec, {
          textTooltip: 'tooltipText'
        });
      })
    };
  },
  parsePlansDataVertical: (state, getters) => (firstAreaPlans, secondAreaPlans) => {
    return firstAreaPlans.map((plan, index) => {
      const annualPlan = secondAreaPlans[index];
      return {
        monthly: {
          ...getters.parsePlan(plan)
        },
        annual: {
          ...getters.parsePlan(annualPlan)
        }
      };
    });
  },
  parsePlans: (state, getters) => (plans) => {
    return plans.map((plan) => {
      return { ...getters.parsePlan(plan) };
    });
  },
  parsePlansDataAnnual: (state, getters) => (plans) => {
    return plans.map((plan) => {
      return { annual: getters.parsePlan(plan) };
    });
  },
  parsePlansDataMonthy: (state, getters) => (plans) => {
    return plans.map((plan) => {
      return { monthly: getters.parsePlan(plan) };
    });
  },
  getButtonText: (state, getters, rootState, rootGetters) => (text, textUpgrade) => {
    const hasPlan = rootGetters['user/loggedInWithPlan'];
    return hasPlan ? textUpgrade : text;
  },
  getFreeTrialButtonText: () => (hasFreeTrial, textFreeTrial, text) => {
    return hasFreeTrial ? textFreeTrial : text;
  }
};

export const actions = {
  async LOAD_PRICE_AREA_GROUP({}, slug) {
    try {
      const {
        data: {
          data: { plan_areas: planAreas }
        }
      } = await this.$axiosGeo(`configurations/?config=price_area_group&group=${slug}`);
      if (!planAreas) return {};
      const firstPlanArea = planAreas[0] || {};
      const secondPlanArea = planAreas[1] || {};
      const thirdPlanArea = planAreas[2] || {};
      return { firstPlanArea, secondPlanArea, thirdPlanArea };
    } catch (error) {
      console.error('plan-areas | LOAD_PRICE_AREA_GROUP:', error);
    }
  }
};

export const state = () => ({});
