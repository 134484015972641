export const getAppCookies = (req) => {
  const rawCookies = req.headers.common.cookie ? req.headers.common.cookie.split('; ') : [];
  const parsedCookies = {};
  rawCookies.forEach((rawCookie) => {
    const parsedCookie = rawCookie.split('=');
    parsedCookies[parsedCookie[0]] = parsedCookie[1];
  });

  return parsedCookies;
};

export const getAuthCookie = (isServer, config, cookies) => {
  let authCookie = '';

  if (isServer) {
    authCookie =
      getAppCookies(config)['vx.token.local'] ||
      getAppCookies(config)['vx.token.google'] ||
      getAppCookies(config)['vx.token.facebook'];
  } else {
    authCookie =
      cookies.get('vx.token.local') ||
      cookies.get('vx.token.google') ||
      cookies.get('vx.token.facebook');
  }
  return authCookie;
};

export const setAuthorization = (isServer, config, cookies) => {
  const authCookie = getAuthCookie(isServer, config, cookies);

  if (authCookie) {
    config.headers.common['Authorization'] = authCookie;
  }
};

export const setAuthorizationBearer = (isServer, config, cookies) => {
  const authCookie = getAuthCookie(isServer, config, cookies);

  if (authCookie) {
    config.headers.common['Authorization'] = 'Bearer ' + authCookie;
  }
};

export const handleError = (error, cookies, dispatch, domain) => {
  const code = parseInt(error.response && error.response.status);
  if (code === 401) {
    const isMakersAPI = error.config.url.includes('makersapi.vexels.com');
    if (isMakersAPI) {
      return;
    }
    const path = '/';
    cookies.remove('vx.strategy', { domain, path });
    cookies.remove('vx.token.local', { domain, path });
    cookies.remove('vx.token.google', { domain, path });
    cookies.remove('vx.token.facebook', { domain, path });
    dispatch('auth/logout');
  }
};

export const newInstanceProps = {
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*'
    }
  }
};
