<template>
  <b-container class="mobile-menu">
    <b-row class="mb-5 d-flex flex-column">
      <div
        v-for="(
          { title, isOpen, subMenu, eventItem, link = '', linkSuscriptor = '' }, index
        ) in menuData"
        :key="index"
      >
        <a
          v-if="link || linkSuscriptor"
          :href="isSuscriptor ? linkSuscriptor : link"
          target="_blank"
          class="nav-link p-0 py-3 text-body"
          >{{ $t(title) }}</a
        >
        <h4
          v-if="!link && !linkSuscriptor"
          class="menu-header d-flex justify-content-between align-items-center"
          @click="toggleSubMenu(index)"
        >
          <span class="submenu-title">{{ $t(`${title}`) }}</span>
          <Icon v-if="!isOpen" icon="bootstrap-chevron-down" :width="16" :height="16" />
          <Icon v-else icon="bootstrap-chevron-up" :width="16" :height="16" />
        </h4>
        <ul v-if="!link && !linkSuscriptor" class="contents" :class="{ open: isOpen }">
          <li v-for="(option, index) in subMenu" :key="index" class="link-submenu">
            <ResolveDataLink
              :item="option"
              :modal-name="modalName"
              :event-item="eventItem"
              text-color="#202020"
              text-size="13"
            />
          </li>
        </ul>
        <hr class="m-0" />
      </div>
    </b-row>

    <Button
      shape="full"
      color="green"
      width="full"
      :use-nuxt-link="false"
      :to="$urlBuilder('plans', { keyword: 'menu-mobile' })"
      @click.native="$bvModal.hide(modalName)"
      >{{ $t('main.header.nav.plans') }}</Button
    >
  </b-container>
</template>

<script>
import Icon from 'UI/Icon';
import Button from 'UI/Button';
import ResolveDataLink from 'Components/Header/MenuMobile/Modal/ResolveDataLink';
import menuDataFromJson from 'Components/Header/MenuMobile/Modal/menuData.json';

export default {
  components: {
    Button,
    Icon,
    ResolveDataLink
  },
  data() {
    return {
      contentsOpen: false,
      toolsOpen: false,
      isShow: false,
      menuData: menuDataFromJson,
      modalName: 'menu-mobile-modal'
    };
  },
  computed: {
    isSuscriptor() {
      return this.$auth.loggedIn && this.$auth.idPlan != 1;
    }
  },
  methods: {
    toggleSubMenu(id) {
      if (this.menuData[id] && this.menuData[id].isOpen) {
        this.menuData[id].isOpen = !this.menuData[id].isOpen;
        return;
      }
      this.menuData.forEach((menuItem) => (menuItem.isOpen = false));
      this.menuData[id].isOpen = !this.menuData[id].isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-menu {
  .menu-header {
    height: 55px;
    cursor: pointer;
    margin: 0;
    .submenu-title {
      font-size: 16px;
    }
  }

  .contents {
    list-style: none;
    padding: 0;
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow-y: auto;
    .link-submenu {
      &:first-of-type {
        padding-top: 0;
      }
      padding: 5px 0;
    }
    &.open {
      max-height: 30vh;
      transition: max-height 0.3s ease-in;
    }
  }
}
</style>
