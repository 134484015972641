<template>
  <div v-if="actualVectorCollectionsVectors.length" fluid>
    <div class="w-100 mb-2 mt-4">
      <span>
        {{ $t('preview.relateds.groupers.moreFromCollection') }}
        <nuxt-link
          class="exp-font"
          :to="$urlBuilder('collection', { slug: actualVectorCollection.slug })"
        >
          {{ actualVectorCollection.name }}
        </nuxt-link>
      </span>
    </div>
    <Carousel
      :items="actualVectorCollectionsVectors"
      :hide-uncompleted-rows="false"
      :height="160"
      :see-more-enabled="true"
      :see-more-link="$urlBuilder('collection', { slug: actualVectorCollection.slug })"
      @vector-click="vectorClick"
    >
      <template #default="slotProps">
        <NormalVectorOverlay :data="slotProps.data" />
      </template>
    </Carousel>
  </div>
</template>

<script>
import NormalVectorOverlay from '@/components/Vectors/NormalVectorOverlay';
import Carousel from '@/components/Common/Carousel';
import { mapState } from 'vuex';

export default {
  components: { NormalVectorOverlay, Carousel },
  computed: {
    ...mapState('vectors', ['actualVectorCollectionsVectors', 'actualVectorCollection'])
  },
  methods: {
    async vectorClick({ item, shownListing }, e) {
      e.preventDefault();
      this.$bvModal.show('preview-vector-modal');
      await this.$store.dispatch('vectors/OPEN_PREVIEW', { item, list: shownListing });
    }
  }
};
</script>

<style></style>
