import { dateBetweenRange, formatDate } from '../utils/common';

export const mutations = {
  SET_OPEN_PLAN(state, plan) {
    state.openPlan = plan;
  },
  SET_PLANS(state, data) {
    const { recurrents } = data;
    state.plans = { ...state.plans, ...recurrents };
  },
  SET_LIFETIME_PLANS(state, data) {
    state.lifetimePlans = data;
  },
  SET_INDIVIDUALS(state, data) {
    const { individuals } = data;
    state.individuals = [...state.individuals, ...individuals];
  },
  SAVE_PLAN(state, plan) {
    const planAlreadySaved = state.savedPlans.some((el) => el.id === plan.id);
    if (!planAlreadySaved) {
      state.savedPlans.push(plan);
    }
  },
  SET_STEP_ACTION(state, action) {
    state.firstStepAction = action;
  },
  SET_PLANS_TO_FETCH(state, newPlansToRender) {
    state.plansToFetch = [...state.plansToFetch, newPlansToRender];
  }
};

export const getters = {
  plansWithIndividual(state, getters, rootState, rootGetters) {
    const merch = rootGetters['experience/getMerch'];
    let result = [];

    const individualSimple = state.individuals[0];
    const individual = { simple: individualSimple };

    if (individual.simple) {
      result.push(individual);
      if (state.plans[merch].length) {
        result.push(...state.plans[merch].slice(0, 2));
      }
    } else if (state.plans[merch].length) {
      result.push(...state.plans[merch]);
    }

    return result;
  },
  plans(state, getters, rootState, rootGetters) {
    const merch = rootGetters['experience/getMerch'];
    const experience = rootGetters['experience/getExperience'] || merch;
    return state.plans[experience];
  },
  lifetimePlans(state) {
    return state.lifetimePlans;
  },
  openPlan(state) {
    return state.openPlan;
  },
  getPlanToFetch: (state) => (uId) => {
    return state.plansToFetch.find((elem) => {
      return elem.uId == uId;
    });
  },
  getHasGift:
    (state, getters, rootState) =>
    ({ startDate, endDate }) => {
      const chargeFrecuency = rootState.auth?.user.chargeFrecuency;
      let planPurchaseDate = rootState.auth?.user.datePlanPurchase;
      planPurchaseDate = formatDate(new Date(planPurchaseDate), 'YYYY-MM-DD');
      const validDate = dateBetweenRange(planPurchaseDate, startDate, endDate);
      return chargeFrecuency && ['ANNUALLY', 'LIFETIME'].includes(chargeFrecuency) && validDate;
    },
  getPlansDiscount:
    ({ state }) =>
    (plans) => {
      return plans[1]?.annual?.discount;
    }
};

export const actions = {
  OPEN_PLAN({ commit }, plan) {
    commit('SET_OPEN_PLAN', plan);
  },
  async LOAD_PLANS({ commit, state, rootGetters }) {
    const design = rootGetters['experience/getDesign'];
    const experience = rootGetters['experience/getExperience'] || design;
    if (!state.plans[experience].length) {
      try {
        const response = await this.$axios.get('plan/');
        const data = response.data.data;
        if (data) {
          commit('SET_INDIVIDUALS', { individuals: data.individuals });
          commit('SET_PLANS', { recurrents: { merch: data.merch, design: data.design } });
        }
      } catch (e) {
        console.error('Plans | LOAD_PLANS:', e);
      }
    }
  },
  async LOAD_PREVIEW_PLANS({ commit, dispatch, state, rootGetters }) {
    const merch = rootGetters['experience/getMerch'];
    if (!state.plans[merch].length) {
      try {
        const { firstPlanArea, secondPlanArea, thirdPlanArea } = await dispatch(
          'plan-areas/LOAD_PRICE_AREA_GROUP',
          'preview-plans',
          { root: true }
        );

        const monthlyPlanArea = firstPlanArea;
        const annuallyPlanArea = secondPlanArea;
        const singleLicenseArea = thirdPlanArea;

        const parsePlan = rootGetters['plan-areas/parsePlan'];

        const individuals =
          singleLicenseArea?.plans?.map((plan) => {
            return {
              ...parsePlan(plan)
            };
          }) || [];

        const monthlyPlans = monthlyPlanArea.plans;
        const annualPlans = annuallyPlanArea.plans;
        let plansResult = [];

        for (let i = 0; i < monthlyPlans.length; i++) {
          const monthlyPlan = monthlyPlans[i];
          const annualPlan = annualPlans[i];
          if (monthlyPlan && annualPlan) {
            plansResult.push({
              monthly: { ...parsePlan(monthlyPlan) },
              annual: { ...parsePlan(annualPlan) }
            });
          } else if (monthlyPlan) {
            plansResult.push({ monthly: { ...parsePlan(monthlyPlan) } });
          } else if (annualPlan) {
            plansResult.push({ annual: { ...parsePlan(annualPlan) } });
          }
        }
        const monthly_annual = { recurrents: { merch: plansResult } };

        commit('SET_INDIVIDUALS', { individuals: individuals });
        commit('SET_PLANS', monthly_annual);
      } catch (e) {
        console.error('Plans | LOAD_PLANS:', e);
      }
    }
  },
  async CHECK_TODAY_IN_SPECIAL_DATES({ dispatch }) {
    try {
      const specialDates = await dispatch('GET_SPECIAL_DATES');
      const startDate = specialDates.start;
      const endDate = specialDates.end;
      const today = new Date();
      const todayDate = today.toISOString().split('T')[0];
      const showGiftPlans = dateBetweenRange(todayDate, startDate, endDate);
      return showGiftPlans;
    } catch (e) {
      console.error('Plans | CHECK_TODAY_IN_SPECIAL_DATES:', e);
      return false;
    }
  },
  async GET_PLAN({ commit }, id) {
    try {
      const {
        data: { data, error }
      } = await this.$axiosGeo.post(`v1/plan/${id}/`);
      if (!error) {
        commit('SAVE_PLAN', data);
        return data;
      } else {
        console.error('Plans | GET_PLAN 1:', data);
      }
    } catch (error) {
      console.error('Plans | GET_PLAN 2:', error);
    }
  },
  async GET_SPECIAL_DATES() {
    try {
      const {
        data: { data }
      } = await this.$axiosUtils('configurations?config=special_dates');
      return { start: data.start_date, end: data.end_date };
    } catch (error) {
      console.error('Plans | GET_SPECIAL_DATES:', error);
    }
  }
};

export const state = () => ({
  plans: {
    merch: [],
    design: []
  },
  individuals: [],
  lifetimePlans: [],
  openPlan: null,
  savedPlans: [],
  plansToFetch: [],
  firstStepAction: ''
});
