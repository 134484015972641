import globals from '~/settings.json';
import _get from 'lodash/get';

export default (context, inject) => {
  inject('g', g);
};

const g = (key) => {
  let val = _get(globals, key, '');
  return val || key;
};
