<template>
  <ButtonCarousel
    :aria-label="$t('main.common.carousel.carouselPreviousAriaLabel')"
    @click="$emit('click')"
  >
    <Icon class="invert" icon="arrow" color="white" :width="12" :height="12" />
  </ButtonCarousel>
</template>
<script>
import Icon from 'UI/Icon';
import ButtonCarousel from 'UI/ButtonsCarousel/ButtonCarousel.vue';
export default {
  components: {
    Icon,
    ButtonCarousel
  }
};
</script>
