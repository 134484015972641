export const mutations = {
  SET_BROWSER_REDIRECT(state, value) {
    state.browserRedirect = value;
  },
  SET_TO_PREVIEW(state, value) {
    state.toPreview = value;
  }
};

export const getters = {
  getBrowserRedirect: (state) => {
    return state.browserRedirect;
  },
  getToPreview: (state) => {
    return state.toPreview;
  }
};

export const state = () => ({
  browserRedirect: false,
  toPreview: false
});
