<template>
  <div class="search-bar" :style="wrapperStyles">
    <DimensionsSelector v-if="!prismicDimension" @search="search" />
    <Autocomplete
      :prismic-suggestions="prismicSuggestions"
      :prismic-dimension="prismicDimension"
      :prismic-keyword="prismicKeyword"
      :search-bar-uid="_uid"
      :is-banner="isBanner"
      @search="search"
    />
    <div v-show="!hasSearchText" class="search-icon-container" @click="() => search()">
      <i class="ph-bold ph-magnifying-glass" :style="{ fontSize: '24px' }"></i>
    </div>
    <div v-show="hasSearchText" class="search-icon-container close" @click="clear">
      <i class="ph-bold ph-x" :style="{ fontSize: '24px' }"></i>
    </div>
  </div>
</template>

<script>
import DimensionsSelector from './DimensionsSelector';
import Autocomplete from './Autocomplete';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'SearchBar',
  components: {
    Autocomplete,
    DimensionsSelector
  },
  props: {
    isBanner: {
      type: Boolean,
      default: false,
      required: false
    },
    prismicSuggestions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    prismicKeyword: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    hasSearchText() {
      const prismicSearchById = this.getPrismicSearch.find((el) => el.id === this._uid);
      return this.prismicDimension
        ? prismicSearchById?.value?.length
        : this.actualSearch && this.actualSearch.length;
    },
    ...mapState('searchBar', ['actualSearch', 'hasSuggestionSelected', 'elementSelected']),
    ...mapGetters('searchBar', ['isAnyOpen', 'getShowingDropdown', 'getPrismicSearch']),
    ...mapGetters('dimensions', ['getActualDimension']),
    ...mapGetters('experience', ['getExperience']),
    wrapperStyles() {
      const showingDropdownItem = this.getShowingDropdown.find((el) => el.id === this._uid);
      return `${this.isAnyOpen && !showingDropdownItem?.value && 'z-index: 1;'} ${
        this.isAnyOpen &&
        showingDropdownItem?.value &&
        'border-bottom: none; border-radius: 25px 25px 0 0;'
      }`;
    }
  },
  methods: {
    clear() {
      if (this.prismicDimension) {
        this.$store.commit('searchBar/SET_PRISMIC_SEARCH', { id: this._uid, value: '' });
      } else {
        this.$store.commit('searchBar/SET_ACTUAL_SEARCH', '');
      }
      this.$store.commit('search/SET_QUERY', {});
      const dimension = this.prismicDimension || this.getActualDimension.path;
      const path = this.$urlBuilder('search', {
        path: dimension,
        keyword: ''
      });
      this.$router.push({ path });
    },
    sanitizeDimensionPath(dimension) {
      const dimensionsToAvoid = [
        {
          from: 'free-vectors',
          to: 'merch-designs'
        }
      ];
      const dimensionToAvoid = dimensionsToAvoid.find(({ from }) => dimension.includes(from));
      const sanitizedDimension = dimensionToAvoid ? dimensionToAvoid.to : dimension;
      return sanitizedDimension;
    },
    search(prismicSearch) {
      this.$store.commit('search/SET_QUERY', {});
      this.$store.commit('search/SET_WILDCARD', false);
      let keyword = this.hasSuggestionSelected
        ? this.elementSelected.data.value
        : prismicSearch || this.actualSearch;
      keyword = keyword + (this.prismicKeyword ? ` ${this.prismicKeyword}` : '');
      this.sendGtmEvent(keyword);
      const dimensionPath = this.prismicDimension || this.getActualDimension.path;
      const path = this.$urlBuilder('search', {
        path: this.sanitizeDimensionPath(dimensionPath),
        keyword: keyword
      });
      this.$store.commit('searchBar/SET_ACTUAL_SEARCH', keyword);
      this.$router.push({ path });
    },
    sendGtmEvent(keyword) {
      this.$dataLayerPush('view_search_results', {
        searchTerm: keyword
      });
    }
  }
};
</script>

<style scoped>
.search-bar {
  position: relative;
  display: inline-flex;
  flex-grow: 1;
  transition: width 0.5s;
  align-items: center;
  border-radius: 25px;
  background-color: #ffffff;
  border: 1px solid rgb(209 200 209);
  width: 100%;
  min-height: 50px;
  z-index: 200;
}
.search-icon-container {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #636363;
  margin-right: 5px;
}
</style>
