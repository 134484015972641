<template>
  <div class="vexellent" :class="{ 'position-fixed vexellent-page': page }">
    <b-container class="text-center">
      <div class="animation-container pt-3">
        <img
          width="100"
          height="100"
          src="https://cdn.vexels.com/img/boxed-check.png"
          class="img-fluid image"
          :alt="$t('main.paymentSuccesful.header.success')"
        />
      </div>
      <h1 class="title">{{ title }}</h1>
      <div class="w-100 d-flex flex-column align-items-center mb-4">
        <!-- eslint-disable max-len vue/no-v-html -->
        <div v-if="isSeasonalActive && loadingSpins" class="spin-loading-container">
          <Loading />
        </div>

        <div
          data-tf-live="01JPQ9BCEA1YVP0AWQZE7Q352A"
          class="typeform-widget"
          data-tf-iframe-props="title=feedback_after_purchase"
          :data-tf-hidden="`user_id=${$auth.user.id},email=${$auth.user.email}`"
        ></div>
        <script src="//embed.typeform.com/next/embed.js"></script>
        <Button v-bind="buttonProps" :to="buttonLink" :use-nuxt-link="false" @click="removeModal">
          {{ buttonText }}
        </Button>
      </div>
      <div class="utilities">
        <div class="utility-container">
          <Icon color="white" :width="iconSize" :height="iconSize" icon="hottest" />
          <p>{{ $t('main.paymentSuccesful.content.text1') }}</p>
        </div>
        <div class="utility-container">
          <Icon color="white" :width="iconSize" :height="iconSize" icon="online-editor" />
          <p>{{ $t('main.paymentSuccesful.content.text2') }}</p>
        </div>
        <div class="utility-container">
          <Icon color="white" :width="iconSize" :height="iconSize" icon="license" />
          <p>{{ $t('main.paymentSuccesful.content.text3') }}</p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import { mapGetters } from 'vuex';

export default {
  components: {
    Button,
    Icon
  },
  props: {
    page: {
      type: Boolean,
      require: false,
      default: true
    },
    vector: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      iconSize: 30,
      hasSpins: false,
      loadingSpins: true,
      specialDates: null
    };
  },
  async fetch() {
    if (this.isSeasonalActive) {
      try {
        const { data } = await this.$axiosV2.get('spinwheel/spin');
        this.hasSpins = data.data.length > 0;
      } catch (error) {
        console.error('Payment Successful SPINWHEEL', error);
      }
      this.loadingSpins = false;
    }
  },
  computed: {
    ...mapGetters('config', ['isSeasonalActive']),
    ...mapGetters('plans', ['getHasGift']),
    title() {
      return this.$t('main.paymentSuccesful.header.title');
    },
    buttonProps() {
      return {
        shape: 'square',
        color: 'green',
        size: 'big',
        withGradient: true,
        gradient:
          // eslint-disable-next-line max-len
          'linear-gradient(to right, white 0%, white 43%, rgba(202,221,252,1) 89%, rgba(195,216,250,1) 95%, rgba(184,211,255,1) 100%)',
        fontColor: '#3f80f1'
      };
    },
    buttonLink() {
      const preparingSpin = this.isSeasonalActive && !this.hasSpins && null;
      const spinAvailable = this.isSeasonalActive && this.hasSpins && this.localePath('spin-wheel');

      return preparingSpin || spinAvailable || this.localePath('/home');
    },
    buttonText() {
      const preparingSpin =
        this.isSeasonalActive &&
        !this.hasSpins &&
        this.$t('main.paymentSuccesful.button.preparingSpins');

      const spinAvailable =
        this.isSeasonalActive &&
        this.hasSpins &&
        this.$t('main.paymentSuccesful.button.textSeasonal');

      return preparingSpin || spinAvailable || this.$t('main.paymentSuccesful.button.text');
    }
  },
  async mounted() {
    if (this.page) {
      if (this.vector?.id) {
        const path = {
          path: `/vectors/preview/${this.vector.id}`,
          query: { forceDownload: true }
        };

        await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', {
          id: this.vector.id,
          url: this.vector.urlDownload
        });

        setTimeout(() => {
          this.$router.push(path);
        }, 5000);
      }
    } else if (this.vector?.id) {
      const id = this.vector.id;
      const data = { id, url: this.vector.urlDownload };
      this.$store.commit('downloads/SET_DOWNLOAD_PERMISSION', []);
      await this.$store.dispatch('downloads/DOWNLOAD_VECTOR', data);
    }
    await this.getSpecialDates();

    if (this.isSeasonalActive) {
      let retries = 0;
      const maxRetries = 10;

      const interval = setInterval(async () => {
        try {
          const { data } = await this.$axiosV2.get('spinwheel/spin');
          this.hasSpins = data.data.length > 0;
          if (data.data.length > 0) {
            clearInterval(interval);
          } else {
            console.error('Spins not available');
          }
        } catch (error) {
          console.error('Something went wrong getting spins', error);
        }

        retries++;
        if (retries >= maxRetries) {
          clearInterval(interval);
        }
      }, 1500);
    }
  },
  methods: {
    removeModal() {
      if (!this.page) {
        if (this.vector?.id) {
          this.$bvModal.hide('preview-vector-modal');
        } else {
          this.$bvModal.hide('purchase-modal');
        }
      }
    },
    async getSpecialDates() {
      this.specialDates = await this.$store.dispatch('plans/GET_SPECIAL_DATES');
    }
  }
};
</script>

<style lang="scss" scoped>
.vexellent {
  background-image: url('https://cdn.vexels.com/img/vexellent-background.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  height: 100%;
  width: 100%;

  &.seasonal {
    background: $blackFriday;
    padding: 0 0 40px 0;
    .spin-loading-container {
      height: 80px;
    }
  }
  .animation-container {
    margin: auto;
    @include md {
      width: 100%;
    }
  }

  .image {
    width: 5em;
    aspect-ratio: 1/1;
  }
  .title {
    color: #ffffff;
    font-size: 36px;
    display: inline-block;
    @include md {
      font-size: 32px;
    }
  }
  .typeform-widget {
    width: inherit;
    margin-bottom: 1em;
    @include min-md {
      max-width: 30em;
      max-height: 30em;
      margin-bottom: 3em;
    }
  }
  .utilities {
    display: flex;
    justify-content: center;
    gap: 1em;
    .utility-container {
      color: #ffffff;
      display: grid;
      justify-items: center;
      gap: 0.6em;
      p {
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}

.vexellent-page {
  top: 0;
  left: 0;
  z-index: 2000;
  padding: 0px;
  overflow-y: auto;
  height: 100vh;
  .title {
    font-size: 65px !important;
  }
}
</style>
