<template>
  <div class="d-flex position-relative tooltip-parent">
    <slot name="element"></slot>
    <div :style="tooltipPosition" class="tooltip-icon exp-bg" :class="{ 'tag-icon': iconTag }">
      <h6 v-if="hasTitle" :class="{ 'mb-0': !hasDescription || noMarginTop }">
        <slot name="title"></slot>
      </h6>
      <p class="mb-0">
        <slot></slot>
      </p>
      <span class="triangle" :style="triangleStylesComputed"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Tooltip',
  props: {
    iconTag: {
      type: Boolean,
      default: false
    },
    left: {
      type: Number,
      required: false,
      default: 8
    },
    bottom: {
      type: Number,
      required: false,
      default: 200
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    noMarginTop: {
      type: Boolean,
      default: false
    },
    triangleStyles: {
      type: String,
      default: ''
    },
    tooltipStyles: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('experience', ['getExperienceColor']),

    hasTitle() {
      return this.$slots.title ? true : false;
    },
    hasDescription() {
      return this.$slots.default ? true : false;
    },
    tooltipPosition() {
      let styles = `--background-color-tooltip: ${
        this.backgroundColor || this.getExperienceColor
      };`;

      if (!this.iconTag) {
        styles += ` left: ${this.left}px; bottom:${this.bottom}%;`;
      }

      styles += ` ${this.tooltipStyles}`;

      return styles;
    },
    triangleStylesComputed() {
      return this.triangleStyles;
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-parent:hover .tooltip-icon {
  display: flex;
}
.tooltip-icon {
  position: absolute;
  width: 200px;
  bottom: 200%;
  left: 8px;
  display: none;
  flex-direction: column;
  justify-content: center;
  color: white;
  border-radius: 4px 4px 4px 0;
  z-index: 100;
  padding: 16px;
  background-color: var(--background-color-tooltip) !important;
  &.tag-icon {
    border-radius: 4px;
    width: max-content;
    bottom: 0;
    top: 57px;
    left: -18px;
    padding: 14px;
    .triangle {
      top: -12px;
      left: 31px;
      bottom: 0;
      border-width: 6px;
      border-color: transparent transparent var(--background-color-tooltip) transparent;
    }
  }
  & p {
    font-size: 12px;
    margin: 0;
  }
  & h6 {
    font-size: 14px;
    width: 100%;
    text-align: left;
  }
  & .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 15px 0 0;
    position: absolute;
    bottom: -15px;
    left: 0;
    border-color: var(--exp-color) transparent transparent transparent;
    border-width: 15px 15px 0 0;
  }
}
</style>
