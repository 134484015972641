export default (context, inject) => {
  const contentByExperience = (contentDesign, contentMerch) => {
    const exp = context.store.getters['experience/getExperience'];
    if (exp === 'merch') {
      return contentMerch;
    }
    return contentDesign;
  };
  inject('contentByExperience', contentByExperience);
};
