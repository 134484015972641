export const mutations = {
  SET_DATA_SEO(state, data) {
    state.dataSeo = { ...state.dataSeo, ...data };
  }
};

export const getters = {
  dataSeoExist: (state) => (name) => {
    return Object.keys(state.dataSeo).includes(name);
  },
  validName: (state) => (name) => {
    let [nameToCheck] = name?.split('___');
    return state.enablePages[nameToCheck];
  }
};

export const actions = {
  async LOAD_DATA_SEO({ commit, getters }, { name, lang }) {
    try {
      if (!getters.dataSeoExist(name)) {
        const { data } = await this.$axiosUtils.get(`seoview/?pagename=${name}___${lang}`);
        commit('SET_DATA_SEO', { [name]: data });
      }
    } catch (error) {
      console.error('SEO | LOAD_DATA_SEO:', error);
    }
  }
};

export const state = () => ({
  dataSeo: {},
  enablePages: {
    'merch-plans': 'merch-plans',
    'merch-plans-keyword': 'merch-plans',
    'design-plans': 'design-plans',
    'design-plans-keyword': 'design-plans',
    topics: 'topics',
    styles: 'styles',
    niches: 'niches',
    collections: 'collections',
    categories: 'categories',
    enterprise: 'enterprise',
    design: 'design',
    'about-us': 'about-us',
    merch: 'merch',
    home: 'home',
    index: 'index',
    'new-vexels': 'new-vexels',
    'lifetime-plans': 'lifetime-plans',
    'privacy-policy': 'privacy-policy',
    'redeem-coupon': 'redeem-coupon',
    'terms-and-conditions': 'terms-and-conditions',
    profile: 'profile',
    'profile-billing': 'profile-billing',
    'profile-billing-invoice-history': 'profile-billing',
    'profile-billing-payments': 'profile-billing',
    'profile-my-requests': 'profile-my-requests',
    'profile-my-requests-new': 'profile-my-requests-new',
    'profile-tools': 'profile-tools',
    'profile-tools-merch-digest': 'profile-tools-merch-digest',
    'profile-account': 'profile-account',
    'profile-cancellation': 'profile-cancellation',
    'profile-downloads': 'profile-downloads',
    'profile-favorites': 'profile-favorites',
    'profile-my-designs': 'profile-my-designs',
    'templates-mockup-generator': 'templates-mockup-generator',
    'templates-t-shirt-maker': 'templates-t-shirt-maker'
  }
});
