<!-- eslint-disable max-len -->
<template>
  <a
    class="edit-online-button"
    :href="designerUrl"
    target="_blank"
    rel="noopener noreferrer"
    @click="handleEditOnline"
  >
    <span>{{ $t('preview.bottomBar.section.editOnline') }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" class="edit-online-icon">
      <rect width="256" height="256" fill="none" />
      <path
        d="M164.35,136.35a8,8,0,0,1,2.46-13l44.41-15.82a8,8,0,0,0-.71-14.85L50.44,40.41a8,8,0,0,0-10,10L92.68,210.51a8,8,0,0,0,14.85.71l15.82-44.41a8,8,0,0,1,13-2.46l51.31,51.31a8,8,0,0,0,11.31,0L215.66,199a8,8,0,0,0,0-11.31Z"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="96"
        y1="12"
        x2="96"
        y2="16"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="12"
        y1="96"
        x2="16"
        y2="96"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="132"
        y1="28"
        x2="136"
        y2="16"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="28"
        y1="132"
        x2="16"
        y2="136"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
    </svg>
  </a>
</template>

<script>
export default {
  name: 'EditOnlineButton',
  props: {
    contentId: {
      type: Number,
      required: true
    }
  },
  computed: {
    designerUrl() {
      return `${process.env.DESIGNER_URL}?graphic_id=${this.contentId}`;
    }
  },
  methods: {
    handleEditOnline() {
      this.$dataLayerPush('click_preview_options', {
        preview: { optionClicked: 'Edit Online' },
        content: { id: this.contentId }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-online-button {
  align-items: center;
  background-color: white;
  border-radius: 23px;
  border: 2px solid #38b27a;
  color: #38b27a;
  cursor: pointer;
  display: none;
  font-size: 15px;
  height: 45px;
  justify-content: space-between;
  min-height: 45px;
  min-width: 164px;
  padding-bottom: 10px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 10px;

  &:hover {
    background-color: #329f6c;
    border: 2px solid #329f6c;
    color: white;
  }

  .edit-online-icon {
    width: 1rem;
  }
}
@media (min-width: 1024px) {
  .edit-online-button {
    display: flex;
  }
}
</style>
