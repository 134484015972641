<template>
  <b-col class="h-100 creditcard-container" :class="{ active: !getLoadingPayment }">
    <b-row class="mx-4" :class="{ 'h-100': getLoadingPayment }">
      <b-col>
        <div class="loading-container w-100 h-100" :class="{ active: !getLoadingPayment }">
          <Loading />
        </div>
        <form v-show="!getLoadingPayment" id="checkout-form" action="" method="POST">
          <b-row class="name-container">
            <div class="d-flex align-items-center pl-2 w-100">
              <Icon :width="iconSize" :height="iconSize" :as-original="true" icon="account" />
              <input
                v-model="name"
                type="text"
                :placeholder="$t('purchase.bluesnap.form.namePlaceholder')"
                class="w-100 mr-2 my-2"
                @blur="onBlurName"
              />
            </div>
          </b-row>
          <b-row class="number-container">
            <div class="d-flex align-items-center pl-2 w-100">
              <Icon :width="iconSize" :height="iconSize" :as-original="true" icon="credit-card" />
              <div data-bluesnap="ccn" class="ccn w-100"></div>
              <div v-if="creditCardLogo">
                <img
                  :src="creditCardLogo"
                  class="card-type-image"
                  :alt="$t('purchase.bluesnap.form.creditCardLogoAlt')"
                  loading="lazy"
                />
              </div>
            </div>
          </b-row>
          <b-row class="code-exp-container d-flex">
            <div class="d-flex align-items-center w-50 pl-2">
              <Icon
                :width="iconSize"
                :height="iconSize"
                :as-original="true"
                icon="csv-credit-card"
              />
              <div data-bluesnap="cvv" class="cvv"></div>
            </div>
            <div class="d-flex align-items-center w-50 pl-2">
              <Icon :width="iconSize" :height="iconSize" :as-original="true" icon="calendar" />
              <div data-bluesnap="exp" class="exp"></div>
            </div>
          </b-row>
          <b-row v-if="error" class="mt-2 error-message">
            <b-col class="pl-0">{{ errorMessage }}</b-col>
          </b-row>
          <b-row v-if="bluesnapError" class="mt-2 error-message">
            <b-col>
              <b-row>{{ $t(bluesnapError) }}</b-row>
              <b-row
                v-if="bluesnapErrorShowPaypalButton"
                class="cursor-pointer"
                @click="$emit('show-paypal')"
              >
                {{ $t('purchase.bluesnap.form.tryWithPayPal') }}
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2 d-flex justify-between align-items-center bottom-container">
            <b-col class="bs-text pl-0">
              {{ $t('purchase.bluesnap.form.bottomText1') }}
              <br />
              {{ $t('purchase.bluesnap.form.bottomText2') }}
              <br />
              {{ $t('purchase.bluesnap.form.bottomText3') }}
              <nuxt-link to="#contact" @click.native="$bvModal.show('contact-modal')">
                {{ $t('purchase.bluesnap.form.contactUs') }}
              </nuxt-link>
              <br />
              {{ $t('purchase.bluesnap.form.bottomText4') }}
              <br />
              <a
                target="_blank"
                href="https://home.bluesnap.com/terms-and-conditions/"
                rel="noopener noreferrer"
              >
                {{ $t('purchase.bluesnap.form.bottomText5') }}
              </a>
              |
              <a
                target="_blank"
                href="https://home.bluesnap.com/privacy-policy/"
                rel="noopener noreferrer"
              >
                {{ $t('purchase.bluesnap.form.bottomText6') }}
              </a>
              |
              <a
                target="_blank"
                href="https://home.bluesnap.com/legal/#refund"
                rel="noopener noreferrer"
              >
                {{ $t('purchase.bluesnap.form.bottomText7') }}
              </a>
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import Icon from 'UI/Icon';
import Loading from 'UI/Loading';

export default {
  components: {
    Icon,
    Loading
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    default: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      name: '',
      error: false,
      errorMessage: '',
      creditCardLogo: false,
      iconSize: 30
    };
  },
  computed: {
    ...mapState('payments', ['bluesnapToken', 'bluesnapError', 'bluesnapErrorShowPaypalButton']),
    ...mapGetters('payments', ['getLoadingPayment']),
    ...mapState('plans', ['openPlan']),
    ...mapState('vectors', ['actualVector']),
    nameValid() {
      return this.name.trim() != '';
    }
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.setBuyFunction();
      }
    }
  },
  async mounted() {
    this.SET_LOADING_PAYMENT(true);
    const idPlan = this.openPlan.id;
    const idVector = this.actualVector && this.actualVector.id ? this.actualVector.id : '';
    const data = { idPlan, idVector };
    await this.$store.dispatch('payments/GET_BLUESNAP_TOKEN', data);
    if (this.bluesnapToken) {
      this.$bluesnapLoadFields(
        () => this.SET_LOADING_PAYMENT(false),
        (err) => {
          this.SET_LOADING_PAYMENT(false);
          this.SET_BLUESNAP_ERROR(err);
        },
        (cardImage) => (this.creditCardLogo = cardImage),
        () => {
          this.SET_LOADING_PAYMENT(false);
          this.SET_BLUESNAP_ERROR(false);
        }
      );
    } else {
      this.SET_LOADING_PAYMENT(false);
    }
    if (this.default) {
      this.setBuyFunction();
    }
  },
  methods: {
    ...mapMutations('payments', ['SET_LOADING_PAYMENT', 'SET_BLUESNAP_ERROR']),
    async proccessCorrectAuthPayment() {
      const data = {
        idPlan: this.openPlan.id,
        token: this.bluesnapToken,
        firstName: this.name,
        idVector: this.actualVector?.id,
        affiliate_click_id: this.$cookies.get('vx.aff'),
        ga_client_id: this.$cookies.get('_ga')
      };
      const result = await this.$store.dispatch('payments/PROCESS_BLUESNAP_PAYMENT', data);

      if (result) {
        this.$emit('payment');
      }
    },
    buyClick() {
      if (!this.nameValid) {
        this.error = true;
        this.errorMessage = this.$t('purchase.bluesnap.errors.nameValid');
        return;
      }
      if (this.nameValid && !this.bluesnapError) {
        this.SET_LOADING_PAYMENT(true);
        const cost = this.openPlan.cost ? this.openPlan.cost : this.openPlan.price;
        const threeDSObj = {
          amount: parseFloat(cost),
          currency: 'USD',
          email: this.$auth.user.email
        };
        this.$bluesnapSubmitFields(
          threeDSObj,
          this.proccessCorrectAuthPayment,
          this.SET_BLUESNAP_ERROR
        );
      }
    },
    onBlurName() {
      this.error = !this.nameValid;
      this.errorMessage = this.nameValid ? '' : this.$t('purchase.bluesnap.errors.nameValid');
    },
    setBuyFunction() {
      this.$store.commit('payments/SET_BUY_NOW_FUNCTION', this.buyClick);
    }
  }
};
</script>

<style lang="scss" scoped>
.creditcard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #bbbbbb;
  .error-message {
    font-size: 12px;
    color: red;
  }
  .number-container,
  .code-exp-container,
  .bottom-container {
    opacity: 0;
  }
  .card-type-image {
    height: 25px;
    margin-right: 5px;
  }
  .bs-text {
    font-size: 10px;
  }
  .loading-container {
    position: absolute;
    opacity: 1;
    &.active {
      display: none;
    }
  }
  &.active {
    .ccn,
    .cvv,
    .exp {
      height: 40px;
      padding: 5px;
    }
    .cvv {
      border-right: 2px solid #c5c5c5;
    }
    .number-container,
    .code-exp-container,
    .bottom-container {
      opacity: 1;
    }
    .name-container {
      border: 2px solid #c5c5c5;
      border-radius: 4px 4px 0px 0px;
      border-bottom: 0;
      input {
        border: none;
        font-size: 15px;
        margin-left: 12px;
      }
    }
    .number-container {
      border: 2px solid #c5c5c5;
      border-bottom: 0;
    }
    .code-exp-container {
      border: 2px solid #c5c5c5;
      border-radius: 0px 0px 4px 4px;
    }
  }
}
</style>
