<template>
  <div class="mt-3 mb-3">
    <slot v-if="amountContentsShown > 0"></slot>
    <div>
      <Grid
        :items="contents"
        :max-height="rowHeight"
        :hide-uncompleted-rows="true"
        :send-vector-click="true"
        @amount-shown="amountShownContentsChange"
        @item-click="vectorClick"
      >
        <template #default="slotProps">
          <NormalVectorOverlay :data="slotProps.data" />
        </template>
      </Grid>
    </div>
    <div v-if="amountContentsShown > 0">
      <div v-if="!seeMore && showButton" class="d-flex justify-content-center my-2">
        <Button shape="outline-transparent" text="gray" @click="seeMore = true">
          {{ $t('preview.relateds.groups.seeMore') }}
        </Button>
      </div>
      <div v-else>
        <Grid
          :items="seeMoreContent"
          :max-height="rowHeight"
          :hide-uncompleted-rows="true"
          @item-click="vectorClick"
        >
          <template #default="slotProps">
            <NormalVectorOverlay :data="slotProps.data" />
          </template>
        </Grid>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'UI/Button';
import Grid from '@/components/Common/Grid';
import NormalVectorOverlay from '@/components/Vectors/NormalVectorOverlay';

export default {
  components: { Grid, NormalVectorOverlay, Button },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rowHeight: 200,
      amountContentsShown: 0,
      seeMore: false
    };
  },
  computed: {
    showButton() {
      return this.data.seeMore && this.data.seeMore.length > 0;
    },
    contents() {
      return this.data.content;
    },
    seeMoreContent() {
      return this.data.seeMore;
    }
  },
  methods: {
    amountShownContentsChange(amount) {
      this.amountContentsShown = amount;
    },
    async vectorClick({ item, shownListing }, e) {
      e.preventDefault();
      await this.$store.dispatch('vectors/CHANGE_PREVIEW_VECTOR', item.id);
      if (document.getElementById('preview-vector-modal')) {
        await this.$store.dispatch('vectors/OPEN_PREVIEW', { item, list: shownListing });
        document.getElementById('preview-vector-modal').scrollTop = 0;
      } else {
        this.$store.commit('vectors/SET_ACTUAL_VECTOR', { vector: null, vectorId: null });
        this.$router.push({ path: item.link });
      }
    }
  }
};
</script>
