<template>
  <div class="features-area">
    <h2 class="title">{{ $t('profile.signUp.main.access') }}</h2>
    <ul class="features-list">
      <li class="item">
        <Icon icon="more-download" color="white" :width="25" :height="26" />
        <p>{{ $t('profile.signUp.main.moreDownloads') }}</p>
      </li>
      <li class="item">
        <Icon icon="stroke" color="white" :width="30" :height="23" />
        <p>{{ $t('profile.signUp.main.accessToOnlineTools') }}</p>
      </li>
      <li class="item">
        <Icon icon="online-editor" color="white" :width="30" :height="36" />
        <p>{{ $t('profile.signUp.main.betterExperience') }}</p>
      </li>
      <li class="item">
        <Icon icon="star" color="white" :width="25" :height="26" />
        <p>{{ $t('profile.signUp.main.exclusiveContent') }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from 'UI/Icon';
export default {
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;
}
p {
  margin: 0;
}
::v-deep li:nth-last-child(2) .svg-icon {
  margin-left: -3px;
}
.features-area {
  background-image: url('https://cdn.vexels.com/img/sign-up-background.png');
  background-size: cover;
  color: white;
  border-radius: 15px 0 0 15px;

  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 2.75em 2.6em;
  gap: 1em;

  .title {
    font-size: 1.9em;
    font-weight: 600;
  }
  .features-list {
    display: grid;
    align-content: center;
    gap: 1.8em;
    .item {
      font-size: 1em;
      font-weight: 400;
      display: grid;
      grid-template-columns: 2em 1fr;
      align-items: center;
      gap: 1em;
      :first-child {
        justify-self: center;
      }
    }
  }
}
</style>
