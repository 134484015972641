export const mutations = {
  SET_SEASONAL_ACTIVE(state, active) {
    state.seasonalActive = active;
  },
  SET_TRANSPARENT_HEADER(state, value) {
    state.transparentHeader = value;
  },
  SET_HEADER_TEXT_COLOR(state, value) {
    state.headerTextColor = value || '#545454';
  },
  SET_PAGE_BACKGROUND_COLOR(state, value) {
    state.pageBackgroundColor = value || '#fff';
  }
};

export const getters = {
  isSeasonalActive(state) {
    return state.seasonalActive;
  },
  isTransparentHeader(state) {
    return state.transparentHeader;
  },
  getPageBackgroundColor(state) {
    return state.pageBackgroundColor;
  },
  getHeaderTextColor(state) {
    return state.headerTextColor;
  },
  hasCustomBackgroundColor(state) {
    return !(state.pageBackgroundColor == '#fff' || state.pageBackgroundColor == '');
  }
};

export const actions = {
  async SET_PRISMIC_PAGE_CONFIGURATION({ commit }, config) {
    const { header_text_color, header_transparent, page_background_color } = config;
    await commit('SET_TRANSPARENT_HEADER', header_transparent);
    await commit('SET_HEADER_TEXT_COLOR', header_text_color);
    await commit('SET_PAGE_BACKGROUND_COLOR', page_background_color);
  }
};

export const state = () => ({
  seasonalActive: false,
  transparentHeader: false,
  pageBackgroundColor: '',
  headerTextColor: ''
});
