<template>
  <span>
    <button class="close" :class="{ 'close-inverse': inverse }" @click="closeModal">
      <Icon icon="close" color="white" />
    </button>
  </span>
</template>

<script>
import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  props: {
    modalName: {
      type: String,
      required: true
    },
    inverse: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('user/SET_SHOW_MESSAGE_MODAL', false);
      this.$bvModal.hide(this.modalName);
    }
  }
};
</script>

<style lang="scss" scoped>
.close {
  outline: none;
  position: absolute;
  right: -20px;
  top: 15px;
  background-color: #ffffff40;
  width: 30px;
  border-radius: 50%;
  justify-content: center;
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  color: white;
  opacity: 1;
  font-weight: 400;
  z-index: 100;
  @include md {
    right: 20px;
    top: 20px;
    background-color: #505050;
  }
}

.close:hover {
  background-color: #27272740;
  @include md {
    background-color: #969696;
  }
}

.close-inverse {
  background-color: #27272740;
}

.close-inverse:hover {
  background-color: #27272793;
}
</style>
