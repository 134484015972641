<template>
  <div :class="['overlay', { 'is-active': createFoldersActive }]" :style="overlayStyles">
    <div>
      <div v-if="isDownloaded" class="overlay-downloaded">
        <span
          ><img
            src="https://cdn.vexels.com/img/icons/green-circled-tick.svg"
            class="check-icon"
            :alt="$t('preview.main.content.downloadedIconAlt')"
            height="28"
            width="28"
            loading="lazy"
          /><span class="downloaded-text">
            {{ $t('preview.main.content.alreadyDownloaded') }}
          </span>
        </span>
      </div>
      <div class="overlay-bg" :style="overlayStyles"></div>

      <div v-if="data.attributesText" class="attribute-container">
        <span
          v-for="(attributeText, index) in attributesText"
          :key="index"
          :class="{ text: !isAiContent }"
        >
          <template v-if="!isAiContent">
            {{ attributeText }}
          </template>
        </span>
      </div>

      <div v-if="isAiContent" class="attribute-container">
        <AIIcon />
      </div>

      <div class="tags-container">
        <IconTag
          v-if="isPremium && !isMerchOnly"
          :text="$t('main.vectors.commonOverlay.text')"
          src="https://cdn.vexels.com/img/icons/crown.svg"
          :alt="$t('preview.main.content.crownAlt')"
          :grid-tag="true"
        />
        <IconTag
          v-if="isMerchOnly"
          :text="$t('main.vectors.commonOverlay.text2')"
          src="https://cdn.vexels.com/img/icons/merch_only.svg"
          :alt="$t('preview.main.content.merchOnlyIconAlt')"
          :width="28"
          :grid-tag="true"
        />
      </div>

      <div class="title-container">
        <h3 class="text">{{ data.name }}</h3>
      </div>
      <div class="tags-container like-container-wrapper">
        <LikeTag
          :vector-id="data.id"
          :create-folders-active="createFoldersActive"
          @toggleCreateFolderActive="(state) => toggleCreateFolderActive(state)"
        />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IconTag from '@/components/IconTag';
import LikeTag from '@/components/LikeTag';
import AIIcon from '@/components/Vectors/AIIcon';
import { mapGetters } from 'vuex';

export default {
  components: {
    IconTag,
    LikeTag,
    AIIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hoveringDiv: false,
      createFoldersActive: false
    };
  },
  computed: {
    ...mapGetters('user', ['isVectorDownloaded']),
    isDownloaded() {
      return this.isVectorDownloaded(parseInt(this.data.id));
    },
    overlayStyles() {
      return `border-radius: ${this.data.borderRadius}px;`;
    },
    isPremium() {
      if (!this.data.attributes) return false;
      return this.data.attributes.some((el) => Number(el.id) == this.$g('PREMIUM_ATTRIBUTE_CODE'));
    },
    isMerchOnly() {
      if (!this.data.attributes) return false;
      return this.data.attributes.some(
        (el) => Number(el.id) == this.$g('MERCH_ONLY_ATTRIBUTE_CODE')
      );
    },
    isAiContent() {
      const AIAttributeKey = 'ai_generated';

      return this.data.attributes?.some((attr) => attr.key === AIAttributeKey);
    },
    attributesText() {
      return this.data.attributesText;
    }
  },
  methods: {
    toggleCreateFolderActive(state) {
      this.createFoldersActive = state;
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  z-index: 2;
  div {
    z-index: 100;
  }
  .overlay-downloaded {
    background: rgba(0, 0, 0, 0.65);
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .check-icon {
      margin-right: 5px;
    }
  }
  &.is-active {
    z-index: 6;
  }
  &:hover {
    z-index: 5;
  }
  &.is-active,
  &:hover {
    .title-container,
    .like-container,
    .download-container,
    .premium-container,
    .attribute-container,
    .tags-container {
      opacity: 1;
    }
    .overlay-bg {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
      height: 70%;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    .overlay-downloaded {
      background: unset;
      .check-icon,
      .downloaded-text {
        display: none;
      }
    }
  }
}
.like-container-wrapper {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.attribute-container {
  transition: 0.4s ease;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  .text {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    padding: 2px 6px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 3px 6px #0000001a;
    -webkit-box-shadow: 0px 3px 6px #0000001a;
    -moz-box-shadow: 0px 3px 6px #0000001a;
    display: flex;
    font-size: 11px;
    color: black;
    width: max-content;
  }
}

.premium-container {
  opacity: 0;
  .text {
    margin: 2px 11px;
    opacity: 1;
  }
  .icon-container {
    width: 35px;
  }
}
.tags-container {
  opacity: 0;
}
.icon-container {
  height: 20px;
  position: absolute;
  right: 0;
  width: 38px;
  display: flex;
  align-content: center;
  top: 8px;
}
.icon-container > .icon {
  height: 100%;
  margin: auto;
}
.title-container {
  opacity: 0;
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  color: white;
  font-weight: 500;
  transition: 0.4s ease;
  width: 74%;
  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media all and (max-width: 767px) {
  .title-container {
    font-size: 15px;
  }
}
@media all and (max-width: 550px) {
  .title-container {
    font-size: 13px;
  }
}
</style>
