const middleware = {}

middleware['afterLogin'] = require('../middleware/afterLogin.js')
middleware['afterLogin'] = middleware['afterLogin'].default || middleware['afterLogin']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authRequired'] = require('../middleware/authRequired.js')
middleware['authRequired'] = middleware['authRequired'].default || middleware['authRequired']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

export default middleware
