<template>
  <div :style="{ '--text-color': textColor, '--text-size': textSizePx }">
    <nuxt-link v-if="internalLink" :class="linkClassName" :to="href" @click.native="handleClick"
      >{{ $t(value) }}
      <div v-if="pill" class="pill">{{ $t(pill) }}</div>
    </nuxt-link>
    <a
      v-else
      :class="linkClassName"
      :href="href"
      target="_blank"
      rel="noopener noreferrer"
      @click="handleClick"
      >{{ $t(value) }}
      <div v-if="pill" class="pill">{{ $t(pill) }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    internalLink: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true
    },
    textSize: {
      type: String,
      required: true
    },
    linkClassName: {
      type: String,
      default: ''
    },
    pill: {
      type: String,
      default: ''
    }
  },
  computed: {
    textSizePx() {
      if (!isNaN(this.textSize)) {
        return `${this.textSize}px`;
      }
      return this.textSize;
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', { href: this.href });
    }
  }
};
</script>
<style lang="scss" scoped>
a {
  color: var(--text-color);
  font-size: var(--text-size);
}
.pill {
  display: inline-block;
  color: white;
  background: linear-gradient(90deg, #4c7bf1 0%, #da4cf1 100%);
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
}
</style>
