<template>
  <b-row class="d-none d-md-block title mt-4">
    <b-col>
      {{ title }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.title {
  color: gray;
  font-size: 1.5em;
  font-weight: 500;
}
</style>
