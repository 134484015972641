// Local Provider configuration
const local = {
  strategy: 'local',
  init: () => {},
  login: async (ctx, payload) => {
    try {
      return await ctx.store.dispatch('auth/localLogin', payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  logout: (ctx) => {
    ctx.store.dispatch('auth/logout');
  }
};

// Google Provider configuration
const google = {
  strategy: 'google',
  init: (ctx) => {
    if (window.gapi?.auth2) {
      return;
    }
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: process.env.GOOGLE_CLIENT_ID
        })
        .then(async () => {
          window.gapi.auth2.getAuthInstance().currentUser.listen(async (user) => {
            const id_token = user.getAuthResponse().id_token;
            await ctx.store.dispatch('auth/googleLogin', { id_token });
          });
        });
    });
  },
  login: () => {
    window.gapi.auth2
      .getAuthInstance()
      .signIn({
        scope: 'openid profile email'
      })
      .then(() => {
        return true;
      });
  },
  logout: () => {
    if (window.gapi?.auth2) {
      const instance = window.gapi.auth2.getAuthInstance();
      if (instance) {
        instance.signOut();
      }
    }
  },
  initOneTap: (callback) => {
    if (!window.google) return;
    window.google.accounts.id.initialize({
      client_id: process.env.GOOGLE_CLIENT_ID,
      callback,
      context: 'signin'
    });
    window.google.accounts.id.prompt();
  }
};

// Facebook Provider configuration
const facebook = {
  strategy: 'facebook',
  init: () => {},
  login: (ctx) => {
    window.FB.login(
      async function (response) {
        if (response.status === 'connected') {
          const accessToken = response.authResponse.accessToken;
          await ctx.store.dispatch('auth/facebookLogin', { accessToken });
        }
      },
      { scope: 'public_profile,email' }
    );
  },
  logout: () => {
    window.FB?.getLoginStatus((status) => {
      if (status === 'connected') {
        window.FB.logout();
      }
    });
  }
};

const providers = [local, google, facebook];

export default (ctx, inject) => {
  inject('auth', auth(ctx));
};

const auth = (ctx) => {
  return {
    init() {
      providers.forEach((p) => p.init(ctx));
    },
    get loggedIn() {
      return ctx.store.state.auth.user && ctx.store.state.auth.token;
    },
    get user() {
      return ctx.store.state.auth.user;
    },
    loginWith: async (strategy, payload) => {
      const provider = providers.find((p) => p.strategy === strategy);
      if (provider) {
        await provider.login(ctx, payload);
        if (ctx.route.path === '/login') {
          window.location.href = '/';
        }
      } else {
        console.error('Not configured provider.');
      }
    },
    logout: () => {
      providers.forEach((p) => p.logout(ctx));
      window.location.href = '/';
    },
    fetchUser: async (forceLoad = false, callback = () => {}) => {
      const strategy = ctx.app.$cookies.get('vx.strategy');
      if (strategy) {
        const token = ctx.app.$cookies.get(`vx.token.${strategy}`);
        if (token) {
          await ctx.store.dispatch('auth/loadUser', { token, strategy, forceLoad });
          callback(true);
        } else {
          callback(false);
        }
      } else {
        callback(false);
      }
    },
    getProvider: (strategy) => {
      const provider = providers.find((p) => p.strategy === strategy);
      if (provider) {
        return provider;
      }
      return null;
    }
  };
};
