<template>
  <div ref="preview-image">
    <div
      v-if="!actualVector"
      class="vx-carousel-container w-100 d-flex align-items-center justify-content-center"
    >
      <div class="preview-skeleton-wrapper">
        <div class="preview-picture-skeleton skeleton-box"></div>
        <div class="preview-info-skeleton-container">
          <div class="preview-info-attributes-skeleton-wrapper">
            <div class="preview-info-icons-wrapper-skeleton">
              <div class="preview-info-icon-skeleton skeleton-box"></div>
              <div class="preview-info-icon-skeleton skeleton-box"></div>
              <div class="preview-info-icon-skeleton skeleton-box"></div>
            </div>
            <div class="preview-info-attributes-skeleton skeleton-box"></div>
          </div>
          <UiButton
            :enabled="false"
            class="download-btn download-btn-skeleton"
            min-width="250"
            min-height="45"
            height="45"
            >{{ $t('preview.download.main.download') }}</UiButton
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="vx-carousel-container preview-actual-vector-rendering-span-wrapper"
        :class="{ 'bg-gray': actualVector.svg }"
      >
        <div
          class="preview-actual-vector-rendering-span"
          :class="{ 'd-none': imageLoaded || actualVector.svg }"
        />
        <div
          class="container-previews d-flex flex-column flex-md-row-reverse"
          :class="{ container: actualVector.svg }"
          @contextmenu.prevent="$emit('show-purchase-flow')"
        >
          <div
            class="container-preview-image d-flex justify-content-center rounded"
            :class="{ 'gray-background': isAvailableRenderCarousel }"
          >
            <picture class="picture-preview-image">
              <EditColor
                v-if="actualVector.svg"
                :vector-image="actualVector.list"
                :vector-height="actualVector.height"
                :vector-width="actualVector.width"
                :alt-text="actualVector.name"
              />
              <source
                v-for="{ resolution, size } in imagesSize"
                :key="resolution"
                :media="`(max-width: ${resolution}px)`"
                :srcset="`${currentImage}?w=${size}&fmt=webp`"
              />
              <img
                v-if="!actualVector.svg"
                :src="currentImage"
                class="preview-image img-fluid"
                :alt="actualVector.name"
                no-webp
                height="667"
                :width="(667 * actualVector.width) / actualVector.height"
                @load="imageLoad"
              />
            </picture>
            <Overlay :data="actualVector" />
          </div>
          <div
            v-if="isAvailableRenderCarousel"
            width="200px"
            class="container-preview-gallery flex-md-column pb-2 pb-md-0 pr-md-2"
          >
            <template v-for="({ image }, index) in actualVector.previews">
              <Thumbnail
                v-if="image"
                :key="index"
                :src="image"
                :alt="actualVector.name"
                :is-active="currentIndex === index"
                @click="handleClickThumbnail(index)"
              />
            </template>
          </div>
        </div>
      </div>
      <nav id="preview-bottom" class="preview-bottom d-flex justify-content-between">
        <div class="icons-container d-flex">
          <AttributeIcons v-if="displayIcons" :icons="actualVector.attributesIcons" />
          <VectorDetailsInfo
            :vector-image="actualVector.image"
            :date-added="actualVector.dateAdded"
            :file-type="actualVector.type"
            :licence="actualVector.licence.licenceCode"
            :is-ai="isAiContent"
          />
          <div v-if="hasStyle" class="vx-styles-container">
            <ul>
              <li class="vx-styles-item">
                <nuxt-link
                  :to="$trailingSlash(`${localePath('styles')}/${style.link}`)"
                  class="vx-styles-image-container"
                  @click.native="onClickStyle()"
                >
                  <img :src="style.thumbnail" :alt="style.name" loading="lazy" />
                  <span class="exp-bg custom-btn">{{
                    $t('preview.bottomBar.styles.seeStyles')
                  }}</span>
                </nuxt-link>
                <p class="vx-styles-name">{{ style.name }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex flex-col md:flex-row items-center justify-center gap-4">
          <EditOnlineButton v-if="actualVector.isSvg" :content-id="actualVector.id" />
          <div>
            <DownloadBtn
              v-show="!getDownloaded"
              :content-id="parseInt(actualVector.id)"
              @show-purchase-flow="$emit('show-purchase-flow')"
            />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AttributeIcons from '@/components/Vectors/AttributeIcons';
import UiButton from 'UI/Button';
import VectorDetailsInfo from '@/components/Vectors/VectorDetailsInfo';
import DownloadBtn from '@/components/Preview/Download/DownloadBtn';
import Overlay from '@/components/Preview/Overlay';
import Thumbnail from 'Components/Preview/Carousel/Thumbnail';
import EditOnlineButton from 'Components/Preview/Carousel/EditOnlineButton';

const EditColor = () => import('@/components/Preview/EditColor');

export default {
  name: 'PreviewContent',
  components: {
    AttributeIcons,
    DownloadBtn,
    EditColor,
    EditOnlineButton,
    Overlay,
    Thumbnail,
    UiButton,
    VectorDetailsInfo
  },
  data() {
    return {
      imageLoaded: false,
      imagesSize: [
        { resolution: 360, size: 330, minResolution: 0 },
        { resolution: 375, size: 345, minResolution: 361 },
        { resolution: 414, size: 384, minResolution: 376 },
        { resolution: 768, size: 738, minResolution: 415 },
        { resolution: 834, size: 804, minResolution: 769 },
        { resolution: 1024, size: 994, minResolution: 835 },
        { resolution: 1366, size: 1321, minResolution: 1025 },
        { resolution: 1536, size: 1506, minResolution: 1367 },
        { resolution: 1920, size: 1800, minResolution: 1537 }
      ],
      currentIndex: null
    };
  },
  head() {
    let preloadImages = [];
    if (this.actualVector) {
      if (!this.actualVector.isSvg) {
        this.imagesSize.map((item) => {
          preloadImages.push({
            rel: 'preload',
            as: 'image',
            href: `${this.actualVector.image}?w=${item.size}&fmt=webp`,
            media: `(min-width: ${item.minResolution}px) and (max-width: ${item.resolution}px)`,
            type: 'image/webp',
            fetchpriority: 'high'
          });
        });
      } else {
        preloadImages.push({
          rel: 'preload',
          as: 'image',
          href: this.actualVector.image,
          fetchpriority: 'high'
        });
      }

      return {
        link: [...preloadImages]
      };
    }
  },
  jsonld() {
    if (!this.actualVector) {
      return null;
    }
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'ImageObject',
          '@id': this.actualVector.image,
          url: this.actualVector.image,
          contentUrl: this.actualVector.image,
          caption: this.actualVector.name
        }
      ]
    };
  },
  computed: {
    ...mapGetters('vectors', ['actualVector']),
    ...mapGetters('downloads', ['getDownloaded']),

    displayIcons() {
      return this.actualVector.attributesIcons?.length;
    },
    hasStyle() {
      return this.actualVector.styles && this.actualVector.styles[0];
    },
    style() {
      return this.actualVector.styles && this.actualVector.styles[0]
        ? this.actualVector.styles[0]
        : null;
    },
    validImages() {
      return this.actualVector.previews.filter((preview) => preview.image);
    },
    currentImage() {
      const candidateImage = this.actualVector?.previews[this.currentIndex]?.image;
      const secondaryCandidateImage = this.actualVector.image;

      if (this.actualVector.isSvg) {
        return secondaryCandidateImage;
      }
      if (candidateImage) return candidateImage;
      return secondaryCandidateImage;
    },
    isAvailableRenderCarousel() {
      return !this.actualVector.isSvg && this.validImages.length > 1;
    },
    isAiContent() {
      const AIAttributeKey = 'ai_generated';
      return this.actualVector.attributes.some(
        (attr) => attr.key === AIAttributeKey || attr.slug === AIAttributeKey
      );
    }
  },
  watch: {
    actualVector() {
      this.imageLoaded = false;
      this.initCurrentIndex();
    }
  },
  created() {
    this.$store.dispatch('vectors/SET_SHOW_EDIT_COLOR', false);
  },
  mounted() {
    this.initCurrentIndex();
  },
  methods: {
    async initCurrentIndex() {
      if (this.actualVector) {
        const candidateIndex = this.actualVector.previews.findIndex((preview) => preview.image);
        this.currentIndex = candidateIndex == -1 ? null : candidateIndex;
      }
    },
    imageLoad() {
      this.imageLoaded = true;
    },
    onClickStyle() {
      this.$bvModal.hide('preview-vector-modal');
    },
    handleClickThumbnail(index) {
      this.currentIndex = index;
    },
    handleClickFiverrButton() {
      this.$dataLayerPush('click_fiverr');
    }
  }
};
</script>
<style lang="scss" src="../Preview.scss" scoped></style>
<style lang="scss" scoped>
.opacity-1 {
  opacity: 1 !important;
}
.preview-skeleton-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  @include md {
    gap: 16px;
    margin: 8px 0px 12px 0px;
  }
  .preview-picture-skeleton {
    width: 80%;
    height: 560px;
    @include xl2 {
      height: 460px;
    }
    @include xl {
      height: 320px;
    }
    @include lg {
      height: 260px;
    }
    @include md {
      height: 200px;
    }
  }
  .preview-info-skeleton-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .preview-info-attributes-skeleton-wrapper {
      display: flex;
      gap: 12px;
    }
    .preview-info-attributes-skeleton {
      width: 12vw;
      max-width: 200px;
      height: 32px;
      margin-right: 8px;
      @include md {
        width: 60vw;
      }
    }
    .preview-info-icons-wrapper-skeleton {
      display: flex;
      gap: 8px;
      flex-direction: row;
      justify-content: flex-start;
      width: fit-content;
    }
    .preview-info-icon-skeleton {
      width: 34px;
      height: 32px;
    }
    .download-btn-skeleton {
      @include xl {
        display: none;
      }
    }
  }
}
</style>
<style lang="scss">
.container-previews {
  --gap: 0.5em;
  max-height: 60vh;
  gap: var(--gap);

  .container-preview-image {
    position: relative;
    flex: 0 0 40vh;
    min-height: 0;
    @include min-md {
      flex: 1 1 20em;
    }
    .picture-preview-image {
      display: flex;
    }
    .preview-image {
      transition: opacity 0.1s ease-out;
      pointer-events: none;
      object-fit: contain;
    }
    &.gray-background {
      background: #f3f3f3 0% 0% no-repeat padding-box;
    }
  }
  .container-preview-gallery {
    overflow: auto;
    display: flex;
    gap: var(--gap);
    &::-webkit-scrollbar {
      display: none;
    }
    .component-thumbnail {
      flex: 0 0 auto;
      width: auto;
      @include min-md {
        width: 10em;
        height: auto;
      }
    }
  }
}
#container-preview-svg {
  min-height: 280px;
  padding-top: 20px;
  padding-bottom: 20px;
  @include xl2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.vx-styles-image-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 45px;
  height: 45px;
  transition: all 0.5s;
  z-index: 5;
  &:hover {
    img {
      filter: brightness(50%);
    }
  }

  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    transition: all 0.5s;
    border-radius: 4px;
    border-radius: 50px;
  }

  .custom-btn {
    position: absolute;
    left: 50px;
    bottom: 10px;
    padding: 2.5px 17px;
    background: $defaultColor;
    width: max-content;
    border-radius: 15px;
    font-size: 14px;
    color: white;
    opacity: 0;
    transition: all 0.5s;
    @include md {
      opacity: 0;
    }
  }
}

.vx-styles-item {
  transition: margin 0.5s;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  &:hover {
    .vx-styles-name {
      opacity: 0;
      @include md {
        opacity: 1;
      }
    }
  }
}

.vx-styles-name {
  margin: 0;
  margin-right: 10px;
  @include md {
    opacity: 1;
    left: 55px;
    position: absolute;
  }
}

.vx-styles-container {
  display: flex;
  align-items: center;
  border-left: 1px solid #e2e2e2;
  margin-left: 15px;
  @include md {
    padding: 0;
    margin-left: 0;
    border-left: none;
  }
  ul {
    padding-left: 15px;
    list-style: none;
    display: flex;
    @include md {
      padding: 0;
      flex-wrap: wrap;
    }
  }
}

.icons-preview {
  display: flex;
  align-items: center;
}
.vx-download-container {
  margin-left: auto;
}
.preview-bottom {
  padding: 15px 0px;
  flex-flow: column-reverse wrap;
  gap: 0.5rem;
  @include min-xl {
    flex-flow: row;
  }
  .icons-container {
    @include md {
      flex-flow: column;
      margin: 15px 0;
    }
  }
}
.container-download-options .download-btn .vexels-button {
  @include min-xl {
    min-width: 250px !important;
  }
}
.canvas-container {
  min-height: 250px;
  max-width: 250px;
  width: 100%;
  margin: auto;
  svg {
    height: 100%;
    width: 100%;
  }
}
.editor-color-open {
  svg {
    path {
      &:hover {
        stroke: black;
        stroke-width: 4px;
      }
    }
  }
}
.bg-gray {
  background-color: #efefef;
}
.preview-actual-vector-rendering-span-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-actual-vector-rendering-span {
    position: absolute;
    width: calc(calc(90vw - 50px) * 0.8);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    height: 100%;
  }
}
.vx-carousel-container {
  .carousel {
    padding-bottom: 30px;
    position: relative;
    width: max-content;
    max-width: 100%;
    .carousel-inner {
      border-radius: 5px;
      height: 100%;
      width: max-content;
      max-width: 100%;
      .carousel-item {
        height: 100%;
        width: auto;
        max-width: 100%;
        img {
          height: 60vh;
          width: auto !important;
        }
      }
    }
  }
  .carousel-control-next {
    width: auto;
    background-color: rgba(142, 142, 142, 0.5);
    padding: 10px;
    height: 50px;
    top: calc(50% - 25px);
  }
  .carousel-control-prev {
    width: auto;
    background-color: rgba(142, 142, 142, 0.5);
    padding: 10px;
    height: 50px;
    top: calc(50% - 25px);
  }
  .carousel-indicators {
    li {
      width: 10px;
      height: 10px;
      border-radius: 200%;
      background-color: #bba6a6;
    }
    .active {
      background-color: #ff5a5f;
    }
  }
}

@media all and (min-width: 768px) {
  .vx-styles-item {
    &:hover {
      .vx-styles-image-container {
        width: 60px;
        transition: width 0.5s;
      }
      .custom-btn {
        opacity: 1;
        transition: all 0.5s;
        left: 60px;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .vx-styles-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 50%;
  }
  .vx-styles-image-container {
    position: initial;
    margin: 0;
    width: 30%;
  }
}

.lh-1 {
  line-height: 1;
}

.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-4 {
  gap: 1rem;
}

@media (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row;
  }
}
</style>
