<template>
  <div
    v-if="actualVector.showEditColors"
    class="justify-content-center align-items-center vx-btns-svg"
  >
    <div class="vx-btn-container" @click="openEditColor">
      <button type="button" class="icon-edit btn p-0 border-l">
        <img
          src="https://cdn.vexels.com/img/edit-colors.svg"
          width="36"
          height="36"
          :alt="$t('main.preview.editColor.colorsWheelAlt')"
          loading="lazy"
        />
      </button>

      <div>
        <p class="tooltip-description d-none d-md-block">
          {{ $t('main.preview.editColor.tooltipEdit') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('vectors', ['actualVector', 'showEditColor'])
  },
  methods: {
    openEditColor() {
      this.$dataLayerPush('click_preview_options', {
        preview: { optionClicked: 'Edit Colors' },
        content: { id: this.actualVector.id }
      });
      if (!this.showEditColor) {
        this.$store.dispatch('vectors/LOAD_SVG_CONTENT', this.actualVector.id).then(() => {
          this.$store.dispatch('vectors/EDIT_COLOR_DATA');
        });
      }
      this.$store.dispatch('vectors/SET_SHOW_EDIT_COLOR', !this.showEditColor);
    },
    clickShirtMaker() {
      this.$dataLayerPush('click_preview_options', {
        preview: { optionClicked: 'T-Shirt Maker' },
        content: { id: this.actualVector.id }
      });
      const data = { artId: this.actualVector.id };
      localStorage.setItem('vx-try-editor', JSON.stringify(data));
    }
  }
};
</script>
<style scoped lang="scss">
.vx-btns-svg {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  z-index: 20;
  .vx-btn-container {
    padding: 4px 9px;
    border-radius: 7px;
    cursor: pointer;
    align-items: center;
    display: flex;
    background: #fff;
    margin: 0 10px 0 0;
    .icon-edit img {
      width: 28px;
      height: 28px;
    }
    .tooltip-description {
      color: #414141;
      font-size: 13px;
      margin: 0 0 0 10px;
    }
    &:hover {
      background: rgb(249, 249, 249);
    }
  }
}
</style>
