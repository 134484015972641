export function validatePreviewId(actualVector, fullPath, urlBuilder, redirect) {
  if (!actualVector) {
    return false;
  }

  let actualUrl = urlBuilder('preview', {
    id: actualVector.id,
    canonizedName: actualVector.canonizedName,
    type: actualVector.idType
  });

  return validateRedirectUrl(actualUrl, fullPath, redirect);
}

export function validateCategoryId(category, url, params, urlBuilder, redirect) {
  if (!category || (Array.isArray(category) && !category.length)) {
    return false;
  }

  let actualUrl = urlBuilder('category', {
    id: category.id,
    slug: category.slug,
    page: params.page
  });

  return validateRedirectUrl(actualUrl, url, redirect);
}

export function validateEmail(value) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
}

function validateRedirectUrl(actualUrl, fullPath, redirect) {
  let url = fullPath.split('?');
  let urlCompare = url[0];
  let urlQueryParams = url[1];

  if (actualUrl != urlCompare) {
    if (urlQueryParams) {
      redirect(301, `${actualUrl}?${urlQueryParams}`);
    } else {
      redirect(301, actualUrl);
    }
  }

  return true;
}
export function validateUrl(urlString) {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
}

export function getDateFromDate(date) {
  let [day, month, year] = date.split(' ')[0].split('/');
  let formatDate = new Date(year, month - 1, day);
  return formatDate;
}

export function modifyDate(date, type, quantity) {
  switch (type) {
    case 'Y':
      return _modifyYear(date, quantity);
    case 'M':
      return _modifyMonth(date, quantity);
    case 'D':
      return _modifyDay(date, quantity);
    default:
      return date;
  }
}

function _modifyYear(date, quantity) {
  return new Date(date.setFullYear(date.getFullYear() + quantity));
}

function _modifyMonth(date, quantity) {
  return new Date(date.setMonth(date.getMonth() + quantity));
}

function _modifyDay(date, quantity) {
  return new Date(date.setDate(date.getDate() + quantity));
}

export function formatDate(date, format, lang = 'en') {
  let year = date.getFullYear();
  let month = _getMonth(date);
  let day = date.getDate().toString().padStart(2, '0');

  switch (format) {
    case 'MMDDYYYY':
      return date.toLocaleString(lang, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    case 'YYYY':
      return year;
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`;
    case 'YYYY-MM':
      return `${year}-${month}`;
    case 'MMMM':
      return date.toLocaleString(lang, {
        month: 'long'
      });
    case 'DD/MM/YYYY':
      return `${day}/${month}/${year}`;
    case 'MM/DD/YYYY':
      return `${month}/${day}/${year}`;
    case 'YYYY/MM/DD':
      return `${year}/${month}/${day}`;
    default:
      return date;
  }
}

function _getMonth(date) {
  return date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
}

export function dateBetweenRange(date, dateRangeStart, dateRangeEnd) {
  date = new Date(date.replace(/-/g, '/'));
  dateRangeStart = new Date(dateRangeStart.replace(/-/g, '/'));
  dateRangeEnd = new Date(dateRangeEnd.replace(/-/g, '/'));

  return date >= dateRangeStart && date <= dateRangeEnd;
}

export function isMerchPlansOrHomePage(nameChecker) {
  return (
    nameChecker('merch-plans') ||
    nameChecker('merch-plans-keyword') ||
    nameChecker('index') ||
    nameChecker('home')
  );
}

export function getOGImage(experience) {
  let image = '';

  if (experience == 'merch') {
    image = 'https://cdn.vexels.com/img/Open-Graph-Merch-Listings-CustomListings-Groupers.jpg';
  } else if (experience == 'design') {
    image = 'https://cdn.vexels.com/img/Open-Graph-Design-Listings-CustomListings-Groupers.jpg';
  } else {
    image = 'https://cdn.vexels.com/img/Open-Graph-Home-Vexels.jpg';
  }

  return image;
}

export function msToHMS(ms) {
  let seconds = ms / 1000;
  const hours = prependCero(parseInt(seconds / 3600));
  seconds = seconds % 3600;
  const minutes = prependCero(parseInt(seconds / 60));
  seconds = prependCero(Math.round(seconds % 60));
  return hours + ':' + minutes + ':' + seconds;
}

const prependCero = (num) => {
  return num < 10 ? '0' + num : num;
};

export function getWindowScrollTop() {
  return window.pageYOffset !== undefined
    ? window.pageYOffset
    : (document.documentElement || document.body.parentNode || document.body).scrollTop;
}

export function getMonthlyPriceEntire(price) {
  return Math.trunc(price / 12);
}
export function getMonthlyPriceDecimal(price) {
  const monthlyPrice = price / 12;
  return monthlyPrice.toFixed(2).split('.')[1];
}
export function getMonthlyAnnualCommitmentEntire(price) {
  return Math.trunc(price);
}
export function getMonthlyAnnualCommitmentDecimal(monthlyPrice) {
  return Math.trunc((monthlyPrice - Math.trunc(monthlyPrice)) * 100);
}
export function parsePlanAreasSlug(plansName) {
  return plansName.toLowerCase().split(' ').join('-');
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function parseKeysToCamelCase(obj) {
  const camelCaseObject = Array.isArray(obj) ? [] : {};
  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = key.replace(/_(\w)/g, (match, letter) => letter.toUpperCase());
    const value = obj[key];
    acc[camelCaseKey] =
      typeof value === 'object' && value !== null ? parseKeysToCamelCase(value) : value;
    return acc;
  }, camelCaseObject);
}

export function updateKeyNames(obj, keyMap) {
  const newObj = {};
  for (let key in obj) {
    const newKey = keyMap[key] || key;
    newObj[newKey] = obj[key];
  }
  return newObj;
}
