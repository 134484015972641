<template>
  <div v-if="isOpen" v-click-outside="hideDropdown" class="dropdown" :style="wrapperStyles">
    <Keywords
      :prismic-dimension="prismicDimension"
      :prismic-keyword="prismicKeyword"
      :list="processedKeywords"
      @open-close-bar="openCloseBar"
    />
    <div v-if="styles.length > 0 || collections.length > 0" class="footer-autocomplete">
      <hr class="divider-autocomplete" />
      <Collections :list="processedCollections" @open-close-bar="openCloseBar" />
      <Styles :list="processedStyles" @open-close-bar="openCloseBar" />
    </div>
  </div>
</template>

<script>
import Collections from './Collections';
import Styles from './Styles';
import Keywords from './Keywords';
import { mapGetters } from 'vuex';
export default {
  components: {
    Collections,
    Styles,
    Keywords
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    isBanner: {
      type: Boolean,
      default: false,
      required: false
    },
    searchBarUid: {
      type: Number,
      required: true
    },
    prismicSuggestions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    prismicKeyword: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapGetters('experience', ['getExperience']),
    ...mapGetters('searchBar', [
      'getDefaultSuggestions',
      'getTypingSuggestions',
      'getPrismicTypingSuggestions'
    ]),
    isOpen() {
      const hasKeywords = this.processedKeywords.length > 0;
      const hasCollections = this.processedCollections.length > 0;
      const hasStyles = this.processedStyles.length > 0;
      return this.open && (hasKeywords || hasCollections || hasStyles);
    },
    hasPrismicTypingSugestions() {
      return (
        this.getPrismicTypingSuggestionsId?.keywords?.length ||
        this.getPrismicTypingSuggestionsId?.collections?.length ||
        this.getPrismicTypingSuggestionsId?.styles?.length
      );
    },
    hasPrismicSugestions() {
      return (
        this.prismicSuggestions?.keywords?.length ||
        this.prismicSuggestions?.collections?.length ||
        this.prismicSuggestions?.styles?.length
      );
    },
    getPrismicTypingSuggestionsId() {
      return this.getPrismicTypingSuggestions(this.searchBarUid);
    },
    keywords() {
      const value = this.prismicDimension
        ? this.getPrismicKeySuggestion('keywords')
        : this.getTypingSuggestions
        ? this.getTypingSuggestions.keywords
        : this.getDefaultSuggestions
        ? this.getDefaultSuggestions.keywords
        : [];

      return value.slice(0, this.$g('SEARCH_BAR_KEYWORDS_MAX_AMOUNT'));
    },
    collections() {
      const value = this.prismicDimension
        ? this.getPrismicKeySuggestion('collections')
        : this.getTypingSuggestions
        ? this.getTypingSuggestions.collections
        : this.getDefaultSuggestions
        ? this.getDefaultSuggestions.collections
        : [];
      return value.slice(0, this.$g('SEARCH_BAR_COLLECTIONS_MAX_AMOUNT'));
    },
    styles() {
      const value = this.prismicDimension
        ? this.getPrismicKeySuggestion('styles')
        : this.getTypingSuggestions
        ? this.getTypingSuggestions.styles
        : this.getDefaultSuggestions
        ? this.getDefaultSuggestions.styles
        : [];
      return value.slice(0, this.$g('SEARCH_BAR_STYLES_MAX_AMOUNT'));
    },
    elementSelected: {
      get() {
        return this.$store.getters['searchBar/getElementSelected'];
      },
      set(value) {
        if (value) {
          if (value.type === 'keywords') value.data = this.keywords[value.index];
          if (value.type === 'collections') value.data = this.collections[value.index];
          if (value.type === 'styles') value.data = this.styles[value.index];
        }
        this.$store.commit('searchBar/SET_ELEMENT_SELECTED', value);
      }
    },
    processedKeywords() {
      return this.keywords.map((el, i) => {
        return { ...el, selected: this.selectedType == 'keywords' && i == this.selectedIndex };
      });
    },
    processedCollections() {
      return this.collections.map((el, i) => {
        return { ...el, selected: this.selectedType == 'collections' && i == this.selectedIndex };
      });
    },
    processedStyles() {
      return this.styles.map((el, i) => {
        return { ...el, selected: this.selectedType == 'styles' && i == this.selectedIndex };
      });
    },
    selectedType() {
      return this.elementSelected ? this.elementSelected.type : null;
    },
    selectedIndex() {
      return this.elementSelected ? this.elementSelected.index : null;
    },
    selectedIsTypeFirst() {
      if (!this.selectedType) return false;
      return this.selectedIndex === 0;
    },
    selectedIsTypeLast() {
      if (this.selectedType === 'keywords')
        return this.selectedIndex === Math.max(this.keywords.length - 1, 0);
      if (this.selectedType === 'collections')
        return this.selectedIndex === Math.max(this.collections.length - 1, 0);
      if (this.selectedType === 'styles')
        return this.selectedIndex === Math.max(this.styles.length - 1, 0);
      return false;
    },
    nextSelectionType() {
      if (this.selectedType === 'keywords') {
        if (this.collections.length <= 0 && this.styles.length <= 0) return null;
        if (this.collections.length > 0) return 'collections';
        if (this.styles.length > 0) return 'styles';
      }
      if (this.selectedType === 'collections') {
        if (this.keywords.length <= 0 && this.styles.length <= 0) return null;
        if (this.styles.length > 0) return 'styles';
        return null;
      }
      if (this.selectedType === 'styles') {
        return null;
      }
      if (this.keywords.length > 0) return 'keywords';
      if (this.collections.length > 0) return 'collections';
      if (this.styles.length > 0) return 'styles';
      return null;
    },
    prevSelectionType() {
      if (this.selectedType === 'keywords') {
        return null;
      }
      if (this.selectedType === 'collections') {
        if (this.keywords.length <= 0 && this.styles.length <= 0) return null;
        if (this.keywords.length > 0) return 'keywords';
        return null;
      }
      if (this.selectedType === 'styles') {
        if (this.collections.length <= 0 && this.keywords.length <= 0) return null;
        if (this.collections.length > 0) return 'collections';
        if (this.keywords.length > 0) return 'keywords';
      }
      if (this.styles.length > 0) return 'styles';
      if (this.collections.length > 0) return 'collections';
      if (this.keywords.length > 0) return 'keywords';
      return null;
    },
    selectedTypeMaxIndex() {
      if (this.selectedType === 'keywords') return Math.max(this.keywords.length - 1, 0);
      if (this.selectedType === 'collections') return Math.max(this.collections.length - 1, 0);
      if (this.selectedType === 'styles') return Math.max(this.styles.length - 1, 0);
      return 0;
    },
    wrapperStyles() {
      return 'border-top: none';
    }
  },
  watch: {
    open(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.elementSelected = null;
        this.$store.commit('searchBar/SET_HAS_SELECTED_SUGGESTION', false);
      }
    },
    isOpen(newValue) {
      this.$store.commit('searchBar/SET_SHOWING_DROPDOWN', {
        id: this.searchBarUid,
        value: newValue
      });
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyPressed);
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyPressed);
  },
  methods: {
    getKeyArrayFromObject(obj, key) {
      return obj?.[key]?.length ? obj[key] : undefined;
    },
    getPrismicKeySuggestion(key) {
      const suggestionByKey = this.hasPrismicTypingSugestions
        ? this.getKeyArrayFromObject(this.getPrismicTypingSuggestionsId, key)
        : this.hasPrismicSugestions
        ? this.getKeyArrayFromObject(this.prismicSuggestions, key)
        : this.getKeyArrayFromObject(this.getDefaultSuggestions, key);
      return suggestionByKey || [];
    },
    hideDropdown(event) {
      if (!event.target.closest('.search-bar')) this.openCloseBar(false);
    },
    openCloseBar(status) {
      this.$emit('open-close-bar', status);
    },
    enterPressed() {
      if (this.elementSelected) {
        if (this.selectedType == 'keywords') {
          this.$emit('search');
        } else if (this.selectedType == 'collections') {
          const collection = this.collections[this.selectedIndex];
          const path = this.$urlBuilder('collection', {
            slug: collection.slug
          });
          this.$router.push({ path });
        } else if (this.selectedType == 'styles') {
          const style = this.styles[this.selectedIndex];
          const path = this.$urlBuilder('style', { slug: style.slug });
          this.$router.push({ path });
        }
        this.$emit('open-close-bar', false);
      }
    },
    keyPressed(e) {
      const code = e.keyCode;
      if (!this.isOpen) return;
      if (code == 13) {
        e.preventDefault();
        this.enterPressed();
      } else if ([37, 38, 39, 40].includes(code)) {
        const dir = code == 40 ? 'down' : code == 38 ? 'up' : code == 37 ? 'left' : 'right';
        if (this.selectedType == 'collections' || this.selectedType == 'styles') {
          e.preventDefault();
        }
        let selection = {};
        if (!this.elementSelected && dir == 'down') {
          const next = this.nextSelectionType;
          if (!next) {
            selection = null;
          } else {
            selection = { type: next, index: 0 };
          }
        } else if (!this.elementSelected && dir == 'up') {
          const nextType = this.prevSelectionType;
          if (!nextType) {
            selection = null;
          } else {
            let index = 0;
            if (nextType == 'keywords') index = this.keywords.length - 1;
            if (nextType == 'collections') index = 0;
            if (nextType == 'styles') index = 0;
            selection = { type: nextType, index };
          }
        } else {
          const isLast = this.selectedIsTypeLast;
          const isFirst = this.selectedIsTypeFirst;
          selection.type = this.elementSelected.type;
          selection.index = this.elementSelected.index;
          if (dir == 'up') {
            if (selection.type == 'keywords') {
              if (isFirst) {
                selection = null;
              } else {
                selection.index--;
              }
            } else {
              const next = this.prevSelectionType;
              if (!next) {
                selection = null;
              } else {
                if (next == 'styles') {
                  if (selection.index >= this.styles.length) {
                    selection.index = this.styles.length - 1;
                  }
                } else if (next == 'collections') {
                  if (selection.index >= this.collections.length) {
                    selection.index = this.collections.length - 1;
                  }
                }
                if (selection.type == 'collections') {
                  selection.index = Math.max(this.keywords.length - 1, 0);
                }
                selection.type = next;
              }
            }
          } else if (dir == 'down') {
            if (selection.type == 'keywords') {
              if (isLast) {
                const next = this.nextSelectionType;
                if (!next) {
                  selection = null;
                } else {
                  selection.type = next;
                  selection.index = 0;
                }
              } else {
                selection.index++;
              }
            } else {
              const next = this.nextSelectionType;
              if (!next) {
                selection = null;
              } else {
                if (next == 'styles') {
                  if (selection.index >= this.styles.length) {
                    selection.index = this.styles.length - 1;
                  }
                } else if (next == 'collections') {
                  if (selection.index >= this.collections.length) {
                    selection.index = this.collections.length - 1;
                  }
                }
                selection.type = next;
              }
            }
          } else if (dir == 'left') {
            if (selection.type == 'styles' || selection.type == 'collections') {
              if (selection.index - 1 < 0) {
                selection.index = this.selectedTypeMaxIndex;
              } else {
                selection.index = selection.index - 1;
              }
            }
          } else if (dir == 'right') {
            if (selection.type == 'styles' || selection.type == 'collections') {
              if (selection.index + 1 > this.selectedTypeMaxIndex) {
                selection.index = 0;
              } else {
                selection.index = selection.index + 1;
              }
            }
          }
        }
        if (selection) {
          this.$store.commit('searchBar/SET_HAS_SELECTED_SUGGESTION', true);
        } else {
          this.$store.commit('searchBar/SET_HAS_SELECTED_SUGGESTION', false);
        }
        this.elementSelected = selection;
      } else {
        this.$store.commit('searchBar/SET_HAS_SELECTED_SUGGESTION', false);
        this.elementSelected = null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-top: none;
  z-index: 2;
  display: block;
  top: 100%;
  left: -1px;
  border-radius: 0px 0px 25px 25px;
  overflow: hidden;
  width: calc(100% + 2px);
}
.divider-autocomplete {
  margin-top: 0;
}
ul li.selected-merch {
  color: $defaultColor;
}
ul li.selected-design {
  color: $defaultColor;
}

.autocomplete-result-list {
  position: relative !important;
  border: none;
}
.autocomplete-input {
  padding: 12px 25px 12px 12px;
  background-position: right;
}
.autocomplete-input:focus,
.autocomplete-result-list {
  box-shadow: none;
}

.autocomplete-result {
  background: none !important;
}
.autocomplete-result:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: white;
}
.collection-button:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: white;
}
.style-item:hover {
  color: black;
}
.style-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  margin-right: 15px;
  color: #787878;
}
a.style-item:link {
  text-decoration: none;
}
.style-item > .icon {
  width: 40px;
  height: 40px;
  background-color: #e5e5e5;
  border-radius: 200%;
  border: 1px solid #707070;
}
.style-item > span.name {
  margin-left: 10px;
}
.list-btns.collections a {
  text-decoration: none;
}
</style>
