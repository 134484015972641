export default ({ env, store }, inject) => {
  if (!env.GTM_ID) {
    inject('dataLayerPush', () => {});
    return;
  }
  const userId = store.state.auth.user ? store.state.auth.user.id : undefined;
  const dataLayerPush = async (eventName, newData) => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    let eventData = { event: eventName, data: newData };
    if (store.state.auth.user) {
      eventData.user_id = userId;
    }
    window.dataLayer.push(eventData);
    return;
  };

  dataLayerPush({ data: null });

  inject('dataLayerPush', dataLayerPush);
};
