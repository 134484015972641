<template>
  <b-container :ref="scrollPlans" fluid :class="{ 'scroll-x': alternativeLayout }">
    <b-row id="recommended-plans" class="plans-container" :class="lifetimeLayout">
      <div :ref="scrollPlansInner" class="plans-switch-container">
        <div
          v-show="showSwitchPlans"
          class="mb-5 plan-switch-container"
          :class="{ 'with-billed': showBilledInfo }"
        >
          <div class="discount-img">
            <img v-bind="offImgProps" />
          </div>
          <div class="switch-container-lower">
            <SwitchPlan
              class="switch-element"
              v-bind="switchProps"
              :switch-on-annual="switchOnAnnual"
              background-color="#e4ddda73"
              inactive-pill-text-color="#211e1c"
              active-pill-color="#5388f2"
              @switch-plans="switchPlan"
            >
              <template #annual-discount>
                <span :class="{ 'd-lg-none': alternativeLayout }"> {{ percentageOffText }} </span>
              </template>
            </SwitchPlan>
            <p v-if="alternativeLayout && prismicProps.description" class="switch-text">
              {{ prismicProps.description }}
            </p>
          </div>
        </div>

        <div
          class="plans-container-internal"
          :class="{ 'd-flex justify-content-around': alternativeLayout }"
        >
          <Plan
            v-for="(plan, i) in shownPlans"
            :key="i"
            v-model="annual"
            :plan-index="i"
            :plans-length="plansLength"
            :plan="plan"
            :is-free-user="isFreeUser"
            :see-more-link="seeMoreLink"
            :lifetime="lifetime"
            :is-design-plan="isDesignPlan"
            :prismic-props="prismicProps"
            :custom-plan="hasCustomPlans"
            :value-switch="switchOnAnnual"
            :loading="isLoading"
            :u-id="uId"
            :alternative-layout="alternativeLayout"
            :specs-amount="prismicProps.specsAmount"
            :individual-vector-plans="individualVectorPlans"
            @subscribe-click="subscribeClick"
          />
        </div>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Plan from '@/components/PurchaseFlow/Plans/Plan';
import SwitchPlan from '@/components/PurchaseFlow/Plans/SwitchPlan';

export default {
  components: {
    Plan,
    SwitchPlan
  },
  props: {
    individualVectorPlans: {
      type: Boolean,
      default: false
    },
    seeMoreLink: {
      type: String,
      required: false,
      default: ''
    },
    lifetime: {
      type: Boolean,
      default: false,
      required: false
    },
    cantDownloadReason: {
      type: String,
      required: false,
      default: ''
    },
    prismicProps: {
      type: Object,
      default: () => ({})
    },
    customPlans: {
      type: Array,
      default: () => [],
      required: false
    },
    uId: {
      type: Number,
      required: true
    },
    alternativeLayout: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      annual: true,
      switchOnAnnual: true
    };
  },
  computed: {
    ...mapGetters('plans', [
      'getPlansDiscount',
      'plansWithIndividual',
      'plans',
      'lifetimePlans',
      'getPlanToFetch'
    ]),
    ...mapState('vectors', ['actualVector']),
    isFreeUser() {
      return !this.$auth.loggedIn || this.$auth.user.idPlan === 1 ? true : false;
    },
    lifetimeLayout() {
      let className = this.alternativeLayout
        ? 'alternative'
        : this.lifetime
        ? 'justify-content-center plans-gap'
        : this.showRecommendedStyles
        ? 'justify-content-around'
        : 'space-evenly';
      return className;
    },
    shownPlans() {
      if (this.isLoading) {
        return this.getPlanToFetch(this.uId).amount;
      }
      if (this.hasCustomPlans) {
        return this.customPlans;
      }
      if (this.individualVectorPlans) {
        return this.plansWithIndividual;
      }
      if (this.lifetime) {
        return this.lifetimePlans;
      }
      return this.plans;
    },
    isDesignPlan() {
      return this.actualVector ? !!this.plansWithIndividual[0]?.extended : false;
    },
    isLoading() {
      return this.hasCustomPlans
        ? this.customPlans.length === 0
        : this.plansWithIndividual?.length === 0 && this.plans?.length === 0;
    },
    hasCustomPlans() {
      return this.primaryIdsLength > 0 || this.customPlans?.length > 0;
    },
    primaryIdsLength() {
      return this.prismicProps?.primaryIds?.length;
    },
    showRecommendedStyles() {
      return this.shownPlans?.length === 3;
    },
    plansLength() {
      return this.isLoading ? this.shownPlans : this.shownPlans?.length;
    },
    prismicSwitchOnAnnual() {
      return this.prismicProps?.switchOnAnnual;
    },
    showSwitchPlans() {
      return !this.lifetime && (this.prismicProps.showSwitch || !this.hasCustomPlans);
    },
    switchProps() {
      return this.alternativeLayout
        ? {
            activePillColor: '#5388f2',
            activePillTextColor: '#fff'
          }
        : {};
    },
    showBilledInfo() {
      return (this.switchOnAnnual && this.showSwitchPlans) || this.individualVectorPlans;
    },
    percentageOffText() {
      const annualDiscount = this.getPlansDiscount(this.shownPlans);
      return annualDiscount
        ? this.alternativeLayout
          ? `(${annualDiscount}% ${this.$t('plans.common.cards.off')})`
          : `(-${annualDiscount}%)`
        : '';
    },
    offImgProps() {
      return {
        width: '100',
        height: '100',
        class: 'img-fluid w-100',
        src: this.switchOnAnnual
          ? this.prismicProps?.switchImageAnnual?.url || 'https://cdn.vexels.com/img/25off-dots.jpg'
          : this.prismicProps?.switchImageMonthly?.url || 'https://cdn.vexels.com/img/25off.jpg',
        alt: this.switchOnAnnual
          ? this.prismicProps?.switchImageAnnual?.alt || this.$t('plans.common.cards.25OffAlt2')
          : this.prismicProps?.switchImageMonthly?.alt || this.$t('plans.common.cards.25OffAlt1')
      };
    },
    scrollPlans() {
      return `scrollPlans${this._uid}`;
    },
    scrollPlansInner() {
      return `scrollPlansInner${this._uid}`;
    }
  },
  watch: {
    primaryIdsLength(newValue) {
      if (!newValue.length) {
        this.$store.dispatch('plans/LOAD_PLANS');
      }
    },
    prismicSwitchOnAnnual(newValue) {
      this.switchOnAnnual = newValue;
    }
  },
  async mounted() {
    if (this.alternativeLayout) {
      const notScrolled =
        this.$refs[this.scrollPlansInner].clientWidth - this.$refs[this.scrollPlans].clientWidth;
      this.$refs[this.scrollPlans].scroll({
        left: notScrolled / 2 + 25,
        behavior: 'smooth'
      });
    }

    if (!this.hasCustomPlans) {
      await this.$store.dispatch('plans/LOAD_PREVIEW_PLANS');
      // TODO: BLACK FRIDAY Uncomment to show switch on monthly plans
      //this.switchOnAnnual = false;
    } else if (!this.prismicProps?.showSwitch) {
      this.switchOnAnnual = this.prismicProps.switchOnAnnual;
    }
  },
  methods: {
    subscribeClick(plan) {
      this.$cookies.set('vx.plan', plan);
      this.$store.dispatch('plans/OPEN_PLAN', plan);
      this.$emit('suscribe-click', plan);
    },
    switchPlan(value) {
      this.switchOnAnnual = value;
    }
  }
};
</script>
<style lang="scss">
.scroll-x {
  overflow-y: hidden;
  padding: 10px 0;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  display: flex;
  justify-content: start;
  @include min-md {
    justify-content: center;
  }
}
.plans-switch-container {
  width: 100%;
  .discount-img {
    display: none;
  }
  .plans-container-internal {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .plan-switch-container {
    width: 100%;
  }
}
.alternative {
  &.plans-container {
    .plans-switch-container {
      display: flex;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin: 20px;
      border-radius: 15px;
      background: white;
      .discount-img {
        display: block;
        height: 156px;
        @include xl {
          display: none;
        }
      }
      @include xl {
        margin-left: 35px;
      }
      @media (max-width: 1024px) {
        display: block;
        box-shadow: none;
        background: transparent;
      }
      &.no-switch {
        .plans-container-internal {
          @media (max-width: 1024px) {
            margin-top: 0;
          }
        }
      }
      .plans-container-internal {
        flex-wrap: nowrap;
        @media (max-width: 1024px) {
          margin-top: 60px;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          border-radius: 15px;
        }
      }
    }

    background: transparent;
    display: flex;
    flex-wrap: nowrap;
    width: min-content;
    .plan-switch-container {
      display: flex;
      flex-direction: column;
      position: relative;
      bottom: 0;
      height: 14.5rem;
      font-size: 13px;
      min-width: 265px;
      align-self: start;
      padding: 10px;
      @include xl {
        align-self: flex-end;
      }
      .switch-container-lower {
        padding: 0 10px 10px 10px;
      }
      @media (max-width: 1024px) {
        top: 0;
        position: absolute;
      }
      .switch-element {
        @media (max-width: 1024px) {
          margin-bottom: 30px;
          left: -15px;
          position: absolute;
          width: 100vw;
        }
      }
      .switch-text {
        font-size: 15px;
        margin-bottom: 30px;
        margin-top: 12px;
        padding-left: 15px;
        line-height: 21px;
        @media (max-width: 1024px) {
          display: none;
        }
      }
    }
  }
}
.alert-circle {
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 200%;
  background-color: #ff4747;
}
.message {
  color: #4d4d4d;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
}
.plans-gap {
  gap: 26px;
}
.space-evenly {
  justify-content: space-evenly;
}
</style>
