<template>
  <div class="search-bar-container">
    <div v-click-outside="(event) => openCloseBar(false, event)" class="search-input-container">
      <Input
        :search-bar-uid="searchBarUid"
        :prismic-dimension="prismicDimension"
        :is-banner="isBanner"
        @search="search"
        @open-close-bar="openCloseBar"
      />
    </div>
    <Dropdown
      :prismic-suggestions="prismicSuggestions"
      :search-bar-uid="searchBarUid"
      :prismic-dimension="prismicDimension"
      :prismic-keyword="prismicKeyword"
      :open="dropdownOpen"
      :is-banner="isBanner"
      @search="search"
      @open-close-bar="openCloseBar"
    />
  </div>
</template>

<script>
import Input from './Input';
import Dropdown from './Dropdown';
export default {
  components: {
    Input,
    Dropdown
  },
  props: {
    isBanner: {
      type: Boolean,
      required: false,
      default: false
    },
    prismicSuggestions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    prismicKeyword: {
      type: String,
      default: '',
      required: false
    },
    searchBarUid: {
      type: Number,
      required: true
    }
  },
  computed: {
    dropdownOpen() {
      return this.$store.getters['searchBar/isOpen'](this.searchBarUid);
    }
  },
  mounted() {
    this.$store.commit('searchBar/SET_BAR_OPEN', {
      id: this.searchBarUid,
      isBanner: this.isBanner,
      status: false
    });
  },
  methods: {
    search(prismicSearch) {
      this.$emit('search', prismicSearch);
    },
    openCloseBar(status, e) {
      if (!e || !e.target.closest('.dropdown')) {
        this.$store.commit('searchBar/SET_BAR_OPEN', {
          id: this.searchBarUid,
          isBanner: this.isBanner,
          status
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
}
.search-bar-container::-webkit-scrollbar {
  display: none;
}
.search-input-container {
  border: none;
  min-width: 20%;
  margin-left: 10px;
  position: relative;
  height: 100%;
  cursor: text;
  width: 100%;
}
</style>
