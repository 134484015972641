import { PLANS_SINGLE_LICENCE, PLAN_FREE } from '~/settings.json';

import { formatDate } from '~/utils/common.js';

export const mutations = {
  SET_DATA(state, data) {
    state.profileData = data;
  },
  SET_RECENT_SEARCHES(state, data) {
    state.recent_searches = data;
  },
  SET_LIKED_VECTORS(state, data) {
    state.likedVectors = data;
  },
  ADD_LIKE_VECTOR(state, idVector) {
    if (!state.likedVectors.includes(idVector)) {
      state.likedVectors.push(idVector);
    }
  },
  ADD_FOLDER_VECTOR(state, data) {
    state.folderVectors = { ...state.folderVectors, ...data };
  },
  ADD_LIKE_VECTORS(state, idVectors) {
    state.likedVectors.push(...idVectors);
  },
  ADD_DOWNLOADED_VECTOR(state, id_vector) {
    state.downloadedVectors.push(id_vector);
  },
  SET_FOLDERS(state, folders) {
    state.folders = folders;
  },
  SET_DOWNLOADED_VECTORS(state, data) {
    state.downloadedVectors = data;
  },
  SET_DOWNLOADS(state, data = []) {
    state.downloads = data.map((el) => {
      const url = this.$urlBuilder('preview', {
        id: el.id,
        canonizedName: el.slug,
        type: el.idType
      });
      return {
        ...el,
        url,
        link: url
      };
    });
  },
  SET_LATEST_DOWNLOADS(state) {
    state.latestDownloads = state.downloads.length ? [...state.downloads.slice(0, 10)] : [];
  },
  SET_DOWNLOADS_CURRENT_PAGE(state, data) {
    state.downloadsCurrentPage = data;
  },
  SET_DOWNLOADS_PAGES(state, data) {
    state.downloadsPages = data;
  },
  SET_MY_DESIGNS(state, data) {
    const filter = data.filter ? data.filter.toLowerCase() : 'all';
    state.myDesigns[filter] = [...state.myDesigns[filter], ...data.data];
    state.totalDesigns[filter] = data.totalResults;
  },
  SET_BILLING(state, data) {
    state.myBilling = [...state.myBilling, ...data.data];
    state.totalBilling = data.totalResults;
  },
  SET_ACTUAL_PLAN(state, data) {
    state.actualPlan = data;
  },
  SET_ACTIVE_PLANS(state, data) {
    state.activePlans = data;
  },
  SET_COMPLETED_REQUESTS(state, data) {
    state.completedRequests = data;
  },
  SET_FAVORITES(state, data) {
    state.favorites = data.map((el) => {
      return {
        ...el,
        link: this.$urlBuilder('preview', {
          id: el.id,
          canonizedName: el.canonizedName,
          type: el.idType
        })
      };
    });
  },
  SET_FAVORITES_CURRENT_PAGE(state, data) {
    state.favoritesCurrentPage = data;
  },
  SET_FAVORITES_PAGES(state, data) {
    state.favoritesPages = data;
  },
  SET_REQUESTS(state, data) {
    state.requestsList = data;
  },
  SET_REQUESTS_STATS(state, data) {
    state.canRequest = data.canRequest;
    state.weeklyRequestsAvailable = data.weeklyRequestsAvailable;
    state.currentWeekDesignRequestsAmount = data.currentWeekDesignRequestsAmount;
    state.areDesignRequestsEnabled = data.areDesignRequestsEnabled;
    state.designRequestsDisabledMessage = data.designRequestsDisabledMessage;
  },
  SET_MADE_REQUESTS(state, data) {
    const resolveFinishedAt = (item) =>
      item.status === state.requestsStatus.finished ? item.finished_at : item.released_at;
    let newRequests = data.map((item) => ({
      ...item,
      finished_at: resolveFinishedAt(item),
      link: item.content_link,
      image: item.content_image,
      width: 300,
      height: 300,
      name: item.user_idea
    }));
    state.madeRequests = [...state.madeRequests, ...newRequests];
  },
  SET_TOTAL_RELEASED_REQUESTS(state, data = 0) {
    state.totalRequestsReleased = data;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_LOADING_DOWNLOADS(state, loading) {
    state.isLoadingDownloads = loading;
  },
  SET_LOADING_FAVORITES(state, loading) {
    state.isLoadingFavorites = loading;
  },
  SET_EMAIL_CHECKING_LOADING(state, status) {
    state.isEmailChecking = status;
  },
  SET_EMAIL_AVAILABLE(state, email) {
    state.emailAvailability.push({ email, status: true });
  },
  SET_EMAIL_NOT_AVAILABLE(state, email) {
    state.emailAvailability.push({ email, status: false });
  },
  SET_MODAL_MODE(state, mode) {
    state.modalMode = mode;
  },
  SET_CREDIT(state, data) {
    state.credit = data;
  },
  SET_SHOW_MESSAGE_MODAL(state, { title, buttonText, buttonAction, type }) {
    state.messageModal = {
      title,
      buttonText,
      buttonAction,
      type
    };
  },
  SET_FORM_SUBMISSION(state, { error = false, submited = true }) {
    state.formSubmission.error = error;
    state.formSubmission.submited = submited;
  },
  SET_FEEDBACK_BUTTON_CLICK(state, value) {
    state.sendFeedbackClicked = value;
  },
  SET_USE_DEFAULT_LOADING(state, value) {
    state.useDefaultLoading = value;
  },
  SET_PLATFORM_CREDITS(state, { aiCredits, aiCreditsLeft, downloadCredits, downloadCreditsLeft }) {
    state.aiCredits = aiCredits;
    state.aiCreditsLeft = aiCreditsLeft;
    state.downloadCredits = downloadCredits;
    state.downloadCreditsLeft = downloadCreditsLeft;
  },
  SET_DESIGN_REQUEST_CREDITS(
    state,
    {
      designRequestCredits,
      designRequestCreditsLeft,
      designRequestCreditsPlan,
      designRequestCreditsPlanLeft,
      designRequestCreditsUserLeft
    }
  ) {
    state.designRequestCredits = designRequestCredits;
    state.designRequestCreditsLeft = designRequestCreditsLeft;
    state.designRequestCreditsPlan = designRequestCreditsPlan;
    state.designRequestCreditsPlanLeft = designRequestCreditsPlanLeft;
    state.designRequestCreditsUserLeft = designRequestCreditsUserLeft;
  },
  REMOVE_LIKE_FOLDER_VECTOR(state, idVector) {
    delete state.folderVectors[idVector];
  },
  SET_DESIGNER_PROJECTS(state, data) {
    state.designerProjects = data;
  },
  SET_DESIGNER_PROJECTS_CURRENT_PAGE(state, page) {
    state.designerProjectsCurrentPage = page;
  },
  SET_DESIGNER_PROJECTS_PAGES(state, pages) {
    state.designerProjectsPages = pages;
  }
};

export const getters = {
  username(state, getters, rootState) {
    return rootState.auth.user.userName;
  },
  avatar(state, getters, rootState) {
    return rootState.auth.user.userAvatar;
  },
  getLikedVectors(state) {
    return state.likedVectors;
  },
  getFolders(state) {
    return state.folders;
  },
  isVectorLiked(state) {
    return function (id) {
      return state.likedVectors.includes(id);
    };
  },
  getFolderValue(state) {
    return function (id) {
      if (id in state.folderVectors) {
        return state.folderVectors[id];
      }
      return null;
    };
  },
  isVectorDownloaded(state) {
    return function (id) {
      return state.downloadedVectors.includes(id);
    };
  },
  isEmailAvailable: (state) => (email) => {
    return state.emailAvailability.some((el) => el.email == email && el.status);
  },
  isFree: (state, getters, rootState) => rootState.auth.user?.idPlan === PLAN_FREE,
  loggedInFreeUser(state, getters, rootState) {
    return !rootState.auth.loggedIn || rootState.auth.user.idPlan === 1 ? true : false;
  },
  loggedInWithPlan(state, getters, rootState) {
    return getters.isLoggedIn && rootState.auth.user.idPlan !== 1;
  },
  isLoggedIn(state, getters, rootState) {
    return rootState.auth.user;
  },
  hasBilling: (state) => state.myBilling.length > 0,
  latestDownloads(state) {
    return state.latestDownloads;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isLoadingDownloads(state) {
    return state.isLoadingDownloads;
  },
  isLoadingFavorites(state) {
    return state.isLoadingFavorites;
  },
  isSingleLicence(state, getters, rootState) {
    const currentPlan = rootState.auth.user?.idPlan;
    return PLANS_SINGLE_LICENCE.includes(currentPlan);
  },
  isFreePlanOrSingleLicence(state, getters) {
    return getters.isFree || getters.isSingleLicence;
  },
  getActualPlan(state) {
    return state.actualPlan;
  },
  canRequestDesign: (state) => {
    return state.canRequest;
  },
  getUseDefaultLoading(state) {
    return state.useDefaultLoading;
  },
  areDesignRequestsEnabled(state) {
    return state.areDesignRequestsEnabled;
  },
  designRequestsDisabledMessage(state) {
    return state.designRequestsDisabledMessage;
  }
};

export const actions = {
  async LOAD_DATA({ commit }) {
    try {
      const response = await this.$axios.get('user/profile/');
      if (!response.data.error && response.data.data) commit('SET_DATA', response.data.data);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('User | LOAD_DATA:', error);
    }
  },
  async UPDATE_DATA({ commit }, data) {
    try {
      const {
        address,
        zipCode,
        countryCode,
        city,
        companyName,
        vatId,
        phone,
        firstName,
        lastName,
        weburl
      } = data;
      const response = await this.$axios.post('user/profile/save-user-data/', {
        address,
        zip_code: zipCode,
        country: countryCode,
        city,
        company_name: companyName,
        vat_id: vatId,
        phone,
        firstName,
        lastName,
        weburl
      });
      if (!response.data.error) {
        commit('SET_DATA', data);
        return true;
      } else return false;
    } catch (error) {
      console.error('User | UPDATE_DATA:', error);
      return false;
    }
  },
  async LOAD_RECENT_SEARCHES({ commit }) {
    try {
      const response = await this.$axios.get('user/recent-searches/');
      commit('SET_RECENT_SEARCHES', response.data.logs);
      return true;
    } catch (error) {
      console.error('User | LOAD_RECENT_SEARCHES', error);
      return false;
    }
  },
  async LOAD_VECTORS_STATS({ commit, state }, vectorIds) {
    try {
      const strategy = this.$cookies.get('vx.strategy');
      const token = this.$cookies.get(`vx.token.${strategy}`);
      if (vectorIds.length && (this.$auth.loggedIn || (strategy && token))) {
        const data = { contents: vectorIds.join(',') };
        const response = await this.$axios.post('user/contents-stats/', data);
        if (!response.data.error && response.data.data) {
          const {
            liked: vectorsLiked,
            downloaded: vectorsDownloaded,
            folders: vectorsInFolders
          } = response.data.data;

          vectorsInFolders.forEach((folder) => {
            if (!state.folders.includes(folder) && folder.vectors) {
              folder.vectors.forEach((vector) => {
                commit('ADD_FOLDER_VECTOR', { [vector]: folder.id });
              });
            }
          });

          vectorsLiked.forEach((vector) => {
            commit('ADD_LIKE_VECTOR', vector);
          });

          vectorsDownloaded.forEach((vector) => {
            if (!state.downloadedVectors.includes(vector)) {
              commit('ADD_DOWNLOADED_VECTOR', vector);
            }
          });
        }
      }
    } catch (error) {
      console.error('User | LOAD_VECTORS_STATS:', error);
    }
  },
  async SET_LIKE_VECTOR(
    { commit, state, dispatch },
    { idVector, isLiked, bvModal, folder = null }
  ) {
    try {
      if (!this.$auth.loggedIn) {
        bvModal.show('user-modal');
        dispatch('CHANGE_MODAL_MODE', 'login');
      } else {
        const action = isLiked ? 'unlike' : 'like';
        idVector = parseInt(idVector);
        commit('REMOVE_LIKE_FOLDER_VECTOR', idVector);

        if (action === 'like') {
          if (folder) {
            commit('ADD_FOLDER_VECTOR', { [idVector]: folder });
          }
          commit('ADD_LIKE_VECTOR', idVector);
        } else {
          let liked = state.likedVectors.filter((l) => l != idVector);
          commit('SET_LIKED_VECTORS', liked);
        }

        await this.$axios.post('user/like-vector/', { idVector, action, folder });
      }
    } catch (error) {
      console.error('User | SET_LIKE_VECTOR:', error);
    }
  },
  async SEND_FEEDBACK({ commit }, { inputPayload, context }) {
    commit('SET_FEEDBACK_BUTTON_CLICK', true);
    try {
      const res = await this.$axios.post('/user/profile/send-feedback/', {
        feedback: inputPayload,
        context
      });
      if (res.data.error) {
        commit('SET_FORM_SUBMISSION', { error: true });
      } else {
        commit('SET_FORM_SUBMISSION', {});
      }
    } catch (error) {
      commit('SET_FORM_SUBMISSION', { error: true });
    }
    setTimeout(() => {
      commit('SET_FORM_SUBMISSION', { submited: false });
      commit('SET_FEEDBACK_BUTTON_CLICK', false);
    }, 5000);
  },
  async LOAD_DOWNLOADS({ commit }, data = {}) {
    try {
      commit('SET_LOADING_DOWNLOADS', true);
      data.page = !data.page ? 1 : data.page;
      data.perPage = !data.perPage ? 30 : data.perPage;

      if (data.dateFrom && data.dateTo) {
        data.dateFrom = formatDate(data.dateFrom, 'MM/DD/YYYY');
        data.dateTo = formatDate(data.dateTo, 'MM/DD/YYYY');
      }

      const isLatestDownloads = data.page === 1 && !(data.dateFrom || data.dateTo) && !data.text;
      const response = await this.$axios.post('user/profile/downloads/', data);

      if (!response.data.error && response.data.data) {
        const { total, vectors } = response.data.data;

        const totalPages = parseInt(response ? Math.ceil(parseInt(total) / data.perPage) : 0);
        const currentPage = data.page;

        commit('SET_DOWNLOADS', vectors);
        commit('SET_DOWNLOADS_CURRENT_PAGE', currentPage);
        commit('SET_DOWNLOADS_PAGES', totalPages);

        if (isLatestDownloads) {
          commit('SET_LATEST_DOWNLOADS');
        }

        commit('SET_LOADING_DOWNLOADS', false);
      }
    } catch (error) {
      console.error('User | LOAD_DOWNLOADS:', error);
    }
  },
  async LOAD_FAVORITES({ commit, dispatch }, data) {
    try {
      commit('SET_LOADING_FAVORITES', true);
      data = data || new Object();
      data.page = data.page || 1;
      data.perPage = data.perPage || 30;

      const response = await this.$axios.post('user/profile/favorites/', data);
      if (!response.data.error && response.data.data) {
        const { total, vectors } = response.data.data;

        const totalPages = parseInt(response ? Math.ceil(parseInt(total) / data.perPage) : 0);
        const currentPage = data.page;
        const idVectors = vectors.map((item) => item.id);

        commit('SET_FAVORITES', vectors);
        commit('SET_FAVORITES_CURRENT_PAGE', currentPage);
        commit('SET_FAVORITES_PAGES', totalPages);
        commit('ADD_LIKE_VECTORS', idVectors);
        commit('SET_LOADING', false);
        await dispatch('LOAD_VECTORS_STATS', idVectors);
      }
      commit('SET_LOADING_FAVORITES', false);
    } catch (error) {
      console.error('User | LOAD_FAVORITES:', error);
    }
  },
  async LOAD_MY_DESIGNS({ commit }, { page, filter }) {
    commit('SET_LOADING', true);
    try {
      const response = await this.$axios.get(
        `https://makersapi.vexels.com/v1/editions/edition/?page=${page}${
          filter ? `&editionType=${filter}` : ''
        }`
      );
      const data = response.data.results ? response.data.results : [];
      const totalResults = response.data.count ? response.data.count : 0;
      commit('SET_MY_DESIGNS', { data, totalResults, filter });
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_LOADING', false);
    }
  },
  async LOAD_REQUESTS({ commit }, params) {
    try {
      if (!params) params = {};
      params.owner = this.$auth.user.id;
      const response = await this.$axiosV2.get('design-request/design-request', { params });
      if (!response.data.error && response.data.data) {
        const requests = response.data.data.results || [];
        commit('SET_REQUESTS', requests);
      }
    } catch (error) {
      console.error('User | LOAD_REQUESTS:', error);
    }
  },
  async LOAD_REQUESTS_STATS({ commit }) {
    try {
      const {
        data: {
          error,
          data: {
            are_design_requests_enabled,
            design_requests_disabled_message,
            weekly_requests_available,
            are_credits_available,
            current_week_design_requests_amount
          }
        }
      } = await this.$axiosV2.get('design-request/stats');
      if (!error) {
        commit('SET_REQUESTS_STATS', {
          areDesignRequestsEnabled: are_design_requests_enabled,
          designRequestsDisabledMessage: design_requests_disabled_message,
          canRequest: are_credits_available,
          weeklyRequestsAvailable: weekly_requests_available,
          currentWeekDesignRequestsAmount: current_week_design_requests_amount
        });
      }
    } catch (error) {
      console.error('User | LOAD_REQUESTS_STATS:', error);
    }
  },
  async LOAD_MADE_REQUESTS({ state, commit }, { page = 1 }) {
    try {
      let params = {
        status__in: `${state.requestsStatus.released},${state.requestsStatus.finished}`,
        page,
        owner: this.$auth.user.id
      };
      const response = await this.$axiosV2.get('design-request/design-request', { params });

      if (!response.data.error && response.data.data) {
        const requests = response.data.data.results || [];
        const totalReleased = response.data.data.count || 0;
        commit('SET_MADE_REQUESTS', requests);
        commit('SET_TOTAL_RELEASED_REQUESTS', totalReleased);
      }
    } catch (error) {
      console.error('User | LOAD_MADE_REQUESTS:', error);
    }
  },
  async LOAD_DESIGNER_PROJECTS({ commit, state }) {
    try {
      const response = await this.$axios.get(
        // eslint-disable-next-line max-len
        `${process.env.API_DESIGNER_URL}/api/design/my-designs?page=${state.designerProjectsCurrentPage}`
      );
      if (!response.data.error && response.data.data) {
        const projects = response.data.data.designs || [];
        const totalPages = response.data.data.totalPages || 0;
        commit('SET_DESIGNER_PROJECTS', projects);
        commit('SET_DESIGNER_PROJECTS_PAGES', totalPages);
      }
    } catch (error) {
      console.error('User | LOAD_DESIGNER_PROJECTS:', error);
    }
  },
  async LOAD_BILLING({ commit }, { page }) {
    commit('SET_LOADING', true);
    try {
      const {
        data: { data: response }
      } = await this.$axios.post('user/profile/billing/', {
        page,
        perPage: 10
      });
      const data = response.payments ? response.payments : [];
      const totalResults = response.total ? response.total : 0;
      const actualPlan = response.plansInfo ? response.plansInfo[0] : null;
      commit('SET_BILLING', { data, totalResults });
      commit('SET_ACTUAL_PLAN', actualPlan);
      commit('SET_ACTIVE_PLANS', response.plansInfo);
      commit('SET_LOADING', false);
    } catch (error) {
      console.error('User | LOAD_BILLING:', error);
      commit('SET_LOADING', false);
    }
  },
  async CHECK_EMAIL_AVAILABILITY({ commit, state }, email) {
    commit('SET_EMAIL_CHECKING_LOADING', true);
    const exists = state.emailAvailability.some((el) => el.email == email);
    if (!exists) {
      try {
        const response = await this.$axios.post('auth/check-email/', { email });
        const available = response.data.data == 'success';
        if (available) {
          commit('SET_EMAIL_AVAILABLE', email);
        } else {
          commit('SET_EMAIL_NOT_AVAILABLE', email);
        }
      } catch (error) {
        console.error('User | CHECK_EMAIL_AVAILABILITY:', error);
      }
    }
    commit('SET_EMAIL_CHECKING_LOADING', false);
  },
  async SIGN_UP({ dispatch, rootGetters }, data) {
    try {
      const response = await this.$axios.post('auth/register/', data);
      if (response.error || response.data.error) {
        return response.data;
      }
      this.$dataLayerPush('sign_up', {
        auth: { method: 'local' }
      });
      if (rootGetters['vectors/purchaseFlowStatus'] === 'account') {
        dispatch('vectors/CHANGE_PURCHASE_FLOW_STATUS', 'payment', { root: true });
      }

      return { error: false, token: response.data.data.token };
    } catch (error) {
      return { error: true, message: 'There was an error. Please try again later.' };
    }
  },
  CHANGE_MODAL_MODE({ commit }, mode) {
    commit('SET_MODAL_MODE', mode);
  },
  async SEND_SURVEY({ commit }, data) {
    try {
      const response = await this.$axios.post('/survey/complete/', data);
      if (response.data.error) {
        return {
          error: true,
          message: 'There was an error trying to submit form. Please try again later.'
        };
      }
      return {
        error: false,
        message: 'Submitted successfully'
      };
    } catch (error) {
      return {
        error: true,
        message: 'There was an error trying to submit form. Please try again later.'
      };
    }
  },
  async SEND_CANCELLATION({ commit }, data) {
    try {
      const response = await this.$axios.post('/user/cancel-subscription/', data);

      if (response.data.error) {
        let { message } = response.data.data[0];
        return {
          error: true,
          message: message
        };
      }
      this.$auth.fetchUser(true);
      return {
        error: false,
        message: 'Submitted successfully'
      };
    } catch (error) {
      return {
        error: true,
        message: 'There was an error trying to submit form. Please try again later.'
      };
    }
  },
  async SEND_CANCELLATION_FREE_TRIAL() {
    const errorMessage = {
      error: true,
      message: 'There was an error trying to submit form. Please try again later.'
    };
    try {
      const response = await this.$axios.post('/user/cancel-free-trial/');
      if (response.data.error) {
        return errorMessage;
      }
      await this.$auth.fetchUser(true);
      return {
        error: false,
        message: 'Submitted successfully'
      };
    } catch (error) {
      return errorMessage;
    }
  },
  async LOAD_CREDIT({ commit, state }) {
    try {
      if (!state.credit) {
        const {
          data: { error, data }
        } = await this.$axios.post('/user/credit/');
        if (!error) commit('SET_CREDIT', data);
        else commit('SET_CREDIT', null);
      }
    } catch (error) {
      console.error('User | LOAD_CREDIT:', error);
      commit('SET_CREDIT', null);
    }
  },
  async CHANGE_PASSWORD({}, data) {
    try {
      const response = await this.$axios.post('/user/profile/password/', data);
      if (response.error || response.data.error) {
        return { error: true, message: response.data.data[0].code };
      }
      return { error: false, message: '' };
    } catch (error) {
      console.error('User | CHANGE_PASSWORD:', error);
      return { error: true, message: 'profile.account.profile.errorPassword' };
    }
  },
  async CHANGE_PASSWORD_FORGOT({}, data) {
    try {
      const response = await this.$axios.post('/user/forgot-confirm', data);
      if (response.error || response.data.error) {
        return { error: true, message: response.data.data[0].code };
      }
      return { error: false, message: 'profile.account.profile.emailSent' };
    } catch (error) {
      console.error('User | CHANGE_PASSWORD_FORGOT:', error);
      return { error: true, message: 'profile.account.profile.emailError' };
    }
  },
  async FORGOT_PASSWORD({}, data) {
    try {
      const response = await this.$axios.post('/user/forgot/', data);
      if (response.error || response.data.error) {
        return { error: true, message: response.data.data[0] };
      }
      return { error: false, message: 'profile.account.profile.emailSent' };
    } catch (error) {
      console.error('User | FORGOT_PASSWORD:', error);
      return { error: true, message: 'profile.account.profile.emailError' };
    }
  },
  async LOAD_PLATFORM_CREDITS({ commit }) {
    try {
      const {
        data: { error, features }
      } = await this.$axiosV2.get('permission/product/platform');

      if (!error) {
        const aiCredits = features['ai-credits'];
        const downloadCredits = features['download'];
        commit('SET_PLATFORM_CREDITS', {
          aiCredits: aiCredits.total,
          aiCreditsLeft: aiCredits.total - aiCredits.used,
          downloadCredits: downloadCredits.total,
          downloadCreditsLeft: downloadCredits.total - downloadCredits.used
        });
      }
    } catch (error) {
      console.error('User | LOAD_PLATFORM_CREDITS:', error);
    }
  },
  async LOAD_DESIGN_REQUEST_CREDITS({ commit }) {
    try {
      const {
        data: { error, plan_usage, user_usage }
      } = await this.$axiosV2.get('permission/usage/design-requests');

      if (!error) {
        const total = plan_usage.total + user_usage.total;
        const used = plan_usage.used + user_usage.used;
        commit('SET_DESIGN_REQUEST_CREDITS', {
          designRequestCredits: total,
          designRequestCreditsLeft: total - used,
          designRequestCreditsPlan: plan_usage.total,
          designRequestCreditsPlanLeft: plan_usage.total - plan_usage.used,
          designRequestCreditsUserLeft: user_usage.total - user_usage.used
        });
      }
    } catch (error) {
      console.error('User | LOAD_DESIGN_REQUEST_CREDITS:', error);
    }
  },
  async GET_FOLDERS({ commit }) {
    try {
      const response = await this.$axios.get('user/folder/');
      if (response.data.error) {
        return;
      }
      commit('SET_FOLDERS', response.data.data);
    } catch (error) {
      console.error('User | GET_FOLDERS:', error);
    }
  },
  async CREATE_FOLDER({ dispatch }, data) {
    try {
      if (data.name.trim() === '') {
        return;
      }
      await this.$axios.post('user/folder/', data);
      await dispatch('GET_FOLDERS');
    } catch (error) {
      console.error('User | CREATE_FOLDER:', error);
    }
  }
};

export const state = () => ({
  profileData: {},
  recent_searches: [],
  likedVectors: [],
  downloadedVectors: [],
  folders: [],
  downloads: [],
  downloadsPages: 0,
  downloadsCurrentPage: 0,
  latestDownloads: [],
  myDesigns: { all: [], logo: [], tsht: [] },
  totalDesigns: { all: 0, logo: 0, tsht: 0 },
  myBilling: [],
  totalBilling: 0,
  actualPlan: null,
  activePlans: [],
  favorites: [],
  favoritesPages: 0,
  favoritesCurrentPage: 0,
  currentPageDesign: 0,
  madeRequests: [],
  requestsStatus: {
    finished: 'FINISHED',
    released: 'RELEASED'
  },
  totalRequestsReleased: 0,
  requestsList: [],
  canRequest: false,
  weeklyRequestsAvailable: 0,
  currentWeekDesignRequestsAmount: 0,
  isLoading: false,
  isLoadingDownloads: false,
  isLoadingFavorites: false,
  isEmailChecking: false,
  emailAvailability: [],
  modalMode: 'login',
  credit: null,
  messageModal: {
    title: '',
    buttonText: '',
    buttonAction: null,
    type: ''
  },
  formSubmission: {
    error: false,
    submited: false
  },
  sendFeedbackClicked: false,
  useDefaultLoading: false,
  areDesignRequestsEnabled: true,
  designRequestsDisabledMessage: '',
  aiCredits: 0,
  aiCreditsLeft: 0,
  downloadCredits: 0,
  downloadCreditsLeft: 0,
  designRequestCredits: 0,
  designRequestCreditsLeft: 0,
  designRequestCreditsPlan: 0,
  designRequestCreditsPlanLeft: 0,
  designRequestCreditsUserLeft: 0,
  folderVectors: {},
  designerProjects: [],
  designerProjectsPages: 0,
  designerProjectsCurrentPage: 1
});
