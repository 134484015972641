<template>
  <b-container fluid :style="{ padding: 0 }">
    <div v-if="!actualRelateds">
      <div class="relateds-title-skeleton skeleton-box" />
      <div class="relateds-blocks-skeleton skeleton-box" />
    </div>
    <div v-else>
      <BlockContents v-for="(b, i) in actualRelateds" :key="i" :data="b">
        <BlockHeader :block="b" />
      </BlockContents>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BlockContents from '@/components/Preview/Relateds/BlockContents';
import BlockHeader from '@/components/Preview/Relateds/BlockHeader';

export default {
  components: {
    BlockHeader,
    BlockContents
  },
  computed: {
    ...mapGetters('vectors', ['actualRelateds'])
  }
};
</script>
<style lang="scss" src="../Preview.scss" scoped></style>
<style lang="scss" scoped>
.relateds-title-skeleton {
  width: 220px;
  height: 32px;
  border-radius: 4px;
  margin: 24px 0px 12px 0px;
}
.relateds-blocks-skeleton {
  width: 100%;
  height: 240px;
  border-radius: 4px;
  margin-bottom: 42px;
}
</style>
