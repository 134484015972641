<template>
  <span>
    <span v-show="status === 'loading'" class="text-white">{{
      $t('main.header.tags.loading')
    }}</span>
    <span v-show="status !== 'loading'">
      <div v-if="$auth.loggedIn && status === 'loaded'" v-b-modal.profile-menu-modal class="user">
        <div>
          <UserAvatar :width="25" />
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-items-center">
        <div v-b-modal.user-modal class="signup mr-4 auth" @click="setSignupMode">
          <span class="mb-0">{{ $t('main.header.tags.signup') }}</span>
        </div>
        <div v-b-modal.user-modal class="login auth" @click="setLoginMode">
          <span class="mb-0">{{ $t('main.header.tags.login') }}</span>
        </div>
      </div>
    </span>
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserAvatar from 'UI/UserAvatar';
export default {
  components: {
    UserAvatar
  },
  computed: {
    ...mapGetters('user', ['username']),
    ...mapState('auth', ['status'])
  },
  methods: {
    setLoginMode() {
      this.$store.dispatch('user/CHANGE_MODAL_MODE', 'login');
    },
    setSignupMode() {
      this.$store.dispatch('user/CHANGE_MODAL_MODE', 'signup');
    }
  }
};
</script>

<style lang="scss" scoped>
.auth {
  color: $white;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
}
.login,
.signup {
  font-size: 13px;
  white-space: nowrap;
}
.user {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: $white;
  position: relative;
  &:focus {
    outline: none;
  }
  @include lg {
    padding: 0;
    .username {
      display: none;
    }
  }
}
</style>
