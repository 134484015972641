<template>
  <div class="vector-tags">
    <Tag
      v-for="(tag, index) in tagsWithCategories"
      :key="index"
      :text="tag.name"
      :link="tag.link"
      :has-link="tag.hasLink"
      :is-external="tag.type && tag.type === 'external'"
      class="vx-tag"
      :class="{ 'd-inline-flex': showMoreTags }"
      @click.native="hidePreviewModal"
    />
    <span
      v-if="tagsCount"
      class="more-tags rectangular x-small gray effect-color vx-expand-tags"
      @click="$emit('show-more-tags')"
      >{{ tagsCount }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Tag from 'UI/Tag';

export default {
  components: {
    Tag
  },
  props: {
    showMoreTags: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('vectors', ['actualVector']),
    ...mapGetters('dimensions', ['getDimensionPathById']),
    tagsCount() {
      return this.showMoreTags
        ? '-'
        : (this.tagsWithCategories?.length > 3 && `+${this.tagsWithCategories.length - 3}`) || '';
    },
    tagsWithCategories() {
      let tags = this.actualVector.tags.map((tag) => ({
        ...tag,
        link: this.tagLink(tag)
      }));
      const categories = this.actualVector.categories.map((category) => ({
        ...category,
        hasLink: true,
        link: this.categoryLink(category)
      }));

      return tags.concat(categories);
    }
  },
  methods: {
    tagLink(tag) {
      if (tag.type && tag.type === 'external') {
        return tag.link;
      } else {
        return this.$urlBuilder('search', {
          path: this.getDimensionPathById(Number(tag.dimension), tag.experience),
          keyword: tag.slug
        });
      }
    },
    categoryLink(category) {
      return this.$urlBuilder('category', {
        id: category.id,
        slug: category.slug
      });
    },
    hidePreviewModal() {
      this.$bvModal.hide('preview-vector-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.vector-tags {
  border-left: #dfd7d7 1px solid;
  border-right: #dfd7d7 1px solid;
  height: auto;
  min-height: 100%;
  min-width: 15%;
  overflow: hidden;
  padding: 0 15px;
  margin-right: 10px;
}
.vx-tag {
  cursor: pointer;
  display: none;
}
.vx-tag:nth-child(-n + 3),
.vx-tag.more,
.vx-tag.less {
  display: inline-flex;
}
.more-tags {
  align-items: center;
  background-color: #707070;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 10px;
  margin: 7px;
  overflow: visible;
  padding: 4px 10px;
  position: relative;
  text-align: center;
  transition: background-color 0.3s;
  width: auto;
  &.close-description {
    margin: 0 0 0 3px;
    padding: 3px;
    position: relative;
    top: 5px;
  }
}
.more-tags:hover {
  background-color: #918d8d;
}
.d-inline-flex {
  display: inline-flex;
}
.vx-expand-tags {
  display: inline-flex;
  font-size: 10px;
}
@media all and (max-width: 767px) {
  .vector-tags {
    border: none;
    display: block;
    height: auto;
    margin-bottom: 10px;
    padding-top: 0;
    padding: 0;
  }
}
@media all and (max-width: 1000px) {
  .vector-tags {
    border-left: none;
    border-right: none;
    padding: 0;
  }
}
@media all and (max-width: 576px) {
  .vector-tags {
    display: block;
  }
}
</style>
