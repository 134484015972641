<template>
  <div class="wrapper">
    <a :href="homeUrl">
      <img
        src="https://cdn.vexels.com/img/logo-variants/default-logo-variant.svg"
        class="vexels-logo"
        :alt="$t('main.header.logo.vexelsLogoAlt')"
        width="130"
        height="29"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'LogoSection',
  computed: {
    homeUrl() {
      return this.$auth.loggedIn ? this.localePath('/home') : '/';
    }
  }
};
</script>

<style lang="scss" scoped>
.vexels-logo {
  width: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 100%;
  @include xl {
    width: 90px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
