export const vueI18n = {"silentTranslationWarn":true,"silentFallbackWarn":true}
export const vueI18nLoader = false
export const locales = [{"code":"en","name":"English","domain":"www.vexels.com","file":"languages.js","iso":"en"},{"code":"de","name":"Deutsch","domain":"de.vexels.com","file":"languages.js","iso":"de"},{"code":"es","name":"Español","domain":"es.vexels.com","file":"languages.js","iso":"es"},{"code":"pt","name":"Português","domain":"br.vexels.com","file":"languages.js","iso":"pt"}]
export const defaultLocale = 'en'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'locales/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = true
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":true,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","de","es","pt"]
export const trailingSlash = undefined
