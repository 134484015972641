<template>
  <b-row v-if="isLogin" class="login-section my-4 d-none d-md-block">
    <b-col>
      {{ $t('profile.signUp.main.dontHaveAccount') }}
      <span class="login-text" @click="$emit('choose-signup')">
        {{ $t('profile.signUp.main.title') }}
      </span>
    </b-col>
  </b-row>
  <b-row v-else class="login-section my-4 d-none d-md-block">
    <b-col>
      {{ $t('profile.logIn.main.alreadyHaveAccount') }}
      <span class="login-text" @click="$emit('choose-login')">
        {{ $t('profile.logIn.main.title') }}
      </span>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.login-section {
  color: gray;
  font-weight: 400;
}
.login-text {
  cursor: pointer;
  color: #3cd699;
}
</style>
