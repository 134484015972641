<template>
  <b-modal id="profile-menu-modal" hide-footer hide-header hide-backdrop>
    <template #modal-header />
    <UserSection />
    <div class="divider"></div>
    <LogoutSection />
  </b-modal>
</template>

<script>
import UserSection from '@/components/Header/ProfileMenu/UserSection';
import LogoutSection from '@/components/Header/ProfileMenu/LogoutSection';
export default {
  name: 'MenuProfile',
  components: {
    UserSection,
    LogoutSection
  }
};
</script>

<style lang="scss">
#profile-menu-modal {
  .modal-content {
    border: #e4e4e4;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 83px 0px;
  }
  .modal-header {
    border: none;
    padding: 0;
  }
  .modal-dialog {
    width: 256px;
    margin-top: calc(36px + 1rem);
    margin-right: 2rem;
    border-radius: 10px;
    .modal-body {
      padding: 0px;
      .divider {
        width: 100%;
        height: 1px;
        background-color: #e4e4e4;
        margin: 0 auto;
      }
      .close-icon {
        position: absolute;
      }
    }
    @include sm {
      width: 85%;
      margin: auto;
      margin-top: 45px;
    }
    @include xs {
      width: 95%;
    }
  }
  .close {
    outline: none;
    position: absolute;
    right: 20px;
    top: -20px;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
    height: 30px;
    display: flex;
    text-align: center;
    align-items: center;
    color: white;
    opacity: 1;
    font-weight: 400;
    z-index: 100;
    @include md {
      right: 20px;
      top: 20px;
      background-color: #505050;
    }
  }
}
</style>
