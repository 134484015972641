import { render, staticRenderFns } from "./Bubbles.vue?vue&type=template&id=aede0160&scoped=true"
import script from "./Bubbles.vue?vue&type=script&lang=js"
export * from "./Bubbles.vue?vue&type=script&lang=js"
import style0 from "./Bubbles.vue?vue&type=style&index=0&id=aede0160&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aede0160",
  null
  
)

export default component.exports