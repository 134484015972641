export default {
  name: 'plans-page',

  experimentID: '8xuLgcKDRjqziRX0nOXJMQ',

  variants: [
    { uid: 'merch-plans' }, // <-- This is the default variant
    { uid: 'exfmeuq8ygzjps2rzcbgcth68fewbumg' },
    { uid: 'rrdty4favaxkwut7ffskmfzysxtucpn5' }
  ]
};
