<template>
  <div class="input-wrapper">
    <Bubbles
      v-show="!showInput"
      :prismic-dimension="prismicDimension"
      :search-bar-uid="searchBarUid"
      :is-banner="isBanner"
      @disable="disableBubbles"
    />
    <div v-show="showInput" class="input-container">
      <input
        v-if="!prismicDimension"
        ref="input-search"
        v-model="actualSearch"
        class="input-search"
        :aria-label="$t('main.header.logo.searchBar')"
        type="text"
        @keyup.enter="enterPressed"
        @focus="onFocus"
      />
      <input
        v-else
        ref="input-search"
        v-model="prismicSearch"
        class="input-search"
        :aria-label="$t('main.header.logo.searchBar')"
        type="text"
        @keyup.enter="enterPressed"
        @focus="onFocus"
      />
    </div>
  </div>
</template>

<script>
import Bubbles from '@/components/SearchBar/Bubbles';

export default {
  components: {
    Bubbles
  },
  props: {
    isBanner: {
      type: Boolean,
      required: false,
      default: false
    },
    prismicDimension: {
      type: String,
      default: '',
      required: false
    },
    searchBarUid: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loadTimeoutId: null,
      waitingUserInput: false
    };
  },
  computed: {
    actualSearch: {
      get() {
        return this.$store.getters['searchBar/getActualSearch'];
      },
      set(value) {
        this.$store.commit('searchBar/SET_ACTUAL_SEARCH', value);
        this.triggerSuggestions(value);
      }
    },
    prismicSearch: {
      get() {
        const showingDropdownItem = this.$store.getters['searchBar/getPrismicSearch'].find(
          (el) => el.id === this.searchBarUid
        );
        return showingDropdownItem?.value;
      },
      set(value) {
        this.$store.commit('searchBar/SET_PRISMIC_SEARCH', { id: this.searchBarUid, value });
        this.triggerSuggestions(value);
      }
    },
    hasSuggestionSelected() {
      return this.$store.getters['searchBar/hasSuggestionSelected'];
    },
    showInput() {
      return !this.actualSearch || this.isDropdownOpen;
    },
    isDropdownOpen() {
      return this.$store.getters['searchBar/isOpen'](this.searchBarUid);
    }
  },
  watch: {
    isDropdownOpen(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs['input-search'].blur();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.close);
  },
  mounted() {
    window.addEventListener('scroll', this.close);
  },
  methods: {
    triggerSuggestions(value) {
      const amountLetters = this.$g('SEARCH_BAR_AMOUNT_LETTERS_START_AUTOCOMPLETE');
      if (value && value.length >= amountLetters) {
        if (this.waitingUserInput) {
          clearTimeout(this.loadTimeoutId);
        }
        this.loadTimeoutId = setTimeout(() => {
          this.$store.dispatch('searchBar/LOAD_TYPING_SUGGESTIONS', value);
          this.waitingUserInput = false;
        }, 300);
        this.waitingUserInput = true;
      }
    },
    close() {
      this.$store.getters['searchBar/isOpen'](this.searchBarUid) &&
        this.$emit('open-close-bar', false);
    },
    disableBubbles() {
      this.onFocus();
      setTimeout(() => {
        const input = this.$refs['input-search'];
        input.focus();
        input.selectionStart = input.selectionEnd = input.value.length;
      }, 200);
    },
    enterPressed() {
      this.$refs['input-search'].blur();
      if (!this.hasSuggestionSelected) {
        this.$emit('search', this.prismicSearch);
      }
    },
    onFocus() {
      const amountLetters = this.$g('SEARCH_BAR_AMOUNT_LETTERS_START_AUTOCOMPLETE');
      if (this.actualSearch.length >= amountLetters) {
        this.$store.dispatch('searchBar/LOAD_TYPING_SUGGESTIONS', this.actualSearch);
      }
      this.$emit('open-close-bar', true);
      this.$store.commit('searchBar/SET_HAS_SELECTED_SUGGESTION', false);
    }
  }
};
</script>

<style scoped>
.input-wrapper {
  width: 100%;
  height: 100%;
}
.input-container {
  width: 100%;
}
.input-search {
  width: 100%;
  border: none;
}
.input-search:focus {
  outline: none;
}
</style>
