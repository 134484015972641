<template>
  <span :id="customId">
    <nuxt-link
      v-if="showLink && !blank && useNuxtLink && !isJavascript"
      :to="link"
      :class="classes"
      :style="styles"
      v-bind="extraData"
      @click.native="onClick"
    >
      <span class="inner-text d-flex align-items-center"
        ><slot>{{ $t('main.ui.button.text') }}</slot></span
      >
    </nuxt-link>
    <a
      v-if="showLink && (blank || !useNuxtLink || isJavascript)"
      :href="link"
      :target="blank ? '_blank' : null"
      :class="classes"
      :style="styles"
      v-bind="extraData"
      :rel="blank ? 'noopener noreferrer' : null"
      @click="onClick"
    >
      <span class="inner-text d-flex align-items-center"
        ><slot>{{ $t('main.ui.button.text2') }}</slot></span
      >
    </a>
    <button
      v-if="showButton"
      type="submit"
      class="d-flex align-items-center"
      :class="classes"
      :style="styles"
      v-bind="extraData"
      @click="onClick"
    >
      <slot>{{ $t('main.ui.button.text3') }}</slot>
    </button>
    <div
      v-if="showDiv"
      class="inner-text d-flex align-items-center"
      :class="classes"
      :style="styles"
      v-bind="extraData"
      @click="onClick"
    >
      <slot>{{ $t('main.ui.button.text4') }}</slot>
    </div>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    to: {
      type: String,
      required: false,
      default: ''
    },
    blank: {
      type: Boolean,
      required: false,
      default: false
    },
    toParams: {
      type: Object,
      required: false,
      default: () => null
    },
    submit: {
      type: Boolean,
      required: false,
      default: false
    },
    shape: {
      type: String,
      required: false,
      default: 'full'
    },
    color: {
      type: String,
      required: false,
      default: 'exp-bg'
    },
    text: {
      type: String,
      required: false,
      default: 'white'
    },
    textColor: {
      type: String,
      required: false,
      default: ''
    },
    size: {
      type: String,
      required: false,
      default: ''
    },
    hover: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    minWidth: {
      type: String,
      required: false,
      default: ''
    },
    minHeight: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: ''
    },
    noWrap: {
      type: Boolean,
      required: false,
      default: false
    },
    withGradient: {
      type: Boolean,
      required: false,
      default: false
    },
    gradient: {
      type: String,
      required: false,
      default: ''
    },
    fontColor: {
      type: String,
      required: false,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: true
    },
    borderColor: {
      type: String,
      required: false,
      default: ''
    },
    fontSize: {
      type: String,
      required: false,
      default: ''
    },
    useNuxtLink: {
      type: Boolean,
      default: true
    },
    extraData: {
      type: Object,
      default: null
    },
    customId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('experience', ['getDesign', 'getMerch', 'getDefault']),
    showLink() {
      return this.hasLink && !this.submit;
    },
    showButton() {
      return !this.hasLink && this.submit;
    },
    showDiv() {
      return !this.hasLink && !this.submit;
    },
    hasLink() {
      return this.to || this.toParams;
    },
    isJavascript() {
      return this.to && this.to.startsWith('javascript:');
    },
    link() {
      return this.toParams ? this.toParams : this.to;
    },
    classes() {
      var result = 'vexels-button align-items-center justify-content-center';
      result += ` ${this.shape} ${!this.withGradient ? this.color : ''} ${this.size} custom-text-${
        this.text
      }`;
      if (this.borderColor) {
        result += ` ${this.borderColor}-color `;
      }
      if (!this.enabled) {
        result += ' disabled';
      }
      if (this.text == 'exp') {
        result += ' exp-font';
      }
      if (this.hover == 'exp') {
        result += ' exp-bg-hover hover-text-white';
      }
      if (this.hover == 'soft-gray') {
        result += ' soft-gray-hover';
      }
      if (this.hover == 'merch') {
        result += ' merch-bg-hover hover-text-white';
      }
      if (this.hover == 'design') {
        result += ' design-bg-hover hover-text-white';
      }
      if (this.hover == 'default') {
        result += ' default-bg-hover hover-text-white';
      }
      if (this.width == 'full') {
        result += ' w-100';
      }
      if (this.width == 'max') {
        result += ' w-max';
      }
      if (this.height == 'full') {
        result += ' h-100';
      }
      if (this.height == 'max') {
        result += ' h-max';
      }
      if (this.shape.includes('outline-hover')) {
        result += ' exp-border-hover exp-font-hover';
      }
      if (this.textColor === this.getMerch) {
        result += ' custom-text-merch';
      }
      if (this.textColor === this.getDesign) {
        result += ' custom-text-design';
      }
      if (this.textColor === this.getDefault) {
        result += ' custom-text-default';
      }
      return result;
    },
    styles() {
      return `
        ${this.withGradient ? `background: ${this.gradient};` : ''};
        ${this.fontColor ? `color: ${this.fontColor}` : ''};
        ${
          this.width != 'full' && this.width != 'max' && this.width != ''
            ? `width: ${this.width}px;`
            : ''
        }
        ${
          this.height != 'full' && this.height != 'max' && this.height != ''
            ? `height: ${this.height}px;`
            : ''
        }
        ${this.noWrap ? 'white-space: nowrap;' : ''};
        ${this.minWidth ? `min-width: ${this.minWidth}px;` : ''};
        ${this.minHeight ? `min-height: ${this.minHeight}px;` : ''};
        ${this.fontSize ? `font-size: ${this.fontSize}px;` : ''};
      `;
    }
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateProps();
      }
    }
  },
  methods: {
    onClick(e) {
      if (!this.enabled) {
        e.preventDefault();
      } else {
        this.$emit('click', e);
      }
      return true;
    },
    validateProps() {
      if (this.withGradient && this.gradient === '')
        console.error('Invalid prop: custom validator check failed for prop "gradient".');
      if (this.withGradient && this.fontColor === '')
        console.error('Invalid prop: custom validator check failed for prop "fontColor".');
    }
  }
};
</script>

<style lang="scss" scoped>
.vexels-button {
  /* COMMON STYLES */
  display: flex;
  font-size: 15px;
  cursor: pointer;
  border-radius: 23px;
  color: white;
  padding-right: 28px;
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: fit-content;
  border: var(--border, 1px solid $gray);
  &:hover {
    /* HOVER COMMON STYLES */
    filter: brightness(1.1);
    transition: 0.4s;
  }
  &:focus {
    outline: none;
  }

  &.disabled {
    background-color: #d2d2d2 !important;
    border-color: #d2d2d2 !important;
    cursor: not-allowed !important;
    &:hover {
      filter: none !important;
    }
  }

  /* SIZES */
  &.tiny {
    font-size: 11px;
    padding: 6px;
    &.disabled {
      background-color: white !important;
    }
  }
  &.small {
    font-size: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 15px;
    padding-left: 15px;
  }
  &.small-medium {
    padding: 7px 15px;
    border-radius: 20px;
    font-size: 13px;
  }
  &.medium {
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 23px;
    padding-left: 23px;
  }
  &.big {
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    /* default border radius to a big button */
    border-radius: 30px;
  }
  &.long {
    padding-right: 45px;
    padding-left: 45px;
  }
  &.w-max {
    width: max-content;
  }
  &.h-max {
    height: max-content;
  }

  /* TYPES */
  &.outline {
    border: 1px solid;
  }
  &.outline-transparent {
    background-color: transparent !important;
    color: var(--exp-color);
  }
  &.outline-transparent:hover {
    background-color: var(--exp-color) !important;
    border: 1px solid var(--exp-color) !important;
    color: #ffffff;
  }
  &.outline-hover:hover {
    background-color: white !important;
  }
  &.full {
    border: none;
  }
  &.square {
    border-radius: 3px;
  }
  &.neutral-blue-hover {
    background-color: transparent !important;
    border: 1px solid gray;
    color: gray;
  }

  &.neutral-blue-hover:hover {
    background-color: $defaultColor !important;
    color: white;
    border-color: $defaultColor;
  }

  &.neutral-black {
    border: 1px solid #000;
    color: #000;
  }

  &.neutral-gray {
    border: 1px solid gray;
    color: gray;
    filter: brightness(1);
  }

  &.neutral-white {
    border: 1px solid #fff;
    color: #fff;
  }

  &.blue-outline {
    background: transparent !important;
    border: 1px solid #2f88fc;
    color: #ffffff;
  }
  &.light-gray {
    color: gray;
    background-color: #f3f3f3;
  }
  &.light-gray:hover {
    background-color: #e5e5e5;
    filter: brightness(1);
  }
  &.soft-gray-hover:hover {
    background-color: #f3f3f3;
    filter: brightness(1);
  }

  &.no-brightness:hover {
    filter: brightness(1);
  }
  /* COLORS */
  &.green {
    background-color: #3cd699;
    border-color: #3cd699;
    &.no-brightness.disabled {
      background-color: #3cd699 !important;
      border-color: #3cd699 !important;
      opacity: 0.3;
    }
  }
  &.blue {
    background-color: $defaultColor;
    border-color: $defaultColor;
  }
  &.yellow {
    background-color: #ffcc00;
    border-color: #ffcc00;
  }
  &.red {
    background-color: #ff3b30;
    border-color: #ff3b30;
  }
  &.orange {
    background-color: #ff9000;
    border-color: #ff9000;
  }
  &.design-inverted-color {
    background-color: white;
    color: $defaultColor;
  }
  &.design-inverted-color:hover {
    background-color: $defaultColor;
    color: white;
  }
  &.design-color {
    background-color: $defaultColor;
    border-color: $defaultColor;
  }
  &.merch-color {
    background-color: $defaultColor;
    border-color: $defaultColor;
  }
  &.default-color {
    background-color: $defaultColor;
    border-color: $defaultColor;
  }
  &.white {
    background-color: white;
    border-color: white;
  }
  &.transparent {
    background-color: transparent;
  }
  &.custom-text-gray {
    color: $strongGray;
    border-color: $strongGray;
    &.disabled {
      opacity: 0.4;
      background-color: #e6e6e6 !important;
      border-color: #e2e2e2 !important;
    }
  }
  &.custom-text-design {
    color: $defaultColor !important;
    border-color: $defaultColor;
  }
  &.custom-text-default {
    color: $defaultColor !important;
    border-color: $defaultColor;
  }
  &.custom-text-merch {
    color: $defaultColor !important;
    border-color: $defaultColor;
  }
  &.custom-text-black {
    color: #000000;
    border-color: #000000;
  }
  &.custom-text-green {
    color: #3cd699;
    border-color: #3cd699;
  }
  &.hover-text-white:hover {
    color: white;
    border-color: white;
  }
  &.seasonal-black-pink:hover {
    background-color: $neonPink;
    border: 1px solid transparent;
  }
  /* HOVER */
  &.merch-bg-hover:hover {
    background-color: $defaultColor;
  }
  &.design-bg-hover:hover {
    background-color: $defaultColor;
  }
  &.default-bg-hover:hover {
    background-color: $defaultColor;
  }
}
.subtitle-container .vexels-button {
  @include sm {
    white-space: nowrap;
  }
}
.input-group-append .vexels-button {
  height: 100%;
  border: none;
}
.vx-custom-background .white {
  background-color: #3a3a3a;
  border-color: #3a3a3a;
  color: white;

  &:hover {
    border-color: transparent;
  }
}
</style>
