<template>
  <section
    class="plan-container-wrapper position-relative"
    :class="{
      'recommended-plan': isRecommended,
      'show-recommended-styles': isRecommended,
      alternative: alternativeLayout
    }"
  >
    <Shimmer :border-radius="borderRadiusShimmer" :loading="loading">
      <div class="h-100">
        <div class="badge-container">
          <div v-if="lifetime && selectedPlan.discount" class="discount-badge">
            -{{ selectedPlan.discount }}%
          </div>

          <div>
            <p
              v-if="isRecommended || (isBlackFriday && selectedPlan?.discount !== 0)"
              class="recommended-plan-header text-center"
              :class="{ 'black-friday': isBlackFriday }"
              :style="`color: ${prismicPropsObj.secondaryColor}`"
            >
              {{
                isRecommended && (!isBlackFriday || selectedPlan?.discount === 0)
                  ? $t('plans.common.cards.recommended')
                  : selectedPlan?.discount + '% OFF'
              }}
            </p>
          </div>
        </div>

        <div
          class="plan-container flex-column"
          :class="{ highlighted, 'black-friday': isBlackFriday }"
        >
          <!-- eslint-enable max-len -->
          <b-row>
            <b-col class="title" :style="`color: ${prismicPropsObj.primaryColor}`">
              <p>{{ selectedPlan.name }}</p>
              <p
                v-if="
                  selectedPlan.experienceType !== getDesign &&
                  selectedPlan.subtitle &&
                  !alternativeLayout
                "
                class="plan-subtitle"
              >
                {{ selectedPlan.subtitle }}
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="price-container" :class="{ 'no-switch': !showSwitch }">
              <b-row class="align-items-baseline flex-nowrap mb-2">
                <div class="price align-self-center mr-1">
                  {{ selectedPlan.currencySymbol }}
                </div>

                <div class="price">{{ priceBigNumber }}</div>

                <div class="price">{{ priceSmallNumber }}</div>

                <div v-show="showAnnualPlan" class="per-period">
                  {{ $t('plans.common.cards.perMonth') }}
                </div>
              </b-row>

              <b-row
                v-if="prismicPropsObj.showPreviousPrice"
                class="previous-cost"
                :style="{ '--borderColor': prismicPropsObj.primaryColor }"
              >
                <span>$</span><span>{{ previousPrice }}</span>
              </b-row>

              <b-row v-if="!isSingleLicense" class="billed">
                <span class="align-items-center d-flex justify-content-center text-uppercase">
                  <span
                    v-if="showAnnualPlan && !isMonthlyAnnualCommitment"
                    class="mr-1 font-weight-bold"
                  >
                    <del
                      >{{ selectedPlan.currencySymbol
                      }}{{ selectedPlan.originalCostWithoutDiscount }}</del
                    >
                  </span>
                  <span
                    v-if="showAnnualPlan && !isMonthlyAnnualCommitment"
                    class="font-weight-bold amount mr-1"
                  >
                    {{ selectedPlan.currencySymbol }}{{ selectedPlan.cost }}
                  </span>
                  {{ chargeDescription }}
                </span>
              </b-row>

              <b-row v-if="lifetime" class="billed">
                <span>
                  {{ $t('plans.common.cards.billedLifetime') }}
                </span>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="justify-content-center subscribe-container">
            <b-col class="button-container" cols="11">
              <Button v-bind="buttonProps" @click="subscribeClick">
                {{ buttonText }}
              </Button>
            </b-col>
          </b-row>

          <b-row v-if="isSingleLicense && isDesignPlan" class="justify-content-center">
            <Checkbox @input="extended = !extended">
              {{ $t('plans.common.cards.forMerchandiseUse') }}
            </Checkbox>
          </b-row>

          <b-row v-if="hasSpecs" class="mt-2 justify-content-center">
            <b-col cols="11" class="specs d-flex flex-column align-items-start">
              <div v-for="(s, i) in specs" :key="i" class="row mt-2">
                <b-col cols="1" class="align-items-center d-flex justify-content-center">
                  <Icon
                    :icon="s?.icon ?? 'check-icon'"
                    :width="alternativeLayout ? 11 : 16"
                    :height="alternativeLayout ? 11 : 16"
                    :color="tickIconColor"
                    :class="`${alternativeLayout && 'icon-spec'}`"
                    :exp-active="true"
                  />
                </b-col>

                <b-col class="text-left spec">
                  {{ s.title }}
                  <b-button
                    v-if="s.tooltip"
                    :id="`btn-tooltip-${selectedPlan.id}-${i}`"
                    class="btn-tooltip ml-1"
                  >
                    i
                  </b-button>
                </b-col>

                <b-tooltip
                  v-if="s.tooltip"
                  custom-class="custom-tooltip"
                  :target="`btn-tooltip-${selectedPlan.id}-${i}`"
                  placement="top"
                >
                  {{ s.tooltip }}
                </b-tooltip>
              </div>
            </b-col>
          </b-row>

          <b-row
            v-if="!alternativeLayout && prismicPropsObj.showBenefitsText"
            class="my-2 justify-content-center"
          >
            <b-col
              cols="11"
              class="specs cursor-pointer d-flex flex-column align-items-start"
              :style="`color: ${prismicPropsObj.primaryColor}`"
              @click="goToTable"
            >
              {{ $t('plans.common.cards.blackFridayBenefits') }}
            </b-col>
          </b-row>

          <b-row
            v-if="!alternativeLayout && selectedPlan.image"
            class="mt-4 justify-content-center"
          >
            <b-col cols="11">
              <img
                :src="selectedPlan.image"
                :alt="$t('plans.common.cards.PODlogosAlt')"
                class="mt-2 img-fluid filter-grayscale"
                loading="lazy"
                width="200"
                height="30"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </Shimmer>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from 'UI/Button';
import Checkbox from 'UI/Checkbox';
import Icon from 'UI/Icon';
import {
  getWindowScrollTop,
  getMonthlyAnnualCommitmentEntire,
  getMonthlyPriceEntire,
  getMonthlyPriceDecimal,
  getMonthlyAnnualCommitmentDecimal
} from '~/utils/common.js';
import Shimmer from '@/components/PurchaseFlow/Plans/Shimmer';

export default {
  components: {
    Button,
    Checkbox,
    Icon,
    Shimmer
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    valueSwitch: {
      type: Boolean,
      required: false,
      default: true
    },
    individualVectorPlans: {
      type: Boolean,
      default: false
    },
    planIndex: {
      type: Number,
      required: false,
      default: 0
    },
    plansLength: {
      type: Number,
      required: false,
      default: 3
    },
    plan: {
      type: [Object, Number],
      required: true
    },
    isFreeUser: {
      type: Boolean,
      required: true
    },
    seeMoreLink: {
      type: String,
      required: false,
      default: ''
    },
    lifetime: {
      type: Boolean,
      default: false,
      required: false
    },
    isDesignPlan: {
      type: Boolean,
      default: false
    },
    prismicProps: {
      type: Object,
      default: () => ({})
    },
    customPlan: {
      type: Boolean,
      default: false,
      required: false
    },
    uId: {
      type: Number,
      required: true
    },
    alternativeLayout: {
      type: Boolean,
      default: false,
      required: false
    },
    specsAmount: {
      type: Number,
      required: false,
      default: 4
    }
  },
  data() {
    return {
      shownSpecsAmount: this.specsAmount,
      extended: false,
      showDetail: false,
      defaultColor: '#5388f2',
      isPreview: false,
      isBlackFriday: false
    };
  },
  computed: {
    ...mapGetters('experience', ['getExperience', 'getDesign']),
    buttonProps() {
      let props = {
        enabled: this.buttonEnabled,
        width: 'full',
        class: 'w-100',
        withGradient: this.isRecommended || this.lifetime || this.alternativeLayout,
        ...(this.isRecommended || this.lifetime
          ? {
              gradient:
                this.prismicPropsObj.buttonGradientColor1 &&
                this.prismicPropsObj.buttonGradientColor2
                  ? // eslint-disable-next-line max-len
                    `transparent linear-gradient(85deg, ${this.prismicPropsObj.buttonGradientColor1} 0%, ${this.prismicPropsObj.buttonGradientColor2} 100%) 0% 0% no-repeat padding-box;`
                  : this.defaultColor
            }
          : {
              shape: 'outline',
              color: 'transparent',
              text: this.buttonTextColor,
              fontColor: this.prismicPropsObj.tertiaryColor,
              ...(this.alternativeLayout
                ? {
                    gradient:
                      // eslint-disable-next-line max-len
                      'transparent linear-gradient(255deg, #5287F2 0%, #A27BFF 100%) 0% 0% no-repeat padding-box;',
                    fontColor: this.prismicPropsObj.tertiaryColor || this.defaultColor
                  }
                : {})
            })
      };
      return props;
    },
    prismicPropsObj() {
      return this.prismicProps || {};
    },
    previousPrice() {
      return this.prismicPropsObj.previousPrice[this.planIndex];
    },
    specs() {
      return this.selectedPlan?.specs || [];
    },
    specsLength() {
      return this.specs.length;
    },
    isRecommended() {
      return this.planIndex == 1 && !this.lifetime && this.plansLength === 3;
    },
    highlighted() {
      return this.selectedPlan ? this.selectedPlan.highlighted : false;
    },
    showAnnualPlan() {
      return this.valueSwitch && this.plan.annual;
    },
    selectedPlan() {
      if (this.plan) {
        if (this.showAnnualPlan) {
          return this.plan.annual;
        } else if (!this.valueSwitch && this.plan.monthly) {
          return this.plan.monthly;
        } else if (this.extended && this.plan.extended) {
          return this.plan.extended;
        } else if (!this.extended && this.plan.simple) {
          return this.plan.simple;
        } else if (this.lifetime || this.customPlan) {
          return this.plan;
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
    showSwitch() {
      if (this.isSingleLicense || this.lifetime) {
        return false;
      } else {
        if (this.plan) {
          return this.plan.monthly && this.plan.annual;
        } else {
          return false;
        }
      }
    },
    hasSpecs() {
      return this.specs && this.specsLength > 0;
    },
    buttonEnabled() {
      return this.selectedPlan.upgrade || this.isSingleLicense;
    },
    buttonTextColor() {
      return !this.alternativeLayout
        ? this.buttonEnabled
          ? this.prismicPropsObj.tertiaryColor || 'black'
          : 'white'
        : 'white';
    },
    recommendedButtonTextColor() {
      return this.prismicPropsObj.buttonGradientColor1
        ? this.prismicPropsObj.buttonGradientColor1
        : '';
    },
    buttonText() {
      if (this.isSingleLicense) {
        return this.$t('plans.common.cards.getNow');
      } else {
        if (this.$auth.loggedIn && !this.isFreeUser && this.selectedPlan.upgrade) {
          return this.$t('plans.common.cards.upgrade');
        } else if (this.selectedPlan.hasFreeTrial) {
          return this.$t('plans.common.cards.try');
        } else if (this.individualVectorPlans) {
          return this.$t('plans.common.cards.getNow');
        } else {
          return this.$t('plans.common.cards.suscribe');
        }
      }
    },
    isSingleLicense() {
      return this.plan && (this.plan.simple || this.plan.extended) ? true : false;
    },
    shownPrice() {
      var price = this.selectedPlan.cost;

      const noDecimals = this.isAnnually
        ? getMonthlyPriceEntire(price)
        : this.isMonthlyAnnualCommitment
        ? getMonthlyAnnualCommitmentEntire(price)
        : price;

      const decimals = this.isAnnually
        ? getMonthlyPriceDecimal(price)
        : this.isMonthlyAnnualCommitment
        ? getMonthlyAnnualCommitmentDecimal(price)
        : null;

      if (decimals) {
        return [noDecimals, `.${decimals}`];
      } else {
        return [noDecimals];
      }
    },
    priceBigNumber() {
      return this.shownPrice[0];
    },
    priceSmallNumber() {
      return this.shownPrice.length == 2 ? this.shownPrice[1] : '';
    },
    tickIconColor() {
      return this.isRecommended ? '#FFF' : '#545454';
    },
    borderRadiusShimmer() {
      const alternativeBorders = ['0', '15px', '0 15px 15px 0'];
      return this.alternativeLayout ? alternativeBorders[this.planIndex] : '15px';
    },
    chargeDescription() {
      if (this.selectedPlan.chargeFrecuency == 'MONTHLY_ANNUAL_COMMITMENT') {
        return this.$t('plans.common.cards.billedMonthlyAnnualCommitment');
      } else if (this.selectedPlan.chargeFrecuency == 'MONTHLY') {
        return this.$t('plans.common.cards.billedMonthly');
      } else if (this.selectedPlan.chargeFrecuency == 'ANNUALLY') {
        return this.$t('plans.common.cards.billedAnnually');
      } else if (this.selectedPlan.chargeFrecuency == 'LIFETIME') {
        return this.$t('plans.common.cards.billedLifetime');
      } else {
        return '';
      }
    },
    isMonthlyAnnualCommitment() {
      return this.selectedPlan.chargeFrecuency == 'MONTHLY_ANNUAL_COMMITMENT';
    },
    isAnnually() {
      return this.selectedPlan.chargeFrecuency == 'ANNUALLY';
    }
  },
  methods: {
    subscribeClick() {
      this.fireGTMBeginCheckout(this.selectedPlan);
      this.$emit('subscribe-click', this.selectedPlan);
      this.$dataLayerPush('click_top_subscribe', {
        subscribe: {
          idPlan: this.selectedPlan.id
        }
      });
    },
    fireGTMBeginCheckout(data) {
      if (!data) return;
      this.$dataLayerPush('begin_checkout', {
        beginCheckout: data
      });
    },
    async goToTable() {
      const element = document.getElementById('compare-plans');
      if (element) {
        const hideMenuSeasonal = await this.$cookies.get('vx.hideMenuSeasonal');
        const headerOffset = hideMenuSeasonal ? 120 : 260;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + getWindowScrollTop() - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
  margin: 10px;
  .tooltip-inner {
    padding: 10px;
  }
}
.plan-container-wrapper {
  border-radius: 15px;
  background-color: #f9f9f9;
  display: flex;
  min-height: 30rem;
  flex-direction: column;
  align-items: center;
  flex: 0 0 30%;
  &.show-recommended-styles {
    width: 31%;
  }
  @include lg {
    flex: 0 0 45%;
    width: 45% !important;
  }
  @include md {
    flex: 0 0 100%;
    width: 100% !important;
  }
  &.recommended-plan {
    background: #211e1c;
    margin: 0;
    .title,
    .billed,
    .specs,
    .price,
    .per-period,
    .details {
      color: white;
    }

    .plan-container {
      &.black-friday::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        background: linear-gradient(90deg, #3f80f1, #d946ef);
        z-index: -1;
        border-radius: 15px;
      }
    }
  }

  .recommended-plan-header {
    color: #211e1c;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0 0;
    background-color: #fff;
    padding: 6px 14px;
    border-radius: 38px;
    display: inline-block;
    border: solid 1px #211e1c;

    &.black-friday {
      color: white;
      background: linear-gradient(90deg, #9966f0, #d946ef);
      border-color: transparent;
    }
  }

  .discount-badge {
    background-color: var(--exp-color);
    color: white;
    padding: 3px 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    z-index: 1;
  }

  .spec {
    padding-left: 0;
  }

  &.alternative {
    min-height: 26rem;
    margin: 0;
    box-shadow: none;
    min-width: 280px;
    @include lg {
      width: 100%;
      flex: unset;
    }
    .icon-spec {
      border-radius: 6px;
      padding: 4px;
    }
    .spec {
      padding-left: 12px;
      font-size: 13px;
    }
    .recommended-plan-header {
      display: none;
    }
    .plan-container {
      .subscribe-container {
        margin: 20px 0;
        .button-container {
          width: 100%;
          padding: 0;
        }
      }
    }
    &.recommended-plan {
      .plan-container {
        padding: 1.5rem 2rem 2rem;
        .title {
          padding-top: 15px;
        }
      }
    }
    &.show-recommended-styles {
      position: relative;
      background: transparent linear-gradient(209deg, #5287f2 0%, #a180ff 100%) 0% 0% no-repeat
        padding-box;
      margin-top: -15px;
      margin-bottom: -15px;
      .title,
      .billed,
      .specs,
      .price,
      .per-period,
      .details {
        color: white;
      }
    }
  }
}

.plan-container {
  position: relative;
  display: flex;
  min-height: 100%;
  height: max-content;
  padding: 40px;
  border-radius: 5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.1);
  width: 100%;
  &.highlighted {
    color: rgba(32, 185, 181, 0.4);
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #211e1c;
    p {
      margin-bottom: 8px;
    }
    .plan-subtitle {
      font-size: 12px;
      text-transform: none;
      color: $gray;
      margin-bottom: 0px;
    }
  }
  .billed {
    .amount {
      font-size: 16px;
      font-weight: 600;
    }
    line-height: 110%;
    letter-spacing: -0.0208333em;
    color: #211e1c;
    font-size: 16px;
  }
  .price-container {
    height: 115px;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .price {
    font-size: 50px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.0208333em;
    color: #211e1c;
  }
  .price.small {
    font-size: 37px;
    align-self: baseline;
  }
  .per-period {
    color: #211e1c;
  }
  .specs {
    color: #545454;
    font-size: 14px;
    .btn-tooltip {
      background-color: #5388f2;
      border: none;
      height: 20px;
      width: 20px;
      border-radius: 200%;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
    }
  }
  .details {
    color: #545454;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 2px solid;
  }
  .no-switch {
    margin-top: 0px;
  }
  .subscribe-container {
    margin-top: 25px;
    margin-bottom: 25px;
    .button-container {
      align-self: flex-end;
    }
  }
  .previous-cost {
    color: white;
    position: relative;
    height: 100%;
    padding: 0 5px;
    &:before {
      border-bottom: 2px solid var(--borderColor);
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 50%;
    }
  }
}

.filter-grayscale {
  filter: grayscale(1);
}

.badge-container {
  position: absolute;
  width: 100%;
  left: 0;
  top: -40px;
  display: flex;
  justify-content: center;
}
</style>
