<template>
  <div class="container-download-options">
    <UiButton
      color="green"
      class="download-btn"
      min-width="164"
      width="inherit"
      min-height="45"
      height="45"
      :to="localePath({ name: 'profile-my-designs' })"
      :extra-data="{ event: '' }"
      @click="clickMyLicences"
    >
      {{ $t('preview.download.main.afterDownloadLicence') }}
    </UiButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UiButton from 'UI/Button';

export default {
  components: {
    UiButton
  },
  computed: {
    ...mapGetters('vectors', ['actualVector']),
    ...mapGetters('user', ['isVectorLiked']),
    likeIcon() {
      return this.isLiked ? 'like-full' : 'like-empty';
    },
    isLiked() {
      return this.isVectorLiked(parseInt(this.actualVector.id));
    }
  },
  methods: {
    clickMyLicences() {
      this.$bvModal.hide('preview-vector-modal');
      this.$router.push(this.localePath({ name: 'profile-my-designs' }));
    }
  }
};
</script>

<style lang="scss" scoped>
.like-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 38px;
  justify-content: center;
  margin-top: 2px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 38px;
  z-index: 2;
  @include lg {
    display: none;
  }
  .icon-container {
    top: 3px;
  }
}
.download-btn {
  align-items: center;
  display: flex;
  text-align: center;
  @include sm {
    width: 100%;
  }
}
.container-download-options {
  align-items: center;
  display: flex;
  gap: 16px;
  height: max-content;
  justify-content: flex-end;
  margin-left: auto;
  @include md {
    width: 100%;
  }
}
</style>
