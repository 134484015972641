<template>
  <div class="button-text-container">
    <b-button
      type="submit"
      variant="primary"
      :class="{
        'success-change': success,
        'invalid-password': formFailed || error,
        formFailed,
        error
      }"
      class="mx-auto submit-button"
      :disabled="formFailed || Boolean(error) || success"
    >
      <Icon v-show="success" :width="25" :height="25" icon="tick" color="white" />
      <b-spinner
        v-show="loading && !error"
        small
        variant="light"
        label="Loading..."
        class="ml-2"
      ></b-spinner>
      <span v-show="!success && !formFailed && !loading && !error"> {{ submitText }}</span>
      <span v-show="formFailed || error" class="failed-icon">!</span>
    </b-button>
    <div class="text-response">
      <p v-if="responseText">
        {{ responseText }}
      </p>
    </div>
  </div>
</template>

<script>
import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  props: {
    error: {
      type: String,
      required: true
    },
    success: {
      type: Boolean,
      required: true
    },
    successText: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    formFailed: {
      type: Boolean,
      required: false,
      default: false
    },
    formFailedText: {
      type: String,
      required: false,
      default: ''
    },
    submitText: {
      type: String,
      required: true
    }
  },

  computed: {
    responseText() {
      return this.success
        ? this.successText
        : this.formFailed
        ? this.formFailedText
        : this.error
        ? this.error
        : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.submit-button {
  width: 100%;
  border-radius: 30px;
  height: 45px;
  background: $defaultColor;
  border: 1px solid $defaultColor;
  margin-top: 35px;
  transition: all 0.5s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-button.success-change {
  opacity: 1;
  width: 45px;
  background: #5ddc90 !important;
  border: 1px solid #5ddc90;
  transition: all 0.5s linear;
}
.submit-button.invalid-password {
  width: 45px;
  background: #ff5a5f !important;
  border: 1px solid #ff5a5f;
  transition: all 0.5s linear;
}
.text-response {
  height: 60px;
  position: absolute;
  width: 100%;
  color: #c9c9c9;
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
}
.button-text-container {
  min-height: 80px;
  position: relative;
}
</style>
