<template>
  <div class="title">
    <h1 class="text">
      {{ title }}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('vectors', ['actualVector']),
    ...mapGetters('downloads', ['getDownloaded']),
    title() {
      return this.getDownloaded
        ? this.$t('preview.download.main.afterDownloadTitle')
        : this.actualVector.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: inline-flex;
  padding-right: 20px;
  .text {
    font-size: 17px;
    margin-bottom: 0;
    width: max-content;
  }
}
@media all and (max-width: 1300px) {
  .title .text {
    font-size: 15px;
  }
}
@media all and (max-width: 767px) {
  .title {
    display: block;
    display: inline-flex;
    height: auto;
    margin-bottom: 10px;
    margin-left: 0;
    margin-left: 0;
    max-width: 100%;
    padding-top: 0;
    padding: 0;
    width: auto;
  }
  .title .text {
    width: auto;
  }
}
@media all and (max-width: 1000px) {
  .title {
    margin-left: 0px;
    max-width: 100%;
  }
}
</style>
