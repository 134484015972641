<template>
  <div class="user-avatar-container" @click="avatarClick">
    <div class="img-container-profile">
      <picture>
        <source :srcset="buildSrcWebP(userAvatarSource)" type="image/webp" />
        <img
          :src="userAvatarSource"
          :alt="$t('profile.main.dashboard.profilePicAlt')"
          :style="imageStyles"
          @error="replaceByDefault"
        />
      </picture>
    </div>
    <slot name="hover"></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    }
  },
  computed: {
    userAvatarSource() {
      return this.$auth.user.userAvatar;
    },
    imageStyles() {
      return this.width > 0 ? { width: this.width + 'px', height: this.width + 'px' } : {};
    }
  },
  methods: {
    avatarClick() {
      this.$emit('click');
    },
    replaceByDefault(e) {
      e.target.src = '/img/default-profile-thumbnail.png';
    },
    buildSrcWebP(link) {
      return link + '?fmt=webp&w=50';
    }
  }
};
</script>

<style lang="scss" scoped>
.user-avatar-container {
  position: relative;
}
.img-container-profile {
  height: 100%;
  img {
    border-radius: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @include lg {
    margin: 0;
  }
}
.ribbon {
  position: absolute;
  max-width: 32px;
  max-height: 32px;
  min-width: 14px;
  min-height: 14px;
  height: 40%;
  width: 40%;
  right: -3px;
  bottom: -3px;
  background: url('https://cdn.vexels.com/img/subscribed.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
