<template>
  <div class="w-100 d-flex justify-content-center align-items-center my-5">
    <div class="text-center">
      <p class="vexellent-text">
        {{ $t('preview.freeDownload.downloadComplete.vexellent') }}
        <img
          src="https://cdn.vexels.com/img/group-2534.svg"
          :alt="$t('preview.freeDownload.downloadComplete.downloadCompleteAlt')"
          class="mx-1"
        />
        {{ $t('preview.freeDownload.downloadComplete.textBeforeLink') }}
        <nuxt-link
          :to="$urlBuilder('plans')"
          class="cursor-pointer"
          @click.native="$bvModal.hide('preview-vector-modal')"
        >
          {{ $t('preview.freeDownload.downloadComplete.textLink') }}
        </nuxt-link>
        {{ $t('preview.freeDownload.downloadComplete.textAfterLink') }}
      </p>
      <p class="use-attr-link">
        {{ $t('preview.freeDownload.downloadComplete.useAttributionTitle') }}
      </p>
      <b-input-group class="mt-3">
        <img
          src="https://cdn.vexels.com/img/group-2500.svg"
          :alt="$t('preview.freeDownload.downloadComplete.attachIconAlt')"
          class="img-link position-absolute"
        />
        <b-form-input class="pl-5 vector-link-input" :value="vectorLink" disabled></b-form-input>
        <b-input-group-append>
          <b-button
            id="copy-link-btn"
            v-clipboard:copy="vectorLink"
            v-clipboard:success="onCopy"
            class="copy-btn position-absolute px-4 h-100"
            @mouseleave="disableCopy()"
          >
            {{ $t('preview.freeDownload.downloadComplete.copyAttributionLink') }}
          </b-button>
          <b-tooltip
            :disabled.sync="tooltipDisable"
            :show.sync="Linkcopied"
            target="copy-link-btn"
            placement="bottom"
          >
            {{ $t('preview.freeDownload.downloadComplete.copied') }}
          </b-tooltip>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      Linkcopied: false,
      tooltipDisable: true
    };
  },
  computed: {
    vectorLink() {
      return `<a target="_blank" href="${window.location.href}">
      ${this.$t('preview.freeDownload.downloadComplete.attributionLinkText')}
      </a>`;
    }
  },
  methods: {
    onCopy() {
      this.Linkcopied = true;
      this.tooltipDisable = false;
    },
    disableCopy() {
      this.Linkcopied = false;
      this.tooltipDisable = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.vexellent-text {
  font-size: 25px;
  margin-bottom: 30px;
  img {
    width: 30px;
  }
}
.use-attr-link {
  font-size: 13px;
}
.img-link {
  width: 17px;
  left: 20px;
  z-index: 2;
  top: 0;
  bottom: 0;
  margin: auto;
}
.vector-link-input {
  color: #c4c4c4;
  border: #b9b9b9 1px solid !important;
}
.copy-btn {
  border-radius: 20px !important;
  right: 0;
  background-color: $defaultColor !important;
  border-color: $defaultColor !important;
  font-size: 13px;
  &:hover {
    background-color: $defaultColor !important;
    border-color: $defaultColor !important;
  }
}
</style>
