<template>
  <div class="purchase-header">
    <span class="title">{{ $t('purchase.payment.common.paymentMethod') }}</span>
    <Steps class="d-sm-flex d-none" :status="status" />
  </div>
</template>

<script>
import Steps from '@/components/PurchaseFlow/Header/Steps';
export default {
  components: {
    Steps
  },
  props: {
    status: {
      type: String,
      required: false,
      default: 'plans'
    }
  }
};
</script>
<style lang="scss" scoped>
.purchase-header {
  border-radius: 5px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  .title {
    margin-right: 40px;
    font-size: 28px;
    @include lg {
      margin: 0 0 10px 0;
    }
    @include sm {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  @include lg {
    flex-direction: column;
  }
  @include md {
    padding: 0;
    align-items: flex-start;
  }
}
</style>
