<template>
  <nav class="navbar wrapper navbar-light navbar-expand p-0">
    <ul class="navbar-nav">
      <li
        v-for="(
          { title, subMenu, eventItem, link = '', linkSuscriptor = '', pill }, index
        ) in menuData"
        :key="index"
        class="nav-bar-options nav-item b-nav-dropdown dropdown option-item"
      >
        <button class="nav-link vx-dropdown" :class="{ 'dropdown-toggle': subMenu }">
          <a
            v-if="link || linkSuscriptor"
            :href="isSuscriptor ? linkSuscriptor : link"
            target="_blank"
            class="nav-link"
            >{{ $t(title) }}
          </a>
          <span v-else>
            {{ $t(title) }}
          </span>
          <img v-if="pill?.src && pill?.alt" :src="pill.src" :alt="pill.alt" />
        </button>
        <ul v-if="subMenu" class="dropdown-menu">
          <li v-for="(option, index) in subMenu" :key="index" class="nav-dropdow-item">
            <ResolveDataLink
              link-class-name="dropdown-item"
              :event-item="eventItem"
              :item="option"
              text-color="#fff"
              text-size="0.875rem"
            />
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import menuData from 'Components/Header/MenuMobile/Modal/menuData.json';
import ResolveDataLink from 'Components/Header/MenuMobile/Modal/ResolveDataLink';

export default {
  components: { ResolveDataLink },
  data() {
    return {
      menuData
    };
  },
  computed: {
    requestLink() {
      const link = this.$auth.loggedIn
        ? this.localePath('profile-my-requests')
        : this.$urlBuilder('landing', { uid: 'design-requests' });
      return link;
    },
    isSuscriptor() {
      return this.$auth.loggedIn && this.$auth.user.idPlan != 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.vx-dropdown {
  background: none;
  border: none;
  outline: none;

  display: flex;
  gap: 0.2em;
  align-items: center;
}
.dropdown-menu {
  font-size: 13px !important;
  border-radius: 0.5rem;
  padding: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.dropdown:hover .dropdown-menu {
  display: grid;
  gap: 0.25rem;
}
.dropdown-menu:hover {
  display: grid;
  gap: 0.25rem;
}
.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  height: 10px;
  width: 100%;
}
.navbar-nav {
  margin: 0 auto;
}
.nav-bar-options {
  padding: 0 10px 0 0;
  @include md {
    padding: 0 10px 0 0 !important;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: $white;
}
.option-item {
  font-size: 12px;
  margin: auto;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  margin-left: 10px;
  margin-right: 10px;
}
</style>

<style lang="scss">
.dropdown-toggle::after {
  color: $white;
  top: 1px;
  position: relative;
}
.nav-dropdow-item > a {
  color: $white;
  @include xl {
    font-size: 13px !important;
  }
}
.option-item > a > span {
  color: $white;
  margin-right: 5px;
}
.option-item > ul {
  margin-top: 9px;
  background-color: #1d1d1d;
  transition: tabindex 0.3s;
}
.dropdown-item {
  padding: 0.5rem;
  padding-right: 3rem;
  border-radius: 0.375rem;
}
.dropdown-item:hover {
  color: $white;
  background-color: #2c2c2c;
}
</style>
