import { DESIGN_IDEA_MAX, DESIGN_IDEA_MIN } from '~/settings.json';

const setObjectValues = ({ validated, anchorId, value, key }) => {
  const res = { anchorId };
  if (key !== undefined) {
    res.key = key;
  }
  if (validated !== undefined) {
    res.validated = validated;
  }
  if (value !== undefined) {
    res.value = value;
  }
  return res;
};

export const mutations = {
  SET_ACCEPTED_TERMS(state, data) {
    state.acceptedTerms = setObjectValues({ ...state.acceptedTerms, ...data });
  },
  SET_DRAFT_REQUEST_ID(state, value) {
    state.draftRequestId = value;
  },
  SET_DESCRIPTION_IDEA(state, data) {
    state.descriptionIdea = setObjectValues({ ...state.descriptionIdea, ...data });
  },
  SET_SELECTED_STYLE(state, data) {
    state.selectedStyle = setObjectValues({ ...state.selectedStyle, ...data });
  },
  SET_STYLES(state, data) {
    state.styles = data;
  },
  SET_QUOTE(state, data) {
    state.quote = setObjectValues({ ...state.quote, ...data });
  },
  SET_EDITABLE_TEXT(state, data) {
    state.editableText = setObjectValues({ ...state.editableText, ...data });
  },
  SET_SELECTED_PRODUCT(state, data) {
    state.selectedProduct = setObjectValues({ ...state.selectedProduct, ...data });
  },
  SET_SELECTED_SUB_PRODUCT(state, data) {
    state.selectedSubProduct = setObjectValues({ ...state.selectedSubProduct, ...data });
  },
  SET_PRODUCTS(state, data) {
    state.products = data;
  },
  SET_FORMAT(state, data) {
    state.format = setObjectValues({ ...state.format, ...data });
  },
  SET_ERRORS(state, { key, message }) {
    state.errors = [...state.errors, { key, message }];
  },
  RESET_ERRORS(state) {
    state.errors = [];
  },
  RESET_ERROR_BY_KEY(state, key) {
    const result = state.errors.filter((error) => error.key !== key);
    state.errors = result;
  },
  SET_REFERENCES_IMAGES(state, data) {
    state.referencesImages = setObjectValues({
      ...state.referencesImages,
      ...data
    });
  },
  SET_REQUEST_SENT(state, value) {
    state.requestSent = value;
  },
  SET_LOADING_REQUEST(state, value) {
    state.loadingRequest = value;
  },
  SET_LOADING_DRAFT(state, value) {
    state.loadingDraft = value;
  }
};

export const getters = {
  getAcceptedTerms(state) {
    return state.acceptedTerms.validated;
  },
  getStyles(state) {
    return state.styles;
  },
  getSelectedStyle(state) {
    return state.selectedStyle;
  },
  getSelectedStyleValues(state) {
    return state.styles.find((style) => style.id === state.selectedStyle.value);
  },
  getQuote(state) {
    return state.quote;
  },
  getEditableText(state) {
    return state.editableText;
  },
  getProducts(state) {
    return state.products;
  },
  getSelectedProduct(state) {
    return state.selectedProduct;
  },
  getSelectedProductValues(state) {
    return state.products.find((product) => product.id === state.selectedProduct.value);
  },
  getSubProducts(_, getters) {
    const selectedProduct = getters.getSelectedProductValues;
    return selectedProduct ? selectedProduct.subproducts : [];
  },
  getSelectedSubProduct(_, getters) {
    const selectedSubProduct = getters.getSubProducts.find(
      (subProduct) => subProduct.id === getters.getSelectedSubProductValue
    );
    return selectedSubProduct;
  },
  getSelectedSubProductValue(state) {
    return state.selectedSubProduct.value;
  },
  getHasErrorMessageByKey: (state) => (key) => {
    const error = state.errors.find((error) => error.key === key);
    return error ? error.message : '';
  },
  getDescriptionIdea(state) {
    return state.descriptionIdea;
  },
  getFormat(state) {
    return state.format;
  },
  getReferencesImages(state) {
    return state.referencesImages;
  },
  getValidateIdea(state) {
    const ideaLength = state.descriptionIdea.value.length;
    const isTooLong = ideaLength > DESIGN_IDEA_MAX;
    const isTooShort = ideaLength < DESIGN_IDEA_MIN;
    return { isTooLong, isTooShort, isValid: !isTooLong && !isTooShort };
  }
};

export const actions = {
  async INIT_SELECTED_PRODUCT({ commit, getters, dispatch }, data) {
    const resolveValues = (data) => {
      if (!data.value) {
        commit('SET_SELECTED_PRODUCT', data);
        commit('SET_SELECTED_SUB_PRODUCT', { value: null });
        return;
      }
      commit('SET_SELECTED_PRODUCT', data);
      const productData = getters.getSelectedProductValues;
      if (!productData.subproducts.length) {
        commit('SET_SELECTED_SUB_PRODUCT', { value: null });
        return;
      }
      commit('SET_SELECTED_SUB_PRODUCT', {
        value: productData.subproducts[0].id
      });
    };
    resolveValues(data);
    await dispatch('EDIT_DRAFT_REQUEST', 'selectedProduct');
    dispatch('EDIT_DRAFT_REQUEST', 'selectedSubProduct');
  },
  async LOAD_STYLES({ commit }) {
    try {
      const data = { page: 1, perPage: 50 };
      const response = await this.$axios.post(
        '/style/?is_available_for_request=1&order=request',
        data
      );
      const styles = response.data.data.styles ? response.data.data.styles : [];
      commit('SET_STYLES', styles);
    } catch (error) {
      console.error('Design Request | LOAD_STYLES:', error);
    }
  },

  async LOAD_PRODUCTS({ commit }) {
    try {
      const response = await this.$axiosV2.get('/design-request/product?limit=20');
      const products = response.data.data.results ? response.data.data.results : [];
      commit('SET_PRODUCTS', products);
    } catch (error) {
      console.error('Design Request | LOAD_PRODUCTS:', error);
    }
  },
  async CREATE_DRAFT_REQUEST({ commit }) {
    try {
      const {
        data: {
          error,
          data: { id }
        },
        status
      } = await this.$axiosV2.post('design-request/design-request');
      if (status === 201 && !error) {
        commit('SET_DRAFT_REQUEST_ID', id);
      } else {
        console.error('Design Request | CREATE_DRAFT_REQUEST:', error);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async CLEAR_FORM({ commit }) {
    commit('SET_ACCEPTED_TERMS', { validated: false });
    commit('SET_DESCRIPTION_IDEA', { value: '' });
    commit('SET_SELECTED_STYLE', { validated: false, value: '' });
    commit('SET_QUOTE', { validated: true, value: '' });
    commit('SET_EDITABLE_TEXT', { validated: true, value: '' });
    commit('SET_SELECTED_PRODUCT', { validated: false, value: '' });
    commit('SET_FORMAT', { validated: true, value: '' });
    commit('SET_REFERENCES_IMAGES', { validated: false, value: [] });
  },
  async EDIT_DRAFT_REQUEST({ state, commit }, id) {
    try {
      if (state.draftRequestId) {
        commit('SET_LOADING_DRAFT', true);
        const value = state[id].value === 0 ? null : state[id].value;
        const data = {
          [state[id].key]: value
        };
        commit('RESET_ERROR_BY_KEY', id);
        const {
          data: { error },
          status
        } = await this.$axiosV2.put(`design-request/design-request/${state.draftRequestId}`, data);
        if (status !== 201 && error) {
          console.error('Design Request | EDIT_DRAFT_REQUEST:', error);
        }
        commit('SET_LOADING_DRAFT', false);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async CONFIRM_REQUEST({ state, dispatch, commit }) {
    try {
      if (state.draftRequestId) {
        commit('SET_LOADING_REQUEST', true);
        commit('SET_REQUEST_SENT', false);
        const data = { status: 'NEW' };
        const {
          data: { error },
          status
        } = await this.$axiosV2.put(`design-request/design-request/${state.draftRequestId}`, data);
        if (status !== 201 && error) {
          console.error('Design Request | CONFIRM_REQUEST:', error);
        } else {
          dispatch('CLEAR_FORM', true);
          commit('SET_REQUEST_SENT', true);
        }
        commit('SET_LOADING_REQUEST', false);
      }
    } catch (error) {
      console.error(error);
    }
  },
  VALIDATE_QUOTE({ state, commit }) {
    const quote = state.quote.value;
    const validated = !quote || quote.length < 200;
    if (!validated) {
      commit('SET_ERRORS', {
        key: 'quote',
        message: 'main.profile.myRequests.quoteTooLong'
      });
    }
    return validated;
  },
  VALIDATE_STYLE({ state, commit }) {
    const validated = state.selectedStyle.validated;
    if (!validated) {
      commit('SET_ERRORS', {
        key: 'selectedStyle',
        message: 'main.profile.myRequests.missingField'
      });
    }
    return validated;
  },
  VALIDATE_DESCRIPTION_IDEA({ commit, getters }) {
    const { isTooLong, isValid } = getters.getValidateIdea;
    if (!isValid) {
      const message = isTooLong
        ? 'main.profile.myRequests.descriptionTooLong'
        : 'main.profile.myRequests.descriptionTooShort';
      commit('SET_ERRORS', { key: 'descriptionIdea', message });
    }
    return isValid;
  },
  async VALIDATE_FIELDS({ state, commit, dispatch }) {
    commit('RESET_ERRORS');
    let fieldsKeys = [
      'selectedProduct',
      'descriptionIdea',
      'referencesImages',
      'format',
      'quote',
      'editableText',
      'acceptedTerms'
    ];
    let validated = true;
    for (let key of fieldsKeys) {
      if (!state[key].validated) {
        validated = false;
        commit('SET_ERRORS', { key, message: 'main.profile.myRequests.missingField' });
      }
    }
    const validQuote = await dispatch('VALIDATE_QUOTE');
    const validStyle = await dispatch('VALIDATE_STYLE');
    const validDescription = await dispatch('VALIDATE_DESCRIPTION_IDEA');
    validated = validated && validQuote && validStyle && validDescription;
    if (!validated) {
      const key = state.errors[0].key;
      return state[key].anchorId;
    }
    return undefined;
  },
  async SEND_REFERENCE_IMAGE({ state, commit }, reference) {
    try {
      let data = null;
      let headers = {
        'Content-Type': 'application/json'
      };
      if (reference.file) {
        data = new FormData();
        data.append('image', reference.file);
        headers = {
          'Content-Type': 'multipart/form-data'
        };
      } else if (reference.url) {
        data = {
          link: reference.url,
          is_link: true
        };
      } else {
        return;
      }
      commit('SET_LOADING_DRAFT', true);
      const apiUrl = `design-request/design-request/${state.draftRequestId}/reference`;
      const {
        status,
        data: {
          data: { id }
        }
      } = await this.$axiosV2.post(apiUrl, data, { headers });
      commit('SET_LOADING_DRAFT', false);
      if (status !== 201) {
        throw new Error('SEND_REFERENCE_IMAGE');
      } else {
        return id;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async REMOVE_REFERENCE_IMAGE({ state, commit }, id) {
    try {
      const apiUrl = `design-request/design-request/${state.draftRequestId}/reference/${id}`;
      commit('SET_LOADING_DRAFT', true);
      const { status } = await this.$axiosV2.delete(apiUrl);
      commit('SET_LOADING_DRAFT', false);
      if (status !== 200) {
        throw new Error('REMOVE_REFERENCE_IMAGE');
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const state = () => ({
  draftRequestId: null,
  acceptedTerms: {
    validated: false
  },
  descriptionIdea: {
    value: '',
    validated: true,
    key: 'user_idea'
  },
  selectedStyle: {
    validated: false,
    key: 'style'
  },
  styles: [],
  quote: {
    value: '',
    validated: true,
    key: 'quote'
  },
  editableText: {
    validated: true,
    value: true,
    key: 'is_quote_editable'
  },
  selectedProduct: {
    validated: false,
    key: 'product'
  },
  selectedSubProduct: {
    anchorId: '',
    key: 'sub_product',
    value: null
  },
  products: [],
  format: {
    value: '',
    validated: true,
    key: 'format'
  },
  errors: [],
  referencesImages: {
    validated: false,
    value: {}
  },
  requestSent: false,
  loadingRequest: false,
  loadingDraft: false
});
