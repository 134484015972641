<template>
  <div v-show="showModal" class="popup-overlay" @click="overlayClick">
    <div class="popup-container">
      <div class="popup-content">
        <div class="popup-content-inner">
          <div class="popup-column">
            <div class="popup-text">
              <div class="popup-title">{{ title }}</div>
              <div class="popup-description">
                {{ description }}
              </div>
              <div class="popup-subscribe">
                {{ suscribeText }}
              </div>
              <button class="popup-close" @click="closeModal">
                {{ $t('main.purchaseFlow.popUpFreeTrial.text4') }}
              </button>
            </div>
          </div>
          <div class="popup-image">
            <img loading="lazy" src="https://cdn.vexels.com/img/free-trial.png" class="popup-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapState('payments', ['freeTrialUsed', 'isFreeTrialPlan']),
    isSuscriptor() {
      return this.$auth.loggedIn && this.$auth.idPlan != 1;
    },
    title() {
      return this.$t('main.purchaseFlow.popUpFreeTrial.text1');
    },
    description() {
      return this.isSuscriptor
        ? this.$t('main.purchaseFlow.popUpFreeTrial.text5')
        : this.$t('main.purchaseFlow.popUpFreeTrial.text2');
    },
    suscribeText() {
      return this.isSuscriptor
        ? this.$t('main.purchaseFlow.popUpFreeTrial.text6')
        : this.$t('main.purchaseFlow.popUpFreeTrial.text3');
    }
  },
  watch: {
    freeTrialUsed() {
      this.showModal = this.freeTrialUsed && this.isFreeTrialPlan;
    }
  },
  destroyed() {
    this.showModal = false;
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    overlayClick(e) {
      if (e.target.className === 'overlay') this.closeModal();
    }
  }
};
</script>

<style style="scss" scoped>
.popup-overlay {
  height: 100%;
  width: 100%;
  background-color: #8080804f;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: white;
  max-width: 80%;
  max-height: 80%;
  height: 390px;
  width: 800px;
  position: relative;
  overflow: hidden;
  font-family: Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.popup-content {
  margin-left: 42px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.popup-content-inner {
  display: flex;
}

.popup-column {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: calc(25% - 10px);
  margin-left: 0px;
}

.popup-text {
  margin-top: 25%;
  display: flex;
  flex-direction: column;
}

.popup-title {
  color: #747474;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  width: 100%;
  max-width: 81px;
  margin-left: 1px;
  align-self: start;
  display: flex;
  flex-direction: column;
}

.popup-description {
  color: #747474;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  max-width: 139px;
  margin-top: 18px;
  align-self: start;
  display: flex;
  flex-direction: column;
}

.popup-subscribe {
  color: #747474;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  margin-top: 20px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.popup-close {
  border-radius: 20px;
  background: #ef2af3;
  width: 100%;
  max-width: 112px;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 11px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border: none;
}

.popup-image {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: calc(79% - 10px);
  margin-left: 20px;
}

.popup-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

@media (max-width: 1024px) {
  .popup-container {
    height: auto;
    width: 350px;
    margin: 50% auto;
  }
  .popup-text {
    margin-top: auto;
  }
  .popup-image {
    display: none;
  }
  .popup-content {
    align-items: center;
    margin: 50px;
  }
  .popup-column {
    width: 100%;
  }
}
</style>
