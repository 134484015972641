<template>
  <div
    id="nav-image-preview"
    ref="nav-image-preview"
    class="nav-image-preview-box"
    :class="{ 'is-svg': actualVector.isSvg }"
  >
    <b-img
      :src="actualVector.list"
      :alt="actualVector.name"
      class="image"
      loading="lazy"
      :class="{ 'is-downloaded': getDownloaded || showPurchaseFlow }"
    ></b-img>
    <div v-if="getDownloaded" class="check-iso">
      <Icon color="white" :width="20" :height="20" icon="tick" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon';

export default {
  components: {
    Icon
  },
  props: {
    showImage: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('vectors', ['actualVector', 'showPurchaseFlow']),
    ...mapGetters('downloads', ['getDownloaded'])
  },
  watch: {
    showImage(val) {
      this.setShowNavPreview(val);
    }
  },
  methods: {
    async setShowNavPreview(val) {
      const imgPreview = this.$refs['nav-image-preview'];
      imgPreview.style.display = 'block';
      let imgWidth = imgPreview.clientWidth;
      if (val) {
        imgPreview.style.marginLeft = '0px';
      } else {
        imgPreview.style.marginLeft = `-${imgWidth}px`;
        imgPreview.style.display = 'none';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#nav-image-preview {
  transition: all 0.3s;
}
.nav-image-preview-box {
  align-items: center;
  display: flex;
  display: none;
  justify-content: center;
  margin-left: -350px;
  position: relative;
  width: fit-content;
  .image {
    height: 65px;
    width: auto;
    &.is-downloaded {
      border-radius: 15px 0 0 0;
      pointer-events: none;
    }
  }
  .check-iso {
    align-items: center;
    background-color: #45d17b;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    width: 32px;
  }
  &.is-svg {
    padding-left: 7px;
  }
}

@media all and (max-width: 576px) {
  .nav-image-preview-box {
    padding-top: 10px !important;
  }
}
</style>
