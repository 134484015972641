export const mutations = {
  SET_LANG(state, lang) {
    state.lang = lang;
  }
};

export const actions = {
  SET_LANG({ commit }, lang) {
    commit('SET_LANG', lang);
  }
};

export const state = () => ({
  lang: 'en'
});
