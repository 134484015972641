<template>
  <button :aria-label="ariaLabel" @click="$emit('click')">
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    ariaLabel: {
      type: String,
      default: '',
      required: true
    }
  }
};
</script>
<style lang="css" scoped>
button {
  display: grid;
  place-content: center;
  background-color: #000000b3;
  aspect-ratio: 1/1;
  border-radius: 50%;
  color: white;
  border: none;
  font-size: 20px;
  width: 2em;
}
</style>
