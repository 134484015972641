<template>
  <b-col class="stripe-container">
    <b-row class="mx-4">
      <b-col>
        <img
          src="https://cdn.vexels.com/img/master-card.png"
          :alt="$t('purchase.payment.common.masterCardAlt')"
          class="mt-3 mx-2 cc-type-logo"
          loading="lazy"
        />
        <img
          src="https://cdn.vexels.com/img/visa.png"
          :alt="$t('purchase.payment.common.visaAlt')"
          class="mt-3 mx-2 cc-type-logo"
          loading="lazy"
        />
        <img
          src="https://cdn.vexels.com/img/american-express.png"
          :alt="$t('purchase.payment.common.americanExpressAlt')"
          class="mt-3 mx-2 cc-type-logo"
          loading="lazy"
        />
        <img
          src="https://cdn.vexels.com/img/discover.png"
          :alt="$t('purchase.payment.common.discoverAlt')"
          class="mt-3 mx-2 cc-type-logo"
          loading="lazy"
        />
      </b-col>
    </b-row>
    <b-row class="mx-4">
      <b-col>
        {{ $t('purchase.stripe.form.titleForm') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <form
          ref="formStripe"
          :action="`${$config.API_URL}stripe/create-checkout-session`"
          method="post"
        >
          <input type="hidden" name="token" :value="token" />
          <input type="hidden" name="lookup_key" :value="openPlan.id" />
          <input type="hidden" name="vector_id" :value="actualVectorId" />
          <input type="hidden" name="download_url" :value="urlDownload" />
          <input type="hidden" name="affiliate_click_id" :value="$cookies.get('vx.aff')" />
        </form>
      </b-col>
    </b-row>
    <b-row class="mx-4">
      <b-col>
        <b-row>
          <b-col>
            <span>{{ $t('purchase.stripe.form.amount') }}:</span>
            <span class="data-text">USD {{ openPlan.originalCost }}{{ periodAv }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span>{{ $t('purchase.stripe.form.plan') }}:</span>
            <span class="data-text">{{ openPlan.name }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    default: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('plans', ['openPlan']),
    ...mapState('vectors', ['actualVector', 'actualVectorId']),
    ...mapGetters('auth', ['token']),
    urlDownload() {
      return this.actualVector?.urlDownload || '';
    },
    periodAv() {
      if (this.openPlan.chargeFrecuency == 'ANNUALLY') {
        return '/year';
      } else if (
        this.openPlan.chargeFrecuency == 'MONTHLY' ||
        this.openPlan.chargeFrecuency == 'MONTHLY_ANNUAL_COMMITMENT'
      ) {
        return '/mo';
      } else {
        return '';
      }
    }
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.setBuyFunction();
      }
    }
  },
  mounted() {
    if (this.default) {
      this.setBuyFunction();
    }
  },
  methods: {
    submitFormStripe() {
      this.$refs.formStripe.submit();
    },
    setBuyFunction() {
      this.$store.commit('payments/SET_BUY_NOW_FUNCTION', this.submitFormStripe);
    }
  }
};
</script>

<style lang="scss" scoped>
.stripe-container {
  color: #bbbbbb;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.data-text {
  font-weight: 500;
}
.cc-type-logo {
  height: 30px;
}
</style>
