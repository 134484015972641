export default function ({ redirect, $cookies, store }) {
  const strategy = $cookies.get('vx.strategy');
  if (!strategy) {
    return redirect('/login');
  }

  const token = $cookies.get(`vx.token.${strategy}`);
  if (!token) {
    return redirect('/login');
  }

  store.commit('auth/SET_STRATEGY', strategy);
  store.commit('auth/SET_TOKEN', token);
}
