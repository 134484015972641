export const dimensions = [
  { id: 0, name: 'All', path: 'merch-designs', experience: 'merch' },
  { id: 1, name: 'Merch Vectors', path: 'merch-vectors', experience: 'merch' },
  { id: 2, name: 'PSD Templates', path: 'merch-psd', experience: 'merch' },
  { id: 3, name: 'PNGs For Merch', path: 'merch-png', experience: 'merch' },

  { id: 0, name: 'All', path: 'graphics' },

  { id: 0, name: 'All', path: 'design-graphics', experience: 'design' },
  { id: 1, name: 'Vectors', path: 'free-vectors', experience: 'design' },
  { id: 2, name: 'PSD', path: 'psd', experience: 'design' },
  { id: 3, name: 'PNG', path: 'svg-png', experience: 'design' },
  { id: 5, name: 'Icons', path: 'icon', experience: 'design' },
  { id: 4, name: 'Logos', path: 'logos', experience: 'design' },

  { id: 6, name: 'Editable', path: 'editables' },

  { name: 'FeaturedVectors', path: 'featured-vectors' },
  { name: 'FeaturedGraphics', path: 'design-featured-graphics', experience: 'design' },
  { name: 'FeaturedDesigns', path: 'merch-featured-designs', experience: 'merch' },
  { name: 'OurGraphics', path: 'our-graphics' },
  { name: 'LatestDesigns', path: 'merch-latest-designs', experience: 'merch' },
  { name: 'LatestVectors', path: 'design-latest-vectors', experience: 'design' },
  { name: 'TrendingVectors', path: 'trending-vectors' },
  { name: 'TrendingDesigns', path: 'merch-trending-designs', experience: 'merch' },
  { name: 'TrendingGraphics', path: 'design-trending-graphics', experience: 'design' }
];

export const mutations = {
  SET_ACTUAL_DIMENSION(state, actualDimension) {
    state.actualDimension = actualDimension;
  },
  SET_PAGINATION_DIMENSION(state, dim) {
    state.paginationDimension = dim;
  }
};

export const getters = {
  getPaginationDimension(state) {
    return state.paginationDimension;
  },
  getActualDimension(state) {
    return state.actualDimension;
  },
  getActualDimensionName(state) {
    return state.actualDimension.name;
  },
  getActualDimensionToUrl(state) {
    return state.actualDimension.path;
  },
  getDimensionAll() {
    return dimensions.filter((dim) => dim.path == 'merch-designs')[0].name;
  },
  getFilterDimensionsByExperience: () => (experience) => {
    //  filter when the dimension has id &&
    // ((is not sent experience && the dimension is All && the dimension has not experience) ||
    //  (is not sent experience && dimension is not All && the dimension has experience) ||
    //  is sent experience and is equal to the dimension experience)

    return dimensions.filter(
      (el) =>
        el.id != undefined &&
        ((!experience && el.id === 0 && !el.experience) ||
          (!experience && el.id != 0 && el.experience) ||
          experience == el.experience)
    );
  },
  getDimensionsByExperience() {
    return dimensions.filter((el) => el.experience);
  },
  getDimensionIdByPath: () => (dimPath) => {
    return dimensions.find((dim) => dim.path === dimPath).id;
  },
  getDimensionPathById: () => (dimId, experience) => {
    return dimensions.find((dim) => dim.id === dimId && dim.experience === experience)?.path;
  },
  getDimensionByName:
    (state, getters, rootState, rootGetters) =>
    (dimName, experience, forceMerch = true) => {
      experience = experience || (forceMerch ? rootGetters['experience/getMerch'] : undefined);
      return dimName === 'All'
        ? dimensions.find((dim) => dim.name === dimName && dim.experience === experience)
        : dimensions.find((dim) => dim.name === dimName);
    }
};

export const actions = {
  ACTUAL_DIMENSION({ state, commit, getters, rootGetters }, dim) {
    let actualDimension = dimensions.filter((dim) => dim.path == 'merch-designs')[0];
    const exp = rootGetters['experience/getMerch'];
    if (state.dimensionOptions.includes(dim)) {
      actualDimension = getters.getDimensionByName(dim, exp);
    } else if (state.dimensionUrls.includes(dim)) {
      actualDimension = getters.getDimensionByName(
        state.dimensionOptions[state.dimensionUrls.indexOf(dim)],
        exp
      );
    } else {
      console.error('Dimensions | The value selected is not valid for options of dimensions');
    }

    commit('SET_ACTUAL_DIMENSION', actualDimension);
  }
};

export const state = () => ({
  actualDimension: dimensions.filter((dim) => dim.path == 'merch-designs')[0],
  // This dimensions are the available options but for visual purpose other dimensions are used
  dimensionOptions: dimensions.filter((el) => el.id != undefined).map((el) => el.name),
  dimensionUrls: dimensions.map((el) => el.path),
  dimensionIds: dimensions.map((el) => el.id),
  paginationDimension: null
});
