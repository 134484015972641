<template>
  <div class="effect-container" :class="shownMockup" @mouseenter="mouseEnter">
    <picture>
      <source :srcset="`${data.image}?fmt=webp&h=250`" type="image/webp" />
      <source :srcset="data.image" type="image/png" />
      <img :src="data.image" :alt="data.name" loading="lazy" />
    </picture>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      shownMockup: '',
      timeDefault: 500, // Original image shown time
      timeMugShown: 1200, // Mug image shown time
      timeTshirtShown: 1200, // Tshirt image shown time
      timeCuadrille: 800, // Cuadrille background shown time
      timeBlack: 1500, // Black background shwon time
      timeWhite: 1500, // White background shwon time
      timeouts: []
    };
  },
  computed: {
    ...mapGetters('experience', ['getExperience'])
  },
  methods: {
    mouseEnter() {
      this.timeouts.forEach((to) => clearTimeout(to));
      this.timeouts = [];
      if (this.getExperience == 'design') {
        this.shownMockup = 'cuadrille';
        const blackTO = setTimeout(() => {
          this.shownMockup = 'black';
          const whiteTO = setTimeout(() => {
            this.shownMockup = 'white';
            const clearTO = setTimeout(() => {
              this.shownMockup = 'cuadrille';
            }, this.timeBlack);
            this.timeouts.push(clearTO);
          }, this.timeWhite);
          this.timeouts.push(whiteTO);
        }, this.timeCuadrille);
        this.timeouts.push(blackTO);
      } else {
        this.shownMockup = '';
        const tshirtTO = setTimeout(() => {
          this.shownMockup = 'tshirt';
          const mugTO = setTimeout(() => {
            this.shownMockup = 'mug';
            const clearTO = setTimeout(() => {
              this.shownMockup = '';
            }, this.timeTshirtShown);
            this.timeouts.push(clearTO);
          }, this.timeMugShown);
          this.timeouts.push(mugTO);
        }, this.timeDefault);
        this.timeouts.push(tshirtTO);
      }
    }
  }
};
</script>

<style lang="scss">
.effect-container {
  z-index: 50 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-size: cover;
  background-color: white;
  background-position: center;
  background-size: cover;
  transition: opacity 0.5s, background 0.5s;
  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.effect-container.cuadrille {
  background-image: url('https://cdn.vexels.com/img/cuadrille.png');
}

.effect-container.black {
  background-color: black;
}

.effect-container.white {
  background-color: white;
}

.effect-container.mug {
  background-image: url('https://cdn.vexels.com/img/mockup-mug.png');
  background-color: white;
  img {
    width: 35%;
    height: auto;
    margin: 32% 32% 0 33%;
    transition: margin 0.5s, width 0.5s, height 0.5s;
  }
}

.effect-container.tshirt {
  background-image: url('https://cdn.vexels.com/img/mockup-t-shirt.png');
  background-color: white;
  img {
    width: 35%;
    height: auto;
    margin: 22% 30% 0 35%;
    transition: margin 0.5s, width 0.5s, height 0.5s;
  }
}

.overlay:hover .effect-container {
  opacity: 1;
}
</style>
