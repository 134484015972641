<template>
  <ResolveTypeLink
    :internal-link="internalLink"
    :href="getHref()"
    :value="value"
    :text-color="textColor"
    :text-size="textSize"
    :link-class-name="linkClassName"
    :pill="pill"
    @click="handleClick"
  />
</template>

<script>
import ResolveTypeLink from 'Components/Header/MenuMobile/Modal/ResolveTypeLink';

export default {
  components: { ResolveTypeLink },
  props: {
    item: {
      type: Object,
      required: true
    },
    modalName: {
      type: String,
      default: ''
    },
    eventItem: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      required: true
    },
    textSize: {
      type: String,
      required: true
    },
    linkClassName: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      internalLink: null,
      checkUserLogged: null,
      href: null,
      value: null,
      linkUrlBuilder: null,
      linkUrlBuilderForUserLogged: null,
      hrefSuscriptor: null,
      pill: null
    };
  },
  created() {
    const {
      internalLink,
      checkUserLogged,
      href,
      hrefSuscriptor = '',
      value,
      linkUrlBuilder,
      linkUrlBuilderForUserLogged,
      pill = ''
    } = this.item;
    this.internalLink = internalLink;
    this.checkUserLogged = checkUserLogged;
    this.href = href;
    this.value = value;
    this.linkUrlBuilder = linkUrlBuilder;
    this.linkUrlBuilderForUserLogged = linkUrlBuilderForUserLogged;
    this.hrefSuscriptor = hrefSuscriptor;
    this.pill = pill;
  },
  methods: {
    getHref() {
      if (this.internalLink && this.checkUserLogged && this.$auth.loggedIn) {
        return this.useUrlBuilder(this.linkUrlBuilderForUserLogged);
      }
      if (this.internalLink) {
        return this.useUrlBuilder(this.linkUrlBuilder);
      }
      if (this.$auth.loggedIn && this.$auth.user.idPlan != 1 && this.hrefSuscriptor) {
        return this.hrefSuscriptor;
      }
      return this.href[this.$i18n.locale] ? this.href[this.$i18n.locale] : this.href;
    },
    useUrlBuilder({ type, id, uid, url, path, keyword, slug }) {
      const href = this.$urlBuilder(type, {
        id,
        uid,
        url,
        path,
        keyword,
        slug
      });
      return href;
    },
    gaEvent({ eventItem, url }) {
      this.$dataLayerPush('top_menu_click', {
        menuClick: { clickUrl: url, clickItem: eventItem }
      });
    },
    handleClick({ href }) {
      this.$bvModal.hide(this.modalName);
      this.gaEvent({ eventItem: this.eventItem, url: href });
    }
  }
};
</script>
