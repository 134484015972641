<template>
  <div class="checkbox" :class="{ checked }">
    <div v-if="checked" class="inner-circle" />
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: block;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 2px solid #707070;
  margin: 10px;
  &.checked {
    border: 2px solid #9b86f7;
  }
  .inner-circle {
    display: block;
    height: 12px;
    width: 12px;
    margin: 3px;
    background-color: #9b86f7;
    border-radius: 50%;
    display: inline-block;
  }
}
</style>
